import React, { useState, useCallback } from 'react';

import { Button, Typography, Divider, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import RangePickerLocale from './RangePickerLocale';

const DATE_START = '1900-01-01T00:00:00.000Z';
const DATE_END = '3000-01-01T00:00:00.000Z';
const EMPTY_DATE_RANGE = [null, null];
const FilterDateWrapper = ({
  title,
  searchTitle,
  resetTitle,
  setSelectedKeys,
  confirm,
  clearFilters,
}) => {
  const [value, setValue] = useState(EMPTY_DATE_RANGE);

  const onChange = useCallback(
    ([start, end]) => {
      if (!start && !end) {
        setValue(EMPTY_DATE_RANGE);
        setSelectedKeys(null);
      } else {
        setValue([start, end]);
        setSelectedKeys([
          {
            from: start ? start.toJSON() : DATE_START,
            to: end ? end.toJSON() : DATE_END,
          },
        ]);
      }
    },
    [setSelectedKeys],
  );

  const onClearFilters = useCallback(() => {
    clearFilters();
    setValue(EMPTY_DATE_RANGE);
  }, [clearFilters]);

  return (
    <div style={{ padding: 16, paddingTop: 16 }}>
      <Typography.Paragraph>{title}</Typography.Paragraph>
      <RangePickerLocale
        allowClear={false}
        allowEmpty={[true, true]}
        format="DD.MM.YYYY"
        style={{ width: '18rem' }}
        value={value}
        onChange={onChange}
      />
      <Divider />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 120 }}
        >
          {searchTitle}
        </Button>
        <Button onClick={onClearFilters} size="small" style={{ width: 120 }}>
          {resetTitle}
        </Button>
      </Space>
    </div>
  );
};

export default FilterDateWrapper;
