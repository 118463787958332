import React, { useState } from 'react';

import { Form, Input } from 'antd';

const Uploader = ({ props }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { names, handleFileUploaded } = props;
  const onFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
    handleFileUploaded(e.target.files[0]);
  };

  return (
    <Form.Item name={names}>
      <Input
        className="input_container"
        type="file"
        value={selectedFile}
        onChange={(e) => onFileUpload(e)}
      />
    </Form.Item>
  );
};

export default Uploader;
