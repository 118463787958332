import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Descriptions, Typography } from 'antd';
import { FormContext } from '../../hooks/useForm';

const TotalPrice = () => {
  const { t } = useTranslation();
  const form = useContext(FormContext);

  const { value } = form;
  const { products } = value;

  const totalPrice = useMemo(() => {
    return products.reduce((acc, product) => {
      const productPersonPrice = product.productPersons.reduce(
        (personAcc, productPerson) => personAcc + productPerson.price / 100,
        0,
      );
      return acc + productPersonPrice;
    }, 0.0);
  }, [products]);

  return (
    <Descriptions bordered style={{ padding: 40, paddingTop: 0 }}>
      <Descriptions.Item label={t('entity.orders.totalPrice')}>
        <Typography.Title style={{ textAlign: 'right', padding: 0, margin: 0 }}>
          {totalPrice.toFixed(2)}
        </Typography.Title>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default TotalPrice;
