import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Table, Tooltip } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { Default } from '../../../layouts';
import Column from '../../../helpers/columns';
import PageTitle from '../../../components/PageTitle';

import AuthContext, { hasPermission } from '../../../components/auth';

const Taxonomies = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const content = useMemo(
    () => [
      {
        name: t('entity.taxonomy.company._plural'),
        description: t('entity.taxonomy.company._description'),
        link: 'companies',
      },
      {
        name: t('entity.taxonomy.retailer._plural'),
        description: t('entity.taxonomy.retailer._description'),
        link: 'retailers',
      },
      {
        name: t('entity.taxonomy.userType._plural'),
        description: t('entity.taxonomy.userType._description'),
        link: 'user-types',
      },
      {
        name: t('entity.taxonomy.docType._plural'),
        description: t('entity.taxonomy.docType._description'),
        link: 'doc-types',
      },
      {
        name: t('entity.taxonomy.poses._plural'),
        description: t('entity.taxonomy.poses._description'),
        link: 'poses',
      },
      {
        name: t('entity.taxonomy.stationType._plural'),
        description: t('entity.taxonomy.stationType._description'),
        link: 'station-types',
      },
      {
        name: t('entity.taxonomy.station._plural'),
        description: t('entity.taxonomy.station._description'),
        link: 'stations',
      },
      {
        name: t('entity.taxonomy.routeGroup._plural'),
        description: t('entity.taxonomy.routeGroup._description'),
        link: 'route-groups',
      },
      {
        name: t('entity.taxonomy.route._plural'),
        description: t('entity.taxonomy.route._description'),
        link: 'routes',
      },
      {
        name: t('entity.taxonomy.tvaRate._plural'),
        description: t('entity.taxonomy.tvaRate._description'),
        link: 'tva-rates',
      },
      {
        name: t('entity.taxonomy.holiday._plural'),
        description: t('entity.taxonomy.holiday._description'),
        link: 'holidays',
      },
      {
        name: t('entity.taxonomy.validators.parameters._plural'),
        description: t('entity.taxonomy.validators.parameters._description'),
        link: 'validators/parameters',
      },
      {
        name: t('entity.taxonomy.articleType._plural'),
        description: t('entity.taxonomy.articleType._description'),
        link: 'article-types',
      },

      {
        name: t('entity.taxonomy.transportType._plural'),
        description: t('entity.taxonomy.transportType._description'),
        link: 'transport/types',
      },
      {
        name: t('entity.taxonomy.transportBrand._plural'),
        description: t('entity.taxonomy.transportBrand._description'),
        link: 'transport/brands',
      },
      {
        name: t('entity.taxonomy.transportDepo._plural'),
        description: t('entity.taxonomy.transportDepo._description'),
        link: 'transport/depos',
      },
      {
        name: t('entity.taxonomy.transportCostCenter._plural'),
        description: t('entity.taxonomy.transportCostCenter._description'),
        link: 'transport/cost-centers',
      },
      {
        name: t('entity.taxonomy.transportUnit._plural'),
        description: t('entity.taxonomy.transportUnit._description'),
        link: 'transport/units',
      },
      {
        name: t('entity.taxonomy.equipmentBrand._plural'),
        description: t('entity.taxonomy.equipmentBrand._description'),
        link: 'equipment/brands',
      },
      {
        name: t('entity.taxonomy.sector._plural'),
        description: t('entity.taxonomy.sector._description'),
        link: 'sectors',
      },
      ...(hasPermission(permissions, [
        'SYS_SUPERUSER',
        'TAXONOMIES_PERMISSION',
        'SYS_VALIDATOR_EDIT',
      ])
        ? [
            {
              name: t('entity.taxonomy.validators._plural'),
              description: t('entity.taxonomy.validators._description'),
              link: 'validators',
            },
          ]
        : []),
      {
        name: t('entity.taxonomy.currency._plural'),
        description: t('entity.taxonomy.currency._description'),
        link: 'currencies',
      },
      {
        name: t('entity.taxonomy.tokenSubtype._plural'),
        description: t('entity.taxonomy.tokenSubtype._description'),
        link: 'token/subtypes',
      },
      // {
      //   name: t('entity.taxonomy.zones._plural'),
      //   description: t('entity.taxonomy.zones._description'),
      //   link: 'zones',
      // },
      {
        name: t('entity.taxonomy.blacklistReason._plural'),
        description: t('entity.taxonomy.blacklistReason._description'),
        link: 'blacklist-reasons',
      },
      {
        name: t('entity.taxonomy.cardTemplate._plural'),
        description: t('entity.taxonomy.cardTemplate._description'),
        link: 'card/templates',
      },
      {
        name: t('entity.taxonomy.institutions._plural'),
        description: t('entity.taxonomy.institutions._description'),
        link: 'institutions',
      },
      {
        name: t('entity.taxonomy.countries._plural'),
        description: t('entity.taxonomy.countries._description'),
        link: 'countries',
      },
      {
        name: t('entity.taxonomy.system-parameters._plural'),
        description: t('entity.taxonomy.countries._description'),
        link: 'system-parameters',
      },
    ],
    [permissions, t],
  );
  const [filteredContent, setFilteredContent] = useState(content);

  const filterSearchContent = useCallback(
    (filter) => {
      const normalizedFilter = Object.entries(filter)
        .filter((entry) => entry[0] && entry[1] && Array.isArray(entry[1]))
        .map((el) => ({
          name: el[0],
          value: el[1][0],
        }));

      if (normalizedFilter) {
        setFilteredContent(
          content.filter((item) =>
            normalizedFilter.every((field) =>
              item[field.name]
                .toUpperCase()
                .includes(field.value.toUpperCase()),
            ),
          ),
        );
      }
    },
    [content],
  );
  content.sort((a, b) => a.name.localeCompare(b.name));
  const handleChange = useCallback(
    (_pagination, filter, { order, field }) => {
      content.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        order === 'ascend'
          ? a[field].localeCompare(b[field])
          : order === 'descend'
          ? b[field].localeCompare(a[field])
          : 0,
      );

      filterSearchContent(filter);
    },
    [content, filterSearchContent],
  );

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.name'), {
        filter: true,
        width: 300,
        fixed: 'left',
      }),
      Column.text('description', t('entity.taxonomy.description'), {
        filter: true,
      }),
      Column.actions(t('entity.taxonomy.view'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title={t('actions.edit')}>
            <Button
              shape="circle"
              type="link"
              icon={<MenuOutlined />}
              onClick={() => history.push(`/admin/taxonomies/${record.link}`)}
            />
          </Tooltip>
        </span>
      )),
    ],
    [t, history],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy._plural')} />
      <Table
        columns={columns}
        rowKey="name"
        dataSource={filteredContent}
        onChange={handleChange}
      />
    </Default>
  );
};

export default Taxonomies;
