import React, { useMemo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { FormContext } from '../../../hooks/useForm';
import FormWrapper from '../../FormWrapper';
import Column from '../../../helpers/columns';

const ProductPricesGrid = ({ productCode }) => {
  const { t } = useTranslation();

  const form = useContext(FormContext);

  const [isDynamicPriceEnabledString] = useState(window._env_.DYNAMIC_PRICE);
  const isDynamicPriceEnabled = isDynamicPriceEnabledString === 'true';

  const { value, input, errorMessages, custom, setValue, checkbox } = form;
  const { prices, deleted } = value;

  const columns = useMemo(
    () => [
      {
        title: t('entity.offers.product.prices.price'),
        key: 'price',
        dataIndex: 'price',
        render: (_, record, index) => (
          <FormWrapper.Number
            props={{
              ...input(`prices[${index}].price`),
              min: 0,
              step: 1,
              disabled: deleted,
            }}
          />
        ),
      },
      {
        title: t('entity.offers.product.prices.reportPrice'),
        key: 'reportPrice',
        dataIndex: 'reportPrice',
        render: (_, record, index) => (
          <FormWrapper.Number
            props={{
              ...input(`prices[${index}].reportPrice`),
              min: 0,
              step: 1,
              disabled: deleted,
            }}
          />
        ),
      },
      {
        title: t('entity.offers.product.prices.dateStart'),
        key: 'dateStart',
        dataIndex: 'dateStart',
        render: (_, record, index) => (
          <FormWrapper.DateTimePicker
            props={{
              ...custom(`prices[${index}].dateStart`),
              disabled: deleted,
            }}
          />
        ),
      },
      ...(productCode !== null &&
      productCode === 'MIFARE_1K' &&
      isDynamicPriceEnabled
        ? [
            {
              title: t('entity.offers.product.prices.firstPurchasePrice'),
              key: 'firstPurchasePrice',
              dataIndex: 'firstPurchasePrice',
              render: (_, record, index) => (
                <FormWrapper.CheckBox
                  props={{
                    ...checkbox(`prices[${index}].firstPurchasePrice`),
                    disabled: deleted,
                  }}
                />
              ),
            },
          ]
        : []),

      Column.actions(t('table.actions'), (record, index) => (
        <Button
          shape="circle"
          type="link"
          icon={<DeleteOutlined />}
          disabled={deleted}
          onClick={() =>
            setValue(
              'prices',
              prices.filter((p, idx) => idx !== index),
            )
          }
        />
      )),
    ],
    [
      custom,
      deleted,
      input,
      prices,
      setValue,
      t,
      checkbox,
      productCode,
      isDynamicPriceEnabled,
    ],
  );

  return (
    <>
      <FormWrapper.ErrorMessage message={t(errorMessages?.prices)} />
      {!value.deleted && (
        <Button
          type="primary"
          onClick={() =>
            setValue('prices', [...prices, { key: Math.random() }])
          }
          icon={<PlusOutlined />}
        >
          {t('actions.add')}
        </Button>
      )}
      <Table
        columns={columns}
        rowKey={(row) => row.dateStart || row.key}
        dataSource={prices}
      />
    </>
  );
};

export default ProductPricesGrid;
