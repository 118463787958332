import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditCurrencyForm from '../../../../components/currencies/EditCurrencyForm';

import {
  create,
  findOne,
  update,
} from '../../../../services/taxonomies/currencies';

const initialCurrency = { deleted: false, currencyDefault: false };

const EditCurrency = () => {
  const [currency, handleSubmit, handleCancel] = useEditPage({
    initial: initialCurrency,
    existent: findOne,
    goBackPath: '/admin/taxonomies/currencies',

    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {currency !== undefined && (
        <EditCurrencyForm
          currency={currency}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditCurrency;
