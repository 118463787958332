import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';

import PageTitle from '../../../../components/PageTitle';
import EditEntityButton from '../../../../components/buttons/EditEntityButton';

import Column from '../../../../helpers/columns';
import { getOrderedProducts } from '../../../../services/offers/products';
import { ProductCategories } from '../../../../components/offers/OffersConstants';

const ProductPrioritiesList = () => {
  const { t } = useTranslation();
  const { pagination, content, handleChange } = useDatasource(
    getOrderedProducts,
    { pagination: { pageSize: 100 } },
  );
  const columns = useMemo(
    () => [
      Column.label('id', t('entity.offers.product.id'), {
        width: 100,
        sort: false,
      }),
      Column.text('name', t('entity.offers.product.name._'), {
        filter: true,
        sort: false,
      }),
      Column.dictionary(
        'category',
        t('entity.offers.product.category._'),
        ProductCategories,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) => t(`entity.offers.product.category.${entry.id}`),
        },
      ),
      Column.text('position', t('entity.offers.productPriority.singular'), {
        sort: false,
      }),
    ],
    [t],
  );
  return (
    <Default>
      <PageTitle title={t('entity.offers.priorities.description')}>
        <EditEntityButton
          entity={t('entity.offers.priorities.description')}
          path="/admin/offers/priorities/edit"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default ProductPrioritiesList;
