import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Space, Collapse, Typography, Switch, Col } from 'antd';

import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useForm, { FormContext } from '../../hooks/useForm';

import FormWrapper from '../FormWrapper';

import SaveButton from '../buttons/SaveButton';
import CancelButton from '../buttons/CancelButton';

import { SalesChannels } from '../poses/constants';

const SEVERITY_TYPES = [{ id: 'INFO' }, { id: 'WARNING' }, { id: 'DANGER' }];

const EditMessageForm = ({ message, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [activeRoKeys, setActiveRoKey] = useState(['Ro']);
  const [activeEnKeys, setActiveEnKey] = useState(null);
  const [activeDeKeys, setActiveDeKey] = useState(null);
  dayjs.extend(customParseFormat);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        titleRo: yup.string().required(t('errors.notEmpty')),
        titleEn: yup.string().required(t('errors.notEmpty')),
        titleDe: yup.string().required(t('errors.notEmpty')),
        messageRo: yup.string().required(t('errors.notEmpty')),
        messageEn: yup.string().required(t('errors.notEmpty')),
        messageDe: yup.string().required(t('errors.notEmpty')),
        startDate: yup.mixed().required(t('errors.notEmpty')),
        endDate: yup.mixed().required(t('errors.notEmpty')),
        severity: yup.string().required(t('errors.notEmpty')),
        channels: yup.array().required(t('errors.notEmpty')),
        active: yup.bool(),
      }),
    [t],
  );

  const form = useForm({
    initialValue: message,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const {
    handleSubmit,
    input,
    select,
    setValue,
    value,
    custom,
    setFormValue,
    errorMessages,
  } = form;

  // eslint-disable-next-line arrow-body-style
  const disableDate = (current) => {
    // Can not select days before today and today
    return current < moment().add(-1, 'day');
  };

  useEffect(() => {
    setFormValue(message);
  }, [setFormValue, message]);

  useEffect(() => {
    if (
      errorMessages &&
      Object.keys(errorMessages).some((e) =>
        [
          'titleRo',
          'titleEn',
          'titleDe',
          'messageRo',
          'messageEn',
          'messageDe',
        ].includes(e),
      )
    ) {
      setActiveRoKey(['Ro']);
      setActiveEnKey(['En']);
      setActiveDeKey(['De']);
    }
  }, [errorMessages]);

  return (
    <FormContext.Provider value={form}>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <Col xl={12}>
            {message.id && (
              <FormWrapper.Input
                label={t('entity.offers.product.id')}
                props={{ ...input('id'), disabled: true }}
              />
            )}
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              Ro
            </Typography.Paragraph>
            <Collapse
              style={{ width: '100%' }}
              activeKey={activeRoKeys}
              onChange={() => setActiveRoKey(activeRoKeys ? null : 'Ro')}
            >
              <CollapsePanel key="Ro" header="Titlu / Mesaj">
                <FormWrapper.Input
                  label={t('entity.messages.title')}
                  props={{ ...input('titleRo'), maxlength: 128 }}
                />
                <Typography.Paragraph style={{ marginBottom: 0 }}>
                  {t('entity.messages._singular')}
                </Typography.Paragraph>
                <FormWrapper.TextArea
                  props={{
                    ...input('messageRo'),
                    maxlength: 3000,
                    rows: 6,
                  }}
                />
              </CollapsePanel>
            </Collapse>

            <Typography.Paragraph style={{ marginBottom: 0 }}>
              En
            </Typography.Paragraph>
            <Collapse
              activeKey={activeEnKeys}
              onChange={() => setActiveEnKey(activeEnKeys ? null : 'En')}
            >
              <CollapsePanel key="En" header="Titlu / Mesaj">
                <FormWrapper.Input
                  label={t('entity.messages.title')}
                  props={{ ...input('titleEn'), maxlength: 128 }}
                />
                <Typography.Paragraph style={{ marginBottom: 0 }}>
                  {t('entity.messages._singular')}
                </Typography.Paragraph>
                <FormWrapper.TextArea
                  props={{
                    ...input('messageEn'),
                    rows: 6,
                    maxlength: 3000,
                  }}
                />
              </CollapsePanel>
            </Collapse>

            <Typography.Paragraph style={{ marginBottom: 0 }}>
              De
            </Typography.Paragraph>
            <Collapse
              activeKey={activeDeKeys}
              onChange={() => setActiveDeKey(activeDeKeys ? null : 'De')}
              style={{ marginBottom: '5%' }}
            >
              <CollapsePanel key="De" header="Titlu / Mesaj">
                <FormWrapper.Input
                  label={t('entity.messages.title')}
                  props={{ ...input('titleDe'), maxlength: 128 }}
                />
                <Typography.Paragraph style={{ marginBottom: 0 }}>
                  {t('entity.messages._singular')}
                </Typography.Paragraph>
                <FormWrapper.TextArea
                  props={{
                    ...input('messageDe'),
                    rows: 6,
                    maxlength: 3000,
                  }}
                />
              </CollapsePanel>
            </Collapse>
          </Col>
          <FormWrapper.Single>
            <FormWrapper.Select
              label={t(`entity.messages.salesChannels._`)}
              props={{
                options: SalesChannels.content,
                loading: SalesChannels.loading,
                mode: 'multiple',
                allowClear: true,
                ...select('channels'),
                labelProp: (val) =>
                  t(`entity.messages.salesChannels.${val.id}`),
              }}
            />

            <FormWrapper.RangePicker
              label={t('entity.messages.validPeriod')}
              props={{
                start: custom('startDate'),
                end: custom('endDate'),
                // allowEmpty: [false, true],
                disabledDate: disableDate,
                showTime: { defaultValue: dayjs('00:00:00', 'HH:mm') },
              }}
            />

            <FormWrapper.Select
              label={t(`entity.messages.severity`)}
              props={{
                options: SEVERITY_TYPES,
                ...select('severity'),
                labelProp: (val) =>
                  t(`entity.messages.severityTypes.${val.id}`),
              }}
            />

            <Typography>{t(`entity.messages.active`)}</Typography>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={value?.active}
              onChange={(val) => {
                setValue('active', val);
              }}
            />
          </FormWrapper.Single>
        </FormWrapper>

        <Space>
          <SaveButton />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </FormContext.Provider>
  );
};

export default EditMessageForm;
