/* eslint-disable no-await-in-loop */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, message, notification } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

import {
  ejectCard,
  enrollProduct,
  getEnrolledProduct,
  getNextCardUid,
} from '../../services/stocks/cards';
import { printOnCardLogicalId } from '../../helpers/printing';

const EnrollmentButton = ({ form }) => {
  const { t } = useTranslation();

  const showMessage = useCallback((type, text) => {
    message[type]({
      content: text,
      key: 'pos',
      duration: 5,
      className: 'card-message',
    });
  }, []);

  const handleEnrollment = useCallback(async () => {
    const { records } = form.getFieldsValue('records');

    if (!records || !records[0] || !records[0].quantity) {
      return;
    }
    const { id, quantity, article } = records[0];
    let enrolledQuantity = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < quantity; i++) {
      let physicalId = 0;
      do {
        let ejectionMode = 'EJECT';
        try {
          physicalId = await getNextCardUid();
          if (!physicalId) {
            showMessage('error', t('errors.noCards'));
            return;
          }

          // if card not taken the devconn returns 0 as string
          if (physicalId === '0') {
            showMessage('error', t('errors.noCardTakenFromPrinter'));
            return;
          }

          await enrollProduct({
            invoiceId: id,
            physicalId,
            articleType: article,
          });

          const product = await getEnrolledProduct(physicalId);
          await printOnCardLogicalId(product.serial);

          showMessage(
            'success',
            `A fost inrolat card-ul cu UID-ul ${product.physicalId}`,
          );
          // eslint-disable-next-line no-plusplus
          enrolledQuantity++;
          form.setFieldsValue({
            // eslint-disable-next-line no-loop-func
            records: records.map((val, idx) =>
              idx === 0
                ? {
                    ...val,
                    confirmedQuantity: enrolledQuantity,
                  }
                : val,
            ),
          });
        } catch (e) {
          ejectionMode = 'REJECT';
          physicalId = 0;
          showMessage('error', t(e.inner._));
        }
        await ejectCard(ejectionMode);
      } while (physicalId === 0);
    }
    notification.success({
      message: t('actions.enrollSucces'),
    });
  }, [form, t, showMessage]);

  return (
    <Button
      type="primary"
      htmlType="button"
      className="ant-btn-warning"
      icon={<IdcardOutlined />}
      onClick={handleEnrollment}
    >
      {t('actions.enrollment')}
    </Button>
  );
};

export default EnrollmentButton;
