/* eslint-disable no-await-in-loop */
import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Input, Space, Select, Table } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import * as yup from 'yup';
import AuthContext, { hasPermission } from '../auth';

import useDictionaries, { EMPTY_DICTIONARY } from '../../hooks/useDictionaries';

import FormWrapper from '../FormWrapper';
import CancelButton from '../buttons/CancelButton';
import EditInvoiceRecordsForm from './EditInvoiceRecordsForm';
import { getRetailers } from '../../services/taxonomies/retailers';
import { getPoses } from '../../services/taxonomies/poses';
import { getFiles } from '../../services/files';
import { findConflicts } from '../../services/stocks/invoices';
import {
  InvoiceTypes,
  CurrentRetail,
  Retail,
  POS,
  Warehouse,
  Enrollment,
} from './constants';
import { getCurrentRetailer } from '../../services/admin/users';
import EnrollmentButton from './EnrollmentButton';
import useFormErrors from '../../hooks/useFormErrors';
import FileUploader from '../FileUploader';
import Column from '../../helpers/columns';
import DatePickerLocale from '../DatePickerLocale';

const dictionaryByEntityType = (entityType) => {
  switch (entityType) {
    case CurrentRetail:
      return () =>
        getCurrentRetailer().then((content) => ({
          content: [{ id: content.retailerId, name: content.retailerName }],
        }));
    case Retail:
      return getRetailers;
    case POS:
      return getPoses;
    default:
      return EMPTY_DICTIONARY;
  }
};
const validationSchema = yup.object().shape({
  invoiceNumber: yup.string().required('errors.notEmpty'),
});

const mapEntityType = (entityType, isSuperUser, isWarhouse) =>
  // eslint-disable-next-line no-nested-ternary
  entityType === CurrentRetail
    ? isSuperUser || isWarhouse
      ? Retail
      : CurrentRetail
    : entityType;

const EditInvoiceForm = ({ invoice, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const [fileList, setFileList] = useState([]);

  const { origin: originType, destination: destinationType } = useMemo(() => {
    const { origin, destination } = InvoiceTypes[invoice.invoiceType];

    const isSuperUser = hasPermission(permissions, ['SYS_SUPERUSER']);

    const isWarhouse = origin === Warehouse;

    return {
      origin: mapEntityType(origin, isSuperUser),
      destination: mapEntityType(destination, isSuperUser, isWarhouse),
    };
  }, [invoice, permissions]);

  const dictionaries = useMemo(() => {
    return {
      source: dictionaryByEntityType(originType),
      destination: dictionaryByEntityType(destinationType),
    };
  }, [originType, destinationType]);

  const [{ source, destination }] = useDictionaries(dictionaries);

  // eslint-disable-next-line no-unused-vars
  const [currentStock, setCurrentStock] = useState(null);
  const [currentConflicts, setCurrentConflicts] = useState(null);

  // todo: Uncomment when stocks will work
  // useEffect(() => {
  //   (originType
  //     ? findAllStocks(symbolToString(originType), invoice.originId)
  //     : Promise.resolve([])
  //   )
  //     .then((res) =>
  //       Array.isArray(res) && res.length > 0
  //         ? res.reduce(
  //             (acc, stock) => ({
  //               ...acc,
  //               [stock.article]: stock,
  //             }),
  //             {},
  //           )
  //         : null,
  //     )
  //     .then(setCurrentStock);
  // }, [invoice.originId, originType]);

  useEffect(() => {
    if (invoice.id && invoice.invoiceType && invoice.status === 'CONFLICT') {
      findConflicts(invoice.invoiceType, invoice.id).then((res) => {
        setCurrentConflicts(res);
      });
    } else {
      setCurrentConflicts(null);
    }
  }, [invoice]);

  const [errors, setErrors] = useState(null);
  const [form] = Form.useForm();
  useFormErrors(form, errors);

  useEffect(() => {
    form.setFieldsValue(invoice);
    setFileList([]);

    if (
      invoice &&
      invoice.files &&
      Array.isArray(invoice.files) &&
      invoice.files.length > 0
    ) {
      getFiles(invoice.files).then((res) => setFileList(res));
    }
  }, [form, invoice]);

  const handleSave = useCallback(() => {
    form.setFieldsValue({ _action: '', files: fileList.map((v) => v.id) });
    form.submit();
  }, [form, fileList]);

  const handleAccept = useCallback(() => {
    form.setFieldsValue({ _action: 'accept' });
    form.submit();
  }, [form]);

  const handleReject = useCallback(() => {
    form.setFieldsValue({ _action: 'reject' });
    form.submit();
  }, [form]);

  const handleConfirm = useCallback(() => {
    form.setFieldsValue({ _action: 'confirm' });
    form.submit();
  }, [form]);

  const handleSolveConflicts = useCallback(() => {
    form.setFieldsValue({ _action: 'resolve' });
    form.submit();
  }, [form]);

  const handleChangeOrigin = useCallback(
    (originId) => {
      if (
        destinationType === originType &&
        [CurrentRetail, Retail].includes(destinationType)
      )
        form.setFieldsValue({ destinationId: originId });
      else form.setFieldsValue({ destinationId: null });
    },
    [form, destinationType, originType],
  );

  const handleChangeDestination = useCallback(
    (destinationId) => {
      if (
        destinationType === originType &&
        [CurrentRetail, Retail].includes(destinationType)
      )
        form.setFieldsValue({ originId: destinationId });
      else form.setFieldsValue({ originId: null });
    },
    [form, destinationType, originType],
  );

  const renderOriginDropdown = () => {
    switch (originType) {
      case Warehouse:
        return (
          <Form.Item label={t('entity.stocks.invoice.origin')}>
            <Input disabled value={t('pages.warehouse._')} />
          </Form.Item>
        );
      case Enrollment:
        return (
          <Form.Item label={t('entity.stocks.invoice.origin')}>
            <Input disabled value={t('pages.enrollment._')} />
          </Form.Item>
        );
      case POS:
        return (
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.destinationId !== next.destinationId
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="originId"
                label={t('entity.stocks.invoice.origin')}
              >
                <Select
                  // loading={source.loading}
                  disabled={invoice.status !== 'CREATED'}
                >
                  {source.content
                    .filter(
                      (option) =>
                        // eslint-disable-next-line eqeqeq
                        option.retailer == getFieldValue('destinationId'),
                    )
                    .map((option) => (
                      <Select.Option
                        key={option.id}
                        value={option.id}
                        disabled={option.deleted}
                      >
                        {`${option.name}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
        );
      case Retail:
      case CurrentRetail:
        return (
          <Form.Item name="originId" label={t('entity.stocks.invoice.origin')}>
            <Select
              // loading={source.loading}
              disabled={invoice.status !== 'CREATED'}
              onChange={handleChangeOrigin}
            >
              {source.content.map((option) => (
                <Select.Option
                  key={option.id}
                  value={option.id}
                  disabled={option.deleted}
                >
                  {`${option.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      default:
        return (
          <Form.Item label={t('entity.stocks.invoice.origin')}>
            <Input disabled />
          </Form.Item>
        );
    }
  };

  const renderDestinationDropdown = () => {
    switch (destinationType) {
      case Warehouse:
        return (
          <Form.Item label={t('entity.stocks.invoice.destination')}>
            <Input disabled value={t('pages.warehouse._')} />
          </Form.Item>
        );
      case Enrollment:
        return (
          <Form.Item label={t('entity.stocks.invoice.destination')}>
            <Input disabled value={t('pages.enrollment._')} />
          </Form.Item>
        );
      case POS:
        return (
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.originId !== next.originId}
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="destinationId"
                label={t('entity.stocks.invoice.destination')}
              >
                <Select
                  // loading={destination.loading}
                  disabled={invoice.status !== 'CREATED'}
                >
                  {destination.content
                    .filter(
                      (option) =>
                        // eslint-disable-next-line eqeqeq
                        option.retailer == getFieldValue('originId'),
                    )
                    .map((option) => (
                      <Select.Option
                        key={option.id}
                        value={option.id}
                        disabled={option.deleted}
                      >
                        {`${option.name}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
        );
      case Retail:
      case CurrentRetail:
        return (
          <Form.Item
            name="destinationId"
            label={t('entity.stocks.invoice.destination')}
          >
            <Select
              // loading={destination.loading}
              disabled={invoice.status !== 'CREATED'}
              onChange={handleChangeDestination}
            >
              {destination.content.map((option) => (
                <Select.Option
                  key={option.id}
                  value={option.id}
                  disabled={option.deleted}
                >
                  {`${option.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      default:
        return (
          <Form.Item label={t('entity.stocks.invoice.destination')}>
            <Input disabled />
          </Form.Item>
        );
    }
  };

  const handleFileUpload = useCallback(
    (files) => {
      setFileList((res) => [...res, ...files]);
    },
    [setFileList],
  );

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.user.tabs.files.name'), {
        sort: false,
      }),
      Column.date('uploadDate', t('entity.admin.user.tabs.files.uploadDate'), {
        sort: false,
      }),
      Column.text('uploadBy', t('entity.admin.user.tabs.files.uploadBy'), {
        sort: false,
      }),

      // Column.actions(t('table.actions'), () => (
      //   <>
      //     <Button
      //       shape="circle"
      //       type="link"
      //       icon={<DownloadOutlined />}
      //       onClick={() => null}
      //     />
      //   </>
      // )),
    ],
    [t],
  );

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={invoice}
      onFinish={(value) => {
        setErrors({});
        validationSchema
          .validate(value, { abortEarly: false })
          .then((val) => {
            onSubmit(val);
          })
          .catch((err) => {
            setErrors(
              err.inner.reduce(
                (acc, ve) => ({ ...acc, [ve.path]: ve.message }),
                {},
              ),
            );
          });
      }}
    >
      <Form.Item name="_action" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="files" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <FormWrapper>
        <FormWrapper.Single>
          <Form.Item
            name="invoiceNumber"
            label={t('entity.stocks.invoice.invoiceNumber')}
          >
            <Input disabled={invoice.status !== 'CREATED'} />
          </Form.Item>
          {renderOriginDropdown()}
        </FormWrapper.Single>
        <FormWrapper.Single>
          <Form.Item
            name="invoiceDate"
            label={t('entity.stocks.invoice.invoiceDate')}
          >
            <DatePickerLocale
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              disabled={invoice.status !== 'CREATED'}
            />
          </Form.Item>
          {renderDestinationDropdown()}
        </FormWrapper.Single>
        <FormWrapper.Single>
          <FileUploader onUploadSuccess={handleFileUpload} />
          <Table
            columns={columns}
            pagination={false}
            dataSource={fileList}
            rowKey="id"
          />
        </FormWrapper.Single>
      </FormWrapper>

      <EditInvoiceRecordsForm
        form={form}
        invoiceType={InvoiceTypes[invoice.invoiceType]}
        invoiceStatus={invoice.status}
        stock={currentStock}
        conflicts={currentConflicts}
      />

      <Space>
        {invoice.status === 'CREATED' && (
          <Button
            type="primary"
            htmlType="button"
            icon={<SaveOutlined />}
            onClick={handleSave}
          >
            {t('actions.save')}
          </Button>
        )}

        {invoice.status === 'CREATED' && invoice.invoiceType === 'enroll' && (
          <EnrollmentButton form={form} />
        )}

        {invoice.status === 'CREATED' && (
          <Button
            className="ant-btn-success"
            htmlType="button"
            onClick={handleAccept}
            icon={<CheckOutlined />}
          >
            {t('actions.accept')}
          </Button>
        )}

        {invoice.status === 'CREATED' && (
          <Button
            type="danger"
            htmlType="button"
            onClick={handleReject}
            icon={<CloseOutlined />}
          >
            {t('actions.reject')}
          </Button>
        )}

        {invoice.status === 'PENDING' && (
          <Button
            type="primary"
            htmlType="button"
            onClick={handleConfirm}
            icon={<CheckOutlined />}
          >
            {t('actions.confirm')}
          </Button>
        )}

        {invoice.status === 'CONFLICT' && (
          <Button
            htmlType="button"
            className="ant-btn-warning"
            onClick={handleSolveConflicts}
            icon={<ExclamationCircleOutlined />}
          >
            {t('actions.solveConflicts')}
          </Button>
        )}

        <CancelButton onCancel={onCancel} />
      </Space>
    </Form>
  );
};

export default EditInvoiceForm;
