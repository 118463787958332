import React, { useContext, useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { FormContext } from '../../hooks/useForm';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';
import { getAccounts } from '../../services/accounts/accounts';
import { CustomerTypes } from '../users/constants';

const AccountDescendantsList = () => {
  const { t } = useTranslation();

  const form = useContext(FormContext);
  const { value } = form;
  const { id } = value;

  const { pagination, content, handleChange } = useDatasource((arg) =>
    getAccounts({
      ...arg,
      criterias: {
        ...arg.criterias,
        parentId: id,
      },
    }),
  );

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.account._singular'), {
        filter: true,
        width: 50,
      }),
      Column.text('userName', t('entity.account.name'), {
        filter: true,
      }),
      Column.text('userIdentifier', t('entity.account.identifier'), {
        filter: true,
      }),
      Column.dictionary(
        'customerType',
        t('entity.account.type._'),
        CustomerTypes,
        {
          filter: true,
          width: 150,
          dictLabel: (entry) => t(`entity.account.type.${entry.id}`),
        },
      ),
      Column.bool('deleted', t('entity.account.status._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('common.deleted.true'),
          t('common.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  return (
    <Table
      columns={columns}
      rowKey="id"
      // loading={loading}
      dataSource={content}
      pagination={pagination}
      onChange={handleChange}
      scroll={{ x: 700, y: 700 }}
    />
  );
};

export default AccountDescendantsList;
