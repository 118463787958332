import { search, get, post, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/users';
export const AUTH_BASE_URI = '/api/v1/roles';

export const getRoles = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getRoleById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createRole = async (role) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, role);

export const updateRole = async (role) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${role.id}`, role);

export const deleteRole = async (role) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${role.id}`);

export const getRolesWithDetails = async () =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/details`);
