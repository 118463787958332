import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/companies';

export const getCompanies = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/search`, data);

export const getSellerCompanies = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/search`, {
    ...data,
    criterias: { ...data.criterias, seller: [true] },
  });

export const getCompanyById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createCompany = async (company) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, company);

export const updateCompany = async (company) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${company.id}`, company);

export const deleteCompany = async (company) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${company.id}`);
