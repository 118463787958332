import React from 'react';
import { getI18n } from 'react-i18next';
import DatePickerEnglish from './DatePickerEnglish';
import DatePickerRomanian from './DatePickerRomanian';
import DatePickerGerman from './DatePickerGerman';

const DatePickerLocale = (props) => {
  const { language } = getI18n();
  switch (language) {
    case 'en':
      return <DatePickerEnglish {...props} />;
    case 'de':
      return <DatePickerGerman {...props} />;
    default:
      return <DatePickerRomanian {...props} />;
  }
};
export default DatePickerLocale;
