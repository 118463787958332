import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import useDatasource from '../../hooks/useDatasource';
import { getRetailPoses } from '../../services/taxonomies/poses';
import { FormContext } from '../../hooks/useForm';
import Column from '../../helpers/columns';

const ReteailerPosesList = () => {
  const form = useContext(FormContext);
  const [t] = useTranslation();

  const { value, setValue } = form;

  const { id } = value;

  const { pagination, content, handleChange } = useDatasource((args) => {
    return getRetailPoses({
      ...args,
      criterias: { ...args.criterias, retailerId: id, nullable: true },
    });
  });

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.poses.name'), { filter: true }),
      Column.text('address', t('entity.taxonomy.poses.address')),
      Column.bool('deleted', t('entity.taxonomy.poses.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.poses.deleted.false'),
          t('entity.taxonomy.poses.deleted.true'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      onSelect: (record, selected) =>
        selected
          ? setValue('pos', [...(value.pos || []), record.id])
          : setValue(
              'pos',
              value.pos.filter((pos) => pos !== record.id),
            ),
      selectedRowKeys: value.pos || [],
      getCheckboxProps: (record) => ({
        disabled:
          !(record.retailer === null || record.retailer === id) ||
          (record.deleted &&
            (value.pos?.length > 0
              ? value.pos?.filter((ar) => ar === record.id).length === 0
              : true)),
      }),
    }),
    [value, setValue, id],
  );

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns}
      rowKey="id"
      // loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
    />
  );
};

export default ReteailerPosesList;
