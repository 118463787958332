import React from 'react';
import { Row, Col, Tabs, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import { Empty } from '../layouts';

import LoginForm from '../components/auth/LoginForm';
import TitleLogo from '../components/TitleLogo';
import CardLoginForm from '../components/auth/CardLoginForm';

const Login = () => {
  const { t } = useTranslation();
  return (
    <Empty>
      <TitleLogo />
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 6, offset: 9 }}
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={t('startPage.login')} key="1">
              <Divider style={{ marginTop: 0 }} />
              <LoginForm />
            </Tabs.TabPane>
          </Tabs>
          <Divider>sau</Divider>
          <CardLoginForm />
        </Col>
      </Row>
    </Empty>
  );
};

export default Login;
