/* eslint-disable no-await-in-loop */
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Col, message, Row, notification, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined } from '@ant-design/icons';
import POSButton from '../POSButton';
import POSContext from '../POSContext';

import { getOpenOrders, setOrderCard } from '../../../services/pos/pos';
import {
  downloadImage,
  getNextEnrolled,
  print,
  showMessage,
} from '../../../helpers/printing';
import { ejectCard } from '../../../services/stocks/cards';

const PrintCard = () => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState({});
  const [openOrders, setOpenOrders] = useState([]);
  const { shift, setOrder } = useContext(POSContext);
  const [disabled, setDisabled] = useState(false);

  const getImage = useCallback(
    async (id) => {
      let image = templates[id];
      if (!image) {
        image = await downloadImage(id);
        setTemplates({ ...templates, id: image });
      }
      return image;
    },
    [templates],
  );

  useEffect(() => {
    if (
      openOrders.length > 0 &&
      openOrders.every((o) => o.status === 'CONFIRMED')
    ) {
      setOrder(openOrders[0]);
    }
  }, [openOrders, setOrder]);

  const downloadImages = useCallback(
    async (templateId, userPhotoId) => {
      try {
        const layout = await getImage(templateId);
        const userPhoto = userPhotoId ? await downloadImage(userPhotoId) : null;
        return userPhoto ? [layout, userPhoto] : [layout];
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Eroare date introduse',
        });
      }
      return [];
    },
    [getImage],
  );

  useEffect(() => {
    getOpenOrders(shift)
      .then((res) => setOpenOrders(res))
      .catch((err) => {
        console.error(err);
        setOpenOrders([]);
      });
  }, [shift]);

  const updateOrder = useCallback(
    async (id, card) =>
      setOrderCard(shift, id, { ...card })
        .then((res) => res)
        .catch((err) => {
          console.error(err);
          message.error({
            content: 'Tipul nu poate fi utilizat',
            key: 'pos',
            duration: 5,
            className: 'card-message',
          });
        }),
    [shift],
  );

  const getPayload = useCallback(
    (order) =>
      (order.products || [])
        .map((op) => op.payload)
        .flat()
        .filter((op) => op.shouldScan)[0],
    [],
  );

  const beginPrinting = useCallback(async () => {
    setDisabled(true);
    const allNotPrinted = openOrders
      .filter((o) => o.status === 'PRINTING')
      .map((o) => {
        const { id } = o;
        const { templateId, userPhotoId, userFullname, userIdentityCard } =
          o.account;
        const payloadId = getPayload(o)?.id;
        return {
          templateId,
          userPhotoId,
          userFullname,
          id,
          payloadId,
          userIdentityCard,
        };
      });

    const done = [];
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const pers of allNotPrinted) {
        const {
          templateId,
          userFullname,
          userPhotoId,
          id,
          payloadId,
          userIdentityCard,
        } = pers;
        showMessage('loading', 'Se citeste cardul', 0);
        const { physicalId } = await getNextEnrolled();
        showMessage(
          'loading',
          `Se tipareste cardul ${physicalId} pentru ${userFullname}`,
        );
        const images = await downloadImages(templateId, userPhotoId);
        const updated = await updateOrder(id, { physicalId, payloadId });
        const nr = `${(userIdentityCard || '').substring(0, 7)}`;

        if (updated) {
          // await print(images, `${userFullname}\n${serial}`);
          await print(images, ` \n${nr}\n${userFullname}`);
          done.push(updated);
        }
      }
      if (done.length > 0) {
        showMessage('success', 'Tiparire  cu succes');
      }
    } catch (err) {
      try {
        await ejectCard('REJECT');
      } catch (err2) {
        console.error(err2);
      }
      showMessage('error', err);
    }
    setDisabled(false);
    setOpenOrders(
      openOrders.map((oo) => done.find((d) => d.id === oo.id) || oo),
    );
  }, [openOrders, getPayload, downloadImages, updateOrder]);

  return (
    <>
      <div className="pos">
        <div className="pos__wrapper">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
            {openOrders.map((ord, idx) => (
              <Col xs={{ span: 8 }} lg={{ span: 4 }} key={ord.id}>
                <POSButton
                  title={
                    <>
                      {`Card ${idx + 1}: ${ord?.account?.userFullname || ''}`}
                      {ord?.status === 'CONFIRMED' && (
                        <CheckCircleOutlined
                          style={{ fontSize: '16px', color: '#24ad09' }}
                        />
                      )}
                    </>
                  }
                  color="blue"
                  id={ord.id}
                />
              </Col>
            ))}
          </Row>
          <Divider style={{ margin: '1rem 0' }} />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              <POSButton
                title={t('actions.orders.print')}
                onClick={beginPrinting}
                disabled={disabled}
                color="orange"
              />
            </Col>
          </Row>
          <Divider style={{ margin: '1rem 0' }} />
        </div>
      </div>
    </>
  );
};

export default PrintCard;
