import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import RetryInvoiceGenerationGrid from '../../../components/retry-invoice-generation/RetryInvoiceGenerationGrid';

const RetryInvoiceGeneration = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.retryInvoiceGeneration._')} />
      <RetryInvoiceGenerationGrid />
    </Default>
  );
};

export default RetryInvoiceGeneration;
