import React from 'react';

import { Default } from '../../../../../layouts';

import { useEditPage } from '../../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../../services/taxonomies/card-templates';

import EditCardTemplateForm from './EditCardTemplateForm';

const initial = {};

const EditCardTemplate = () => {
  const [template, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/card/templates',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {template && (
        <EditCardTemplateForm
          template={template}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditCardTemplate;
