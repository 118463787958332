import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import FormWrapper from '../FormWrapper';
import Column from '../../helpers/columns';
import DeleteItemIcon from '../buttons/DeleteItemIcon';
import { downloadFiles } from '../../services/files';
import './OrderFileList.scss';

const OrderFileList = ({ props }) => {
  const { t } = useTranslation();

  const { name, setValue, getValue } = props;

  const onRemove = useCallback(
    (record) => {
      setValue(
        name,
        getValue(name).filter((pr) =>
          record.key ? pr.key !== record.key : pr.id !== record.id,
        ),
      );
    },
    [name, getValue, setValue],
  );

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.user.tabs.files.name'), {
        sort: false,
      }),

      Column.actions(t('table.actions'), (file) => (
        <>
          <Button
            shape="circle"
            type="link"
            icon={<DownloadOutlined />}
            onClick={() => downloadFiles(file.id)}
          />
          {!file?.canNotBeDeleted && (
            <DeleteItemIcon
              title={t('entity.admin.user.tabs.files._delete', file)}
              item={file}
              action={async () => onRemove(file)}
              disabled={file.userFileDeleted}
              reload={() => null}
            />
          )}
        </>
      )),
    ],
    [onRemove, t],
  );

  const tableProps = useMemo(
    () => ({
      rowKey: 'id',
      scroll: { x: 1000 },
      rowClassName: () => 'order-file-list__table-row',
      columns,
    }),
    [columns],
  );

  return (
    <FormWrapper.FileList
      props={{
        name,
        setValue,
        getValue,
        tableProps,
      }}
    />
  );
};

export default OrderFileList;
