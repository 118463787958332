import React from 'react';

import { Row, Col } from 'antd';
import LanguageChooser from '../components/LanguageChooser';

const Empty = ({ children }) => {
  return (
    <>
      <Row style={{ padding: '1rem 0' }}>
        <Col xs={24} style={{ textAlign: 'right' }}>
          <LanguageChooser />
        </Col>
      </Row>

      {children}

      {window._env_.VERSION && (
        <div className="app-version">
          {new Date(
            (parseInt(window._env_.VERSION, 10) || 0) * 1000,
          ).toISOString()}
        </div>
      )}
    </>
  );
};

export default Empty;
