import React, { useCallback, useContext, useState } from 'react';
import { Button, Col, Modal, Row, Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';
import useForm, { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';
import AccountDescendantsList from './AccountDescendantsList';
import TransportTitles from './TransoirtTitlesList';
import CardsList from './CardsList';
import TitlesDescendantsList from './TitlesDescandantsList';
import { CustomerTypes } from '../users/constants';
import TransactionHistoryList from './TransactionHistoryList';
import { updateDeletedStatus } from '../../services/accounts/accounts';
import ViewItemIcon from '../buttons/ViewItemIcon';
import AuthContext, { hasPermission } from '../auth';

const ViewAccountForm = ({ account, onCancel }) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValue: account,
  });

  const { input, select } = form;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(account);

  const toggleDeletedStatus = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleYesClick = () => {
    updateDeletedStatus(currentAccount.id).then(() => {
      const updatedAccount = {
        ...currentAccount,
        deleted: !currentAccount.deleted,
      };
      setCurrentAccount(updatedAccount);
      setIsModalVisible(false);
    });
  };

  const handleNoClick = () => {
    setIsModalVisible(false);
  };
  const { user: currentUser } = useContext(AuthContext);

  const doNotShowElementsByPermissions = hasPermission(
    currentUser.permissions,
    ['ADMINISTRATION_ACCOUNTS_VIEW_PERMISSION'],
  );

  return (
    <FormContext.Provider value={form}>
      <form>
        <PageTitle title={`Account #${account.id}`} />
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.account.name')}
              props={{ ...input('userName'), disabled: true }}
            />
            <FormWrapper.Input
              label={t('entity.account._identifier')}
              props={{ ...input('userIdentifier'), disabled: true }}
            />

            <FormWrapper.Select
              label={t('entity.account.type._')}
              props={{
                options: CustomerTypes.content,
                ...select('customerType'),
                labelProp: (option) => t(`entity.account.type.${option.id}`),
                disabled: true,
              }}
            />
            {account.parentIdentifier && (
              <FormWrapper.Input
                label={t('entity.account.parentAccount')}
                props={{ ...input('parentIdentifier'), disabled: true }}
              />
            )}
            <Row align="middle">
              <Col flex="auto">
                <FormWrapper.Input
                  label={t('entity.admin.user._singular')}
                  props={{ ...input('userId'), disabled: true }}
                />
              </Col>
              <Col>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <ViewItemIcon path={`/admin/users/${account.userId}`} />
                </div>
              </Col>
            </Row>
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={t('entity.account.transportTitle._plural')}
                key="transportTitles"
              >
                <TransportTitles />
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('entity.account.cards._plural')} key="cards">
                <CardsList />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('entity.account.transactionHistory.title')}
                key="transactionHistory"
              >
                <TransactionHistoryList accId={account.id} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('entity.account.descendants')}
                key="accounts"
              >
                <AccountDescendantsList />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('entity.account.descendantsTitles')}
                key="descendantsTitles"
              >
                <TitlesDescendantsList />
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
        </FormWrapper>

        <Modal
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={
            <Space>
              <Button onClick={handleYesClick} type="primary">
                {t('entity.account.toggle.yes')}
              </Button>
              <Button
                onClick={handleNoClick}
                style={{ background: 'grey' }}
                type="primary"
              >
                {t('entity.account.toggle.no')}
              </Button>
            </Space>
          }
        >
          {currentAccount.deleted
            ? t('entity.account.modalTitle', {
                status: t('entity.account.status.active'),
              })
            : t('entity.account.modalTitle', {
                status: t('entity.account.status.inactive'),
              })}
        </Modal>

        {!doNotShowElementsByPermissions ? (
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => toggleDeletedStatus()}
            size="medium"
          >
            {!currentAccount.deleted
              ? t('entity.account.disable')
              : t('entity.account.enable')}
          </Button>
        ) : null}
        <Space>
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </FormContext.Provider>
  );
};

export default ViewAccountForm;
