import React, { createContext, useContext } from 'react';

const ResetComponentContext = createContext({ resetComponent: false });

export const useResetComponent = () => {
  return useContext(ResetComponentContext);
};

export const ResetComponentProvider = ({ children, value }) => {
  return (
    <ResetComponentContext.Provider value={value}>
      {children}
    </ResetComponentContext.Provider>
  );
};
