import {
  get,
  post,
  remove,
  handleHttpErrors,
  search,
  genericCRUDService,
  put,
} from '../../helpers/api';

export const SERVICE_URI = '/pos';
export const BASE_URI = '/api/v1';

export const { downloadAll } = genericCRUDService(
  SERVICE_URI,
  `${BASE_URI}/sales/report`,
);

export const getCurrentShift = () =>
  get(`${SERVICE_URI}${BASE_URI}/shifts/current`);

export const startShift = () => post(`${SERVICE_URI}${BASE_URI}/shifts`, null);

export const closeShift = (raportZ) =>
  post(`${SERVICE_URI}${BASE_URI}/shifts/close`, raportZ);

export const changeInstitution = (orderId, institutionId, subInstitutionId) =>
  put(
    `${SERVICE_URI}${BASE_URI}/shifts/changeInstitution/${orderId}?institutionId=${institutionId}&subInstitutionId=${subInstitutionId}`,
  );

export const closeShiftById = async (id) =>
  post(`${SERVICE_URI}${BASE_URI}/shifts/${id}/close`);

export const getShiftSummary = () =>
  get(`${SERVICE_URI}${BASE_URI}/shifts/summary`);

export const getShiftSummaryById = (id) =>
  get(`${SERVICE_URI}${BASE_URI}/shifts/${id}/summary`);

export const getOpenOrders = (shift) =>
  get(`${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/active`);

export const getCurrentOrder = (shift) =>
  get(`${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders`);

export const openOrder = (shift, card) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders`,
    card ? { card } : null,
  );

export const cancelOrder = (shift, order) =>
  remove(`${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}`)
    .then((resp) => resp.json())
    .then((res) => (res.id ? res : null));

export const cancelOrders = (shift) =>
  remove(`${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders`)
    .then((resp) => resp.json())
    .then((res) => (res.id ? res : null));

export const getAvailableProducts = (shift, order) =>
  get(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/procurables/products`,
  );

export const getAvailableCards = (shift, order) =>
  get(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/procurables/cards`,
  );

export const getAvailableLayouts = (shift, order) =>
  get(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/procurables/layouts`,
  );

export const getShiftsByPosId = (posId, initialSort) => async (data) =>
  search(`${SERVICE_URI}${BASE_URI}/shifts`, {
    ...data,
    sort: data.sort || initialSort,
    criterias: { ...data.criterias, posId },
  });

export const addProductToOrder = (shift, order, product) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/products`,
    product,
  );

export const changeAttachmentsToOrder = (shift, order, value) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/attachments`,
    value,
  );

export const blockCard = (shift, order, reason, cardPosesion) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/block`,
    { reason, cardPosesion },
  );

export const enableOrderCard = (shift, order) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/enableCard`,
  );

export const updateScrapNumber = (orderId, body) =>
  post(`${SERVICE_URI}${BASE_URI}/shifts/update-scrap-number/${orderId}`, body);

export const rechargeProductToOrder = (shift, order, product) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/recharge`,
    product,
  );

export const removeProductFromOrder = (shift, order, product) =>
  remove(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/products/${product.id}`,
  ).then((resp) => resp.json());

export const moveOrderToNextStep = (shift, order) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/_next`,
    null,
  );

export const changeCardToOrder = (shift, order, oldCardPosesion) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/changeCard`,
    { exchangeOwnCard: oldCardPosesion },
  );

export const getRouteGroups = () =>
  get(`${SERVICE_URI}${BASE_URI}/restrictions/routegroups`, {});

export const getRouteById = (id) =>
  get(`${SERVICE_URI}${BASE_URI}/restrictions/routes/${id}`);

export const setOrderCardType = (shift, order, card) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/cardType`,
    card,
  );

export const setOrderCard = (shift, orderId, card) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${orderId}/card`,
    card,
  );

export const setOrderAccount = (shift, order, account) =>
  post(
    `${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/${order.id}/account`,
    account,
  );

export const uploadImage = (data) =>
  post(`${SERVICE_URI}${BASE_URI}/images`, { data });

export const checkout = (shift, data) =>
  post(`${SERVICE_URI}${BASE_URI}/shifts/${shift.id}/orders/checkout`, data);

export const printImages = async (
  endpoint,
  files,
  text,
  cardLogicalId,
  options = {},
) => {
  if (files && files.length === 0) {
    return [];
  }
  const body = new FormData();
  files.forEach((file) => body.append('files', file, file.name));
  body.append('text', text);
  body.append('cardLogicalId', cardLogicalId);

  return fetch(`${endpoint}`, {
    method: 'POST',
    body,
    ...options,
  }).then((response) => {
    if (response.ok) {
      return response;
    }
    throw new Error('Something went wrong');
  });
};

export const printReceipt = (payMethod, posId, orders, cuiCode) => {
  console.log('printReceipt', cuiCode);
  const fiscoOrders = orders.map((order) => ({
    orderId: order.id,
    cardPhysicalId: order.card.cardPhysicalId,
    cardLogicalId: order.card.cardLogicalId,
    cardCategory: order.card.cardCategory,
    qrCode: order.card.qrCode,
    fiscoItems: order.products,
  }));

  const req = {
    type: 'RECEIPT',
    paymentMethod: payMethod,
    posId,
    fiscoOrders,
    cuiCode,
  };

  return fetch(
    `http://${
      localStorage.getItem('devconnIP') || 'localhost'
    }:13385/dev/fisco`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    },
  )
    .then(handleHttpErrors)
    .then((resp) => resp.json());
};

export const printZ = ({ id }) => {
  return fetch(
    `http://${
      localStorage.getItem('devconnIP') || 'localhost'
    }:13385/dev/fisco/${id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then(handleHttpErrors)
    .then((resp) => resp.json());
};

export const identify = async () => {
  return fetch(
    `http://${
      localStorage.getItem('devconnIP') || 'localhost'
    }:13385/dev/identify`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then(handleHttpErrors)
    .then((resp) => resp.json());
};

export const checkPosByIdentifier = async (workstationToken) => {
  return fetch(
    `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/shifts/check/${workstationToken}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then(handleHttpErrors)
    .then((resp) => resp.json());
};

export const getSaleReport = async (data) =>
  search(`${SERVICE_URI}${BASE_URI}/sales/report`, data);

export const validateCui = (country, vatNUmber) =>
  get(
    `${SERVICE_URI}${BASE_URI}/vies?country=${country}&vatNumber=${vatNUmber}`,
    {},
  );
