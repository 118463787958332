import React, { useEffect } from 'react';

import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import useForm from '../../../hooks/useForm';

import PageTitle from '../../PageTitle';
import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';

const EditTransportDepoForm = ({ depo, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const { handleSubmit, value, input, setFormValue } = useForm({
    initialValue: depo,
    onSubmit,
    onCancel,
  });

  useEffect(() => {
    setFormValue(depo);
  }, [setFormValue, depo]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={depo.name || t('entity.taxonomy.transportDepo._singular')}
        />
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.transportDepo.name')}
              props={{
                ...input('name'),
                disabled: value.deleted,
              }}
            />
          </FormWrapper.Single>
        </FormWrapper>
        <Space style={{ paddingTop: '1rem' }}>
          <SaveButton disabled={value.deleted} />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </>
  );
};
export default EditTransportDepoForm;
