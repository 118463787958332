import { notification } from 'antd';
import i18n from 'i18next';

export const handleHttpErrors = (response) => {
  if (!response.ok) {
    switch (response.status) {
      case 401:
      case 403:
        window.location.href = '/';
        return Promise.reject(response);
      case 400:
      case 409:
      case 413:
        return response.json().then((envelope) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          Promise.reject({ inner: envelope.payload || envelope }),
        );
      default:
        return Promise.reject(response);
    }
  }
  return response;
};

const flatObject = (object, target = {}, prev = '') => {
  Object.entries(object).forEach(([k, v]) => {
    const newKey = `${prev}.${k}`;
    if (typeof v === 'object' && v !== null) {
      flatObject(v, target, newKey);
    } else {
      // eslint-disable-next-line no-param-reassign
      target[newKey] = v;
    }
  });
  return target;
};

const pairToString = (key, value) => {
  if (Array.isArray(value)) {
    return value.map((subvalue) => pairToString(key, subvalue)).join('&');
  }

  if (typeof value === 'object' && value !== null) {
    return Object.entries(flatObject(value))
      .map(([flattenKey, subvalue]) =>
        pairToString(`${key}${flattenKey}`, subvalue),
      )
      .join('&');
  }

  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};

const getSearchQueryString = (data) =>
  [
    ...Object.entries(data?.criterias || {}).map(([key, value]) =>
      pairToString(key, value),
    ),
    ...[data?.pageNumber ? `page=${data.pageNumber - 1}` : null],
    ...[data?.pageSize ? `size=${data.pageSize}` : null],
    ...[
      data?.sort
        ? `sort=${encodeURIComponent(`${data.sort[0]},${data.sort[1]}`)}`
        : null,
    ],
  ]
    .filter((val) => val != null)
    .join('&');

const handleSpecificError = (text) => {
  const [errorKey, count, maxCount] = text.split(',');
  const errorMessage = i18n.t(`${errorKey}`, {
    count,
    maxCount,
  });
  notification.error({
    message: errorMessage,
  });
};

export const search = async (endpoint, data, options = {}) => {
  return fetch(
    `${window._env_.API_BACKEND_URL}${endpoint}${
      endpoint.endsWith('&') ? '' : '?'
    }${getSearchQueryString(data)}`,
    {
      method: 'GET',
      credentials: 'include',
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {}),
      },
    },
  )
    .then(handleHttpErrors)
    .then((resp) => resp.json());
};

export const get = async (endpoint, options = {}) =>
  fetch(`${window._env_.API_BACKEND_URL}${endpoint}`, {
    method: 'GET',
    credentials: 'include',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers || {}),
    },
  })
    .then(handleHttpErrors)
    .then((resp) => resp.json());

export const post = async (endpoint, data, options = {}) => {
  const { _pintoken, ...rest } = data || {};
  const query = _pintoken ? `?${pairToString('_pintoken', _pintoken)}` : '';

  return fetch(`${window._env_.API_BACKEND_URL}${endpoint}${query}`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: rest == null || Object.keys(rest) === 0 ? '' : JSON.stringify(rest),
    ...options,
  })
    .then(handleHttpErrors)
    .then((resp) => {
      const contentType = resp.headers.get('content-type');
      return contentType && contentType.indexOf('application/json') !== -1
        ? resp.json()
        : resp.text();
    });
};

export const put = async (endpoint, data, options = {}) => {
  // eslint-disable-next-line no-underscore-dangle
  let _pintoken;

  if (typeof data === 'object' && data != null) {
    // eslint-disable-next-line no-underscore-dangle
    _pintoken = data._pintoken;

    // eslint-disable-next-line no-underscore-dangle,no-param-reassign
    delete data._pintoken;
  }

  const query = _pintoken ? `?${pairToString('_pintoken', _pintoken)}` : '';

  return fetch(`${window._env_.API_BACKEND_URL}${endpoint}${query}`, {
    method: 'PUT',
    credentials: 'include',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers || {}),
    },
    body: JSON.stringify(data),
  })
    .then(handleHttpErrors)
    .then((resp) => {
      const contentType = resp.headers.get('content-type');
      return contentType && contentType.indexOf('application/json') !== -1
        ? resp.json()
        : resp.text();
    });
};

export const remove = async (endpoint, options = {}) =>
  fetch(`${window._env_.API_BACKEND_URL}${endpoint}`, {
    method: 'DELETE',
    credentials: 'include',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers || {}),
    },
  }).then(handleHttpErrors);

export const postFiles = async (endpoint, files, options = {}) => {
  if (files && files.length === 0) {
    return [];
  }
  const body = new FormData();
  files.forEach((file) => body.append('files', file, file.name));

  return fetch(`${window._env_.API_BACKEND_URL}${endpoint}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
    body,
    ...options,
  }).then(handleHttpErrors);
};

export const downloadFile = async (endpoint, options = {}) =>
  fetch(`${window._env_.API_BACKEND_URL}${endpoint}`, {
    method: 'GET',
    credentials: 'include',
    ...options,
  }).then(handleHttpErrors);

export const download = async (
  endpoint,
  data,
  options = {},
  changeToTxt = false,
) => {
  const url = `${window._env_.API_BACKEND_URL}${endpoint}${
    endpoint.endsWith('&') ? '' : '?'
  }${getSearchQueryString(data)}`;

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    ...options,
    headers: {
      'Content-Type': 'application/octet-stream',
      Accept: 'text/csv',
      ...(options.headers || {}),
    },
  });

  await handleHttpErrors(response);

  const blob = await response.blob();
  const text = await blob.text();
  const substringToCheck = text.substring(0, 20);

  if (substringToCheck.includes('errors')) {
    handleSpecificError(text);
    return;
  }

  const downloadUrl = window.URL.createObjectURL(blob);
  const anchorElement = document.createElement('a');
  anchorElement.href = downloadUrl;
  anchorElement.target = '_blank';
  anchorElement.download = `document${changeToTxt ? '.txt' : '.csv'}`;
  document.body.appendChild(anchorElement);
  anchorElement.click();
  anchorElement.remove();
};

export const genericCRUDService = (serviceUri, baseUri) => {
  const uri = `${serviceUri}${baseUri}`;
  return {
    findAll: (data) => search(uri, data),
    findOne: (id) => get(`${uri}/${id}`),
    create: (entity) => post(`${uri}`, entity),
    update: (entity) => put(`${uri}/${entity.id}`, entity),
    remove: (entity) => remove(`${uri}/${entity.id}`),
    downloadAll: (data) => download(`${uri}`, data),
  };
};
