import React from 'react';

import { Default } from '../../../../../layouts';

import { useEditPage } from '../../../../../hooks/useEditPage';

import EditTransportUnitForm from '../../../../../components/taxonomies/transports/EditTransportUnitForm';

import {
  create,
  findOne,
  update,
} from '../../../../../services/taxonomies/transport/units';

const initial = {};

const EditTransportUnit = () => {
  const [transportUnit, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/transport/units',

    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {transportUnit !== undefined && (
        <EditTransportUnitForm
          transportUnit={transportUnit}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditTransportUnit;
