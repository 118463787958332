import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/station-types';

export const getStationTypes = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/search`, data);

export const getStationTypeById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createStationType = async (stationType) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, stationType);

export const updateStationType = async (stationType) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${stationType.id}`, stationType);

export const deleteStationType = async (stationType) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${stationType.id}`);
