import React, { useMemo, useEffect } from 'react';

import { Table, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/columns';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import { getStationTypes } from '../../services/taxonomies/station-types';
import CancelButton from '../buttons/CancelButton';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll } from '../../services/taxonomies/zones';

const dictionaries = {
  zones: findAll,
};

const EditStationForm = ({ station, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        latitude: yup.string().required(t('errors.notEmpty')),
        longitude: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const [{ zones }] = useDictionaries(dictionaries);

  const { value, handleSubmit, input, setValue, setFormValue, select } =
    useForm({
      initialValue: station,
      schema: validationSchema,
      onSubmit,
      onCancel,
    });

  useEffect(() => {
    setFormValue(station);
  }, [setFormValue, station]);

  const { deleted } = value;

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.stationType._singular'), {
        width: 300,
      }),
      Column.bool('deleted', t('entity.taxonomy.stationType.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.stationType.deleted.true'),
          t('entity.taxonomy.stationType.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const { pagination, content, handleChange } = useDatasource(getStationTypes);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: value.stationTypes || [],
      onSelect: (record, selected) =>
        selected
          ? setValue('stationTypes', [...(value.stationTypes || []), record.id])
          : setValue(
              'stationTypes',
              value.stationTypes.filter((id) => id !== record.id),
            ),
      getCheckboxProps: (row) => ({
        disabled:
          row.deleted &&
          (value.stationTypes?.length > 0
            ? value.stationTypes?.filter((ar) => ar === row.id).length === 0
            : true),
      }),
    }),
    [value, setValue],
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={station.name || t('entity.taxonomy.station._singular')}
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.station.name')}
              props={{ ...input('name'), disabled: deleted }}
            />

            <FormWrapper.Number
              label={t('entity.taxonomy.station.latitude')}
              props={{ ...input('latitude'), disabled: deleted }}
            />

            <FormWrapper.Number
              label={t('entity.taxonomy.station.longitude')}
              props={{ ...input('longitude'), disabled: deleted }}
            />

            <FormWrapper.Select
              label={t('entity.taxonomy.station.zone')}
              props={{
                options: zones.content,
                allowClear: true,
                ...select('zone'),
              }}
            />
            <Space style={{ paddingTop: '1rem' }}>
              {!deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns}
              rowKey="id"
              // loading={loading}
              pagination={pagination}
              dataSource={content}
              onChange={handleChange}
            />
          </FormWrapper.Double>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditStationForm;
