import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import { findOne, create, update } from '../../../../services/admin/sectors';
import EditSectorForm from '../../../../components/taxonomies/sectors/EditSectorForm';

const initial = { userIds: [] };

const EditSector = () => {
  const [sector, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/sectors',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {sector !== undefined && (
        <EditSectorForm
          sector={sector}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditSector;
