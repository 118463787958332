import { search, get, post, put, download } from '../../helpers/api';

export const SERVICE_URI = '/stocks';
export const BASE_URI = '/api/v1/invoices';

export const getValidTypesByEntityType = (group) =>
  get(`${SERVICE_URI}${BASE_URI}/${group}?types`);

export const findAllByEntityType = (group) => (data) =>
  search(`${SERVICE_URI}${BASE_URI}/${group}`, data);

export const downloadAllByEntityType = (group) => (spec) =>
  download(`${SERVICE_URI}${BASE_URI}/${group}`, spec);

export const findOneByType = (invoiceType) => (id) =>
  get(`${SERVICE_URI}${BASE_URI}/${invoiceType}/${id}`);

export const create = (invoice) =>
  post(
    `${SERVICE_URI}${BASE_URI}/${invoice.invoiceType.toLowerCase()}/`,
    invoice,
  );

export const update = (invoice, action) =>
  put(
    `${SERVICE_URI}${BASE_URI}/${invoice.invoiceType.toLowerCase()}/${
      invoice.id
    }${action ? `/${action}` : ''}`,
    invoice,
  );

export const findConflicts = (invoiceType, invoiceId) =>
  get(`${SERVICE_URI}${BASE_URI}/${invoiceType}/${invoiceId}/conflicts`);

export const resolveConflicts = (invoiceType, invoiceId, resolutions) =>
  post(
    `${SERVICE_URI}${BASE_URI}/${invoiceType}/${invoiceId}/conflicts`,
    resolutions,
  );
