import React from 'react';
import { Form, Input, Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ShowColumns } from '../../../user-types/ShowColumns';

const UserTypesFields = ({
  showColumns,
  subdivisions,
  changeAttachments,
  handleChangeSubdiv,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {(showColumns || []).map((it) => {
        switch (it) {
          case ShowColumns.SUB_INSTITUTION_ID:
            return (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col span={24}>
                  <Form.Item
                    name="subdivisionId"
                    label={t('entity.admin.user.subdivisionIdNew')}
                  >
                    {/* <Select onChange={handleChangeSubdiv}> */}
                    <Select
                      /* onChange={handleChangeSubdiv} */
                      allowClear
                      onChange={handleChangeSubdiv}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {subdivisions.map((s) => (
                        <Select.Option
                          key={s.id}
                          value={s.id}
                          disabled={s.disabled || s.deleted}
                        >
                          {s.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            );
          default:
          case ShowColumns.META_DATA_1:
            return (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col span={10}>
                  <Form.Item
                    name="metaData1"
                    label={t('entity.admin.user.metaData1New')}
                  >
                    <Input onBlur={changeAttachments} />
                  </Form.Item>
                </Col>
              </Row>
            );
        }
      })}
    </>
  );
};
export default UserTypesFields;
