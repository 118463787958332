import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/route-groups';

export const getRouteGroups = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getRouteGroupById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createRouteGroup = async (routeGroup) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, routeGroup);

export const updateRouteGroup = async (routeGroup) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${routeGroup.id}`, routeGroup);

export const deleteRouteGroup = async (routeGroup) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${routeGroup.id}`);
