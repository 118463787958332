import { get, search } from '../../helpers/api';

export const SERVICE_URI = '/reporting';
export const BASE_URI = '/v1/reporting';

export const listReports = () => search(`${SERVICE_URI}${BASE_URI}`);

export const getReportDefinition = (reportUri) =>
  get(`${SERVICE_URI}${BASE_URI}${reportUri}`);

export const generateReport = (reportUri, params, filename) =>
  fetch(
    `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}${reportUri}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      },
      body: JSON.stringify(params),
    },
  )
    .then((res) =>
      res.blob().then((val) => {
        return res.ok ? Promise.resolve(val) : Promise.reject(val);
      }),
    )
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
