import React from 'react';

import { Default } from '../../../../layouts';
import { useEditPage } from '../../../../hooks/useEditPage';
import {
  getScheduleById,
  createSchedule,
  updateSchedule,
} from '../../../../services/offers/schedules';
import EditScheduleForm from '../../../../components/offers/schedules/EditScheduleForm';

const initialSchedule = { deleted: false, days: [], holidays: [] };

const EditSchedule = () => {
  const [schedule, handleSubmit, handleCancel] = useEditPage({
    initial: initialSchedule,
    existent: getScheduleById,
    goBackPath: '/admin/offers/schedules',

    onCreate: createSchedule,
    onUpdate: updateSchedule,
  });

  return (
    <Default>
      {schedule !== undefined && (
        <EditScheduleForm
          schedule={schedule}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditSchedule;
