import { genericCRUDService } from '../../helpers/api';

export const { findOne, create, update, remove } = genericCRUDService(
  '/validators',
  '/api/v1/validators',
);

export const { findAll, downloadAll } = genericCRUDService(
  '/validators',
  '/api/v1/view-validators',
);
