import React, { useMemo, useCallback } from 'react';
import { Table, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import Column from '../../helpers/columns';
import FormWrapper from '../FormWrapper';
import DeleteItemIcon from '../buttons/DeleteItemIcon';

const functs = Object.freeze([
  { id: 1, name: 'Deschide serviciu' },
  { id: 2, name: 'Inchide serviciu' },
  { id: 4, name: 'Control' },
  { id: 5, name: 'Deblocare card' },
  { id: 6, name: 'Configurare' },
  { id: 7, name: 'Deschide turnichet' },
  { id: 8, name: 'Deschide comercial' },
  { id: 10, name: 'Consola sofer' },
]);

const validatorStates = Object.freeze([
  { id: 'INCHIS', name: 'Inchis' },
  { id: 'GARAJ', name: 'Garaj' },
  { id: 'ACCES/RETR', name: 'Acces' },
  { id: 'COMERCIAL', name: 'Comercial' },
  { id: 'TRANSBORDARE', name: 'Transbordare' },
  { id: 'STATISTICA', name: 'Statistica' },
  { id: 'CONTROL', name: 'Control' },
]);

const RoleActions = ({ form }) => {
  const { value, select, setValue } = form;
  const { t } = useTranslation();

  const actions = useMemo(
    () => (value.actions || []).sort((a, b) => a.functionId - b.functionId),
    [value.actions],
  );

  const functions = useMemo(() => {
    const selected = new Set(actions.map((a) => a.functionId));
    return functs.map((fun) => ({ ...fun, disabled: selected.has(fun.id) }));
  }, [actions]);

  const disabledAddButton = useMemo(() => {
    return (
      actions.some(
        (a) => !a.functionId || !a.validStatuses || !a.validStatuses.length,
      ) || functions.length <= actions.length
    );
  }, [functions, actions]);

  const columns = useMemo(
    () => [
      {
        width: '20%',
        title: `${t('entity.admin.action.functionId')} - ${t(
          'entity.admin.action.description',
        )}`,
        render: (_, record, index) => {
          return (
            <FormWrapper.Select
              props={{
                showArrow: true,
                size: 'small',
                options: functions,
                style: { width: '100%' },
                labelProp: (funct) => `${funct.id}-${funct.name}`,
                ...select(`actions[${index}].functionId`),
              }}
            />
          );
        },
      },
      {
        width: '70%',
        title: t('entity.admin.action.validStates'),
        render: (_, record, index) => {
          return (
            <FormWrapper.Select
              props={{
                mode: 'multiple',
                size: 'small',
                options: validatorStates,
                ...select(`actions[${index}].validStatuses`),
              }}
            />
          );
        },
      },
      Column.actions(
        t('table.actions'),
        (record) => {
          return (
            <>
              <DeleteItemIcon
                popDisabled
                title={t('entity.admin.action._delete')}
                // title="Sterge inregistrarea"
                item={record}
                action={async () => {
                  setValue(
                    'actions',
                    actions.filter((rec) => record !== rec),
                  );
                }}
                reload={() => null}
              />
            </>
          );
        },
        { width: '10%' },
      ),
    ],
    [t, select, functions, actions, setValue],
  );

  const onAdd = useCallback(
    () => setValue('actions', [...actions, { key: Math.random() }]),
    [setValue, actions],
  );
  return (
    <>
      <Row style={{ paddingBottom: '12px' }}>
        <Col xs={12} />
        <Col xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onAdd}
            disabled={disabledAddButton}
          >
            {t('actions.add')} {t('entity.admin.action._singular')}
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey={(record) => record.functionId || `${record.id}`}
        dataSource={actions}
      />
    </>
  );
};

export default RoleActions;
