import { genericCRUDService, search, download } from '../../helpers/api';

export const SERVICE_URI = '/validators';
export const BASE_URI = '/api/v1/inspector-transactions';

const { findAll, downloadAll } = genericCRUDService(SERVICE_URI, BASE_URI);

const findAllByInspectorId = async (data, id) =>
  search(`${SERVICE_URI}/${BASE_URI}`, {
    ...data,
    criterias: { ...data.criterias, inspectorId: [id] },
  });

const downloadAllByInspectorId = async (id, spec) =>
  download(`${SERVICE_URI}/${BASE_URI}`, {
    criterias: { ...spec.criterias, inspectorId: [id] },
    spec,
  });

export { findAll, findAllByInspectorId, downloadAllByInspectorId, downloadAll };
