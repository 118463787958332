import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';

import {
  findAll as findAllArticleTypes,
  remove,
} from '../../../../services/stocks/article-types';

import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import Column from '../../../../helpers/columns';
import useDictionaries from '../../../../hooks/useDictionaries';
import { findAll as findAllTokenSubtypes } from '../../../../services/taxonomies/token-subtypes';

const dictionaries = {
  tokenSubtypes: findAllTokenSubtypes,
};

const ArticleTypesList = () => {
  const { t } = useTranslation();

  const [{ tokenSubtypes }] = useDictionaries(dictionaries);

  const { pagination, content, handleChange, reload } =
    useDatasource(findAllArticleTypes);

  const columns = useMemo(
    () => [
      Column.text('code', t('entity.taxonomy.articleType.code'), {
        filter: true,
        width: 100,
      }),
      Column.text('name', t('entity.taxonomy.articleType.name'), {
        filter: true,
      }),
      Column.text('description', t('entity.taxonomy.articleType.description')),
      Column.dictionary(
        'tokenSubtypeId',
        t('entity.taxonomy.tokenSubtype._singular'),
        tokenSubtypes,
        { dictLabel: 'name' },
      ),
      Column.bool('deleted', t('entity.taxonomy.articleType.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.articleType.deleted.true'),
          t('entity.taxonomy.articleType.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.bool('enrollable', t('entity.taxonomy.articleType.enrollable._'), {
        width: 100,
        filter: true,
        inverted: false,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.articleType.enrollable.true'),
          t('entity.taxonomy.articleType.enrollable.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.text(
        'measurementUnit',
        t('entity.taxonomy.articleType.measurementUnit'),
        {
          filter: true,
        },
      ),
      Column.actions(t('table.actions'), (record) => (
        <>
          <EditItemIcon path={`/admin/taxonomies/article-types/${record.id}`} />
          <DeleteItemIcon
            title={t('entity.taxonomy.articleType._delete', record)}
            message={t('entity.taxonomy.articleType._deleted', record)}
            item={record}
            action={remove}
            reload={reload}
          />
        </>
      )),
    ],
    [t, tokenSubtypes, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.articleType._plural')}>
        <AddEntityButton
          entity={t('entity.taxonomy.articleType._singular')}
          path="/admin/taxonomies/article-types/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default ArticleTypesList;
