import React, { useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { Default } from '../../layouts';

import { useEditPage } from '../../hooks/useEditPage';

import {
  findOneByType,
  create,
  update,
  resolveConflicts,
} from '../../services/stocks/invoices';

import PageTitle from '../../components/PageTitle';
import EditInvoiceForm from '../../components/stocks/EditInvoiceForm';

const EditInvoice = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const params = useParams();

  const { invoiceType } = params;

  const initial = useMemo(
    () => ({
      invoiceDate: moment(new Date()),
      invoiceType,
      status: 'CREATED',
      records: [
        {
          position: 1,
          enrolled: false,
          unitPrice: 0,
          totalWithoutTva: 0,
          tva: 0,
          totalWithTva: 0,
        },
      ],
    }),
    [invoiceType],
  );

  const existent = useCallback(
    (id) =>
      findOneByType(invoiceType)(id).then((res) => ({
        ...res,
        invoiceType,
        invoiceDate: moment(res.invoiceDate),
      })),
    [invoiceType],
  );

  const handleCreate = useCallback(
    (value) => {
      return create({ ...value, invoiceType });
    },
    [invoiceType],
  );

  const handleUpdate = useCallback(
    (value) => {
      const { _action, ...rest } = value;
      switch (_action) {
        case 'resolve':
          return resolveConflicts(
            invoiceType,
            value.id,
            rest.records
              // eslint-disable-next-line no-underscore-dangle
              .filter((record) => record.resolutionStatus)
              .reduce(
                (acc, record) => ({
                  ...acc,
                  // eslint-disable-next-line no-underscore-dangle
                  [record.id]: record.resolutionStatus,
                }),
                {},
              ),
          );
        default:
          return update({ ...rest, invoiceType }, _action);
      }
    },
    [invoiceType],
  );

  const [invoice, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent,
    goBackPath: history.goBack,
    onCreate: handleCreate,
    onUpdate: handleUpdate,
  });

  return (
    <Default>
      <PageTitle title={t(`entity.stocks.invoice.types.${invoiceType}`)} />

      {invoice && (
        <EditInvoiceForm
          invoice={invoice}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditInvoice;
