import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/retailers';

export const getRetailers = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/`, data);

export const getRetailerById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createRetailer = async (retailer) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, retailer);

export const updateRetailer = async (retailer) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${retailer.id}`, retailer);

export const deleteRetailer = async (retailer) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${retailer.id}`);
