import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Table } from 'antd';
import { getAlertList } from '../../../services/tvm/alerts';
import PageTitle from '../../../components/PageTitle';
import useDatasource from '../../../hooks/useDatasource';
import Column from '../../../helpers/columns';

const AlertsDashboard = ({ id }) => {
  const { t } = useTranslation();
  const { pagination, content, handleChange } = useDatasource((args) =>
    getAlertList({
      ...args,
      criterias: {
        ...args.criterias,
        tvmId: id,
      },
    }),
  );

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.tvm.alerts.columns.id'), { width: 100 }),
      Column.text('tvmAlertId', t('entity.tvm.alerts.columns.tvmAlertId'), {
        width: 200,
      }),
      Column.date('timestamp', t('entity.tvm.alerts.columns.timestamp'), {}),
      Column.text('alertType', t('entity.tvm.alerts.columns.alertType'), {
        width: 200,
      }),
      Column.text('description', t('entity.tvm.alerts.columns.description'), {
        width: 200,
      }),
      {
        ...Column.bool('transfered', t('entity.tvm.alerts.columns.transfered')),
        render: (value, { transfer }) => (
          <Badge color={value ? 'green' : 'red'} text={transfer} />
        ),
      },
      Column.text('alertSource', t('entity.tvm.alerts.columns.alertSource'), {
        width: 200,
      }),
      {
        ...Column.bool('isClosed', t('entity.tvm.alerts.columns.isClosed')),
        render: (value, { isClosed }) => (
          <Badge color={value ? 'green' : 'red'} text={isClosed} />
        ),
      },
      Column.text('tvmId', t('entity.tvm.alerts.columns.tvmId'), {
        width: 200,
      }),
      Column.date('created', t('entity.tvm.alerts.columns.created'), {
        width: 200,
      }),
    ],
    [t],
  );

  return (
    <>
      <PageTitle title={t('entity.tvm.alerts.title')} />
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ y: 2000 }}
      />
    </>
  );
};

export default AlertsDashboard;
