import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Badge, Space, Tabs } from 'antd';
import useForm, { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import StationsList from './StationsList';
import StationsSelection from './StationsSelection';
import CancelButton from '../buttons/CancelButton';

const routeTypes = [
  { id: 'NORMAL', name: 'Normal' },
  { id: 'TRANSBORDARE', name: 'Transbordare' },
  { id: 'CIRCULARA', name: 'Circulara' },
];

const EditRouteForm = ({ route, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        code: yup.number().required(t('errors.notEmpty')),
        name: yup.string().required(t('errors.notEmpty')),
        routeType: yup.string().required(t('errors.notEmpty')),
        stations: yup.array().min(1, t('errors.notEmpty')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: route,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const {
    value,
    handleSubmit,
    input,
    setFormValue,
    select,
    setValue,
    errorMessages,
  } = form;

  useEffect(() => {
    setFormValue(route);
  }, [setFormValue, route]);

  const { deleted } = value;

  return (
    <>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <PageTitle
            title={route.name || t('entity.taxonomy.route._singular')}
          />

          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.Number
                label={t('entity.taxonomy.route.code')}
                props={{ ...input('code'), disabled: deleted }}
              />
              <FormWrapper.Input
                label={t('entity.taxonomy.route.name')}
                props={{ ...input('name'), disabled: deleted }}
              />

              <FormWrapper.Select
                label={t('entity.taxonomy.route.routeType')}
                props={{
                  disabled: deleted,
                  allowClear: true,
                  options: routeTypes,
                  onClear: () => setValue('routeType', null),
                  ...select('routeType'),
                }}
              />

              <Space style={{ paddingTop: '1rem' }}>
                {!deleted && <SaveButton />}
                <CancelButton onCancel={onCancel} />
              </Space>
            </FormWrapper.Single>

            <FormWrapper.Double>
              <Tabs>
                <Tabs.TabPane
                  tab={t('entity.taxonomy.route.tabs.selectedStations')}
                  key="sellers"
                >
                  <StationsSelection />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <span>
                      {t('entity.taxonomy.route.tabs.stations')}
                      {errorMessages && errorMessages.stations && (
                        <Badge
                          count={1}
                          style={{ position: 'absolute', top: -25, right: -20 }}
                        />
                      )}
                    </span>
                  }
                  key="pos"
                >
                  <StationsList />
                </Tabs.TabPane>
              </Tabs>
            </FormWrapper.Double>
          </FormWrapper>
        </form>
      </FormContext.Provider>
    </>
  );
};
export default EditRouteForm;
