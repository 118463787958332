import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Popconfirm, Tooltip } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const PopupConfirmButton = ({
  title,
  item,
  buttonName,
  action,
  disabled = false,
  size = 'middle',
  className,
  formRef,
}) => {
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    action(item, null);
    formRef.current.requestSubmit();
  }, [action, item, formRef]);

  return (
    <Popconfirm
      placement="topLeft"
      title={title}
      buttonName={buttonName}
      disabled={disabled}
      onConfirm={handleConfirm}
      okText={t('actions.confirm')}
      cancelText={t('actions.cancel')}
      okButtonProps={{ htmlType: 'submit', type: 'primary' }}
    >
      <Tooltip title={buttonName}>
        <Button
          className={className}
          type="primary"
          icon={<SaveOutlined />}
          disabled={disabled}
          size={size}
        >
          {buttonName}
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};

export default PopupConfirmButton;
