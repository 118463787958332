import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import Default from '../../../../layouts/Default';
import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import {
  getOfferTypes,
  deleteOfferType,
  updateOfferTypes,
} from '../../../../services/offers/offerTypes';
import useDatasource from '../../../../hooks/useDatasource';
import Column from '../../../../helpers/columns';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import DragSortingTable from '../../../../components/DragSortingTable';

const OfferTypesList = () => {
  const { t } = useTranslation();

  const { content, reload, pagination, handleChange } =
    useDatasource(getOfferTypes);

  const columns = useMemo(
    () => [
      Column.text('order', t('entity.offers.offerType.order'), {
        width: 70,
        filter: false,
        sort: false,
      }),
      Column.text('name', t('entity.offers.offerType.name._'), {
        filter: false,
        sort: false,
      }),
      Column.text('createdBy', t('entity.offers.offerType.createdBy'), {
        sort: false,
      }),
      Column.date('createdAt', t('entity.offers.offerType.createdAt'), {
        sort: false,
      }),
      Column.bool('deleted', t('entity.offers.offerType.deleted._'), {
        filter: false,
        sort: false,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.offers.offerType.deleted.true'),
          t('entity.offers.offerType.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (offerType) => (
        <>
          <EditItemIcon path={`/admin/offers/offer-types/${offerType.id}`} />
          <DeleteItemIcon
            title={t('entity.offers.offerType._delete', offerType)}
            message={t('entity.offers.offerType._deleted', offerType)}
            item={offerType}
            action={deleteOfferType}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  const [ofrTypes, setOfrTypes] = useState([]);

  useEffect(() => {
    setOfrTypes(content);
  }, [content]);

  const moveRow = useCallback(
    (oldOrder, newOrder) => {
      if (oldOrder === newOrder) {
        return;
      }
      const oldOrderList = [...ofrTypes];

      const moveElem = ofrTypes[oldOrder];
      ofrTypes.splice(oldOrder, 1);
      ofrTypes.splice(newOrder, 0, moveElem);

      updateOfferTypes(ofrTypes.map((ety, idx) => ({ ...ety, order: idx + 1 })))
        .then(reload)
        .catch((msg) => {
          setOfrTypes(oldOrderList);
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};
          notification.error({
            message: messageCode
              ? t(messageCode, inner)
              : t('actions.saveFailed'),
          });
        });
    },
    [ofrTypes, reload, t],
  );

  return (
    <Default>
      <PageTitle title={t('entity.offers.offerType._plural')}>
        <AddEntityButton
          entity={t('entity.offers.offerType._singular')}
          path="/admin/offers/offer-types/new"
        />
      </PageTitle>
      <DragSortingTable
        moveRow={moveRow}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={ofrTypes}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default OfferTypesList;
