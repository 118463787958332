import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Col, Row, message } from 'antd';

import { useTranslation } from 'react-i18next';
import POSButton from '../POSButton';
import POSContext from '../POSContext';

import {
  cancelOrder,
  getAvailableCards,
  setOrderCardType,
} from '../../../services/pos/pos';
import useCard from '../../../hooks/useCard';
import { getEnrolledProduct } from '../../../services/stocks/cards';
import { showMessage } from '../../../helpers/printing';

const SelectCard = () => {
  const { shift, order, setOrder } = useContext(POSContext);
  const { t } = useTranslation();
  const [cards, setCards] = useState([]);
  const [productId, setProductId] = useState(null);

  const handleEnrollExternalCard = useCallback(
    async (physicalId) => {
      if (physicalId && physicalId !== 0) {
        try {
          const product = await getEnrolledProduct(physicalId);
          if (product.status === 'ENROLLED') {
            const crd = {
              productId,
              physicalId,
              logicalId: product?.serial,
            };

            setOrderCardType(shift, order, { ...crd })
              .then((res) => setOrder(res))
              .catch((err) => {
                console.error(err);
                message.error({
                  content: 'Tipul nu poate fi utilizat',
                  key: 'pos',
                  duration: 5,
                  className: 'card-message',
                });
              });
          } else if (product.status === 'BLOCKED') {
            showMessage('error', t('errors.blockedCardEnrolled'));
          } else if (product.status === 'SOLD') {
            showMessage('error', t('errors.soldCardEnroll'));
          } else {
            showMessage('error', t('errors.errorCardEnroll'));
          }
        } catch (err) {
          const { inner } = err || {};
          const { _: messageCode } = inner || {};
          showMessage(
            'error',
            messageCode ? t(messageCode) : t('errors.errorOccurred'),
          );
        }
      }
    },
    [order, setOrder, shift, t, productId],
  );

  const onCardScan = useCallback(() => {
    message.loading({
      content: 'Identificare card',
      key: 'pos',
      duration: 0,
      className: 'card-message',
    });
  }, []);

  const onCardFound = useCallback(
    (card) => {
      message.success({
        // content: `Card identificat: ${card}`,
        content: t('errors.identifiedCard', { card }),
        key: 'pos',
        duration: 1,
        className: 'card-message',
      });
      handleEnrollExternalCard(card);
    },
    [handleEnrollExternalCard, t],
  );

  const onCardMissing = useCallback(() => {
    message.error({
      content: t('errors.cardNotFound'),
      key: 'pos',
      duration: 5,
      className: 'card-message',
    });
  }, [t]);

  const [, , fetchCard, cancelFetchCard] = useCard({
    onCardScan,
    onCardFound,
    onCardMissing,
  });

  useEffect(() => {
    getAvailableCards(shift, order)
      .then((c) => setCards(c))
      .catch((err) => {
        console.error(err);
        setCards([]);
      });
  }, [shift, order]);

  const handleSelectCard = useCallback(
    (selectedCard) => {
      if (selectedCard.externalCardType) {
        setProductId(selectedCard?.productId);
        fetchCard();
      } else {
        cancelFetchCard();
        setOrderCardType(shift, order, { ...selectedCard })
          .then((res) => setOrder(res))
          .catch((err) => {
            console.error(err);
            message.error({
              content: t('errors.unusableType'),
              key: 'pos',
              duration: 5,
              className: 'card-message',
            });
          });
      }
    },
    [order, setOrder, shift, fetchCard, cancelFetchCard, t],
  );

  const handleCancelOrder = useCallback(() => {
    cancelOrder(shift, order)
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.error(err);
        message.error({
          content: 'Eroare la anulare comanda',
          key: 'pos',
          duration: 5,
          className: 'card-message',
        });
      });
  }, [shift, order, setOrder]);

  return (
    <div className="pos">
      <div className="pos__wrapper">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          {(cards || []).map((card) => (
            <Col span={4} key={card.id}>
              <POSButton
                title={card.name}
                color="green"
                onClick={() =>
                  handleSelectCard({
                    productId: card.id,
                    externalCardType: card.external,
                  })
                }
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className="pos__actions">
        <div>
          <POSButton
            title="Anulare"
            color="red"
            size="small"
            onClick={handleCancelOrder}
          />
        </div>

        <div>&nbsp;</div>
      </div>
    </div>
  );
};

export default SelectCard;
