import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import { FormContext } from '../../hooks/useForm';
import { getRetailUsers } from '../../services/admin/users';
import Column from '../../helpers/columns';

const PosSellersList = () => {
  const form = useContext(FormContext);

  const { t } = useTranslation();

  const { value, setValue, isSubmitting } = form;

  const { id, retailer, sellers } = value;
  const [unselected, setUnselected] = useState([]);

  const columns = useMemo(
    () => [
      Column.text('fullname', t('entity.admin.user.fullname'), {
        filter: true,
      }),
      Column.text('identityCard', t('entity.admin.user.identityCard'), {
        filter: true,
      }),
      Column.text('retailerName', t('entity.taxonomy.retailer._singular')),
      Column.text('posName', t('entity.taxonomy.poses._singular')),
    ],
    [t],
  );

  const { pagination, content, handleChange, reload } = useDatasource((arg) => {
    return getRetailUsers({
      ...arg,
      criterias: {
        ...arg.criterias,
        retailerId: retailer,
        posId: id,
        pos: '',
      },
    });
  });

  const handleSelect = useCallback(
    (record, selected) => {
      if (selected) {
        setValue('sellers', [...(sellers || []), record.id]);
        setUnselected(unselected?.filter((seller) => seller !== record.id));
      } else {
        setValue(
          'sellers',
          sellers.filter((seller) => seller !== record.id),
        );
        setUnselected([...(unselected || []), record.id]);
      }
    },
    [sellers, unselected, setValue],
  );

  useEffect(() => {
    if (isSubmitting === false) {
      reload();
    }
  }, [retailer, reload, isSubmitting]);

  const rowSelection = useMemo(
    () => ({
      onSelect: handleSelect,
      selectedRowKeys: sellers,
      getCheckboxProps: (row) => ({
        disabled:
          !(row.posId === null || row.posId === id) ||
          (row.status === 'DELETED' &&
            (value.sellers?.length > 0
              ? value.sellers?.filter((ar) => ar === row.id).length === 0
              : true)),
      }),
    }),
    [handleSelect, sellers, value.sellers, id],
  );

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns}
      rowKey="id"
      // loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
    />
  );
};

export default PosSellersList;
