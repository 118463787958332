import React, { useMemo, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Space, Row, Col } from 'antd';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';

const DURATION_TYPES = [
  { id: 'DAY' },
  { id: 'WEEK' },
  { id: 'MONTH' },
  { id: 'YEAR' },
  { id: 'CALENDAR_MONTH' },
  { id: 'CALENDAR_HALF_MONTH' },
  { id: 'CALENDAR_YEAR' },
  { id: 'CALENDAR_DAY' },
  { id: 'CALENDAR_WEEK' },
  { id: 'SCHOOL_YEAR' },
  { id: 'PENSIONER_YEAR' },
  { id: 'ACADEMIC_YEAR' },
];

const EditDocTypeForm = ({ docType, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        code: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const {
    value,
    handleSubmit,
    input,
    select,
    checkbox,
    setFormValue,
    setValue,
  } = useForm({
    initialValue: docType,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  useEffect(() => {
    setFormValue(docType);
  }, [setFormValue, docType]);

  const { deleted } = value;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={docType.name || t('entity.taxonomy.docType._singular')}
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.docType.name')}
              props={{ ...input('name'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.docType.description')}
              props={{ ...input('description'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.docType.code')}
              props={{ ...input('code'), disabled: deleted }}
            />

            <FormWrapper.Switch
              label={t('entity.taxonomy.docType.docRequired')}
              props={{
                ...checkbox('docRequired'),
                onChange: (val) => {
                  setValue('docRequired', val);
                },
              }}
            />

            <FormWrapper.Switch
              label={t('entity.taxonomy.docType.hasExpirationDate')}
              props={{
                ...checkbox('hasExpirationDate'),
                onChange: (v1) => {
                  setValue('hasExpirationDate', v1);
                  if (!v1) {
                    setValue('durationType', null);
                    setValue('duration', null);
                    setValue('dateChangeable', false);
                  }
                },
                disabled: deleted,
              }}
            />
            <Row gutter={8}>
              <Col span={12}>
                <FormWrapper.Select
                  label={t('entity.taxonomy.docType.durationType._')}
                  props={{
                    options: DURATION_TYPES,
                    ...select('durationType'),
                    disabled: value.deleted || !value.hasExpirationDate,
                    onChange: (v1) => {
                      setValue('durationType', v1);
                      if (!v1) {
                        setValue('duration', null);
                        setValue('dateChangeable', false);
                      }
                    },
                    allowClear: true,
                    labelProp: (val) =>
                      t(`entity.taxonomy.docType.durationType.${val.id}`),
                  }}
                />
              </Col>
              <Col span={12}>
                <FormWrapper.Number
                  label={t('entity.taxonomy.docType.duration')}
                  props={{
                    ...input('duration'),
                    min: 1,
                    disabled:
                      value.deleted ||
                      !value.hasExpirationDate ||
                      !value.durationType,
                  }}
                />
              </Col>
            </Row>
            <FormWrapper.Switch
              label={t('entity.taxonomy.docType.dateChangeable')}
              props={{
                ...checkbox('dateChangeable'),
                disabled:
                  deleted || !value.hasExpirationDate || !value.durationType,
              }}
            />

            <Space style={{ paddingTop: '1rem' }}>
              {!deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditDocTypeForm;
