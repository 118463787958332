import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { FormContext } from '../../../../../hooks/useForm';
import useDatasource from '../../../../../hooks/useDatasource';

import Column from '../../../../../helpers/columns';

import FormWrapper from '../../../../../components/FormWrapper';

const TaxonomyGrid = ({ fieldName, getTaxonomyData }) => {
  const { t } = useTranslation();

  const form = useContext(FormContext);

  const { value, setValue, errorMessages } = form;

  const { pagination, content, handleChange } = useDatasource(getTaxonomyData);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.grid.name'), {
        filter: true,
        width: 300,
        fixed: 'left',
      }),
      Column.text('description', t('entity.taxonomy.grid.description')),
      Column.bool('deleted', t('entity.taxonomy.grid.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.grid.deleted.true'),
          t('entity.taxonomy.grid.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: value[fieldName],
      onSelect: (record, selected) =>
        selected
          ? setValue(fieldName, [...(value[fieldName] || []), record.id])
          : setValue(
              fieldName,
              value[fieldName].filter((id) => id !== record.id),
            ),
      getCheckboxProps: ({ deleted, id }) => ({
        disabled:
          deleted &&
          (value[fieldName]?.length > 0
            ? value[fieldName]?.indexOf(id) === -1
            : true),
      }),
    }),
    [setValue, value, fieldName],
  );

  return (
    <>
      <FormWrapper.ErrorMessage message={errorMessages?.products} />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default TaxonomyGrid;
