import React from 'react';
import CountryForm from '../../../../components/countries/CountryForm';
import { useEditPage } from '../../../../hooks/useEditPage';
import { Default } from '../../../../layouts';
import {
  getCountryById,
  createCountry,
  updateCountry,
} from '../../../../services/taxonomies/countires';

const initial = {};

const EditCountry = () => {
  const [country, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: getCountryById,
    goBackPath: '/admin/taxonomies/countries',
    onCreate: createCountry,
    onUpdate: updateCountry,
  });

  return (
    <Default>
      {country !== undefined && (
        <CountryForm
          country={country}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditCountry;
