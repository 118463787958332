import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';

import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import CopyEntityIcon from '../../../../components/buttons/CopyEntityIcon';
import { getTVARates } from '../../../../services/taxonomies/tva-rate';

import Column from '../../../../helpers/columns';

import {
  deleteProduct,
  getProducts,
} from '../../../../services/offers/products';
import useDictionaries from '../../../../hooks/useDictionaries';
import { getProductsWithCurrentPrice } from '../../../../components/offers/ProductsGrid';
import {
  NominalTypes,
  ProductCategories,
} from '../../../../components/offers/OffersConstants';

const dictionaries = {
  tvaRates: getTVARates,
};

const ProductsList = () => {
  const { t } = useTranslation();

  const [{ tvaRates }] = useDictionaries(dictionaries);

  const { pagination, content, handleChange, reload } =
    useDatasource(getProducts);

  const columns = useMemo(
    () => [
      Column.label('id', t('entity.offers.product.id'), { width: 100 }),
      Column.text('name', t('entity.offers.product.name._'), { filter: true }),
      Column.text('price', t('entity.offers.product.price')),

      Column.dictionary(
        'vatRateId',
        t('entity.offers.product.vatRate'),
        tvaRates,
        { dictLabel: 'description' },
      ),

      Column.text('unit', t('entity.offers.product.unit')),
      Column.text('duration', t('entity.offers.product.duration')),

      Column.dictionary(
        'category',
        t('entity.offers.product.category._'),
        ProductCategories,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) => t(`entity.offers.product.category.${entry.id}`),
        },
      ),
      Column.dictionary(
        'nominalType',
        t('entity.offers.product.nominalType._'),
        NominalTypes,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`entity.offers.product.nominalType.${entry.id}`),
        },
      ),
      Column.date('dateStart', t('entity.taxonomy.tvaRate.dateStart'), {
        filter: true,
      }),
      Column.date('dateEnd', t('entity.taxonomy.tvaRate.dateEnd'), {
        filter: true,
      }),

      Column.bool('deleted', t('common.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('common.deleted.true'),
          t('common.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),

      Column.actions(t('table.actions'), (product) => (
        <>
          <CopyEntityIcon path={`/admin/offers/products/${product.id}/clone`} />
          <EditItemIcon path={`/admin/offers/products/${product.id}`} />
          <DeleteItemIcon
            title={t('entity.offers.product._delete', product)}
            message={t('entity.offers.product._deleted', product)}
            item={product}
            action={deleteProduct}
            reload={reload}
          />
        </>
      )),
    ],
    [t, tvaRates, reload],
  );

  const processedContent = useMemo(
    () => getProductsWithCurrentPrice(content),
    [content],
  );

  return (
    <Default>
      <PageTitle title={t('entity.offers.product._plural')}>
        <AddEntityButton
          entity={t('entity.offers.product._singular')}
          path="/admin/offers/products/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={processedContent}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default ProductsList;
