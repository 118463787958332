import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Space, Table } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/columns';
import {
  findAll,
  downloadAll,
} from '../../services/validators/travelers-comparation';
import PageTitle from '../PageTitle';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';

const TravelersComparationList = () => {
  const { t } = useTranslation();

  const { pagination, sort, criterias, content, handleChange } =
    useDatasource(findAll);
  const columns = useMemo(
    () => [
      Column.date(
        'validationDate',
        t('pages.reports.travelers.list.columns.validationDate'),
        {
          filter: true,
          format: 'DD-MM-YYYY',
          removeLocalOffset: true,
          showTime: false,
        },
      ),
      Column.text(
        'vehicleId',
        t('pages.reports.travelers.list.columns.vehicleId'),
        {
          filter: true,
        },
      ),
      Column.text(
        'vehiclePlateNo',
        t('pages.reports.travelers.list.columns.vehiclePlateNo'),
        {
          filter: true,
        },
      ),
      Column.text(
        'routeId',
        t('pages.reports.travelers.list.columns.routeId'),
        {
          filter: true,
        },
      ),
      Column.text(
        'validationsCount',
        t('pages.reports.travelers.list.columns.validationsCount'),
        {},
      ),
      Column.text(
        'embarks',
        t('pages.reports.travelers.list.columns.embarks'),
        {},
      ),
    ],
    [t],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="id"
        columns={columns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
        size="small"
      />
    </>
  );
};

export default TravelersComparationList;
