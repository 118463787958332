import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import PageTitle from '../../../../components/PageTitle';
import Column from '../../../../helpers/columns';
import useDatasource from '../../../../hooks/useDatasource';
import { Default } from '../../../../layouts';
import {
  deleteCountry,
  getCountries,
} from '../../../../services/taxonomies/countires';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';

const CountriesList = () => {
  const { t } = useTranslation();
  const { content, pagination, handleChange, reload } =
    useDatasource(getCountries);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.countries.name'), {
        filter: true,
      }),
      Column.text('code', t('entity.taxonomy.countries.code'), {
        filter: true,
      }),
      Column.text('createdBy', t('entity.taxonomy.countries.createdBy'), {
        sort: false,
      }),
      Column.date('createdAt', t('entity.taxonomy.countries.createdAt'), {
        sort: false,
      }),
      Column.bool('deleted', t('entity.taxonomy.countries.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.countries.deleted.true'),
          t('entity.taxonomy.countries.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (institution) => (
        <>
          <EditItemIcon
            path={`/admin/taxonomies/countries/${institution.id}`}
          />
          <DeleteItemIcon
            title={t('entity.taxonomy.countries._delete', institution)}
            message={t('entity.taxonomy.countries._deleted', institution)}
            item={institution}
            action={deleteCountry}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.countries._plural')} />
      <AddEntityButton
        entity={t('entity.taxonomy.countries._singular')}
        path="/admin/taxonomies/countries/new"
      />
      <Table
        columns={columns}
        rowKey="id"
        dataSource={content}
        // loading={loading}
        pagination={pagination}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};
export default CountriesList;
