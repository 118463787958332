import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import { getCurrentUserDetails } from '../../services/auth';
import AppLoader from './AppLoader';

const AuthContext = React.createContext(null);

export const AuthContextWrapper = ({ children }) => {
  const { i18n } = useTranslation();

  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const locale = localStorage.getItem('locale');

  useMemo(() => i18n.changeLanguage(locale), [i18n, locale]);

  useEffect(() => {
    const originalFetch = window.fetch;

    window.fetch = function f(...args) {
      console.log();
      setIsLoading(true);
      return originalFetch.apply(this, args).finally(() => {
        setIsLoading(false);
      });
    };
  }, []);

  useEffect(() => {
    setLoaded(false);
    getCurrentUserDetails()
      .then((res) => {
        setUser(res);
      })
      .catch((err) => {
        console.error(err);
        setUser(null);
      })
      .finally(() => setLoaded(true));
  }, []);

  const contextValue = useMemo(() => ({ user }), [user]);

  return (
    <>
      <AuthContext.Provider
        value={contextValue}
        className={`app-wrapper ${isLoading ? 'loading' : ''}`}
      >
        {loaded ? children : <AppLoader />}
      </AuthContext.Provider>
      {isLoading && (
        <div className="spinner-overlay">
          <SpinnerCircular
            size={50}
            thickness={100}
            speed={100}
            color="rgba(67, 57, 172, 1)"
            secondaryColor="rgba(57, 103, 172, 0.22)"
          />
        </div>
      )}
    </>
  );
};

const arrayIsEmpty = (array) =>
  // eslint-disable-next-line no-nested-ternary
  Array.isArray(array)
    ? array.length === 0
    : typeof array === 'string'
    ? array.length === 0
    : true;

export const hasPermission = (userPerm, allowedPerm) => {
  if (arrayIsEmpty(allowedPerm)) {
    return true;
  }

  if (arrayIsEmpty(userPerm)) {
    return false;
  }

  const perms = Array.isArray(allowedPerm) ? allowedPerm : [allowedPerm];

  for (let i = 0; i < perms.length; i += 1) {
    if (userPerm.includes(perms[i])) {
      return true;
    }
  }

  return false;
};

export const IsAuthenticated = ({ children }) => {
  const { user } = useContext(AuthContext);

  return user ? <>{children}</> : <Redirect to="/" />;
};

export const HasPermission = ({ permissions, children }) => {
  if (!Array.isArray(permissions)) {
    throw new Error('Permissions should be an array');
  }

  const { user } = useContext(AuthContext);

  if (!user) {
    return null;
  }

  const allow = hasPermission(user.permissions, permissions);
  return allow ? children : null;
};

export const PrivateRoute = ({
  component: Component,
  permissions,
  ...rest
}) => {
  const { user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() =>
        // eslint-disable-next-line no-nested-ternary
        user ? (
          hasPermission(user.permissions, permissions) ? (
            <Component />
          ) : (
            <Redirect to={{ pathname: '/forbidden' }} />
          )
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  );
};

export default AuthContext;
