import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Tabs } from 'antd';
import FormWrapper from '../FormWrapper';
import PageTitle from '../PageTitle';
import useForm, { FormContext } from '../../hooks/useForm';
import RetailPosList from './RetailPosList';
import SaveButton from '../buttons/SaveButton';
import CancelButton from '../buttons/CancelButton';

const EditRetailForm = ({ retail, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValue: retail,
    onSubmit,
  });

  const { value, input, handleSubmit, setFormValue } = form;

  useEffect(() => {
    setFormValue(retail);
  }, [setFormValue, retail]);

  const { retailerId } = value;
  return (
    <>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <PageTitle title={t('entity.retail._singular')} />
          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.Input
                label={t('pages.retail.name')}
                props={{ ...input('fullname'), disabled: true }}
              />

              <FormWrapper.Input
                label={t('pages.retail.email')}
                props={{ ...input('email'), disabled: true }}
              />

              <FormWrapper.Input
                label={t('pages.retail.retailer')}
                props={{ ...input('retailerName'), disabled: true }}
              />

              <FormWrapper.Input
                label={t('pages.retail.role')}
                props={{ ...input('role'), disabled: true }}
              />
              <Space>
                <SaveButton />
                <CancelButton onCancel={onCancel} />
              </Space>
            </FormWrapper.Single>
            {retailerId && (
              <FormWrapper.Double>
                <Tabs>
                  <Tabs.TabPane
                    tab={t('pages.retail.pos_plural')}
                    key="retailers"
                  >
                    <RetailPosList />
                  </Tabs.TabPane>
                </Tabs>
              </FormWrapper.Double>
            )}
          </FormWrapper>
        </form>
      </FormContext.Provider>
    </>
  );
};
export default EditRetailForm;
