import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { FormContext } from '../../hooks/useForm';
import Column from '../../helpers/columns';
import { getRoutes } from '../../services/taxonomies/routes';
import useDictionaries from '../../hooks/useDictionaries';

const dictionaries = {
  routes: getRoutes,
};

const RoutesSelection = () => {
  const form = useContext(FormContext);
  const { t } = useTranslation();

  const { value } = form;

  const [{ routes }] = useDictionaries(dictionaries);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.route.name'), {
        filter: false,
        sort: false,
      }),
      Column.bool('deleted', t('entity.taxonomy.route.deleted._'), {
        width: 150,
        filter: false,
        sort: false,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.route.deleted.true'),
          t('entity.taxonomy.route.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const content = useMemo(
    () =>
      value.routes
        .map((routeId) => routes.content.find((route) => route.id === routeId))
        .filter((route) => route && route.id),
    [routes.content, value.routes],
  );

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={content}
      // loading={routes.loading}
      pagination={false}
    />
  );
};

export default RoutesSelection;
