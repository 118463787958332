import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import useDatasource from '../../hooks/useDatasource';
import { getBurglaries } from '../../services/tvm/tvm';
import { getUsers } from '../../services/admin/users';
import Column from '../../helpers/columns';
import EditItemIcon from '../../components/buttons/EditItemIcon';

const FIELDS = {
  id: 'text',
  tvmId: 'text',
  status: 'dictionary',
  tvmName: 'text',
  createdAt: 'date',
  updatedAt: 'date',
  personId: 'dictionary',
};

const BurglaryStatuses = {
  content: [{ id: 'NEW' }, { id: 'IN_PROGRESS' }],
};

const BurglariesActive = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState({ content: [] });

  useEffect(() => {
    getUsers({
      criterias: { permission: 'TVM_DASHBOARD_PERMISSION', tvm: '' },
    }).then(setUsers);
  }, []);

  const addColumnDetails = useCallback(
    (column) => {
      const filter = {
        filter: true,
        dictLabel: (entry) => (column === 'personId' ? entry.email : entry.id),
        width: 80,
      };

      switch (column) {
        case 'personId':
          return [users, filter];
        case 'status':
          return [BurglaryStatuses, filter];
        default:
          return [filter];
      }
    },
    [users],
  );

  const { pagination, content, handleChange } = useDatasource(
    (args) =>
      getBurglaries({
        ...args,
        criterias: {
          ...args.criterias,
          status: args?.criterias?.status || ['NEW', 'IN_PROGRESS'],
        },
      }),
    { pagination: { pageSize: 1000 } },
  );

  const actionsColumn = useMemo(
    () =>
      Column.actions(
        t('table.actions'),
        (record) => (
          <>
            <EditItemIcon path={`/tvm/burglary/${record.id}`} />
          </>
        ),
        { width: 30 },
      ),
    [t],
  );

  const columns = useMemo(
    () => [
      ...Object.keys(FIELDS).map((key) =>
        Column[FIELDS[key]](
          key,
          t(`entity.tvm.burglary.${key}`),
          ...addColumnDetails(key),
        ),
      ),
      actionsColumn,
    ],
    [actionsColumn, addColumnDetails, t],
  );

  return (
    <Table
      columns={columns}
      rowKey="id"
      // loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
      scroll={{ y: 2000 }}
      style={{ marginTop: 10 }}
    />
  );
};

export default BurglariesActive;
