import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Badge, Collapse, Space, Tabs, Typography } from 'antd';

import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import useForm, { FormContext } from '../../../hooks/useForm';
import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';
import ProductsGrid from '../ProductsGrid2';
import ProductGroupsGrid from '../ProductGroupsGrid';
import OffersGrid from '../OffersGrid';

const EditOfferTypeForm = ({ offerType, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [activeKeys, setActiveKey] = useState(['name']);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        nameEn: yup.string().required(t('errors.notEmpty')),
        nameDe: yup.string().required(t('errors.notEmpty')),
        code: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );
  const form = useForm({
    initialValue: offerType,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const { value, handleSubmit, input, setFormValue, errorMessages } = form;

  const { products, productGroups, offers } = value;

  useEffect(() => {
    setFormValue(offerType);
  }, [setFormValue, offerType]);

  useEffect(() => {
    if (
      errorMessages &&
      Object.keys(errorMessages).some((e) =>
        ['name', 'nameEn', 'nameDe'].includes(e),
      )
    ) {
      setActiveKey(['name']);
    }
  }, [errorMessages]);

  return (
    <FormContext.Provider value={form}>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <FormWrapper.Single>
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              {t('entity.offers.offerType.name._')}
            </Typography.Paragraph>
            <Collapse
              activeKey={activeKeys}
              onChange={() => setActiveKey(activeKeys ? null : 'name')}
            >
              <CollapsePanel key="name" header={value.name}>
                <FormWrapper.Input
                  label={t('entity.offers.offerType.name.ro')}
                  props={{ ...input('name'), disabled: value.deleted }}
                />
                <FormWrapper.Input
                  label={t('entity.offers.offerType.name.en')}
                  props={{ ...input('nameEn'), disabled: value.deleted }}
                />
                <FormWrapper.Input
                  label={t('entity.offers.offerType.name.de')}
                  props={{ ...input('nameDe'), disabled: value.deleted }}
                />
              </CollapsePanel>
            </Collapse>

            <FormWrapper.Input
              label={t('entity.offers.offerType.code')}
              props={{ ...input('code') }}
            />
          </FormWrapper.Single>

          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={
                  <>
                    {t('entity.offers.offerType.products')}{' '}
                    <Badge count={products.length} />
                  </>
                }
                key="products"
              >
                <ProductsGrid />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <>
                    {t('entity.offers.offerType.productGroups')}{' '}
                    <Badge count={productGroups.length} />
                  </>
                }
                key="productGroups"
              >
                <ProductGroupsGrid />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <>
                    {t('entity.offers.offerType.offers')}{' '}
                    <Badge count={offers.length} />
                  </>
                }
                key="offers"
              >
                <OffersGrid />
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
        </FormWrapper>
        <Space>
          <SaveButton />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </FormContext.Provider>
  );
};

export default EditOfferTypeForm;
