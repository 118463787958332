import { useTranslation } from 'react-i18next';
import React, { useContext, useMemo } from 'react';
import { Button, notification, Table } from 'antd';
import { downloadFilesById, downloadInvoiceById } from '../../services/files';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';
import { SalesChannels } from '../poses/constants';
import { ProductCategories } from '../offers/OffersConstants';
import { getTransactions } from '../../services/accounts/accounts';
import AuthContext, { hasPermission } from '../auth';
// eslint-disable-next-line import/extensions

const EvenTypes = {
  content: [{ id: 'ADD_NEW' }, { id: 'RECHARGE' }],
  loading: false,
  error: false,
};

const DATE_STATUS = {
  content: [{ id: 0 }, { id: 1 }, { id: 2 }],
  loading: false,
  error: false,
};

const DisableReason = {
  content: [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
  ],
  loading: false,
  error: false,
};

const TransactionHistoryList = ({ accId }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const { pagination, content, handleChange, loading, reload } = useDatasource(
    (arg) =>
      getTransactions({
        ...arg,
        criterias: {
          ...arg.criterias,
          accountId: accId,
        },
      }),
  );

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.account.transactionHistory.id'), {
        filter: false,
        width: 100,
      }),
      Column.date(
        'createdAt',
        t('entity.account.transactionHistory.createdAt'),
        {
          width: 180,
        },
      ),
      Column.dictionary(
        'saleChanel',
        t('entity.account.transactionHistory.salesChannels._'),
        SalesChannels,
        {
          filter: false,
          width: 150,
          sort: true,
          dictLabel: (entry) => {
            return t(
              `entity.account.transactionHistory.salesChannels.${entry.id}`,
            );
          },
        },
      ),
      Column.dictionary(
        'eventType',
        t('entity.account.transactionHistory.eventType._'),
        EvenTypes,
        {
          filter: false,
          width: 150,
          sort: true,
          dictLabel: (entry) => {
            return t(`entity.account.transactionHistory.eventType.${entry.id}`);
          },
        },
      ),
      Column.dictionary(
        'productCategory',
        t('entity.account.transactionHistory.productCategory._'),
        ProductCategories,
        {
          width: 150,
          filter: false,
          sort: true,
          dictLabel: (entry) =>
            t(`entity.account.transactionHistory.productCategory.${entry.id}`),
        },
      ),
      Column.text(
        'parentProdName',
        t('entity.account.transactionHistory.parentProdName'),
        {
          filter: true,
        },
      ),
      Column.text(
        'parentProdQty',
        t('entity.account.transactionHistory.parentProdQty'),
        {
          sort: true,
        },
      ),
      {
        title: `${t(
          'entity.account.transactionHistory.parentProdPrice',
        )} (RON)`,
        key: 'parentProdPrice',
        width: 100,
        render: (v) => {
          const { parentProdPrice } = v;
          return (parentProdPrice / 100).toFixed(2);
        },
      },
      Column.dictionary(
        'dateStatus',
        t('entity.account.transactionHistory.dateStatus._'),
        DATE_STATUS,
        {
          width: 150,
          filter: false,
          sort: true,
          dictLabel: (entry) =>
            t(`entity.account.transactionHistory.dateStatus.${entry.id}`),
        },
      ),
      {
        title: t('entity.account.transactionHistory.valStaDate'),
        key: 'valStaDate',
        width: 150,
        render: (val) => {
          if (val.valStaDate === null) {
            return null;
          }
          const date = val.valStaDate.split(' ');

          return date[0];
        },
      },
      {
        title: t('entity.account.transactionHistory.valStaTime'),
        key: 'valStaTime',
        width: 120,
        render: (val) => {
          if (val.valStaDate === null) {
            return null;
          }
          const date = val.valStaDate.split(' ');

          return date[1];
        },
      },
      {
        title: t('entity.account.transactionHistory.valEndDate'),
        key: 'valEndDate',
        width: 150,
        render: (val) => {
          if (val.valEndDate === null) {
            return null;
          }
          const date = val.valEndDate.split(' ');

          return date[0];
        },
      },
      {
        title: t('entity.account.transactionHistory.valEndTime'),
        key: 'valEndTime',
        width: 120,
        render: (val) => {
          if (val.valEndDate === null) {
            return null;
          }
          const date = val.valEndDate.split(' ');

          return date[1];
        },
      },
      Column.dictionary(
        'disableReason',
        t('entity.account.transactionHistory.disableReason._'),
        DisableReason,
        {
          width: 150,
          filter: false,
          sort: true,
          dictLabel: (entry) =>
            t(`entity.account.transactionHistory.disableReason.${entry.id}`),
        },
      ),

      Column.text('orderId', t('entity.account.transactionHistory.orderId'), {
        filter: false,
        width: 100,
      }),
      Column.actions(t('table.actions'), (transaction) => (
        <span style={{ textAlign: 'right' }}>
          {transaction.invoiceId && (
            <Button
              style={{
                display: 'inline',
                textAlign: 'center',
                marginRight: '2%',
              }}
              disabled={hasPermission(user.permissions, [
                'ADMINISTRATION_ACCOUNTS_VIEW_PERMISSION',
              ])}
              className="ant-btn-warning"
              type="primary"
              htmlType="button"
              onClick={() => {
                if (transaction?.fileId) {
                  downloadFilesById(transaction?.fileId).then(() => reload());
                } else {
                  downloadInvoiceById(transaction?.invoiceId, transaction?.id)
                    .then(() => reload())
                    .catch((msg) => {
                      console.error(msg);
                      const { inner } = msg || {};
                      const { _: messageCode } = inner || {};

                      notification.error({
                        message: messageCode
                          ? t(messageCode, inner)
                          : t('actions.errorOccurred'),
                      });
                    });
                }
              }}
            >
              {t('entity.stocks.invoice._singular')}
            </Button>
          )}
        </span>
      )),
    ],
    [t, user.permissions, reload],
  );

  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </>
  );
};
export default TransactionHistoryList;
