import React from 'react';

import { useHistory } from 'react-router-dom';

import { Default } from '../../../../layouts';

import { reorderProducts } from '../../../../services/offers/products';
import EditProductPrioritiesForm from '../../../../components/offers/products/EditProductPrioritiesForm';

const EditProductPriorities = () => {
  const history = useHistory();

  const goBack = () => history.push('/admin/offers/priorities');
  const handleSubmit = (evt) =>
    reorderProducts(evt).then(() => history.push('/admin/offers/priorities'));
  return (
    <Default>
      <EditProductPrioritiesForm onSubmit={handleSubmit} onCancel={goBack} />
    </Default>
  );
};

export default EditProductPriorities;
