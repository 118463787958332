import React from 'react';
import ReportsList from '../../components/reports/ReportsList';

import { Default } from '../../layouts';

const Reports = () => {
  return (
    <Default>
      <ReportsList />
    </Default>
  );
};

export default Reports;
