import React, { useCallback, useRef } from 'react';

import { Button } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';

import { AUTH_SERVICE_URI, AUTH_BASE_URI } from '../../services/auth';
import usePin from '../../hooks/usePin';

const CardLoginForm = () => {
  const formRef = useRef(null);
  const inputRef = useRef(null);

  const handleSendForm = useCallback(
    ({ token }) => {
      inputRef.current.value = token;
      formRef.current.submit();
      return Promise.resolve();
    },
    [inputRef],
  );

  const [handleGenerateTokenClick] = usePin('auth', handleSendForm);

  return (
    <>
      <form
        method="POST"
        action={`${window._env_.API_BACKEND_URL}${AUTH_SERVICE_URI}${AUTH_BASE_URI}/token`}
        ref={formRef}
      >
        <input type="hidden" name="token" value="" ref={inputRef} />

        <Button
          type="primary"
          block
          htmlType="button"
          onClick={handleGenerateTokenClick}
          icon={<CreditCardOutlined />}
        >
          Conecteaza-te prin card
        </Button>
      </form>
    </>
  );
};

export default CardLoginForm;
