import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import useDatasource from '../../hooks/useDatasource';

import { getPermissions } from '../../services/admin/permissions';
import Column from '../../helpers/columns';

const RolePermissions = ({ permissions, setValue }) => {
  const { t } = useTranslation();
  const { pagination, content, handleChange } = useDatasource(getPermissions);
  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.permission.name'), {
        width: 300,
      }),
      Column.text('description', t('entity.admin.permission.description')),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: permissions ?? [],

      onSelect: (record, selected) => {
        let newPermissions = [...(permissions ?? [])];

        if (selected && !newPermissions.includes(record.name)) {
          newPermissions.push(record.name);
        } else {
          newPermissions = newPermissions.filter(
            (perm) => perm !== record.name,
          );
        }

        setValue('permissions', newPermissions);
      },

      getCheckboxProps: (record) => ({
        disabled:
          record &&
          record.name &&
          ((record.name === 'STOCK_USER' &&
            (permissions ?? []).includes('STOCK_ADMIN')) ||
            (record.name === 'STOCK_ADMIN' &&
              (permissions ?? []).includes('STOCK_USER'))),
      }),
    }),
    [permissions, setValue],
  );

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns}
      rowKey="name"
      // loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
    />
  );
};
export default RolePermissions;
