import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Button, notification, Popconfirm, Table, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import EditItemIcon from '../../components/buttons/EditItemIcon';
import { Default } from '../../layouts';
import useDatasource from '../../hooks/useDatasource';

import { findAll, inactivateMessage } from '../../services/taxonomies/messages';
import PageTitle from '../../components/PageTitle';
import AddEntityButton from '../../components/buttons/AddEntityButton';

import Column from '../../helpers/columns';

const BADGE_COLORS = {
  true: 'green',
  false: '#999',
};

const Messages = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } = useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.messages.id'), {
        width: 100,
      }),
      {
        title: t('entity.messages.channel'),
        key: 'channel',
        width: 250,
        ellipsis: {
          showTitle: false,
        },
        render: (_, record) => {
          const { channels } = record;
          return channels.map((ch) => <td key="channel">{ch}, </td>);
        },
      },
      {
        title: t('entity.messages.title'),
        key: 'titleRo',
        width: 150,
        dataIndex: 'titleRo',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: t('entity.messages._singular'),
        key: 'messageRo',
        width: 350,
        dataIndex: 'messageRo',
        ellipsis: {
          showTitle: false,
        },
      },
      Column.date('startDate', t('entity.messages.start_date'), {
        width: 180,
      }),
      Column.date('endDate', t('entity.messages.end_date'), {
        width: 180,
      }),
      Column.date('createdAt', t('entity.messages.created_at'), {
        width: 180,
      }),
      {
        title: t('entity.messages.created_by'),
        key: 'createdBy',
        width: 200,
        dataIndex: 'createdBy',
        ellipsis: {
          showTitle: false,
        },
      },
      Column.date('updatedAt', t('entity.messages.updated_at'), {
        width: 180,
      }),
      {
        title: t('entity.messages.updated_by'),
        key: 'updatedBy',
        width: 200,
        dataIndex: 'updatedBy',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: t('entity.messages.status'),
        key: 'active',
        width: 150,
        render: (_, value) => {
          const { active } = value;
          return (
            <Badge
              color={BADGE_COLORS[active] || '#fff'}
              text={t(`entity.messages.statuses.${active}`)}
            />
          );
        },
      },
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <EditItemIcon path={`/messages/${record.id}`} />
          <Popconfirm
            placement="topLeft"
            title={t('entity.messages.actions.inactivateMessage')}
            onConfirm={() => {
              inactivateMessage(record.id)
                .then(() =>
                  notification.success({
                    message: t('entity.messages.actions.inactivated'),
                  }),
                )
                .catch((msg) => {
                  const { inner } = msg || {};
                  const { _: messageCode } = inner || {};

                  notification.error({
                    message: messageCode
                      ? t(messageCode)
                      : t('actions.messageDeleteError'),
                  });
                })
                .finally(() => reload());
            }}
            okText={t('entity.messages.actions.inactivate')}
            cancelText={t('actions.cancel')}
          >
            <Tooltip title={t('entity.messages.actions.inactivate')}>
              <Button
                shape="circle"
                type="link"
                icon={<DeleteOutlined />}
                size="middle"
              />
            </Tooltip>
          </Popconfirm>
        </span>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.messages._')}>
        <AddEntityButton
          entity={t('entity.messages._singular')}
          path="/messages/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default Messages;
