import { download, genericCRUDService, search } from '../../helpers/api';

export const SERVICE_URI = '/validators';
export const BASE_URI = '/api/v1/validators-status';
export const BASE_VW_URI = '/api/v1/vw-validators-status';
export const { findOne, create, update, remove } = genericCRUDService(
  SERVICE_URI,
  BASE_URI,
);

export const { findAll, downloadAll } = genericCRUDService(
  SERVICE_URI,
  BASE_VW_URI,
);

export const downloadOnlyValidatorsData = async (spec) =>
  download(
    `${SERVICE_URI}/${BASE_VW_URI}`,
    {
      criterias: { ...spec.criterias, equipmentType: 'VALIDATOR' },
      spec,
    },
    {},
    true,
  );

export const getValidatorsData = async (data) =>
  search(`${SERVICE_URI}${BASE_VW_URI}`, data);

export const getValidatorsRetailerData = async (data) =>
  search(`${SERVICE_URI}${BASE_URI}/retailer`, data);
