import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';
import { getOffers } from '../../services/offers/offers';

const ProductGroupsGrid = () => {
  const { t } = useTranslation();

  const form = useContext(FormContext);

  const { value, setValue, errorMessages } = form;

  const { offers } = value;

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.offers.offer.name')),
      Column.text('price', t('entity.offers.offer.price')),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: offers,
      onSelect: (record, selected) =>
        selected
          ? setValue('offers', [...offers, record.id])
          : setValue(
              'offers',
              offers.filter((id) => id !== record.id),
            ),
      getCheckboxProps: ({ deleted, id }) => ({
        disabled: deleted && !offers.some((ofId) => ofId === id),
      }),
    }),
    [offers, setValue],
  );

  const { pagination, content, handleChange } = useDatasource(getOffers);

  return (
    <>
      <FormWrapper.ErrorMessage message={errorMessages?.offers} />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default ProductGroupsGrid;
