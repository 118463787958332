import { search, get, post, put, remove, download } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/institutions';

export const getInstitutions = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getInstitutionById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const downloadInstitutions = async (data) =>
  download(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const createInstitution = async (institution) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, institution);

export const updateInstitution = async (institution) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${institution.id}`, institution);

export const deleteInstitution = async (institution) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${institution.id}`);
