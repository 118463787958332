import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import useDatasource from '../../../hooks/useDatasource';
import { FormContext } from '../../../hooks/useForm';
import { getActiveInspectors } from '../../../services/admin/users';
import Column from '../../../helpers/columns';
import FormWrapper from '../../FormWrapper';
import { findAll } from '../../../services/admin/sectors';
import useDictionaries from '../../../hooks/useDictionaries';

const dictionaries = {
  sectors: findAll,
};

const SectorInspectorsList = () => {
  const form = useContext(FormContext);
  const { t } = useTranslation();

  const { value, setValue, errorMessages } = form;

  const { id, deleted, userIds } = value;

  const [{ sectors }] = useDictionaries(dictionaries);

  const columns = useMemo(
    () => [
      Column.text(
        'fullname',
        t('entity.taxonomy.sector.tabs.inspectors.fullname'),
        {
          filter: true,
        },
      ),
      Column.text(
        'identityCard',
        t('entity.taxonomy.sector.tabs.inspectors.identityCard'),
        {
          filter: true,
        },
      ),
      Column.text(
        'employeeNumber',
        t('entity.taxonomy.sector.tabs.inspectors.employeeNumber'),
        {
          filter: true,
        },
      ),
      Column.dictionary(
        'sectorId',
        t('entity.taxonomy.sector.tabs.inspectors.sector'),
        sectors,
        { filter: true },
      ),
    ],
    [sectors, t],
  );

  const { pagination, content, handleChange } =
    useDatasource(getActiveInspectors);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      onSelect: (record, selected) =>
        selected
          ? setValue('userIds', [...(userIds || []), record.id])
          : setValue(
              'userIds',
              userIds.filter((userId) => userId !== record.id),
            ),
      selectedRowKeys: userIds,
      getCheckboxProps: (record) => ({
        disabled:
          deleted ||
          !(record.sectorId === null || record.sectorId === id) ||
          (record.status === 'DELETED' &&
            (value.userIds?.length > 0
              ? value.userIds?.filter((ar) => ar === record.id).length === 0
              : true)),
      }),
    }),
    [userIds, setValue, deleted, id, value],
  );

  return (
    <>
      <FormWrapper.ErrorMessage message={errorMessages?.userIds} />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default SectorInspectorsList;
