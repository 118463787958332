import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Form, Input, InputNumber, Select, Button } from 'antd';
import moment from 'moment';
import ProductList from './controls/ProductList';
import InstitutionsList from './controls/InstitutionsList';
import DatePickerLocale from '../DatePickerLocale';

const FORMATS = ['pdf', 'xlsx', 'csv'];

const ReportWindow = ({ report, onPrint, onClose, buttonDisabled }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (report) {
      form.resetFields();
    }
  }, [form, report]);

  const renderInputControl = useCallback(
    (control) => {
      const disabledStartDate = (current) => {
        const endDate = form.getFieldValue('END');
        return endDate ? current.isAfter(endDate, 'day') : false;
      };

      const disabledEndDate = (current) => {
        const startDate = form.getFieldValue('START');
        return startDate ? current.isBefore(startDate, 'day') : false;
      };

      return (
        <Form.Item
          key={control.parameter}
          label={control.label}
          name={control.parameter}
        >
          {(() => {
            switch (control.type) {
              case 'numeric':
                return <InputNumber />;
              case 'datetime':
                return <DatePickerLocale />;
              case 'date':
                // return control.label === 'START' ? (
                //     <DatePickerLocale disabledDate={disabledStartDate} />
                // ) : (
                //     <DatePickerLocale
                //         disabledDate={disabledEndDate}
                //         defaultValue={moment()}
                //     />
                // );
                // eslint-disable-next-line no-case-declarations
                let content;
                if (control.label === 'START') {
                  content = (
                    <DatePickerLocale disabledDate={disabledStartDate} />
                  );
                } else if (control.label === 'END') {
                  content = <DatePickerLocale disabledDate={disabledEndDate} />;
                } else {
                  content = (
                    <DatePickerLocale
                      disabledDate={disabledEndDate}
                      defaultValue={moment()}
                    />
                  );
                }
                return content;
              case 'text':
                return <Input />;
              case 'Procent_Decontare_90':
                return <InputNumber defaultValue="90" />;
              case 'Procent_Decontare_10':
                return <InputNumber defaultValue="10" />;
              case 'Varsta':
                return <InputNumber defaultValue="30" />;
              case 'products_list':
                return <ProductList />;
              case 'institutions_list':
                return <InstitutionsList form={form} />;
              default:
                return <Input />;
            }
          })()}
        </Form.Item>
      );
    },
    [form],
  );

  return (
    <Modal
      title={report ? report.label : ''}
      visible={!!report}
      onCancel={onClose}
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={buttonDisabled}
          onClick={form.submit}
        >
          Generează
        </Button>,
      ]}
      width={800}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ _format: FORMATS[0] }}
        onFinish={onPrint}
        autoComplete="off"
      >
        <Form.Item label="Format" name="_format">
          <Select>
            {FORMATS.map((format) => (
              <Select.Option key={format} value={format}>
                {t(`reports.format.${format}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {report && report.inputControls.map(renderInputControl)}
      </Form>
    </Modal>
  );
};

export default ReportWindow;
