import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import Comment from './Comment';
import AddComment from './AddComment';

const Comments = ({ users, value, setValue, enableComment = false }) => {
  const { t } = useTranslation();
  const commentsPanel = useRef(null);

  const commentsWithAuthors = useMemo(
    () =>
      value
        ?.map((comment) => ({
          ...comment,
          author: users?.find((u) => u.id === comment.authorId),
        }))
        .sort((a, b) =>
          a.createdAt && b.createdAt
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : 1,
        ),
    [users, value],
  );

  useEffect(() => {
    commentsPanel?.current?.scrollTo(0, 10000);
  }, [commentsWithAuthors]);

  return (
    <div
      style={{
        'background-color': '#F8FBFF',
      }}
    >
      <Row>
        <Col xs={12} offset={1}>
          <Typography.Title level={5}>{`${t('entity.tvm.burglary.comments')} (${
            value?.length
          })`}</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col
          xs={20}
          ref={commentsPanel}
          style={{
            maxHeight: enableComment ? '32rem' : '40rem',
            'overflow-y': 'scroll',
            'caret-color': 'transparent',
          }}
        >
          {commentsWithAuthors?.map((comment) => (
            <Comment value={comment} key={comment.id} />
          ))}
        </Col>
      </Row>
      {enableComment && (
        <Row>
          <Col
            xs={20}
            style={{
              margin: '2rem',
            }}
          >
            <AddComment
              setValue={(comment) => {
                setValue([...value, { comment, createdAt: new Date() }]);
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default Comments;
