import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';

import {
  Form,
  Col,
  Row,
  Menu,
  Modal,
  message,
  Space,
  Button,
  Table,
  InputNumber,
  Popconfirm,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  addProductToOrder,
  removeProductFromOrder,
  cancelOrder,
  moveOrderToNextStep,
  getAvailableProducts,
} from '../../../services/pos/pos';

import POSButton from '../POSButton';
import POSContext from '../POSContext';
import Div from '../Div';
import Restriction from '../Restriction';
import DatePickerLocale from '../../DatePickerLocale';
import { getUserTypeByCode } from '../../../services/taxonomies/user-types';
import { UserTypesEnum } from '../../../pages/orders/constants';
import { getTitlesById } from '../../../services/accounts/accounts';

const CATEGORY_COLORS = {
  PASS: 'cyan',
  TICKET: 'green',
  PURSE: 'gold',
  GROUP: 'blue',
  OFFER: 'magenta',
};

const SelectProducts = () => {
  const { t } = useTranslation();
  const { shift, order, setOrder } = useContext(POSContext);
  const { account } = order || {};
  const { userProfileTypeId } = account || {};
  const [currentGroup, setCurrentGroup] = useState();

  const [products, setProducts] = useState([]);
  const [accountCurrentTitles, setAccountCurrentTitles] = useState([]);
  const [travelerUserTypeId, setTravelerUserTypeId] = useState(null);
  const [line, setLine] = useState(null);
  const [whereIsRestriction, setWhereIsRestriction] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productGroupHasPass, setProductGroupHasPass] = useState(null);

  useEffect(() => {
    getUserTypeByCode(UserTypesEnum.CALATOR).then((res) =>
      res ? setTravelerUserTypeId(res.id) : null,
    );
  }, []);

  useEffect(() => {
    if (account?.accountId != null) {
      getTitlesById(account.accountId, { pageSize: 1000 }).then((res) =>
        res?.content?.length > 0
          ? setAccountCurrentTitles(res.content)
          : setAccountCurrentTitles([]),
      );
    }
  }, [account?.accountId]);

  useEffect(() => {
    getAvailableProducts(shift, order)
      .then((prod) => {
        if (prod?.length > 0) {
          setCurrentGroup(prod[0]);
        }
        setProducts(prod);
      })
      .catch((err) => {
        console.error(err);
        setProducts([]);
      });
  }, [shift, order]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  dayjs.extend(customParseFormat);

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current < moment() ||
      Date.parse(current) > Date.parse(moment().add(90, 'days'))
    );
  };

  const addProductToBasket = useCallback(
    (product) => {
      setSelectedProduct(product);
      setProductGroupHasPass(
        product?.category === 'GROUP' &&
          (product?.payload || []).find((p) => p.category === 'PASS'),
      );
      const hasRestriction = product.payload.some((item) =>
        item.restrictions.some(
          (rest) =>
            rest.restriction === 'LINE_GROUPS' &&
            (rest.value1 === 1 ||
              rest.value3 === 1 ||
              rest.value5 === 1 ||
              rest.value7 === 1),
        ),
      );
      const whereIsRestrictionData = product.payload.map((item) => {
        const results = [];
        item.restrictions.forEach((rest) => {
          if (
            rest.restriction === 'LINE_GROUPS' &&
            (rest.value1 === 1 ||
              rest.value3 === 1 ||
              rest.value5 === 1 ||
              rest.value7 === 1)
          ) {
            if (rest.value1 === 1) {
              results.push('value1');
            }
            if (rest.value3 === 1) {
              results.push('value3');
            }
            if (rest.value5 === 1) {
              results.push('value5');
            }
            if (rest.value7 === 1) {
              results.push('value7');
            }
          }
        });
        return results;
      });
      const titleFound = accountCurrentTitles.find(
        (title) => title.productId === product.id,
      );
      const ownedTitle = titleFound !== undefined ? titleFound : null;
      const now = new Date();
      const isDateAOutsideInterval =
        ownedTitle?.endDateA === null ||
        (ownedTitle?.endDateA != null && new Date(ownedTitle?.endDateA) < now);
      const isDateBOutsideInterval =
        ownedTitle?.endDateB === null ||
        (ownedTitle?.endDateB != null && new Date(ownedTitle?.endDateB) < now);
      const canAddTitleOnEveryLine =
        ownedTitle === null ||
        (isDateBOutsideInterval === true && isDateAOutsideInterval === true);

      if (
        product?.category === 'PASS' &&
        hasRestriction &&
        canAddTitleOnEveryLine === false
      ) {
        setLine(ownedTitle?.lines);
        setWhereIsRestriction(whereIsRestrictionData);
      }
      form.setFieldsValue({
        product: { ...product, count: 1 },
      });

      setIsModalVisible(true);
    },
    [accountCurrentTitles, form],
  );

  const handleOk = useCallback(
    (value) => {
      const { product } = value;

      if (product.category === 'PURSE') {
        if (
          selectedProduct?.maxCantityValue &&
          product.count * 100 > selectedProduct.maxCantityValue
        ) {
          return;
        }
        product.count *= 100;
      }

      addProductToOrder(shift, order, product)
        .then((res) => {
          setOrder(res);
          setIsModalVisible(false);
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: err.inner._
              ? t(
                  err.inner._,
                  products.find(
                    (it) =>
                      it.id === product.id && it.category === product.category,
                  ),
                )
              : 'Produsul nu a fost adaugat in cos',
            key: 'pos',
            duration: 5,
            className: 'card-message',
          });
        });
    },
    [shift, order, selectedProduct, setOrder, t, products],
  );

  const removeProductFromBasket = useCallback(
    (product) => {
      removeProductFromOrder(shift, order, product)
        .then((res) => {
          setOrder(res);
          setIsModalVisible(false);
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: 'Produsul nu a fost sters in cos',
            key: 'pos',
            duration: 5,
            className: 'card-message',
          });
        });
    },
    [shift, order, setOrder],
  );

  const checkUserType = useCallback(() => {
    const product = form.getFieldValue('product');
    if (
      !userProfileTypeId ||
      (userProfileTypeId &&
        product?.payload[0].userTypeId === travelerUserTypeId)
    )
      return true;

    if (product) {
      return product.payload[0].userTypeId === userProfileTypeId;
    }
    return true;
  }, [form, userProfileTypeId, travelerUserTypeId]);

  const columns = useMemo(
    () => [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: t('entity.account.transportTitle.startValidPeriod'),
        key: 'payload',
        render: (element) => {
          // eslint-disable-next-line no-nested-ternary
          if (element.payload[0].validityStartType === 'FUTURE') {
            return element.category === 'GROUP' || element.category === 'OFFER'
              ? ''
              : // eslint-disable-next-line no-nested-ternary
                moment(element.payload[0].startDate).format('YYYY-MM-DD HH:mm');
          }
          if (element.category === 'GROUP' || element.category === 'OFFER') {
            return '';
          }
          return element.payload[0].validityStartType === 'SALE'
            ? moment(new Date()).format('YYYY-MM-DD HH:mm')
            : t('entity.account.transportTitle.activeAtValidation');
        },
        width: 200,
      },
      {
        title: 'Pret',
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (_, row) =>
          row.category === 'PURSE'
            ? (row.qty * 0.01).toFixed(2)
            : (row.price * 0.01).toFixed(2),
      },
      {
        title: 'Cantitate',
        width: 150,
        dataIndex: 'qty',
        key: 'count',
        render: (_, row) => (row.category === 'PURSE' ? '-' : row.qty),
      },
      {
        title: 'Perioada de valabilitate',
        width: 300,
        dataIndex: 'validPeriod',
        key: 'validPeriod',
      },
      {
        title: 'Pret total',
        width: 150,
        key: 'totalPrice',
        render: (_, row) => <>{(row.qty * row.price * 0.01).toFixed(2)}</>,
      },
      {
        title: 'Actiuni',
        key: 'actions',
        width: 1,
        render: (_, row) => (
          <Space>
            <Button type="primary" disabled>
              Configurare
            </Button>
            <Button type="danger" onClick={() => removeProductFromBasket(row)}>
              Sterge
            </Button>
          </Space>
        ),
      },
    ],
    [removeProductFromBasket, t],
  );

  const handleCancelOrder = useCallback(() => {
    cancelOrder(shift, order)
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.error(err);
        message.error({
          content: 'Eroare la anulare comanda',
          key: 'pos',
          duration: 5,
          className: 'card-message',
        });
      });
  }, [shift, order, setOrder]);

  const handleNext = useCallback(() => {
    moveOrderToNextStep(shift, order)
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.error(err);
        message.error({
          content: err.inner._
            ? t(err.inner._)
            : 'Eroare la trecere pas urmator',
          key: 'pos',
          duration: 5,
          className: 'card-message',
        });
      });
  }, [shift, order, setOrder, t]);

  return (
    <div className="pos">
      <div className="pos__wrapper">
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}
          style={{ height: '100%' }}
        >
          <Col span={4}>
            <Menu mode="inline" selectedKeys={[`${currentGroup?.id}`]}>
              {(products || []).map((prodGroup) => (
                <Menu.Item
                  key={`${prodGroup?.id}`}
                  onClick={() => setCurrentGroup(prodGroup)}
                >
                  {prodGroup?.name}
                </Menu.Item>
              ))}
            </Menu>
          </Col>
          <Col span={20}>
            <div className="pos__main">
              {currentGroup?.products?.length > 0 && (
                <div>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                    {(currentGroup?.products || []).map((prod) => (
                      <Col span={4} key={prod.id}>
                        <POSButton
                          title={prod?.name}
                          color={CATEGORY_COLORS[prod?.category]}
                          onClick={() => addProductToBasket(prod)}
                        />
                      </Col>
                    ))}
                  </Row>
                  <br />
                </div>
              )}

              {currentGroup?.productGroups?.length > 0 && (
                <div>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                    {(currentGroup?.productGroups || []).map((prod) => (
                      <Col span={4} key={prod.id}>
                        <POSButton
                          title={prod?.name}
                          color={CATEGORY_COLORS[prod?.category]}
                          onClick={() => addProductToBasket(prod)}
                        />
                      </Col>
                    ))}
                  </Row>
                  <br />
                </div>
              )}

              {currentGroup?.offers?.length > 0 && (
                <div>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                    {(currentGroup?.offers || []).map((prod) => (
                      <Col span={4} key={prod.id}>
                        <POSButton
                          title={prod?.name}
                          color={CATEGORY_COLORS[prod?.category]}
                          onClick={() => addProductToBasket(prod)}
                        />
                      </Col>
                    ))}
                  </Row>
                  <br />
                </div>
              )}
              <Table
                dataSource={order.products}
                columns={columns}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="pos__actions">
        <div>
          <POSButton
            title="Anulare"
            color="red"
            size="small"
            onClick={handleCancelOrder}
          />
        </div>

        <div>
          <POSButton
            title="Mai departe"
            color="green"
            size="small"
            onClick={handleNext}
          />
        </div>
      </div>

      <Modal
        title="Configurare"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          !checkUserType() ? (
            <Popconfirm
              key="confirmUserType"
              placement="topLeft"
              title={t('entity.orders.changeUserType')}
              okText={t('actions.confirm')}
              cancelText={t('actions.cancel')}
              okButtonProps={{ htmlType: 'submit', type: 'primary' }}
              onConfirm={() => {
                form
                  .validateFields()
                  .then(handleOk)
                  .catch((info) => {
                    console.error('Validate Failed:', info);
                  });
              }}
            >
              <Button className="ant-btn-success" type="primary">
                OK
              </Button>
            </Popconfirm>
          ) : (
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                form
                  .validateFields()
                  .then(handleOk)
                  .catch((info) => {
                    console.error('Validate Failed:', info);
                  })
              }
            >
              OK
            </Button>
          ),
          <Button
            key="close"
            type="primary"
            onClick={() => setIsModalVisible(false)}
          >
            {t(`actions.close`)}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item hidden name={['product', 'id']} />
          <Form.Item hidden name={['product', 'category']} />

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.product?.category !== next.product?.category
            }
          >
            {({ getFieldValue }) => (
              <>
                {getFieldValue(['product', 'category']) !== 'PASS' && (
                  <Row gutter={16}>
                    <Col span={8}>
                      <POSButton
                        title="Scade"
                        onClick={() =>
                          form.setFieldsValue({
                            product: {
                              ...form.getFieldValue('product'),
                              count: Math.max(
                                1,
                                form.getFieldValue(['product', 'count']) - 1,
                              ),
                            },
                          })
                        }
                        color="red"
                        size="xsmall"
                      />
                    </Col>
                    <Col span={8}>
                      <Form.Item noStyle name={['product', 'count']}>
                        {getFieldValue(['product', 'category']) === 'PURSE' ? (
                          <InputNumber
                            className="account-custom-input-number"
                            min={1}
                            max={
                              selectedProduct?.maxCantityValue
                                ? selectedProduct?.maxCantityValue / 100
                                : undefined
                            } // Added upper bound
                            defaultValue={1}
                            step={0.01}
                            addonAfter="RON"
                            parser={(purseVal) => purseVal.replace(',', '.')}
                          />
                        ) : (
                          <InputNumber
                            style={{ height: '100%', fontSize: '2rem' }}
                            min={1}
                            max={productGroupHasPass ? 1 : undefined} // Added upper bound
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <POSButton
                        title="Adauga"
                        onClick={() =>
                          form.setFieldsValue({
                            product: {
                              ...form.getFieldValue('product'),
                              count:
                                form.getFieldValue(['product', 'count']) + 1,
                            },
                          })
                        }
                        color="green"
                        size="xsmall"
                        disabled={productGroupHasPass}
                      />
                    </Col>
                  </Row>
                )}
                {productGroupHasPass && (
                  <Row>
                    <Col span={24}>
                      <div
                        style={{
                          color: 'red',
                          fontSize: 'small',
                          textAlign: 'center',
                        }}
                      >
                        {t(`selectProducts.maxPassOnProductGroup`)}
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Form.Item>

          <Form.List name={['product', 'payload']}>
            {(fields) => {
              return fields.map((field) => (
                <React.Fragment key={field.key}>
                  <Form.Item name={[field.name, 'name']} noStyle>
                    <Div />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.product?.payload[field.key]?.validityDateStart !==
                      next.product?.payload[field.key]?.validityDateStart
                    }
                  >
                    {({ getFieldValue }) => {
                      return (
                        getFieldValue([
                          'product',
                          'payload',
                          field.key,
                          'validityStartType',
                        ]) === 'FUTURE' && (
                          <Form.Item noStyle name={[field.name, 'startDate']}>
                            <DatePickerLocale
                              format="DD.MM.YYYY HH:mm"
                              showTime={{
                                defaultValue: dayjs('00:00:00', 'HH:mm'),
                              }}
                              style={{ width: '100%' }}
                              disabledDate={disabledDate}
                            />
                          </Form.Item>
                        )
                      );
                    }}
                  </Form.Item>

                  <Form.List name={[field.name, 'restrictions']}>
                    {(fields2) =>
                      fields2.map((field2) => (
                        <Restriction
                          key={field2.key}
                          form={form}
                          line={line}
                          whereIsRestriction={whereIsRestriction}
                          name={[field.name, 'restrictions', field2.name]}
                        />
                      ))
                    }
                  </Form.List>
                </React.Fragment>
              ));
            }}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};

export default SelectProducts;
