import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import SaleReportGridMobile from '../../../components/sale-report-mobile/SaleReportGridMobile';

const SaleReportMobile = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.salesReportMobile._')} />
      <SaleReportGridMobile />
    </Default>
  );
};

export default SaleReportMobile;
