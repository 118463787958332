import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditBlackListReasonForm from '../../../../components/blacklist-reasons/EditBlacklistReasonForm';

import {
  create,
  findOne,
  update,
} from '../../../../services/taxonomies/blacklist-reason';

const initialBlacklistReason = { deleted: false, isDefault: false };

const EditBlackListReason = () => {
  const [blacklistReason, handleSubmit, handleCancel] = useEditPage({
    initial: initialBlacklistReason,
    existent: findOne,
    goBackPath: '/admin/taxonomies/blacklist-reasons',

    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {blacklistReason !== undefined && (
        <EditBlackListReasonForm
          blacklistReasons={blacklistReason}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditBlackListReason;
