import React, { useCallback, useContext, useState } from 'react';
import { Col, notification, Popconfirm, Row } from 'antd';

import { startShift } from '../../../services/pos/pos';

import POSButton from '../POSButton';
import POSContext from '../POSContext';

const Startup = () => {
  const { setShift } = useContext(POSContext);
  const [disabled, setDisabled] = useState(false);

  const handleStartShift = useCallback(() => {
    setDisabled(true);
    startShift()
      .then((shift) => {
        setShift(shift);
        setDisabled(false);
      })
      .catch((err) => {
        console.error(err);
        notification.error({
          message: err.message,
        });
        setDisabled(false);
      });
  }, [setShift]);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
        <Col xs={{ span: 24 }} lg={{ span: 4, offset: 10 }}>
          <Popconfirm
            title="Sigur doriti sa deschideti schimbul?"
            onConfirm={handleStartShift}
            onCancel={() => null}
            disabled={disabled}
            okText="Da"
            cancelText="Nu"
          >
            <div>
              <POSButton
                title="Deschide schimb"
                onClick={() => null}
                disabled={disabled}
                color="green"
              />
            </div>
          </Popconfirm>
        </Col>
      </Row>
    </>
  );
};

export default Startup;
