import React from 'react';

import { DatePicker } from 'antd';

import locale from 'antd/es/date-picker/locale/en_US';

const RangePickerEnglish = (props) => (
  <DatePicker.RangePicker locale={locale} {...props} />
);
export default RangePickerEnglish;
