import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';
import { remove, findAll } from '../../../../services/taxonomies/currencies';

import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import Column from '../../../../helpers/columns';

const CurrenciesList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } = useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.taxonomy.currency.id')),
      Column.text('name', t('entity.taxonomy.currency.name'), { filter: true }),
      Column.bool(
        'currencyDefault',
        t('entity.taxonomy.currency.currencyDefault._'),
        {
          width: 150,
          filter: true,
          labels: [
            t('table.filter.all'),
            t('entity.taxonomy.currency.currencyDefault.true'),
            t('entity.taxonomy.currency.currencyDefault.false'),
            t('table.filter.unknown'),
          ],
        },
      ),
      Column.text('exchangeRate', t('entity.taxonomy.currency.exchangeRate')),
      Column.bool('deleted', t('entity.taxonomy.currency.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.currency.deleted.true'),
          t('entity.taxonomy.currency.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <>
          <EditItemIcon path={`/admin/taxonomies/currencies/${record.id}`} />
          <DeleteItemIcon
            title={t('entity.taxonomy.currency.delete._', record)}
            message={t('entity.taxonomy.currency.delete.deleted', record)}
            item={record}
            action={remove}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.currency._plural')}>
        <AddEntityButton
          entity={t('entity.taxonomy.currency._singular')}
          path="/admin/taxonomies/currencies/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default CurrenciesList;
