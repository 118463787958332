import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { notification, Space, Table } from 'antd';

import { Default } from '../../../../../layouts';

import useDatasource from '../../../../../hooks/useDatasource';
import {
  findAll,
  remove,
  SERVICE_URI,
  BASE_URI,
} from '../../../../../services/taxonomies/transport/units';

import PageTitle from '../../../../../components/PageTitle';
import AddEntityButton from '../../../../../components/buttons/AddEntityButton';
import ImportEntitiesButton from '../../../../../components/buttons/ImportEntitiesButton';
import EditItemIcon from '../../../../../components/buttons/EditItemIcon';

import Column from '../../../../../helpers/columns';
import DeleteItemIcon from '../../../../../components/buttons/DeleteItemIcon';

const TransportUnitsList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } = useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text(
        'inventoryNumber',
        t('entity.taxonomy.transportUnit.inventoryNumber'),
        {
          filter: true,
        },
      ),
      Column.text(
        'serialNumber',
        t('entity.taxonomy.transportUnit.serialNumber'),
        {
          filter: true,
        },
      ),
      Column.text(
        'plateNumber',
        t('entity.taxonomy.transportUnit.plateNumber'),
        {
          width: 200,
          filter: true,
        },
      ),
      Column.text('type', t('entity.taxonomy.transportUnit.type'), {
        filter: true,
      }),
      Column.text('brand', t('entity.taxonomy.transportUnit.brand'), {
        filter: true,
      }),
      Column.text('depo', t('entity.taxonomy.transportUnit.depo'), {
        filter: true,
      }),
      Column.text('costCenter', t('entity.taxonomy.transportUnit.costCenter')),
      Column.bool('deleted', t('entity.taxonomy.transportUnit.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.transportUnit.deleted.true'),
          t('entity.taxonomy.transportUnit.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <>
          <EditItemIcon
            path={`/admin/taxonomies/transport/units/${record.id}`}
          />
          <DeleteItemIcon
            title={t('entity.taxonomy.transportCostCenter._delete', record)}
            message={t('entity.taxonomy.transportCostCenter._deleted', record)}
            item={record}
            disabled={record.deleted}
            action={remove}
            reload={reload}
          />
        </>
      )),
    ],
    [reload, t],
  );

  const onUploadSuccess = useCallback(
    (transportUnits) => {
      reload();
      notification.success({
        message: t('entity.taxonomy.transportUnit.import.success', {
          quantity: transportUnits.length,
        }),
      });
    },
    [reload, t],
  );

  const onUploadFailed = useCallback(
    (msg) => {
      const messageCode = msg?.inner?._;
      notification.error({
        message: messageCode
          ? t(messageCode)
          : t('entity.taxonomy.transportUnit.import.fail'),
      });
    },
    [t],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.transportUnit._plural')}>
        <Space>
          <ImportEntitiesButton
            entity={t('entity.taxonomy.transportUnit._plural')}
            uploadUrl={`${SERVICE_URI}${BASE_URI}/upload`}
            onUploadSuccess={onUploadSuccess}
            onUploadFailed={onUploadFailed}
          />
          <AddEntityButton
            entity={t('entity.taxonomy.transportUnit._singular')}
            path="/admin/taxonomies/transport/units/new"
          />
        </Space>
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        scroll={{ x: 700, y: 700 }}
        onChange={handleChange}
      />
    </Default>
  );
};

export default TransportUnitsList;
