import React from 'react';

import { Default } from '../../../../layouts';
import { useEditPage } from '../../../../hooks/useEditPage';

import {
  createCalendar,
  getCalendarById,
  updateCalendar,
} from '../../../../services/offers/calendars';
import EditCalendarForm from '../../../../components/offers/calendars/EditCalendarForm';

const initialSchedule = { deleted: false, days: [], holidays: [] };

const EditCalendar = () => {
  const [calendar, handleSubmit, handleCancel] = useEditPage({
    initial: initialSchedule,
    existent: getCalendarById,
    goBackPath: '/admin/offers/calendars',

    onCreate: createCalendar,
    onUpdate: updateCalendar,
  });

  return (
    <Default>
      {calendar !== undefined && (
        <EditCalendarForm
          calendar={calendar}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditCalendar;
