import React from 'react';
import { useEditPage } from '../../../../hooks/useEditPage';
import {
  createSystemParameter,
  getSystemParameterById,
  updateSystemParameter,
} from '../../../../services/taxonomies/system-parameters';
import { Default } from '../../../../layouts';
import SystemParameterForm from './SystemParameterForm';

const initial = {};
const EditSystemParameter = () => {
  const [parameter, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: getSystemParameterById,
    goBackPath: '/admin/taxonomies/system-parameters',
    onCreate: createSystemParameter,
    onUpdate: updateSystemParameter,
  });

  return (
    <Default>
      {parameter !== undefined && (
        <SystemParameterForm
          param={parameter}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditSystemParameter;
