import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Card, Divider } from 'antd';
import {
  UngroupOutlined,
  MenuOutlined,
  GroupOutlined,
  DatabaseOutlined,
  ScheduleOutlined,
  ReconciliationOutlined,
  CalendarOutlined,
} from '@ant-design/icons';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import FormWrapper from '../../../components/FormWrapper';

const Offers = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Default>
      <PageTitle title={t('entity.offers._')} />
      <FormWrapper>
        <FormWrapper.Single>
          <Card
            actions={[
              <MenuOutlined
                key="menu"
                onClick={() => history.push('/admin/offers/products')}
              />,
            ]}
          >
            <Card.Meta
              avatar={<UngroupOutlined style={{ fontSize: '56px' }} />}
              title={t('entity.offers.product._plural')}
              description={t('entity.offers.product.description')}
            />
          </Card>
          <Divider />
          <Card
            actions={[
              <MenuOutlined
                key="menu"
                onClick={() => history.push('/admin/offers/schedules')}
              />,
            ]}
          >
            <Card.Meta
              avatar={<ScheduleOutlined style={{ fontSize: '56px' }} />}
              title={t('entity.offers.schedule._plural')}
              description={t('entity.offers.schedule.list')}
            />
          </Card>
          <Divider />
          <Card
            actions={[
              <MenuOutlined
                key="menu"
                onClick={() => history.push('/admin/offers/calendars')}
              />,
            ]}
          >
            <Card.Meta
              avatar={<CalendarOutlined style={{ fontSize: '56px' }} />}
              title={t('entity.offers.calendars._')}
              description={t('entity.offers.calendars.description')}
            />
          </Card>
        </FormWrapper.Single>
        <FormWrapper.Single>
          <Card
            actions={[
              <MenuOutlined
                key="menu"
                onClick={() => history.push('/admin/offers/groups')}
              />,
            ]}
          >
            <Card.Meta
              avatar={<GroupOutlined style={{ fontSize: '56px' }} />}
              title={t('entity.offers.productGroup._plural')}
              description={t('entity.offers.productGroup.description')}
            />
          </Card>
          <Divider />
          <Card
            actions={[
              <MenuOutlined
                key="menu"
                onClick={() => history.push('/admin/offers/offer-types')}
              />,
            ]}
          >
            <Card.Meta
              avatar={<GroupOutlined style={{ fontSize: '56px' }} />}
              title={t('entity.offers.offerType._plural')}
              description={t('entity.offers.offerType.description')}
            />
          </Card>
        </FormWrapper.Single>
        <FormWrapper.Single>
          <Card
            actions={[
              <MenuOutlined
                key="menu"
                onClick={() => history.push('/admin/offers/offers')}
              />,
            ]}
          >
            <Card.Meta
              avatar={<DatabaseOutlined style={{ fontSize: '56px' }} />}
              title={t('entity.offers.offer._plural')}
              description={t('entity.offers.offer.description')}
            />
          </Card>
          <Divider />
          <Card
            actions={[
              <MenuOutlined
                key="menu"
                onClick={() => history.push('/admin/offers/priorities')}
              />,
            ]}
          >
            <Card.Meta
              avatar={<ReconciliationOutlined style={{ fontSize: '56px' }} />}
              title={t('entity.offers.priorities._')}
              description={t('entity.offers.priorities.description')}
            />
          </Card>
        </FormWrapper.Single>
      </FormWrapper>
    </Default>
  );
};

export default Offers;
