import React from 'react';
import { useTranslation } from 'react-i18next';

import FileUploader from '../FileUploader';

const ImportEntitiesButton = ({
  entity,
  uploadUrl,
  onUploadSuccess,
  onUploadFailed,
}) => {
  const { t } = useTranslation();

  return (
    <FileUploader
      uploadUrl={uploadUrl}
      text={`${t('actions.import')} ${entity}`}
      multiple={false}
      dragger={false}
      onUploadSuccess={onUploadSuccess}
      onUploadFailed={onUploadFailed}
    />
  );
};

export default ImportEntitiesButton;
