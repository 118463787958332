import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { Badge, notification, Switch, Table } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import {
  findAll,
  downloadAllStatuses,
  setTVMLoggingStatus,
  getLastCashForAllTVMs,
  getJSONStatus,
} from '../../services/tvm/tvm';

import useDatasource from '../../hooks/useDatasource';

import Column from '../../helpers/columns';
import { findSystemParametersByGroupCode } from '../../services/taxonomies/system-parameters';
import ExportEntitiesButton from '../../components/buttons/ExportEntitiesButton';
import RawStatusPopover from './RawStatusPopover';

const MINUTES_15 = 1000 * 60 * 15;

const notShownRecyclers = [
  'cashRecycler3',
  'cashRecycler4',
  'cashRecycler5',
  'cashRecycler8',
  'cashRecycler9',
];
const notShownBoxes = ['cashBox8', 'cashBox9'];

const TVMStatuses = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [rawStatus, setRawStatus] = useState({});

  const getRawStatus = useCallback(
    (tvmId) => {
      if (tvmId !== rawStatus.tvmId) {
        getJSONStatus(tvmId).then((res) =>
          setRawStatus({ ...res, ...JSON.parse(res.json) }),
        );
      }
    },
    [rawStatus.tvmId],
  );

  const joinData = useCallback(
    (content, supplement) =>
      content.map((row) => {
        const lastSupply = supplement.find((e) => e.tvmId === row.tvmId) || {};
        const lastSupplyDate = lastSupply.updatedAt;
        const value = Object.keys(lastSupply)
          .filter((key) => key.includes('box') || key.includes('recycler'))
          .reduce((acc, cur) => {
            const amount = lastSupply[cur];
            if (!amount) {
              return acc;
            }
            const type = cur.includes('Bill') ? 'Bill' : 'Coin';
            // eslint-disable-next-line no-unused-vars
            const [box, val] = cur.split(type);
            return amount * (type === 'Bill' ? val : val / 10) + acc;
          }, 0);
        const lastSupplyValue = value ? `${value.toFixed(2)} RON` : '';

        return {
          ...row,
          lastSupplyDate,
          lastSupplyValue,
        };
      }),
    [],
  );

  const getData = useCallback(
    (params) =>
      findAll(params).then(async (data) => {
        const supplement = await getLastCashForAllTVMs();
        if (!supplement) {
          return data;
        }

        const content = joinData(data.content, supplement);
        return { ...data, content };
      }),
    [joinData],
  );

  const { loading, pagination, content, criterias, handleChange, reload } =
    useDatasource(getData, { pagination: { pageSize: 1000 } });

  const [dashboardSysParams, setDashboardSysParams] = useState([]);
  const [greenStatesSysParams, setGreenStatesSysParams] = useState([]);

  const refTime = useRef(Date.now());
  const [alerTrigger, setAlerTrigger] = useState(0);

  useEffect(() => {
    findSystemParametersByGroupCode('TVM_DASHBOARD').then((res) =>
      setDashboardSysParams(res),
    );
    findSystemParametersByGroupCode('TVM_GREEN_STATUSES').then((res) =>
      setGreenStatesSysParams(res),
    );
  }, []);

  const refreshRate = useMemo(() => {
    if (dashboardSysParams !== null && dashboardSysParams !== undefined) {
      const params = dashboardSysParams
        ?.filter((sysParam) => 'EVENT_CHECKER'.match(sysParam.code))
        .map((x) => x.value);
      return params.length > 0 ? params[0] * 1000 : null;
    }
    return null;
  }, [dashboardSysParams]);

  useEffect(() => {
    const intervalId = setInterval(
      () => setAlerTrigger((val) => val + 1),
      refreshRate + 1,
    );
    return () => {
      clearInterval(intervalId);
    };
  }, [setAlerTrigger, refreshRate]);

  useEffect(() => {
    if (content != null && !loading) {
      if (Date.now() - refTime.current > refreshRate) {
        refTime.current = Date.now();
        reload();
      }
    }
  }, [alerTrigger, refreshRate, reload, loading, content]);

  const isOld = useCallback(
    (updatedAt) => refTime.current > new Date(updatedAt).getTime() + MINUTES_15,
    [],
  );

  const getColor = useCallback(
    (status) => {
      if (status != null) {
        const hasGreenStatus = greenStatesSysParams.filter(
          (greenParams) => greenParams.code === status.toUpperCase(),
        );
        if (hasGreenStatus.length > 0) {
          return hasGreenStatus[0].value;
        }
        if (status.toUpperCase() === 'ERROR') {
          return 'red';
        }
        return 'yellow';
      }
      return 'red';
    },
    [greenStatesSysParams],
  );

  const getText = useCallback(
    (status) => {
      if (status != null) {
        const hasGreenStatus = greenStatesSysParams.filter(
          (greenParams) =>
            greenParams.code === status.toUpperCase() &&
            greenParams.value === 'green',
        );
        if (hasGreenStatus.length > 0) {
          return 'OK';
        }
      }
      return status;
    },
    [greenStatesSysParams],
  );

  const changeTVMLoggingStatus = useCallback(
    (id, checked) =>
      setTVMLoggingStatus(id, checked)
        .then(reload)
        .catch(() =>
          notification.error({
            message: t('actions.loadFailed'),
          }),
        ),
    [reload, t],
  );

  const columns = useMemo(
    () => [
      {
        ...Column.text('tvmId', t('entity.tvm.status.tvmId'), {
          width: 100,
          fixed: 'left',
        }),
        className: 'red',
        render: (value) => (
          <>
            {value}{' '}
            <EyeOutlined
              name="plus"
              onClick={() => {
                history.push(`admin/taxonomies/validators/${value}`);
              }}
            />
          </>
        ),
      },
      {
        ...Column.text('tvmName', t('entity.tvm.status.tvmName'), {
          width: 200,
          fixed: 'left',
        }),
        className: 'red',
      },
      {
        ...Column.text('version', t('entity.tvm.status.version'), {
          width: 100,
        }),
        className: 'red',
      },
      {
        ...Column.text('ip', 'IP', {
          width: 130,
        }),
        className: 'red',
      },
      {
        ...Column.text('posStatus', t('entity.tvm.status.pos'), { width: 130 }),
        render: (value, { tvmId, posEnabled, updatedAt = new Date() }) => (
          <RawStatusPopover data={rawStatus} tvmId={tvmId} device="pos">
            <Badge
              color={isOld(updatedAt) || !posEnabled ? 'red' : getColor(value)}
              text={isOld(updatedAt) ? 'OFFLINE' : getText(value)}
              onClick={() => getRawStatus(tvmId)}
            />
          </RawStatusPopover>
        ),
      },
      {
        ...Column.text('cashStatus', t('entity.tvm.status.cash'), {
          width: 130,
        }),
        render: (value, { tvmId, cashEnabled, updatedAt = new Date() }) => (
          <RawStatusPopover data={rawStatus} tvmId={tvmId} device="cash">
            <Badge
              color={isOld(updatedAt) || !cashEnabled ? 'red' : getColor(value)}
              text={isOld(updatedAt) ? 'OFFLINE' : getText(value)}
            />
          </RawStatusPopover>
        ),
      },
      {
        ...Column.text('alarmStatus', t('entity.tvm.status.alarm'), {
          width: 130,
        }),
        render: (value, { tvmId, alarmEnabled, updatedAt }) => (
          <RawStatusPopover data={rawStatus} tvmId={tvmId} device="alarm">
            <Badge
              color={
                isOld(updatedAt) || !alarmEnabled ? 'red' : getColor(value)
              }
              text={isOld(updatedAt) ? 'OFFLINE' : getText(value)}
            />
          </RawStatusPopover>
        ),
      },
      {
        ...Column.text('dispenserStatus', t('entity.tvm.status.dispenser'), {
          width: 130,
        }),
        render: (value, { tvmId, dispenserEnabled, updatedAt }) => (
          <RawStatusPopover data={rawStatus} tvmId={tvmId} device="dispenser">
            <Badge
              color={
                isOld(updatedAt) || !dispenserEnabled ? 'red' : getColor(value)
              }
              text={isOld(updatedAt) ? 'OFFLINE' : getText(value)}
            />
          </RawStatusPopover>
        ),
      },
      {
        ...Column.text('printerStatus', t('entity.tvm.status.printer'), {
          width: 130,
        }),
        render: (value, { tvmId, printerEnabled, updatedAt }) => (
          <RawStatusPopover data={rawStatus} tvmId={tvmId} device="printer">
            <Badge
              color={
                isOld(updatedAt) || !printerEnabled ? 'red' : getColor(value)
              }
              text={isOld(updatedAt) ? 'OFFLINE' : getText(value)}
            />
          </RawStatusPopover>
        ),
      },

      {
        ...Column.text('total', t('entity.tvm.status.total'), { width: 150 }),
        render: (__, value) => {
          const total = Array.from({ length: 9 }, (_, idx) => {
            const denom = value[`cashDenomination${idx}`];
            if (denom) {
              const val =
                denom.split(' Bill').length > 1
                  ? denom.split(' Bill')[0]
                  : denom.split(' Coin')[0];
              const box = (value[`cashBox${idx}`] || 0) * Number(val);
              const recycler = (value[`cashRecycler${idx}`] || 0) * Number(val);
              return box + recycler;
            }
            return 0;
          }).reduce((acc, curr) => acc + curr, 0);
          return <>{total.toFixed(2)} RON</>;
        },
      },
      {
        ...Column.text('totalBox', t('entity.tvm.status.totalBox'), {
          width: 150,
        }),
        render: (__, value) => {
          const total = Array.from({ length: 9 }, (_, idx) => {
            const denom = value[`cashDenomination${idx}`];
            if (denom) {
              const val =
                denom.split(' Bill').length > 1
                  ? denom.split(' Bill')[0]
                  : denom.split(' Coin')[0];
              return (value[`cashBox${idx}`] || 0) * Number(val);
            }
            return 0;
          }).reduce((acc, curr) => acc + curr, 0);
          return <>{total.toFixed(2)} RON</>;
        },
      },
      Column.text('dispenserCards0', t('entity.tvm.status.dispenserCards'), {
        width: 100,
      }),
      Column.date(
        'maintenanceStartAt',
        t('entity.tvm.status.maintenanceStartAt'),
        { width: 120 },
      ),
      Column.date('maintenanceEndAt', t('entity.tvm.status.maintenanceEndAt'), {
        width: 120,
      }),
      Column.text('lastSupplyValue', t('entity.tvm.status.lastSupplyValue'), {
        width: 160,
      }),
      Column.date('lastSupplyDate', t('entity.tvm.status.lastSupplyDate'), {
        width: 120,
      }),
      Column.date('updatedAt', t('entity.tvm.status.updatedAt'), {
        width: 120,
      }),
      ...new Array(10)
        .fill(null)
        .map((_v, idx) => ({
          ...Column.text(
            `cashRecycler${idx}`,
            t(`entity.tvm.status.cashRecycler${idx}`),
            {
              width: 120,
            },
          ),
          render: (value, row) => (
            <>
              {value} &times; {row[`cashDenomination${idx}`]}
            </>
          ),
        }))
        .filter((x) => !notShownRecyclers.includes(x.key)),

      ...new Array(10)
        .fill(null)
        .map((_v, idx) => ({
          ...Column.text(
            `cashBox${idx}`,
            t(`entity.tvm.status.cashBox${idx}`),
            {
              width: 120,
            },
          ),
          render: (value, row) => (
            <>
              {dashboardSysParams.filter(
                (sysParam) => sysParam.code === `cashBox${idx}`,
              )[0]?.value > value ? (
                <div style={{ color: '#ff0000' }}>
                  <WarningOutlined
                    style={{ fontSize: '12', color: '#ff0000' }}
                  />
                  {value} &times; {row[`cashDenomination${idx}`]}
                </div>
              ) : (
                <div style={{ color: '#136500' }}>
                  {value} &times; {row[`cashDenomination${idx}`]}
                </div>
              )}
            </>
          ),
        }))
        .filter((x) => !notShownBoxes.includes(x.key)),
      Column.actions(
        t('entity.tvm.status.saveLogs'),
        ({ tvmId, saveLogs }) => (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={saveLogs}
            onChange={(checked) => changeTVMLoggingStatus(tvmId, checked)}
          />
        ),
        { fixed: false },
      ),
    ],
    [
      t,
      history,
      rawStatus,
      isOld,
      getColor,
      getText,
      getRawStatus,
      dashboardSysParams,
      changeTVMLoggingStatus,
    ],
  );

  return (
    <>
      <ExportEntitiesButton
        columns={columns}
        data={{
          criterias,
        }}
        downloadFiles={downloadAllStatuses}
      />
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ y: 550 }}
        style={{ marginTop: 10 }}
        rowClassName={(record) =>
          getColor(record.posStatus) === 'red' ||
          getColor(record.cashStatus) === 'red' ||
          getColor(record.alarmStatus) === 'red' ||
          getColor(record.printerStatus) === 'red' ||
          getColor(record.dispenserStatus) === 'red'
            ? 'red'
            : ''
        }
      />
    </>
  );
};

export default TVMStatuses;
