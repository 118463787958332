import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// import * as yup from 'yup';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Table,
} from 'antd';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import useDictionaries from '../../hooks/useDictionaries';

import { findAll as findAllArticleTypes } from '../../services/stocks/article-types';

import {
  Enrolled,
  Enrollment,
  NonEnrolled,
  Scrap,
  Warehouse,
} from './constants';
import Column from '../../helpers/columns';

const dictionaries = {
  articleTypes: findAllArticleTypes,
};

const fieldNameByAccountOrFlag = (account, enrolled) => {
  switch (account) {
    case Enrolled:
      return 'enrolled';
    case NonEnrolled:
      return 'nonEnrolled';
    case Scrap:
      return 'scrap';
    default:
      return enrolled ? 'enrolled' : 'nonEnrolled';
  }
};

const getArticleTypeById = (dict, ident) => {
  if (dict.loading) {
    return null;
  }

  if (!Array.isArray(dict.content)) {
    return null;
  }

  // eslint-disable-next-line eqeqeq
  return dict.content.find(({ id }) => id == ident);
};

const resolutions = {
  NONE: {
    key: 0,
    lable: 'none',
  },
  CT_VALID: {
    key: 1,
    lable: 'ctValid',
  },
  DT_VALID: {
    key: 2,
    lable: 'dtValid',
  },
  BOTH_VALID: {
    key: 3,
    lable: 'bothValid',
  },
};

const conflictResolutions = new Map(
  Object.values(resolutions).map((c) => [c.key, c]),
);

const EditInvoiceRecordsForm = ({
  form,
  invoiceType,
  invoiceStatus,
  stock,
  conflicts,
}) => {
  const { t } = useTranslation();

  const [{ articleTypes }] = useDictionaries(dictionaries);

  const { records: recs } = form.getFieldsValue('records');
  const hasConflicts = useMemo(() => {
    if (!recs) {
      return false;
    }
    return recs.some((rec) => rec.resolutionStatus);
  }, [recs]);

  const prefixQty = useCallback(
    (index, field) => {
      const { records } = form.getFieldsValue('records');
      if (!records) {
        return '';
      }

      const { id, resolutionStatus, quantity, confirmedQuantity } =
        records[index];
      if (
        !(id && confirmedQuantity && resolutionStatus && quantity && quantity)
      ) {
        return '';
      }
      if (
        resolutions.BOTH_VALID === conflictResolutions.get(resolutionStatus)
      ) {
        return `0`;
      }
      if (
        field === 'quantity' &&
        resolutions.DT_VALID === conflictResolutions.get(resolutionStatus)
      ) {
        const diff = confirmedQuantity - quantity;
        return `${diff > 0 ? '+' : ''}${diff}`;
      }
      if (
        field === 'confirmedQuantity' &&
        resolutions.CT_VALID === conflictResolutions.get(resolutionStatus)
      ) {
        const diff = quantity - confirmedQuantity;
        return `${diff > 0 ? '+' : ''}${diff}`;
      }
      return '';
    },
    [form],
  );

  const isConflictProduct = useCallback(
    (index) => {
      const { records } = form.getFieldsValue('records');
      if (!records) {
        return false;
      }
      const { id, resolutionStatus } = records[index];

      if (!id) {
        return false;
      }

      return (conflicts && conflicts.includes(id)) || resolutionStatus;
    },
    [conflicts, form],
  );

  const getCurrentStock = useCallback(
    (index) => {
      if (!stock) {
        return 0;
      }

      const { records } = form.getFieldsValue('records');
      if (!records) {
        return 0;
      }

      const { article, enrolled } = records[index];

      if (!article) {
        return 0;
      }

      const articleEntity = getArticleTypeById(articleTypes, article);
      if (!articleEntity) {
        return 0;
      }

      const fieldName = fieldNameByAccountOrFlag(
        invoiceType.account,
        articleEntity.enrollable ? enrolled : true,
      );

      const val = stock[article] || {};
      return val[fieldName] || 0;
    },
    [articleTypes, form, invoiceType, stock],
  );

  const isEnrollableProduct = useCallback(
    (index) => {
      const { records } = form.getFieldsValue('records');
      if (!records) {
        return false;
      }

      const { article } = records[index];

      if (!article) {
        return false;
      }

      const articleEntity = getArticleTypeById(articleTypes, article);
      return articleEntity ? articleEntity.enrollable : false;
    },
    [form, articleTypes],
  );

  const updateRowTotals = useCallback(
    (index) => {
      const { records } = form.getFieldsValue('records');

      const { unitPrice, tva, serialNumberStart } = records[index];
      let { quantity } = records[index];

      if (!Number.isInteger(quantity)) {
        quantity *= 1;
      }

      let { serialNumberEnd } = records[index];

      const totalWithoutTva = quantity * unitPrice;
      const totalWithTva = (totalWithoutTva * (100 + tva)) / 100;

      if (
        Number.isInteger(parseInt(serialNumberStart, 10)) &&
        parseInt(serialNumberStart, 10) >= 0 &&
        Number.isInteger(quantity)
      ) {
        serialNumberEnd = `${
          quantity - 1 + parseInt(serialNumberStart, 10)
        }`.padStart(serialNumberStart.length, '0');
      }

      form.setFieldsValue({
        records: records.map((val, idx) =>
          idx === index
            ? {
                ...val,
                totalWithoutTva,
                totalWithTva,
                serialNumberStart,
                serialNumberEnd,
              }
            : val,
        ),
      });
    },
    [form],
  );

  const columns = useMemo(() => {
    const { columns: visibleColumns, account: defaultBucket } = invoiceType;
    return [
      {
        key: 'position',
        title: t('entity.stocks.invoice.position'),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item noStyle name={[index, 'position']}>
            <Input disabled />
          </Form.Item>
        ),
      },
      {
        key: 'article',
        title: t('entity.stocks.invoice.article'),
        width: 200,
        render: (_, _record, index) => (
          <Form.Item noStyle name={[index, 'article']}>
            <Select
              // loading={articleTypes.loading}
              disabled={invoiceStatus !== 'CREATED'}
            >
              {articleTypes.content.map((option) => (
                <Select.Option
                  key={option.id}
                  value={option.id}
                  disabled={
                    (invoiceType.origin === Warehouse &&
                      invoiceType.destination === Enrollment) ||
                    (invoiceType.origin === Enrollment &&
                      invoiceType.destination === Enrollment)
                      ? option.deleted || !option.enrollable
                      : option.deleted
                  }
                >
                  {`${option.code} - ${option.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ),
      },
      {
        key: 'enrolled',
        title: t('entity.stocks.invoice.enrolled'),
        width: 80,
        render: (_, _record, index) => (
          <Form.Item noStyle shouldUpdate>
            {() => (
              <>
                {isEnrollableProduct(index) && (
                  <Form.Item
                    noStyle
                    name={[index, 'enrolled']}
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren="Da"
                      unCheckedChildren="Nu"
                      disabled={invoiceStatus !== 'CREATED'}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </Form.Item>
        ),
      },
      {
        key: 'quantity',
        title: t('entity.stocks.invoice.quantity'),
        width: 100,
        render: (_, _record, index) => (
          <Input.Group compact>
            <Form.Item
              noStyle
              name={[index, 'quantity']}
              dependencies={
                // eslint-disable-next-line no-sequences
                ([index, 'totalWithoutTva'], [index, 'totalWithTva'])
              }
            >
              <Input
                type="number"
                min={1}
                style={{
                  width: stock && invoiceStatus === 'CREATED' ? '50%' : '100%',
                }}
                onChange={() => updateRowTotals(index)}
                disabled={invoiceStatus !== 'CREATED'}
                addonBefore={prefixQty(index, 'quantity')}
              />
            </Form.Item>
            {stock && invoiceStatus === 'CREATED' && (
              <Form.Item noStyle shouldUpdate>
                {() => (
                  <Input
                    disabled
                    style={{ width: '50%' }}
                    value={getCurrentStock(index)}
                  />
                )}
              </Form.Item>
            )}
          </Input.Group>
        ),
      },

      {
        key: 'askedQuantity',
        title: t('entity.stocks.invoice.askedQuantity'),
        width: 100,
        render: (_, _record, index) => (
          <Form.Item noStyle name={[index, 'quantity']}>
            <InputNumber min={1} disabled={invoiceStatus === 'CONFIRMED'} />
          </Form.Item>
        ),
      },

      {
        key: 'confirmedQuantity',
        title: t('entity.stocks.invoice.enrolledQuantity'),
        width: 100,
        render: (_, _record, index) => (
          <Form.Item noStyle name={[index, 'confirmedQuantity']}>
            <InputNumber disabled />
          </Form.Item>
        ),
      },

      {
        key: 'conflictResolution',
        title: t('entity.stocks.invoice.conflictResolution._'),
        width: 100,
        render: (_, _record, index) => (
          <>
            {isConflictProduct(index) && (
              <Form.Item noStyle name={[index, 'resolutionStatus']}>
                <Select disabled={invoiceStatus === 'CONFIRMED'}>
                  <Select.Option key={resolutions.NONE.key} value={null}>
                    {t(
                      `entity.stocks.invoice.conflictResolution.${resolutions.NONE.lable}`,
                    )}
                  </Select.Option>
                  <Select.Option
                    key={resolutions.CT_VALID.key}
                    value={resolutions.CT_VALID.key}
                  >
                    {t(
                      `entity.stocks.invoice.conflictResolution.${resolutions.CT_VALID.lable}`,
                    )}
                  </Select.Option>
                  <Select.Option
                    key={resolutions.DT_VALID.key}
                    value={resolutions.DT_VALID.key}
                  >
                    {t(
                      `entity.stocks.invoice.conflictResolution.${resolutions.DT_VALID.lable}`,
                    )}
                  </Select.Option>
                  <Select.Option
                    key={resolutions.BOTH_VALID.key}
                    value={resolutions.BOTH_VALID.key}
                  >
                    {t(
                      `entity.stocks.invoice.conflictResolution.${resolutions.BOTH_VALID.lable}`,
                    )}
                  </Select.Option>
                </Select>
              </Form.Item>
            )}
          </>
        ),
      },

      {
        key: 'serialLetter',
        title: t('entity.stocks.invoice.serialLetter'),
        width: 100,
        render: (_, _record, index) => (
          <Form.Item noStyle name={[index, 'serialLetter']}>
            <Input disabled={invoiceStatus !== 'CREATED'} />
          </Form.Item>
        ),
      },
      {
        key: 'serialNumberStart',
        title: t('entity.stocks.invoice.serialNumberStart'),
        width: 100,
        render: (_, _record, index) => (
          <Form.Item
            noStyle
            name={[index, 'serialNumberStart']}
            dependencies={[index, 'serialNumberEnd']}
          >
            <Input
              onChange={() => updateRowTotals(index)}
              disabled={invoiceStatus !== 'CREATED'}
            />
          </Form.Item>
        ),
      },
      {
        key: 'serialNumberEnd',
        title: t('entity.stocks.invoice.serialNumberEnd'),
        width: 100,
        render: (_, _record, index) => (
          <Form.Item noStyle name={[index, 'serialNumberEnd']}>
            <Input min={0} disabled />
          </Form.Item>
        ),
      },

      {
        key: 'unitPrice',
        title: t('entity.stocks.invoice.unitPrice'),
        width: 100,
        render: (_, _record, index) => (
          <Form.Item
            noStyle
            name={[index, 'unitPrice']}
            // eslint-disable-next-line no-sequences
            dependencies={([index, 'totalWithoutTva'], [index, 'totalWithTva'])}
          >
            <InputNumber
              min={0}
              precision={2}
              onChange={() => updateRowTotals(index)}
              disabled={invoiceStatus !== 'CREATED'}
            />
          </Form.Item>
        ),
      },
      {
        key: 'totalWithoutTva',
        title: t('entity.stocks.invoice.totalWithoutTva'),
        width: 100,
        render: (_, _record, index) => (
          <Form.Item noStyle name={[index, 'totalWithoutTva']}>
            <InputNumber min={0} precision={2} disabled />
          </Form.Item>
        ),
      },
      {
        key: 'tva',
        title: t('entity.stocks.invoice.tva'),
        width: 100,
        render: (_, _record, index) => (
          <Form.Item
            noStyle
            name={[index, 'tva']}
            dependencies={[index, 'totalWithTva']}
          >
            <InputNumber
              min={0}
              precision={2}
              onChange={() => updateRowTotals(index)}
              disabled={invoiceStatus !== 'CREATED'}
            />
          </Form.Item>
        ),
      },
      {
        key: 'totalWithTva',
        title: t('entity.stocks.invoice.totalWithTva'),
        width: 100,
        render: (_, _record, index) => (
          <Form.Item noStyle name={[index, 'totalWithTva']}>
            <InputNumber min={0} precision={2} disabled />
          </Form.Item>
        ),
      },
      Column.actions(t('table.actions'), (record, index) => (
        <Button
          shape="circle"
          type="link"
          icon={<DeleteOutlined />}
          disabled={invoiceStatus !== 'CREATED'}
          onClick={() => record.$remove(index)}
        />
      )),
    ].filter(
      (column) =>
        visibleColumns.includes(column.key) ||
        (column.key === 'enrolled' && defaultBucket == null) ||
        (column.key === 'conflictResolution' &&
          (invoiceStatus === 'CONFLICT' || hasConflicts)),
    );
  }, [
    invoiceType,
    invoiceStatus,
    stock,
    hasConflicts,
    prefixQty,
    t,
    // articleTypes.loading,
    articleTypes.content,
    isEnrollableProduct,
    updateRowTotals,
    getCurrentStock,
    isConflictProduct,
  ]);

  const tableWidth = useMemo(
    () => (columns || []).reduce((acc, column) => acc + (column.width || 0), 0),
    [columns],
  );

  return (
    <Form.List name="records">
      {(fields, { add, remove }) => (
        <>
          {invoiceStatus === 'CREATED' && !invoiceType.individual && (
            <Space align="end">
              <Button
                type="primary"
                style={{ marginBottom: '8px' }}
                onClick={() =>
                  add({
                    position: (fields.length || 0) + 1,
                    enrolled: false,
                    quantity: 1,
                    unitPrice: 0,
                    totalWithoutTva: 0,
                    tva: 0,
                    totalWithTva: 0,
                  })
                }
                icon={<PlusOutlined />}
              >
                Adauga articol
              </Button>
            </Space>
          )}
          <Table
            size="small"
            rowKey="position"
            columns={columns}
            dataSource={fields}
            pagination={false}
            onRow={(record) => {
              // eslint-disable-next-line no-param-reassign
              record.$remove = remove;
            }}
            scroll={{ x: tableWidth }}
          />
        </>
      )}
    </Form.List>
  );
};

export default EditInvoiceRecordsForm;
