import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Space, Input, Modal, notification, Tooltip, Spin } from 'antd';
import { CreditCardOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { post } from '../helpers/api';
import useCard from './useCard';

const defaultOptions = {
  length: 4,
};

const setFocusOnElement = (id) => {
  const el = document.getElementById(id);
  if (el) {
    el.focus();
    el.select();
  }
};

export const PinCodeForm = ({ id, length, modal }) => {
  const [currentCard, cardLoading, fetchCard] = useCard();

  const [pin, setPin] = useState([]);

  const setPinDigit = (index, digit) => {
    setPin((oldPin) => {
      oldPin.splice(index, 1, digit);
      // eslint-disable-next-line no-param-reassign
      modal.value = {
        ...(modal.value || {}),
        pin: pin.filter((v) => /^\d$/.test(v)).join(''),
      };
      return [...oldPin];
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    modal.value = { ...(modal.value || {}), identifier: currentCard };
  }, [currentCard, modal]);

  useEffect(() => {
    fetchCard();
  }, [fetchCard]);

  useEffect(() => {
    setPin(new Array(length).fill(''));
  }, [length, id]);

  const handleChange = (evt) => {
    evt.preventDefault();

    const {
      target: {
        value,
        dataset: { index },
      },
    } = evt;

    if (value === '' || /^\d$/.test(value)) {
      setPinDigit(index, value);
      if (value !== '') {
        setFocusOnElement(`${id}-${+index + 1}`);
      }
    }
  };

  const handleKeyUp = (evt) => {
    const {
      target: {
        dataset: { index },
      },
    } = evt;

    const key = evt.keyCode || evt.charCode;
    if (key === 8 && +index > 0) {
      setPinDigit(index - 1, '');
      setFocusOnElement(`${id}-${+index - 1}`);
      return;
    }

    const value = pin[index];
    if (value !== '') {
      setFocusOnElement(`${id}-${+index + 1}`);
    }
  };

  return (
    <>
      {cardLoading ? (
        <Spin
          style={{
            position: 'absolute',
            right: '1rem',
            top: '1rem',
          }}
        />
      ) : (
        <Tooltip placement="left" title={currentCard}>
          <CreditCardOutlined
            onClick={fetchCard}
            style={{
              position: 'absolute',
              right: '1rem',
              top: '1rem',
              color: currentCard === 0 ? '#ccc' : '#1890ff',
            }}
          />
        </Tooltip>
      )}

      <Space size="large">
        {pin.map((value, index) => (
          <Input
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-${index}`}
            id={`${id}-${index}`}
            data-index={index}
            maxLength="1"
            value={value}
            size="large"
            style={{ width: '3rem', textAlign: 'center' }}
            type="password"
            disabled={currentCard === 0}
            autoComplete="off"
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        ))}
      </Space>
    </>
  );
};

const usePin = (type, handler, { length } = defaultOptions) => {
  const { t } = useTranslation();
  const show = useCallback(
    (...args) => {
      return new Promise((resolve, reject) => {
        const id = `pin${Date.now()}${Math.round(Math.round() * 1000)}`;

        const modal = Modal.confirm({
          title: 'Pin Code',
          icon: <LockOutlined />,
          width: `${38 + 72 * length + 40}px`,
          disabled: true,
          content: <></>,
          onOk: (close) => {
            const { pin, identifier } = modal.value;
            if (pin.length !== length) {
              return;
            }

            post(`/users/api/v1/pin/${type}`, { pin, identifier })
              .then((res) => {
                const { token } = res;

                // eslint-disable-next-line no-param-reassign
                const [data, ...rest] = args;
                handler.apply(handler, [{ ...data, token }, ...rest]).then(
                  (res2) => resolve(res2),
                  (err) => reject(err),
                );
                close();
              })
              .catch((err) => {
                notification.error({
                  message:
                    err.inner && err.inner._
                      ? t(err.inner._)
                      : 'PIN a fost introdus gresit',
                });
              });
          },

          onCancel: () => {
            reject();
          },
        });

        modal.update((prevConfig) => ({
          ...prevConfig,
          content: <PinCodeForm id={id} length={length} modal={modal} />,
        }));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handler, length],
  );

  const result = useMemo(() => [show], [show]);

  return result;
};

export default usePin;
