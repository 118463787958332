import React from 'react';

import { Col, Row, Typography } from 'antd';

const PageTitle = ({ title, children }) => (
  <Row style={{ paddingBottom: '24px' }}>
    <Col xs={12}>
      <Typography.Title level={5}>{title}</Typography.Title>
    </Col>
    {children && (
      <Col xs={12} style={{ textAlign: 'right' }}>
        {children}
      </Col>
    )}
  </Row>
);

export default PageTitle;
