import React, { useContext, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Tooltip, Button, notification } from 'antd';

import { EyeOutlined } from '@ant-design/icons';

import Column from '../../helpers/columns';
import POSContext from './POSContext';

import { openOrder } from '../../services/pos/pos';

const FoundCardsList = (cards) => {
  const { t } = useTranslation();
  const { shift, setOrder, setCardBlocked } = useContext(POSContext);
  const handleOpenOrder = useCallback(
    (card) => {
      openOrder(shift, card)
        .then((res) => {
          if (res.card.deleted) {
            setCardBlocked(card);
          }
          setOrder(res);
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: err.message,
          });
        });
    },
    [shift, setOrder, setCardBlocked],
  );

  const columns = useMemo(
    () => [
      {
        title: t('entity.pos.cardSearch.logicIdSerial'),
        width: 150,
        key: 'logicIdSerial',
        dataIndex: 'logicIdSerial',
      },
      Column.text(
        'parentIdentifier',
        t('entity.pos.cardSearch.userIdentifier'),
        {
          filter: true,
          width: 50,
        },
      ),
      {
        title: t('entity.pos.cardSearch.customerType'),
        width: 150,
        key: 'customerType',
        dataIndex: 'customerType',
        render: (customerType) => t(`entity.account.type.${customerType}`),
      },
      Column.bool('deleted', t('entity.pos.cardSearch.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.pos.cardSearch.deleted.false'),
          t('entity.pos.cardSearch.deleted.true'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(
        t('table.actions'),
        (record) =>
          record.cardTypeId === 1 && (
            <Tooltip title={t('actions.edit')}>
              <Button
                shape="circle"
                type="link"
                onClick={() => handleOpenOrder(record.physicalId)}
                icon={<EyeOutlined />}
                size="middle"
              />
            </Tooltip>
          ),
      ),
    ],
    [handleOpenOrder, t],
  );

  return (
    // eslint-disable-next-line react/destructuring-assignment
    cards.cards.length > 0 && (
      <Table
        columns={columns}
        rowKey="id"
        pagination={false}
        // eslint-disable-next-line react/destructuring-assignment
        dataSource={cards.cards}
      />
    )
  );
};

export default FoundCardsList;
