import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Spin, Table, Tooltip } from 'antd';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import Column from '../../helpers/columns';
import { getLogFilesList, updateLogsFilesList } from '../../services/tvm/tvm';

const TVMLogFilesDashboard = ({ id }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloadTrigger, setReload] = useState(null);

  const transformData = useCallback(
    ({ list }) =>
      list
        ? list
            .split(',')
            .map((name) => {
              const props = name?.split(':');
              return {
                name: `${props[0]}.log`,
                delete: props[1] === 'delete',
              };
            })
            .sort((a, b) => a.name - b.name)
            .map((e, index) => ({ ...e, index }))
        : [],
    [],
  );

  useEffect(() => {
    setLoading(true);
    getLogFilesList(id)
      .then(transformData)
      .then(setData)
      .then(() => setLoading(false));
  }, [id, transformData, reloadTrigger]);

  const columns = useMemo(
    () => [
      {
        title: t('entity.tvm.logs.fileName'),
        key: 'fileName',
        width: 100,
        dataIndex: 'name',
        sorter: false,
      },
      Column.actions(t('entity.tvm.logs.select'), (el) => {
        return el.delete ? (
          <Tooltip title={t('entity.tvm.logs.waitingForDeleting')}>
            <Spin />
          </Tooltip>
        ) : (
          <Tooltip title={t('entity.tvm.logs.select')}>
            <Checkbox
              checked={el.toDelete}
              onChange={(e) =>
                setData((all) => {
                  console.log('all :>> ', all);
                  return [
                    ...all.filter((l) => l.index !== el.index),
                    { ...el, toDelete: e.target.checked },
                  ].sort((a, b) => a.index - b.index);
                })
              }
            />
          </Tooltip>
        );
      }),
    ],
    [t],
  );

  const removeSelected = useCallback(() => {
    setLoading(true);
    const list = data
      .map((f) => {
        const name = f.name.replace('.log', '');
        return f.delete || f.toDelete ? `${name}:delete` : name;
      })
      .join(',');
    updateLogsFilesList({ list }, id)
      .then(transformData)
      .then((array) => {
        setData(array);
        setLoading(false);
      });
  }, [data, id, transformData]);

  return (
    <>
      <PageTitle title={t('entity.tvm.sales')} />
      <Button
        type="primary"
        onClick={removeSelected}
        icon={<DeleteOutlined />}
        style={{ margin: 10 }}
        disabled={!data.find((e) => e.toDelete)}
      >
        {t('entity.tvm.logs.removeSelected')}
      </Button>
      <Button
        type="primary"
        onClick={() => setReload(Math.random())}
        icon={<ReloadOutlined />}
      >
        {t('entity.tvm.logs.refresh')}
      </Button>
      <Spin tip="Loading" spinning={loading}>
        <Table
          columns={columns}
          rowKey="index"
          dataSource={data}
          size="small"
          style={{ width: 400 }}
        />
      </Spin>
    </>
  );
};

export default TVMLogFilesDashboard;
