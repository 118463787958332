import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import {
  getProductById,
  createProduct,
  updateProduct,
} from '../../../../services/offers/products';
import EditProductForm from '../../../../components/offers/products/EditProductForm';

const initialProduct = {
  roles: [],
  restrictions: [],
  tokenSubtypes: [],
  prices: [],
};

const EditProduct = () => {
  const { pathname } = useLocation();

  const fetchProduct = useCallback(
    (id) => {
      return getProductById(id).then((res) => {
        if (pathname.endsWith('/clone')) {
          // eslint-disable-next-line no-shadow
          const { id, name, code, restrictions, ...rest } = res;
          return {
            ...rest,
            restrictions: restrictions.map((it) => ({ ...it, id: null })),
            deleted: false,
          };
        }

        return res;
      });
    },
    [pathname],
  );

  const [product, handleSubmit, handleCancel] = useEditPage({
    initial: initialProduct,
    existent: fetchProduct,
    goBackPath: '/admin/offers/products',

    onCreate: createProduct,
    onUpdate: updateProduct,
  });

  return (
    <Default>
      {product !== undefined && (
        <EditProductForm
          product={product}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditProduct;
