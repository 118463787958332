import { post, get, search, genericCRUDService } from '../../helpers/api';

export const SERVICE_URI = '/stocks';
export const BASE_URI = '/api/v1/enroll';

export const { downloadAll } = genericCRUDService(SERVICE_URI, `${BASE_URI}`);

const request = async (method, endpoint) =>
  fetch(
    `http://${
      localStorage.getItem('devconnIP') || 'localhost'
    }:13385/dev/print/${endpoint}`,
    {
      // eslint-disable-next-line object-shorthand
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

export const getNextCardUid = () =>
  request('GET', 'card').then((resp) =>
    resp.ok ? resp.text() : Promise.resolve(0),
  );

export const enrollProduct = (product) =>
  post(`${SERVICE_URI}${BASE_URI}`, product);

export const enrollProductExternal = (product) =>
  post(`${SERVICE_URI}${BASE_URI}/external`, product);

export const ejectCard = (ejectionMode) =>
  request('DELETE', `card/${ejectionMode}`);

export const getEnrolledProduct = (physicalId) =>
  get(`${SERVICE_URI}${BASE_URI}/${physicalId}/physical`);

export const getEnrolledProducts = (data) =>
  search(`${SERVICE_URI}${BASE_URI}`, data);
