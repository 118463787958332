import React from 'react';

const POSButton = ({ title, onClick, color, size, disabled, ...rest }) => (
  <button
    type="button"
    className={`pos-button pos-button__${size || 'default'} pos-button__color-${
      color || 'default'
    }`}
    {...rest}
    onClick={onClick}
    disabled={disabled}
  >
    {title}
  </button>
);

export default POSButton;
