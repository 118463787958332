import React, { useMemo } from 'react';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import CancelButton from '../buttons/CancelButton';
import { findAll as getEquipmentBrands } from '../../services/taxonomies/equipment/brands';
import { findAll as getSectors } from '../../services/admin/sectors';
import useDictionaries from '../../hooks/useDictionaries';
import { hasPermission } from '../auth';

const now = new Date();
const tokenLength = 10;
const allowedChars =
  'abcdefghijklmnoprstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export const VALIDATOR_STATUS = [
  { id: 'FUNCTIONAL' },
  { id: 'IMMOBILIZED' },
  { id: 'MAINTENANCE' },
  { id: 'UNKNOWN' },
  { id: 'PROPOSED_FOR_DISCARD' },
  { id: 'SCHOOL' },
];

export const EQUIPMENT_TYPES = [{ id: 'VALIDATOR' }, { id: 'TVM' }];

const dictionaries = {
  brands: getEquipmentBrands,
  sectors: getSectors,
};

const EditValidatorForm = ({ validator, onSubmit, onCancel, permisions }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        serialNr: yup.string().trim().required(t('errors.notEmpty')),
        tokenId: yup
          .string()
          .trim()
          .required(t('errors.notEmpty'))
          .min(tokenLength)
          .max(tokenLength)
          .matches('^[a-zA-Z0-9]+$'),
      }),
    [t],
  );

  const { value, input, handleSubmit, setValue, select, custom } = useForm({
    initialValue: validator,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const randomizeString = () => {
    return Array(tokenLength)
      .fill()
      .map(
        () =>
          [
            ...allowedChars,
            // eslint-disable-next-line no-bitwise
          ][(Math.random() * allowedChars.length) | 0],
      )
      .join('');
  };

  const GenerateSequence = ({ disabled = true }) => {
    return (
      <SettingOutlined
        onClick={() => setValue('tokenId', randomizeString())}
        disabled={disabled}
      />
    );
  };

  const [{ brands, sectors }] = useDictionaries(dictionaries);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.validators.name')}
              props={{
                ...input('name'),
                disabled:
                  value.deleted ||
                  !hasPermission(permisions, ['SYS_SUPERUSER']),
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.validators.serialNumber')}
              props={{
                ...input('serialNr'),
                disabled:
                  value.deleted ||
                  !hasPermission(permisions, ['SYS_SUPERUSER']),
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.validators.tokenId')}
              props={{
                ...input('tokenId'),
                addonBefore: !value.deleted && (
                  <GenerateSequence disabled={value.deleted} />
                ),
                maxLength: tokenLength,
                disabled:
                  value.deleted ||
                  !hasPermission(permisions, ['SYS_SUPERUSER']),
              }}
            />
            <FormWrapper.DateTimePicker
              label={t('entity.taxonomy.validators.lastConnectionAt')}
              props={{
                ...custom(`lastConnectionAt`),
                disabled: true,
                disabledDate: (date) => date.isAfter(now),
              }}
            />
            {value.deleted ? (
              <FormWrapper.Input
                label={t('entity.taxonomy.validators.status._')}
                props={{
                  defaultValue: t('entity.taxonomy.validators.status.DELETED'),
                  disabled: true,
                }}
              />
            ) : (
              <FormWrapper.Select
                label={t('entity.taxonomy.validators.status._')}
                props={{
                  options: VALIDATOR_STATUS,
                  allowClear: true,
                  labelProp: (s) =>
                    t(`entity.taxonomy.validators.status.${s.id}`),
                  ...select('status'),
                  disabled:
                    value.deleted ||
                    !hasPermission(permisions, ['SYS_SUPERUSER']),
                }}
              />
            )}
            <FormWrapper.Select
              label={t('entity.taxonomy.validators.brand')}
              props={{
                ...select('brand'),
                options: brands.content,
                loading: brands.loading,
                disabled:
                  value.deleted ||
                  !hasPermission(permisions, ['SYS_SUPERUSER']),
                allowClear: true,
              }}
            />
          </FormWrapper.Single>
          <FormWrapper.Single>
            <FormWrapper.Number
              label={t('entity.taxonomy.validators.vehicleNumber')}
              props={{
                min: 0,
                ...input('vehicleNumber'),
                disabled: true,
              }}
            />
            <FormWrapper.Number
              label={t('entity.taxonomy.validators.position')}
              props={{
                min: 0,
                ...input('validatorPosition'),
                disabled: true,
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.validators.softwareVersion')}
              props={{
                ...input('softwareVersion'),
                disabled: true,
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.validators.cdVersion')}
              props={{
                ...input('cdVersion'),
                disabled: true,
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.validators.inventoryNumber')}
              props={{
                ...input('inventoryNumber'),
                disabled:
                  value.deleted ||
                  !hasPermission(permisions, ['SYS_SUPERUSER']),
              }}
            />
            <FormWrapper.DateTimePicker
              label={t('entity.taxonomy.validators.commissioningDate')}
              props={{
                ...custom('commissioningDate'),
                disabled:
                  value.deleted ||
                  !hasPermission(permisions, ['SYS_SUPERUSER']),
              }}
            />
          </FormWrapper.Single>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.validators.whitelistVersion')}
              props={{
                ...input('whiteListVersion'),
                disabled: true,
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.validators.depot')}
              props={{
                ...input('depot'),
                disabled: true,
              }}
            />
            <FormWrapper.Number
              label={t('entity.taxonomy.validators.lineVersion')}
              props={{
                min: 0,
                ...input('lineVersion'),
                disabled: true,
              }}
            />
            <FormWrapper.Number
              label={t('entity.taxonomy.validators.warranty')}
              props={{
                ...input('warranty'),
                min: 0,
                max: 32400,
                disabled:
                  value.deleted ||
                  !hasPermission(permisions, ['SYS_SUPERUSER']),
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.validators.type')}
              props={{
                ...select('equipmentType'),
                options: EQUIPMENT_TYPES,
                loading: false,
                labelProp: (s) => t(`entity.taxonomy.validators.types.${s.id}`),
                disabled:
                  value.deleted ||
                  !hasPermission(permisions, ['SYS_SUPERUSER']),
                allowClear: true,
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.validators.sector')}
              props={{
                ...select('sector'),
                options: sectors.content,
                loading: sectors.loading,
                disabled:
                  value.deleted ||
                  !hasPermission(permisions, ['SYS_SUPERUSER']),
                allowClear: true,
              }}
            />
          </FormWrapper.Single>
        </FormWrapper>
        <Space>
          {hasPermission(permisions, ['SYS_SUPERUSER']) && (
            <SaveButton disabled={value.deleted} />
          )}
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </>
  );
};

export default EditValidatorForm;
