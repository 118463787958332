import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Space, Table } from 'antd';
import moment from 'moment';

import useDatasource, { filterDate } from '../../hooks/useDatasource';
import Column from '../../helpers/columns';

import PageTitle from '../PageTitle';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';
import { getAll, downloadAll } from '../../services/telelink/telelink';

const ProductCategory = {
  content: [
    { id: 'STOCABIL' },
    { id: 'OPERATOR' },
    { id: 'TICKET' },
    { id: 'PASS' },
    { id: 'PURSE' },
    { id: 'INTEGRATION' },
    { id: 'GROUP' },
    { id: 'OFFER' },
  ],
  loading: false,
  error: false,
};

const PaymentMethod = {
  content: [{ id: 'CASH' }, { id: 'CARD' }],
  loading: false,
  error: false,
};

const SaleChanel = {
  content: [
    { id: 'TVM_EXTERN' },
    { id: 'TVM_EXTERN2' },
    { id: 'TVM_EXTERN3' },
    { id: 'TVM_EXTERN4' },
    { id: 'TVM_EXTERN5' },
    { id: 'TVM_EXTERN6' },
    { id: 'TVM_EXTERN7' },
    { id: 'TVM_EXTERN8' },
    { id: 'TVM_EXTERN9' },
  ],
  loading: false,
  error: false,
};

const Status = {
  content: [
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
    { id: '6' },
    { id: '7' },
  ],
  loading: false,
  error: false,
};

const ActivationType = {
  content: [{ id: '0' }, { id: '1' }, { id: '2' }],
  loading: false,
  error: false,
};

const OrderType = {
  content: [{ id: 'NEW' }, { id: 'EXCHANGE' }, { id: 'EXISTENT' }],
  loading: false,
};
const SaleReportTvmExternGrid = () => {
  const { t } = useTranslation();

  const { pagination, sort, criterias, content, handleChange } =
    useDatasource(getAll);

  useEffect(() => {
    if (content?.[0]) {
      localStorage.setItem('julianStartDate', content[0].julianStartDate);
    }
  }, [content]);

  const columns = useMemo(
    () => [
      Column.text('tvmId', t('components.salesReport.tvmId'), {
        filter: true,
      }),
      Column.text('tvmName', t('components.salesReport.tvmName'), {
        filter: true,
      }),
      Column.dictionary(
        'saleChanel',
        t('components.salesReport.saleChanel._'),
        SaleChanel,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.saleChanel.${entry.id}`),
        },
      ),
      Column.text('orderId', t('components.salesReport.orderId'), {
        filter: true,
      }),
      Column.text('origProductId', t('components.salesReport.origProductId'), {
        filter: true,
      }),
      Column.dictionary(
        'origProductCategory',
        t('components.salesReport.category._'),
        ProductCategory,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.category.${entry.id}`),
        },
      ),
      Column.dictionary(
        'paymentMethod',
        t('components.salesReport.paymentType._'),
        PaymentMethod,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.paymentType.${entry.id}`),
        },
      ),
      Column.text(
        'origProductName',
        t('components.salesReport.origProductName'),
        { filter: true },
      ),
      Column.text('cardLogicalId', t('components.salesReport.cardLogicalId'), {
        filter: true,
      }),
      {
        title: t('components.salesReport.price'),
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (price) => <>{(price * 0.01).toFixed(2)}</>,
        sorter: true,
      },
      Column.text('qty', t('components.salesReport.qty'), { filter: true }),
      Column.date('updatedAt', t('components.salesReport.updatedAt'), {
        filter: true,
      }),
      Column.text('userFullname', t('components.salesReport.userFullname'), {
        filter: true,
      }),
      Column.text(
        'userIdentityCard',
        t('components.salesReport.userIdentityCard'),
        { filter: true },
      ),
      Column.dictionary(
        'status',
        t('components.salesReport.status.title'),
        Status,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) => t(`components.salesReport.status.${entry.id}`),
        },
      ),
      Column.dictionary(
        'orderType',
        t('components.salesReport.orderType._'),
        OrderType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.orderType.${entry.id}`),
        },
      ),
      Column.dictionary(
        'dateStatusA',
        t('components.salesReport.dateStatus.A'),
        ActivationType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.dateStatus.${entry.id}`),
        },
      ),
      {
        title: t('components.salesReport.valStaDateA'),
        key: 'valStartDateA',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valStaDateA') }),
        render: ({ dateStatusA, valStartDateA, julianStartDate }) => {
          if (dateStatusA !== 0) {
            return null;
          }
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + valStartDateA - 1);
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                valStartDateA ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      {
        title: t('components.salesReport.valEndDateA'),
        key: 'valEndDateA',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valEndDateA') }),
        render: ({ dateStatusA, valEndDateA, julianStartDate }) => {
          if (dateStatusA !== 0) {
            return null;
          }
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + valEndDateA - 1);
          // eslint-disable-next-line react/destructuring-assignment
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                valEndDateA ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      Column.dictionary(
        'dateStatusB',
        t('components.salesReport.dateStatus.B'),
        ActivationType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.dateStatus.${entry.id}`),
        },
      ),
      {
        title: t('components.salesReport.valStaDateB'),
        key: 'valStartDateB',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valStaDateB') }),
        render: ({ dateStatusB, valStartDateB, julianStartDate }) => {
          if (dateStatusB !== 0) {
            return null;
          }
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + valStartDateB - 1);
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                valStartDateB ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      {
        title: t('components.salesReport.valEndDateB'),
        key: 'valEndDateB',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valEndDateB') }),
        render: ({ dateStatusB, valEndDateB, julianStartDate }) => {
          if (dateStatusB !== 0) {
            return null;
          }
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + valEndDateB - 1);
          // eslint-disable-next-line react/destructuring-assignment
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                valEndDateB ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
    ],
    [t],
  );

  const handleChangeHandler = useCallback(
    (first, filters, ...args) => {
      const julianDate = new Date(
        new Date(localStorage.getItem('julianStartDate')).toLocaleDateString(
          'en-US',
          {
            timeZone: 'Europe/Bucharest',
          },
        ),
      );

      const newValues = [
        'valStartDateA',
        'valEndDateA',
        'valStartDateB',
        'valEndDateB',
      ]
        .filter((field) => filters[field])
        .reduce((obj, field) => {
          return {
            ...obj,
            [`dateStatus${field.slice(-1)}`]: [0],
            [field]: [
              {
                from: Math.max(
                  -32768,
                  Math.ceil(
                    (new Date(filters[field][0]?.from) -
                      julianDate -
                      (new Date(filters[field][0]?.from).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
                to: Math.min(
                  32767,
                  Math.ceil(
                    (new Date(filters[field][0]?.to) -
                      julianDate -
                      (new Date(filters[field][0]?.to).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
              },
            ],
          };
        }, {});
      return handleChange(first, { ...filters, ...newValues }, ...args);
    },
    [handleChange],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="tvmId"
        columns={columns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChangeHandler}
        scroll={{ x: 700, y: 700 }}
        size="small"
      />
    </>
  );
};

export default SaleReportTvmExternGrid;
