import React from 'react';

import { Default } from '../../../../../layouts';

import { useEditPage } from '../../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../../services/taxonomies/transport/depos';
import EditTransportDepoForm from '../../../../../components/taxonomies/transports/EditTransportDepoForm';

const initial = {};

const EditTransportDepo = () => {
  const [depo, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/transport/depos',

    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {depo !== undefined && (
        <EditTransportDepoForm
          depo={depo}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditTransportDepo;
