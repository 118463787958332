import React from 'react';
import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ShowColumns } from '../user-types/ShowColumns';

const UserTypeFields = ({ showColumns, subdivisions }) => {
  const { t } = useTranslation();

  return (
    <>
      {(showColumns || []).map((it) => {
        switch (it) {
          case ShowColumns.SUB_INSTITUTION_ID:
            return (
              <Form.Item
                name="subdivisionId"
                label={t('entity.admin.user.subdivisionIdNew')}
              >
                {/* <Select onChange={handleChangeSubdiv}> */}
                <Select
                  /* onChange={handleChangeSubdiv} */
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {subdivisions.map((s) => (
                    <Select.Option
                      key={s.id}
                      value={s.id}
                      disabled={s.disabled || s.deleted}
                    >
                      {s.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          default:
          case ShowColumns.META_DATA_1:
            return (
              <Form.Item
                name="metaData1"
                label={t('entity.admin.user.metaData1New')}
              >
                <Input />
              </Form.Item>
            );
        }
      })}
    </>
  );
};
export default UserTypeFields;
