import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Table, Space } from 'antd';
import { Default } from '../../layouts';
import useDatasource from '../../hooks/useDatasource';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll, remove } from '../../services/orders';
import { downloadAll } from '../../services/ordersV2';
import PageTitle from '../../components/PageTitle';
import AddEntityButton from '../../components/buttons/AddEntityButton';
import EditItemIcon from '../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../components/buttons/DeleteItemIcon';
import Column from '../../helpers/columns';
import { getAccounts } from '../../services/accounts/accounts';
import { getPosesByRoleId } from '../../services/taxonomies/poses';
import AuthContext, { hasPermission } from '../../components/auth';
import { BADGE_COLORS, ORDER_STATUS, OrderTypes } from './constants';
import ExportEntitiesButton from '../../components/buttons/ExportEntitiesButton';

const dictionaries = {
  accounts: (data) =>
    getAccounts({
      ...data,
      pageSize: 1000,
      criterias: { ...data.criterias, customerType: 'LEGAL', userName: '' },
    }),
  poses: getPosesByRoleId,
};

const OrdersList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const [{ accounts, poses }] = useDictionaries(dictionaries);

  const { pagination, content, sort, criterias, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.orders.id'), {
        width: 80,
        filter: true,
      }),
      {
        title: t('entity.orders.bulkTypes'),
        dataIndex: 'bulkType',
        key: 'bulkType',
        sorter: true,
        width: 150,
        render: (value) => t(`entity.orders.bulkType.${value}`),
      },
      Column.dictionary('userId', t('entity.orders.company'), accounts, {
        width: 200,
        filter: true,
        dictLabel: 'userName',
      }),
      Column.dictionary(
        'userId',
        t('entity.orders.userIdentityCard'),
        accounts,
        {
          width: 100,
          filter: true,
          dictLabel: 'userIdentifier',
        },
      ),
      Column.date('createdAt', t('entity.orders.createdAt'), {
        filter: true,
        width: 180,
      }),
      Column.text('createdBy', t('entity.orders.createdBy'), {
        width: 150,
        filter: true,
      }),
      Column.text('modPlata', t('entity.orders.modPlata'), {
        width: 100,
        filter: true,
        sort: false,
      }),
      Column.text('cancelReason', t('entity.orders.cancelReason'), {
        width: 150,
      }),
      Column.dictionary('posId', t('entity.orders.shortPosId'), poses, {
        width: 100,
        filter: true,
        dictLabel: 'name',
      }),
      Column.dictionary('status', t('entity.orders.status.title'), OrderTypes, {
        filter: true,
        width: 150,
        dictLabel: (entry) => {
          const { id } = entry;
          return (
            <Badge
              color={BADGE_COLORS[id] || '#fff'}
              text={t(`entity.orders.status.${id}`)}
            />
          );
        },
      }),
      {
        title: t('entity.orders.orderValue'),
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        width: 130,
        render: (value) => value / 100,
      },
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <EditItemIcon path={`/orders/${record.id}`} />
          {![ORDER_STATUS.CONFIRMED, ORDER_STATUS.DELETED].includes(
            record.status,
          ) && (
            <DeleteItemIcon
              title={t('entity.orders._delete', record)}
              message={t('entity.orders._deleted', record)}
              item={record}
              action={remove}
              reload={reload}
            />
          )}
        </span>
      )),
    ],
    [t, reload, accounts, poses],
  );

  return (
    <Default>
      <PageTitle title={t('entity.orders._plural')}>
        {hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_CREATE']) && (
          <AddEntityButton
            entity={t('entity.orders._singular')}
            path="/orders/new"
          />
        )}
      </PageTitle>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default OrdersList;
