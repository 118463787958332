import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, notification, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const DeleteItemIcon = ({
  title,
  message,
  item,
  action,
  reload,
  disabled = false,
  size = 'middle',
}) => {
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    action(item)
      .then(() => message && notification.success({ message }))
      .catch((msg) => {
        console.error(msg);
        const { inner } = msg || {};
        const { _: messageCode } = inner || {};

        notification.error({
          message: messageCode ? t(messageCode) : t('actions.deleteFailed'),
        });
      })
      .finally(() => reload());
  }, [action, item, message, reload, t]);

  return (
    <Popconfirm
      placement="topLeft"
      title={title}
      disabled={disabled}
      onConfirm={handleDelete}
      okText={t('actions.delete')}
      cancelText={t('actions.cancel')}
    >
      <Tooltip title={t('actions.delete')}>
        <Button
          shape="circle"
          type="link"
          icon={<DeleteOutlined />}
          disabled={disabled}
          size={size}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteItemIcon;
