import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import { FormContext } from '../../hooks/useForm';
import { getRetailers } from '../../services/taxonomies/retailers';
import Column from '../../helpers/columns';
import FormWrapper from '../FormWrapper';

const PosRetailersList = () => {
  const form = useContext(FormContext);

  const { t } = useTranslation();

  const { value, setValue, errorMessages } = form;

  const { retailer } = value;

  const columns = useMemo(
    () => [
      Column.text('code', t('entity.taxonomy.retailer.code'), { filter: true }),
      Column.text('name', t('entity.taxonomy.retailer.name'), { filter: true }),
      Column.text('address', t('entity.taxonomy.retailer.address')),
      Column.bool('deleted', t('entity.taxonomy.retailer.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.retailer.deleted.true'),
          t('entity.taxonomy.retailer.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys) => setValue('retailer', ...selectedRowKeys),
      selectedRowKeys: [retailer],
      getCheckboxProps: (row) => ({
        disabled: row.deleted,
      }),
    }),
    [retailer, setValue],
  );

  const { pagination, content, handleChange } = useDatasource(getRetailers);

  return (
    <>
      <FormWrapper.ErrorMessage message={t(errorMessages?.retailer)} />
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default PosRetailersList;
