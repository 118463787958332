import React from 'react';

import { DatePicker } from 'antd';

import 'moment/locale/ro';
import locale from 'antd/es/date-picker/locale/ro_RO';

const RangePickerRomanian = (props) => (
  <DatePicker.RangePicker locale={locale} {...props} />
);
export default RangePickerRomanian;
