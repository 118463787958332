export const csvToJson = (text) => {
  const [keys, ...values] = text.replaceAll('"', '').split(/[\r\n]/);
  const fields = keys.split(/[,;]/);
  return values
    .map((val) => val.split(/[,;]/))
    .filter((row) => row.length === fields.length)
    .map((row) => {
      const obj = {};
      row.forEach((value, idx) => {
        obj[fields[idx]] = value.trim();
      });
      return obj;
    });
};
