import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import useDatasource from '../../hooks/useDatasource';
import { FormContext } from '../../hooks/useForm';
import Column from '../../helpers/columns';
import { getStations } from '../../services/taxonomies/stations';
import FormWrapper from '../FormWrapper';

const StationsList = () => {
  const form = useContext(FormContext);
  const { t } = useTranslation();

  const { value, setValue, errorMessages } = form;

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.station.name'), {
        filter: true,
      }),
      Column.bool('deleted', t('entity.taxonomy.station.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.station.deleted.true'),
          t('entity.taxonomy.station.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      onSelect: (record, selected) =>
        selected
          ? setValue('stations', [...value.stations, record.id])
          : setValue(
              'stations',
              value.stations.filter((id) => id !== record.id),
            ),
      selectedRowKeys: value.stations || [],
      getCheckboxProps: ({ deleted, id }) => ({
        disabled:
          deleted &&
          (value.stations?.length > 0
            ? value.stations?.filter((ar) => ar === id).length === 0
            : true),
      }),
    }),
    [setValue, value.stations],
  );

  const { pagination, content, handleChange } = useDatasource(getStations);

  return (
    <>
      <FormWrapper.ErrorMessage message={errorMessages?.stations} />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default StationsList;
