import React, { useEffect, useMemo } from 'react';
import { Select } from 'antd';
import FormWrapper from '../FormWrapper';

const SearchSubdivisions = ({ label, props }) => {
  const {
    invalid: errorMessage,
    getValue,
    name,
    disabled,
    field,
    searchField,
    setValue,
    labelProp = 'name',
    fetchContent,
    fetchedContent,
    handleChange,
    reload,
  } = props;

  const content = useMemo(() => fetchedContent, [fetchedContent]);

  useEffect(() => {
    reload();
  }, [fetchContent, reload]);

  return (
    <FormWrapper.FieldWrapper label={label} errorMessage={errorMessage}>
      <Select
        allowClear
        showSearch
        disabled={disabled}
        value={getValue(name)}
        style={{ width: '100%' }}
        onChange={(val) => setValue(name, val)}
        onSearch={(filter) =>
          handleChange(null, { [searchField]: [filter] }, {})
        }
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }
      >
        {content.map((el) => (
          <Select.Option value={el.id} key={el[field]}>
            {typeof labelProp === 'string' ? el[labelProp] : labelProp(el)}
          </Select.Option>
        ))}
      </Select>
    </FormWrapper.FieldWrapper>
  );
};

export default SearchSubdivisions;
