import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditTVARateForm from '../../../../components/tva-rates/EditTVARatesForm';

import {
  createTVARate,
  getTVARateById,
  updateTVARate,
} from '../../../../services/taxonomies/tva-rate';

const initialTVARate = { deleted: false };

const EditTVARate = () => {
  const [tvaRate, handleSubmit, handleCancel] = useEditPage({
    initial: initialTVARate,
    existent: getTVARateById,
    goBackPath: '/admin/taxonomies/tva-rates',
    onCreate: createTVARate,
    onUpdate: updateTVARate,
  });

  return (
    <Default>
      {tvaRate !== undefined && (
        <EditTVARateForm
          tvaRate={tvaRate}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditTVARate;
