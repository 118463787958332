// Entities
export const Warehouse = Symbol('WAREHOUSE');
export const Enrollment = Symbol('ENROLLMENT');
export const Retail = Symbol('RETAIL');
export const POS = Symbol('POS');
export const CurrentRetail = Symbol('CURRENT_RETAIL');

// Account
export const Enrolled = Symbol('CURRENT_ENTITY');
export const NonEnrolled = Symbol('CURRENT_ENTITY');
export const Scrap = Symbol('CURRENT_ENTITY');

// Invoice types
export const InvoiceTypes = {
  // Entry
  entry: {
    destination: Warehouse,
    columns: [
      'position',
      'article',
      'quantity',
      'unitPrice',
      'totalWithoutTva',
      'tva',
      'totalWithTva',
      '__actions',
    ],
    account: NonEnrolled,
  },

  // Enrollment
  enrollment: {
    origin: Warehouse,
    destination: Enrollment,
    columns: [
      'position',
      'article',
      'quantity',
      'confirmedQuantity',
      '__actions',
    ],
    // account: NonEnrolled,
    pin: false,
  },

  enroll: {
    origin: Enrollment,
    destination: Enrollment,
    columns: [
      'position',
      'article',
      'askedQuantity',
      'confirmedQuantity',
      '__actions',
    ],
    account: NonEnrolled,
    pin: false,
  },

  enroll_file: {
    origin: Enrollment,
    destination: Enrollment,
    columns: ['position', 'article', 'quantity', '__actions'],
    account: Enrolled,
    pin: false,
  },

  enrolled: {
    origin: Enrollment,
    destination: Warehouse,
    columns: [
      'position',
      'article',
      'quantity',
      'confirmedQuantity',
      '__actions',
    ],
    account: Enrolled,
    pin: false,
  },

  // Stock management
  transfer_retail: {
    origin: Warehouse,
    destination: Retail,
    columns: [
      'position',
      'article',
      'quantity',
      'confirmedQuantity',
      '__actions',
    ],
    account: Enrolled,
    pin: false,
  },

  transfer_pos: {
    origin: CurrentRetail,
    destination: POS,
    columns: ['position', 'article', 'quantity', '__actions'],
    account: Enrolled,
  },

  return_pos: {
    origin: POS,
    destination: CurrentRetail,
    columns: ['position', 'article', 'quantity', '__actions'],
    account: Enrolled,
  },

  return_retail: {
    origin: CurrentRetail,
    destination: Warehouse,
    columns: [
      'position',
      'article',
      'quantity',
      'confirmedQuantity',
      '__actions',
    ],
    account: Enrolled,
    pin: false,
  },

  // Scrap management
  scrap_retail: {
    origin: CurrentRetail,
    destination: CurrentRetail,
    columns: ['position', 'article', 'quantity', '__actions'],
  },

  scrap_enroll: {
    origin: Enrollment,
    destination: Enrollment,
    columns: ['position', 'article', 'enrolled', 'quantity', '__actions'],
  },

  scrap_warehouse: {
    origin: Warehouse,
    destination: Warehouse,
    columns: ['position', 'article', 'enrolled', 'quantity', '__actions'],
  },

  return_scrap_enroll: {
    origin: Enrollment,
    destination: Warehouse,
    columns: ['position', 'article', 'quantity', '__actions'],
    account: Scrap,
  },

  return_scrap_pos: {
    origin: POS,
    destination: CurrentRetail,
    columns: ['position', 'article', 'quantity', '__actions'],
    account: Scrap,
  },

  return_scrap_retail: {
    origin: CurrentRetail,
    destination: Warehouse,
    columns: [
      'position',
      'article',
      'quantity',
      'confirmedQuantity',
      '__actions',
    ],
    account: Scrap,
    pin: false,
  },

  // Discard

  discard: {
    origin: Warehouse,
    columns: [
      'position',
      'article',
      'enrolled',
      'quantity',
      'serialLetter',
      'serialNumberStart',
      'serialNumberEnd',
      '__actions',
    ],
    account: Scrap,
  },
};

export const symbolToString = (sym) =>
  sym
    .toString()
    .replace(/^(Symbol\()([A-Za-z_]+)(\))$/, '$2')
    .toLowerCase();
