import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import { PinCodeForm } from './usePin';

const usePinModal = (onFinish, length = 4) => {
  const { t } = useTranslation();

  return useCallback(() => {
    const formId = Math.random();
    const modal = Modal.confirm({
      title: t('actions.enterPinCode'),
      icon: <LockOutlined />,
      width: `${38 + 72 * length + 40}px`,
      disabled: true,
      content: <></>,
      onOk: (close) => {
        const { pin, identifier } = modal.value;
        if (pin.length !== length) {
          return notification.error(t('errors.incorrectPin'));
        }
        onFinish({ physicalId: identifier, pin });
        return close();
      },
    });

    modal.update((prevConfig) => ({
      ...prevConfig,
      content: <PinCodeForm id={formId} length={length} modal={modal} />,
    }));
  }, [length, onFinish, t]);
};

export default usePinModal;
