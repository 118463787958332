import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditRouteForm from '../../../../components/routes/EditRouteForm';

import {
  createRoute,
  getRouteById,
  updateRoute,
} from '../../../../services/taxonomies/routes';

const initialRoute = { deleted: false, stations: [] };

const EditRoute = () => {
  const [route, handleSubmit, handleCancel] = useEditPage({
    initial: initialRoute,
    existent: getRouteById,
    goBackPath: '/admin/taxonomies/routes',
    onCreate: createRoute,
    onUpdate: updateRoute,
  });

  return (
    <Default>
      {route !== undefined && (
        <EditRouteForm
          route={route}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditRoute;
