import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { Button, Space, Table, Tooltip } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import Column from '../../helpers/columns';
import { getDocTypes } from '../../services/taxonomies/doc-types';
import useDictionaries from '../../hooks/useDictionaries';
import { getUserTypes } from '../../services/taxonomies/user-types';
import { FormContext } from '../../hooks/useForm';
import DeleteItemIcon from '../buttons/DeleteItemIcon';
import FormWrapper from '../FormWrapper';
import { getProducts } from '../../services/offers/products';
import { downloadFiles } from '../../services/files';
import SearchSubdivisions from './SearchSubdivisions';
import ProductModalDocUpload from './customFileUploader/ProductModalDocUpload';
import {
  BULK_TYPES,
  ORDER_STATUS,
  UserTypesEnum,
} from '../../pages/orders/constants';
import { hasPermission } from '../auth';
import { getSubdivisions } from '../../services/taxonomies/subdivisions';
import useDatasource from '../../hooks/useDatasource';

const dictionaries = {
  docTypesTaxonomy: getDocTypes,
  userTypes: getUserTypes,
  productTypes: getProducts,
};

const ProductUsersList = ({
  index,
  disabled,
  fields,
  institutionId,
  status,
  bulkType,
  permisions,
}) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);
  const [documentTypes, setDocTypes] = useState([]);
  const [userTypeCode, setUserTypeCode] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [{ docTypesTaxonomy, userTypes, productTypes }] =
    useDictionaries(dictionaries);

  const { input, value, setValue, custom, checkbox } = form;
  const { products = [], id: orderId } = value;
  const dynamicPriceString = window._env_.DYNAMIC_PRICE;
  const dynamicPrice = dynamicPriceString === 'true' && orderId;
  const { productPersons = [], productId } = products[index];

  const onRemove = useCallback(
    async (record, idx) => {
      const newProducts = products.map((product) => ({
        ...product,
        productPersons: product.productPersons.filter((pp) =>
          record.key
            ? pp.person.key !== record.key
            : pp.person.id !== record.id,
        ),
      }));
      setValue('products', newProducts);

      if (form?.errorMessages) {
        const pattern = `products[${index}].productPersons[${idx}]`;
        Object.keys(form.errorMessages).forEach((key) => {
          if (key.startsWith(pattern)) {
            delete form.errorMessages[key];
          }
        });
      }
    },
    [products, setValue, index, form],
  );

  const fetchSubdivisions = useMemo(() => {
    return (data) =>
      institutionId
        ? getSubdivisions({
            ...data,
            pageSize: 1000,
            criterias: {
              ...data.criterias,
              institutionId,
            },
          })
        : Promise.resolve({
            content: [],
            loading: false,
            last: true,
            pageNumber: 1,
            pageSize: 10,
            totalElements: 0,
          });
  }, [institutionId]);

  const {
    content: fetchedSubdivisions,
    handleChange: handleChangeSubdivisions,
    reload: reloadSubdivisions,
  } = useDatasource(fetchSubdivisions);

  useEffect(() => {
    const { userTypeId } =
      // eslint-disable-next-line eqeqeq
      productTypes.content.find((pt) => pt.id == productId) || {};
    // eslint-disable-next-line eqeqeq
    const type = userTypes.content.find((c) => c.id == userTypeId);
    setUserTypeCode(type?.code);
    const docs = type?.docTypes || [];

    const res = docTypesTaxonomy.content.filter((dt) => docs.includes(dt.id));
    setDocTypes(res.sort((a, b) => a.id - b.id));
  }, [docTypesTaxonomy.content, productId, productTypes, userTypes.content]);

  const getDocColumns = useCallback(() => {
    return documentTypes.map((dt, docIndex) =>
      Column.actions(dt.name, (record, rowIndex) => {
        const { hasExpirationDate } = dt;
        const currentDoc = record?.person?.documents?.find(
          (savedDoc) => savedDoc && savedDoc.documentTypeId === dt.id,
        );
        return (
          <>
            {hasExpirationDate === true ? (
              <ProductModalDocUpload
                props={{
                  dt,
                  orderId,
                  disabled:
                    disabled &&
                    !(
                      ORDER_STATUS.DOC_VALIDATION === status &&
                      hasPermission(permisions, [
                        'SYS_SUPERUSER',
                        'ORDERS_REVIEW',
                      ])
                    ),
                  currentDoc,
                  customerType: value.customerType,
                  status,
                  ...custom(
                    `products[${index}].productPersons[${rowIndex}].person.documents[${docIndex}]`,
                  ),
                  setValue: (name, file, expDate, isFromPortal) => {
                    if (isFromPortal) {
                      currentDoc.expirationDate = expDate;
                    } else {
                      setValue(name, {
                        fileId: file[0].id,
                        documentTypeId: dt.id,
                        expirationDate: expDate,
                      });
                    }
                  },
                  setFormValue: setValue,
                  getValue: () =>
                    form.value.docMap !== undefined ? form.value.docMap : [],
                }}
              />
            ) : (
              <FormWrapper.FileUploader
                props={{
                  disabled,
                  multiple: false,
                  ...custom(
                    `products[${index}].productPersons[${rowIndex}].person.documents[${docIndex}]`,
                  ),
                  setValue: (name, uploadedFiles) => {
                    setValue(name, {
                      fileId: uploadedFiles[0].id,
                      documentTypeId: dt.id,
                    });
                  },
                  setFieldValue: setValue,
                  getValue: () =>
                    form.value.docMap !== undefined ? form.value.docMap : [],
                }}
              />
            )}
            {currentDoc && (
              <Space style={{ paddingLeft: '1rem' }}>
                <Tooltip
                  title={
                    t('entity.orders.expirationDate') +
                    moment(currentDoc.expirationDate).format('YYYY-MM-DD HH:mm')
                  }
                >
                  <Button
                    shape="circle"
                    type="link"
                    icon={<FileTextOutlined />}
                    style={{ color: '#24ad09' }}
                    onClick={() => {
                      if (record?.id) {
                        downloadFiles(currentDoc.fileId, orderId);
                      } else if (form.value.docMap !== undefined) {
                        const url = window.URL.createObjectURL(
                          form.value.docMap[currentDoc.fileId],
                        );
                        const a = document.createElement('a');
                        a.href = url;
                        a.target = '_blank';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                      }
                    }}
                  />
                </Tooltip>
              </Space>
            )}
          </>
        );
      }),
    );
  }, [
    custom,
    disabled,
    documentTypes,
    index,
    setValue,
    value,
    t,
    orderId,
    status,
    permisions,
    form,
  ]);

  const columns = useMemo(
    () => [
      {
        title: t('entity.orders.index'),
        width: 50,
        sorter: false,
        render: (_, __, idx) => <div>{idx + 1}</div>,
      },
      ...fields
        .filter((it) => it !== 'subdivisionId' && it !== 'metaData1')
        .map((name) =>
          name !== 'foreignCitizen'
            ? {
                title: t(`entity.admin.user.${name}`),
                dataIndex: name,
                key: name,
                sorter: true,
                width: 150,
                render: (_, record, idx) => (
                  <FormWrapper.Input
                    props={{
                      ...input(
                        `products[${index}].productPersons[${idx}].person.${name}`,
                      ),
                      disabled,
                      ...(name === 'fullname'
                        ? {
                            onChange: (e) => {
                              setValue(
                                `products[${index}].productPersons[${idx}].person.fullname`,
                                e.target.value.toUpperCase(),
                              );
                            },
                          }
                        : {}),
                      ...(name === 'cardLogicalId'
                        ? {
                            onChange: (e) => {
                              const id = e.target.value;
                              if (/^\d*$/.test(id)) {
                                setValue(
                                  `products[${index}].productPersons[${idx}].person.cardLogicalId`,
                                  id,
                                );
                              }
                            },
                          }
                        : {}),
                    }}
                  />
                ),
              }
            : {
                title: t('entity.admin.user.foreignCitizen'),
                dataIndex: 'foreignCitizen',
                key: 'foreignCitizen',
                sorter: true,
                width: 150,
                render: (_, record, idx) => (
                  <FormWrapper.Switch
                    props={{
                      disabled,
                      searchField: 'name',
                      field: 'id',
                      institutionId,
                      ...checkbox(
                        `products[${index}].productPersons[${idx}].person.foreignCitizen`,
                      ),
                    }}
                  />
                ),
              },
        ),
      ...(fields.includes('subdivisionId')
        ? [
            {
              title: t('entity.admin.user.subdivisionId'),
              dataIndex: 'subdivisionId',
              key: 'subdivisionId',
              sorter: true,
              width: 150,
              render: (_, record, idx) => (
                <SearchSubdivisions
                  props={{
                    fetchContent: fetchSubdivisions,
                    fetchedContent: fetchedSubdivisions,
                    handleChange: handleChangeSubdivisions,
                    reload: reloadSubdivisions,
                    disabled,
                    searchField: 'name',
                    field: 'id',
                    institutionId,
                    ...custom(
                      `products[${index}].productPersons[${idx}].person.subdivisionId`,
                    ),
                  }}
                />
              ),
            },
          ]
        : []),
      ...(fields.includes('metaData1')
        ? [
            {
              title:
                UserTypesEnum.PENSIONAR === userTypeCode
                  ? t('entity.admin.user.metaData1Pensioner')
                  : t('entity.admin.user.metaData1New'),
              dataIndex: 'metaData1',
              key: 'metaData1',
              sorter: true,
              width: 150,
              render: (_, record, idx) => (
                <FormWrapper.Input
                  props={{
                    ...input(
                      `products[${index}].productPersons[${idx}].person.metaData1`,
                    ),
                    disabled,
                  }}
                />
              ),
            },
          ]
        : []),
      ...(dynamicPrice && bulkType === BULK_TYPES.ISSUING_CARDS
        ? [
            {
              title: t('entity.admin.user.priceProduct'),
              dataIndex: 'priceProduct',
              key: 'priceProduct',
              width: 150,
              render: (_, record, idx) => (
                <FormWrapper.InputTransformed
                  props={{
                    ...input(`products[${index}].productPersons[${idx}].price`),
                    disabled: true,
                  }}
                  transformValue={(val) => val / 100}
                  transformOnChange={(val) => val * 100}
                />
              ),
            },
            {
              title: t('entity.admin.user.priceCard'),
              dataIndex: 'priceCard',
              key: 'priceCard',
              width: 150,
              render: (_, record, idx) => (
                <FormWrapper.InputTransformed
                  props={{
                    ...input(
                      `products[${index + 1}].productPersons[${idx}].price`,
                    ),
                    disabled: true,
                  }}
                  transformValue={(val) => val / 100}
                  transformOnChange={(val) => val * 100}
                />
              ),
            },
          ]
        : []),
      ...getDocColumns(),
      Column.actions(t('table.actions'), (record, idx) => (
        <DeleteItemIcon
          title={t('entity.admin.user._delete', record.person)}
          message={t('entity.admin.user._deleted', record.person)}
          item={record.person}
          reload={() => null}
          action={() => onRemove(record.person, idx)}
          disabled={disabled}
        />
      )),
    ],
    [
      t,
      fields,
      userTypeCode,
      dynamicPrice,
      bulkType,
      getDocColumns,
      input,
      index,
      disabled,
      setValue,
      institutionId,
      checkbox,
      fetchSubdivisions,
      fetchedSubdivisions,
      handleChangeSubdivisions,
      reloadSubdivisions,
      custom,
      onRemove,
    ],
  );

  return (
    <Table
      columns={columns}
      rowKey={(record) => record.key}
      pagination={false}
      dataSource={productPersons}
    />
  );
};

export default ProductUsersList;
