import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Badge, Space, Tabs } from 'antd';

import useForm, { FormContext } from '../../../hooks/useForm';
import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';
import ProductsGrid from '../ProductsGrid';
import ProductGroupsGrid from '../ProductGroupsGrid';
import { getProductGroups } from '../../../services/offers/product-groups';
import useDictionaries from '../../../hooks/useDictionaries';
import { PosTypes } from '../../poses/constants';

const dictionaries = {
  productGroupsDictionary: getProductGroups,
};

const EditOfferForm = ({ offer, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const [{ productGroupsDictionary }] = useDictionaries(dictionaries);

  const productGroupPriceById = useMemo(
    () =>
      productGroupsDictionary.loading || productGroupsDictionary.error
        ? []
        : productGroupsDictionary.content.reduce(
            (acc, cur) => ({ ...acc, [cur.id]: cur.price }),
            {},
          ),
    [productGroupsDictionary],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        dateStart: yup.string().required(t('errors.notEmpty')),
        code: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );
  const form = useForm({
    initialValue: offer,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const { value, handleSubmit, input, select, custom, setFormValue } = form;

  const { products, productGroups } = value;

  const price = useMemo(
    () =>
      products.reduce(
        (acc, pr) => acc + (pr?.discountPrice || 0) * pr.quantity,
        0,
      ) +
      productGroups.reduce(
        (acc, pgId) => acc + (productGroupPriceById?.[pgId] || 0),
        0,
      ),
    [productGroupPriceById, productGroups, products],
  );

  useEffect(() => {
    setFormValue(offer);
  }, [setFormValue, offer]);

  return (
    <FormContext.Provider value={form}>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.offers.offer.name')}
              props={{ ...input('name') }}
            />
            <FormWrapper.Input
              label={t('entity.offers.offer.code')}
              props={{ ...input('code') }}
            />
            <FormWrapper.RangePicker
              label={`${t('entity.offers.offer.dateStart')} / ${t(
                'entity.offers.offer.dateEnd',
              )}`}
              props={{
                ...custom('dateEnd'),
                start: custom('dateStart'),
                end: custom('dateEnd'),
                allowEmpty: [false, true],
              }}
            />

            <FormWrapper.Number
              label={t('entity.offers.offer.price')}
              props={{
                value: price,
                disabled: true,
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.poses.type._')}
              props={{
                options: PosTypes.content,
                loading: PosTypes.loading,
                mode: 'multiple',
                allowClear: true,
                ...select('posTypes'),
                labelProp: (val) => t(`entity.taxonomy.poses.type.${val.id}`),
              }}
            />
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={
                  <>
                    {t('entity.offers.offer.products')}{' '}
                    <Badge count={products.length} />
                  </>
                }
                key="products"
              >
                <ProductsGrid />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <>
                    {t('entity.offers.offer.productGroups')}{' '}
                    <Badge count={productGroups.length} />
                  </>
                }
                key="productGroups"
              >
                <ProductGroupsGrid />
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
        </FormWrapper>
        <Space>
          <SaveButton />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </FormContext.Provider>
  );
};

export default EditOfferForm;
