import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditStationForm from '../../../../components/stations/EditStationForm';

import {
  createStation,
  getStationById,
  updateStation,
} from '../../../../services/taxonomies/stations';

const initialStation = { deleted: false };

const EditStation = () => {
  const [station, handleSubmit, handleCancel] = useEditPage({
    initial: initialStation,
    existent: getStationById,
    goBackPath: '/admin/taxonomies/stations',
    onCreate: createStation,
    onUpdate: updateStation,
  });

  return (
    <Default>
      {station !== undefined && (
        <EditStationForm
          station={station}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditStation;
