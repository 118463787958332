import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import SaleReportGridPortal from '../../../components/sale-report-portal/SaleReportGridPortal';

const SaleReportPortal = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.salesReportPortal._')} />
      <SaleReportGridPortal />
    </Default>
  );
};

export default SaleReportPortal;
