import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, notification } from 'antd';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

import usePinModal from '../../hooks/usePinModal';
import {
  activateOperatorCard,
  deactivateOperatorCard,
} from '../../services/accounts/accounts';

const TransferOperatorCardButton = ({
  activate,
  userId,
  reload = () => null,
  disabled = false,
  size = 'middle',
}) => {
  const { t } = useTranslation();

  const handleTransfer = useCallback(
    (result) => {
      if (result.physicalId && result.pin && userId) {
        (activate ? activateOperatorCard : deactivateOperatorCard)({
          userId,
          ...result,
        })
          .then(() => {
            notification.success({
              message: t(
                `components.inspectors.list.inspector${
                  activate ? 'A' : 'Dea'
                }ctivated`,
              ),
            });
            reload();
          })
          .catch(({ inner }) => {
            notification.error({
              message: t(
                inner._ ||
                  `components.inspectors.list.error${
                    activate ? 'A' : 'Dea'
                  }ctivatingInspector`,
              ),
            });
            reload();
          });
      }
    },
    [activate, reload, t, userId],
  );

  const openModal = usePinModal(handleTransfer);

  return (
    <Button
      shape="circle"
      type="link"
      icon={activate ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
      disabled={disabled}
      size={size}
      onClick={openModal}
    />
  );
};

export default TransferOperatorCardButton;
