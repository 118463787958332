export const OfferType = Object.freeze({
  PRODUCT: 'PRODUCT',
  PRODUCT_GROUP: 'PRODUCT_GROUP',
  OFFER: 'OFFER',
});

export const OfferTypes = Object.freeze([
  Object.freeze({ id: OfferType.PRODUCT }),
  Object.freeze({ id: OfferType.PRODUCT_GROUP }),
  Object.freeze({ id: OfferType.OFFER }),
]);

export const NominalTypes = {
  content: [{ id: 'ALL' }, { id: 'NONNOMINAL' }, { id: 'NOMINAL' }],
  loading: false,
  error: false,
};

export const ProductCategories = {
  content: [
    { id: 'PASS' },
    { id: 'PURSE' },
    { id: 'TICKET' },
    { id: 'INTEGRATION' },
    { id: 'STOCABIL' },
    { id: 'OPERATOR' },
  ],
  loading: false,
  error: false,
};

export const Calendars = {
  content: [{ id: 'SCHOOL_YEAR_DYNAMIC' }, { id: 'ACADEMIC_YEAR_DYNAMIC' }],
  loading: false,
  error: false,
};
