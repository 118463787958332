import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';
import { Default } from '../../layouts';

import PageTitle from '../../components/PageTitle';
import TVMStatuses from './TVMStatuses';
import BurglariesHistory from './BurglariesHistory';
import { getBurglaries } from '../../services/tvm/tvm';
import BurglariesActive from './BurglariesActive';
import './tvm.scss';

const MINUTES_3 = 1000 * 10;

const TVMDashboard = () => {
  const { t } = useTranslation();
  const [blinkTab, setblinkTab] = useState(false);

  const checkActiveBurglaries = useCallback(
    () =>
      getBurglaries({ criterias: { status: ['NEW', 'IN_PROGRESS'] } })
        .then(({ totalElements }) => setblinkTab(totalElements > 0))
        .catch(console.error),
    [],
  );

  useEffect(() => {
    checkActiveBurglaries();
    const triggerId = setInterval(checkActiveBurglaries, MINUTES_3);
    return () => clearTimeout(triggerId);
  }, [checkActiveBurglaries]);

  return (
    <Default>
      <PageTitle title={t('pages.tvmdashboard._')} />

      <Tabs>
        <Tabs.TabPane tab={t('pages.tvmdashboard.tabs.tvms')} key="tvms" active>
          <TVMStatuses />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('pages.tvmdashboard.tabs.burglaries')}
          key="burglaries"
        >
          <BurglariesHistory />
        </Tabs.TabPane>
        {blinkTab && (
          <Tabs.TabPane
            tab={
              <div className="blinked">
                {t('pages.tvmdashboard.tabs.activeBurglaries')}
              </div>
            }
            key="activeBurglaries"
          >
            <BurglariesActive />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Default>
  );
};

export default TVMDashboard;
