import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../layouts';
import useDatasource from '../../../hooks/useDatasource';
import { deleteRole, getRoles } from '../../../services/admin/roles';
import PageTitle from '../../../components/PageTitle';
import AddEntityButton from '../../../components/buttons/AddEntityButton';
import DeleteItemIcon from '../../../components/buttons/DeleteItemIcon';
import EditItemIcon from '../../../components/buttons/EditItemIcon';
import Column from '../../../helpers/columns';

const RolesList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } = useDatasource(getRoles);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.role.name'), {
        filter: true,
        width: 300,
        fixed: 'left',
      }),
      Column.text('description', t('entity.admin.role.description')),
      Column.date('createdAt', t('entity.admin.role.createdAt'), {}),
      Column.text('createdBy', t('entity.admin.role.createdBy'), {}),
      Column.bool('deleted', t('entity.admin.role.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.admin.role.deleted.true'),
          t('entity.admin.role.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <>
          <EditItemIcon path={`/admin/roles/${record.id}`} />
          <DeleteItemIcon
            title={t('entity.admin.role._delete', record)}
            message={t('entity.admin.role._deleted', record)}
            item={record}
            action={deleteRole}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.admin.role._plural')}>
        <AddEntityButton
          entity={t('entity.admin.role._singular')}
          path="/admin/roles/new"
        />
      </PageTitle>

      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default RolesList;
