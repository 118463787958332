import { message } from 'antd';
import { useCallback, useState, useEffect, useRef } from 'react';

const useCard = ({
  onCardScan,
  onCardFound,
  onCardMissing,
  retryTimeout = 5000,
  maxRetries = 5,
} = {}) => {
  const timeoutHandler = useRef(0);

  const [currentCard, setCurrentCard] = useState(0);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);

  const fetchCard = useCallback(() => setCounter((old) => old + 1), []);

  const fetchCardInternal = useCallback(
    (retry) => {
      if (retry === 0 && typeof onCardScan === 'function') {
        onCardScan();
      }

      return (
        fetch(
          `http://${
            localStorage.getItem('devconnIP') || 'localhost'
          }:13385/dev/cardreader`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
          // .catch(() => Promise.resolve(0))
          .then(
            (resp) => (resp.ok ? resp.text() : Promise.resolve(0)),
            () => Promise.resolve(0),
          )
          .then((res) => {
            if (res === 0 && (retry || 0) < maxRetries) {
              timeoutHandler.current = setTimeout(
                () => fetchCardInternal((retry || 0) + 1),
                retryTimeout,
              );
              return 0;
            }

            setLoading(false);
            setCurrentCard(res);

            if (res !== 0 && typeof onCardFound === 'function') {
              onCardFound(res);
            }

            if (res === 0 && typeof onCardMissing === 'function') {
              onCardMissing();
            }

            return res;
          })
      );
    },
    [maxRetries, onCardFound, onCardMissing, onCardScan, retryTimeout],
  );

  useEffect(() => {
    if (counter > 0) {
      setLoading(true);
      setCurrentCard(0);

      if (timeoutHandler.current > 0) {
        clearInterval(timeoutHandler.current);
        timeoutHandler.current = 0;
      }

      fetchCardInternal(0);
    }

    return () => {
      if (timeoutHandler.current > 0) {
        clearInterval(timeoutHandler.current);
        timeoutHandler.current = 0;
      }
    };
  }, [counter, fetchCardInternal]);

  const cancelFetchCard = useCallback(() => message.destroy(), []);

  return [currentCard, loading, fetchCard, cancelFetchCard];
};

export default useCard;
