import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const ExportEntitiesButton = ({ downloadFiles, columns, data }) => {
  const { t } = useTranslation();
  const fields = useMemo(
    () =>
      columns
        .filter(({ key }) => key !== '__actions')
        .map(({ key, title }) => `${key};${title}`),
    [columns],
  );
  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={() =>
        downloadFiles({
          ...data,
          criterias: { ...data.criterias, field: fields },
        })
      }
    >
      {t('actions.download')}
    </Button>
  );
};

export default ExportEntitiesButton;
