import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Space, Tabs, Table, Switch, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import Column from 'antd/lib/table/Column';
import moment from 'moment';
import PageTitle from '../PageTitle';
import FormWrapper from '../FormWrapper';
import CancelButton from '../buttons/CancelButton';
import { getAccounts } from '../../services/accounts/accounts';
import DatePickerLocale from '../DatePickerLocale';

const InstitutionForm = ({ institution, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    form.setFieldsValue(institution);
  }, [form, institution]);

  useEffect(() => {
    getAccounts({
      pageSize: 1000,
      criterias: { customerType: 'LEGAL', userName: '' },
    }).then((res) => setAccounts(res.content));
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <PageTitle
        title={institution.name || t('entity.taxonomy.institutions._singular')}
      />
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <FormWrapper>
          <FormWrapper.Single>
            <Form.Item
              name="name"
              label="Nume"
              rules={[
                {
                  required: true,
                  message: t('errors.notEmpty'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="maintainerId" label="Reprezentant">
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }
              >
                {accounts.map((act) => (
                  <Select.Option key={act.id} value={act.id}>
                    {act.userName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="code"
              label="Cod"
              rules={[
                {
                  required: true,
                  message: t('errors.notEmpty'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={t('entity.taxonomy.subdivisions._plural')}
                key="subdivisions"
              >
                <Form.List name="subdivisions">
                  {(subd, { add }) => {
                    return (
                      <Table
                        dataSource={subd}
                        pagination={false}
                        scroll={{ y: 300 }}
                        footer={() => {
                          return (
                            <Form.Item>
                              <Button onClick={() => add({})}>
                                <PlusOutlined />{' '}
                                {t('entity.taxonomy.subdivisions._add')}
                              </Button>
                            </Form.Item>
                          );
                        }}
                      >
                        <Column
                          title={t('entity.taxonomy.subdivisions.name')}
                          render={(_, __, index) => {
                            return (
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message: t('errors.notEmpty'),
                                  },
                                ]}
                                name={[index, 'name']}
                                shouldUpdate={(prevValues, currentValues) =>
                                  prevValues.name !== currentValues.name
                                }
                              >
                                <Input
                                  placeholder="name"
                                  style={{ width: '100%', marginRight: 8 }}
                                />
                              </Form.Item>
                            );
                          }}
                        />
                        <Column
                          title={t('entity.taxonomy.subdivisions.code')}
                          render={(_, __, index) => {
                            return (
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message: t('errors.notEmpty'),
                                  },
                                ]}
                                name={[index, 'code']}
                                shouldUpdate={(prevValues, currentValues) =>
                                  prevValues.code !== currentValues.code
                                }
                              >
                                <Input
                                  placeholder="code"
                                  style={{ width: '100%', marginRight: 8 }}
                                />
                              </Form.Item>
                            );
                          }}
                        />
                        <Column
                          title={t('entity.taxonomy.subdivisions.createdBy')}
                          render={(_, __, index) => {
                            return (
                              <Form.Item name={[index, 'createdBy']}>
                                <Input
                                  style={{ width: '100%', marginRight: 8 }}
                                  disabled
                                />
                              </Form.Item>
                            );
                          }}
                        />
                        <Column
                          title={t('entity.taxonomy.subdivisions.createdAt')}
                          render={(_, __, index) => {
                            const createdAt = form.getFieldValue([
                              'subdivisions',
                              index,
                              'createdAt',
                            ]);
                            return (
                              createdAt && (
                                <Form.Item>
                                  <DatePickerLocale
                                    disabled
                                    format="DD.MM.YYYY HH:mm"
                                    value={moment(createdAt)}
                                  />
                                </Form.Item>
                              )
                            );
                          }}
                        />
                        <Column
                          title={t('entity.taxonomy.subdivisions.deleted._')}
                          render={(_, __, index) => {
                            return (
                              <Form.Item
                                name={[index, 'deleted']}
                                valuePropName="checked"
                              >
                                <Switch />
                              </Form.Item>
                            );
                          }}
                        />
                      </Table>
                    );
                  }}
                </Form.List>
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                {t('actions.save')}
              </Button>

              <CancelButton onCancel={onCancel} />
            </Space>
          </Form.Item>
        </FormWrapper>
      </Form>
    </>
  );
};
export default InstitutionForm;
