import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import {
  createProductGroup,
  getProductGroupById,
  updateProductGroup,
} from '../../../../services/offers/product-groups';
import EditGroupForm from '../../../../components/offers/groups/EditGroupForm';

const initialProductGroup = { products: [] };

const EditProductGroup = () => {
  const [group, handleSubmit, handleCancel] = useEditPage({
    initial: initialProductGroup,
    existent: getProductGroupById,
    goBackPath: '/admin/offers/groups',

    onCreate: createProductGroup,
    onUpdate: updateProductGroup,
  });

  return (
    <Default>
      {group !== undefined && (
        <EditGroupForm
          group={group}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditProductGroup;
