import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Space, Table } from 'antd';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';

import { getSaleReport, downloadAll } from '../../services/orders';
import { getInstitutions } from '../../services/taxonomies/institutions';
import { getUserTypes } from '../../services/taxonomies/user-types';
import PageTitle from '../PageTitle';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';
import useDictionaries from '../../hooks/useDictionaries';
import { COMMAND_TYPES, BULK_TYPES } from '../../pages/orders/constants';

const dictionaries = {
  userTypes: getUserTypes,
  institutions: getInstitutions,
};

const BulkType = {
  content: Object.values(BULK_TYPES).map((item) => ({ id: item })),
  loading: false,
  error: false,
};
const ActivationType = {
  content: [{ id: '0' }, { id: '1' }, { id: '2' }],
  loading: false,
  error: false,
};
const commandType = {
  content: COMMAND_TYPES,
  loading: false,
  error: false,
};

const SaleReportOrdersGrid = () => {
  const { t } = useTranslation();

  const [{ institutions, userTypes }] = useDictionaries(dictionaries);

  const { pagination, sort, criterias, content, handleChange } =
    useDatasource(getSaleReport);

  useEffect(() => {
    if (content?.[0]) {
      localStorage.setItem('julianStartDate', content[0].julianStartDate);
    }
  }, [content]);

  const columns = useMemo(
    () => [
      Column.text('orderId', t('components.salesReport.orderId'), {
        filter: true,
      }),
      Column.text('origProductId', t('components.salesReport.origProductId'), {
        filter: true,
      }),
      Column.text(
        'origProductName',
        t('components.salesReport.origProductName'),
        { filter: true },
      ),
      Column.text('cardLogicalId', t('components.salesReport.cardLogicalId'), {
        filter: true,
      }),
      Column.text('userFullname', t('components.salesReport.userFullname'), {
        filter: true,
        sort: false,
      }),
      Column.text(
        'userIdentityCard',
        t('components.salesReport.userIdentityCard'),
        {
          filter: true,
          sort: false,
        },
      ),
      Column.text('userId', t('components.salesReport.userId'), {
        filter: true,
      }),
      Column.date(
        'orderCreatedAt',
        t('components.salesReport.orderCreatedAt'),
        {
          filter: true,
        },
      ),
      Column.date('startDate', t('components.salesReport.startDate'), {
        filter: true,
      }),
      Column.text('dateStatusName', t('components.salesReport.statusDate'), {
        filter: true,
        sort: false,
        width: 150,
      }),
      Column.dictionary(
        'dateStatus',
        t('components.salesReport.statusDate'),
        ActivationType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.dateStatus.${entry.id}`),
        },
      ),
      Column.date(
        'startValidPeriod',
        t('components.salesReport.startValidPeriod'),
        {
          sort: false,
        },
      ),
      Column.date(
        'endValidPeriod',
        t('components.salesReport.endValidPeriod'),
        {
          sort: false,
        },
      ),
      Column.text('metaData1', t('components.salesReport.metaData1'), {
        filter: true,
      }),
      Column.text('metaData2', t('components.salesReport.metaData2'), {
        filter: true,
      }),
      Column.dictionary(
        'institution',
        t('entity.admin.user.institutionId'),
        institutions,
        {
          width: 150,
          filter: true,
          dictKey: 'name',
        },
      ),
      Column.text('subdivision', t('entity.admin.user.subdivisionId'), {
        width: 150,
        filter: true,
      }),
      Column.dictionary(
        'userType',
        t('entity.admin.user.userType'),
        userTypes,
        {
          width: 150,
          filter: true,
          dictKey: 'name',
        },
      ),
      Column.text('parentName', t('components.salesReport.parentId'), {
        sort: false,
        filter: true,
      }),
      Column.text(
        'employeeNumber',
        t('components.salesReport.employeeNumber'),
        {
          filter: true,
        },
      ),
      Column.dictionary('bulkType', t('entity.orders.bulkTypes'), BulkType, {
        width: 150,
        filter: true,
        dictLabel: (entry) => t(`entity.orders.bulkType.${entry.id}`),
      }),
      Column.dictionary(
        'commandType',
        t('entity.orders.command'),
        commandType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) => t(`entity.orders.commandType.${entry.id}`),
        },
      ),
      Column.text('productQty', t('components.salesReport.productQty'), {
        filter: true,
      }),
      Column.text(
        'productUnitPrice',
        t('components.salesReport.productUnitPrice'),
        {
          filter: true,
        },
      ),
    ],
    [t, institutions, userTypes],
  );

  const filteredColumnsForTable = useMemo(
    () => columns.filter((column) => column.key !== 'dateStatusName'),
    [columns],
  );

  const filteredColumnsForCSV = useMemo(
    () => columns.filter((column) => column.key !== 'dateStatus'),
    [columns],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={filteredColumnsForCSV}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="productId"
        columns={filteredColumnsForTable}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
        size="small"
      />
    </>
  );
};

export default SaleReportOrdersGrid;
