import { download, genericCRUDService, search } from '../../helpers/api';

export const VALIDATORS_SERVICE_URI = '/validators';
export const VALIDATORS_BASE_URI = '/api/v1/fines';

export const { downloadAll } = genericCRUDService(
  VALIDATORS_SERVICE_URI,
  VALIDATORS_BASE_URI,
);

export const getTransactionsFains = async (data) =>
  search(`${VALIDATORS_SERVICE_URI}${VALIDATORS_BASE_URI}`, data);

export const downloadAllByInspectorId = async (id, spec) =>
  download(`${VALIDATORS_SERVICE_URI}/${VALIDATORS_BASE_URI}`, {
    criterias: { ...spec.criterias, inspectorId: [id] },
    spec,
  });
