import React from 'react';

import { Button, Dropdown, Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const LanguageChooser = () => {
  const { i18n } = useTranslation();

  const setLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('locale', lang);
  };

  const menu = (
    <Menu>
      <Menu.Item key="ro">
        <Button type="text" onClick={() => setLang('ro')}>
          Romana
        </Button>
      </Menu.Item>
      <Menu.Item key="en">
        <Button type="text" onClick={() => setLang('en')}>
          English
        </Button>
      </Menu.Item>
      <Menu.Item key="fr">
        <Button type="text" onClick={() => setLang('fr')}>
          Français
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button type="text">
        <GlobalOutlined />
      </Button>
    </Dropdown>
  );
};

export default LanguageChooser;
