/* eslint-disable no-throw-literal */
/* eslint-disable no-await-in-loop */

import { message, notification } from 'antd';
import {
  ejectCard,
  getEnrolledProduct,
  getNextCardUid,
} from '../services/stocks/cards';
import {
  BASE_URI as FILE_BASE_URI,
  SERVICE_URI as FILE_SERVICE_URI,
} from '../services/files';
import { downloadFile } from './api';
import { printImages } from '../services/pos/pos';

export const showMessage = (type, text, duration = 5) => {
  message[type]({
    content: text,
    key: 'pos',
    duration,
    className: 'card-message',
  });
};

export const print = async (images, text) =>
  printImages(
    `http://${
      localStorage.getItem('devconnIP') || 'localhost'
    }:13385/dev/print`,
    images,
    text,
  )
    .then(() => showMessage('success', 'Cardul a fost printat'))
    .catch(() => {
      showMessage('error', 'Nu mai sunt carduri in imprimanta');
      throw 'Nu mai sunt carduri in imprimanta';
    });

export const downloadImage = (imageId) =>
  downloadFile(`${FILE_SERVICE_URI}${FILE_BASE_URI}/${imageId}?download`)
    .then((res) => res.blob())
    .catch((err) => {
      console.error(err);
      notification.error({
        message: 'Eroare date introduse',
      });
    });

export const getNextEnrolled = async (allPersons, t) => {
  do {
    const physicalId = await getNextCardUid();
    if (!physicalId) {
      showMessage('error', 'Nu mai sunt carduri in imprimanta');
      throw 'Nu mai sunt carduri in imprimanta';
    }

    try {
      const product = await getEnrolledProduct(physicalId);
      if (product.status !== 'ENROLLED') {
        throw Error();
      }
      if (
        allPersons &&
        allPersons.length > 0 &&
        allPersons.some(
          (pers) =>
            pers.cardPhysicalId === product.physicalId ||
            pers.CardLogicalId === product.serial,
        )
      ) {
        throw t('errors.cardIsPrinted');
      }
      return product;
    } catch (err) {
      showMessage(
        'error',
        typeof err === 'string' ? err : 'Cardul curent este invalid',
      );
      await ejectCard('REJECT');
    }

    // eslint-disable-next-line no-constant-condition
  } while (true);
};

export const printOnCardLogicalId = async (cardLogicalId) =>
  fetch(
    `http://${
      localStorage.getItem('devconnIP') || 'localhost'
    }:13385/dev/print/${cardLogicalId}`,
    {
      method: 'POST',
      body: null,
    },
  ).catch(() => {
    showMessage('error', 'Nu s-a putut printa serial-ul cardului');
  });
