import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Space, Tabs } from 'antd';

import useForm from '../../hooks/useForm';

import SaveButton from '../buttons/SaveButton';
import CancelButton from '../buttons/CancelButton';
import FormWrapper from '../FormWrapper';
import RolePermissions from './RolePermissions';
import RoleActions from './RoleActions';

const EditRoleForm = ({ role, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        code: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: role,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });
  const { value, handleSubmit, input, setValue, setFormValue } = form;

  useEffect(() => {
    setFormValue(role);
  }, [setFormValue, role]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.admin.role.name')}
              props={{ ...input('name') }}
            />
            <FormWrapper.Input
              label={t('entity.admin.role.code')}
              props={{ ...input('code') }}
            />
            <FormWrapper.TextArea
              label={t('entity.admin.role.description')}
              props={{ ...input('description'), rows: 4 }}
            />
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={t('entity.admin.permission._plural')}
                key="permissions"
              >
                <RolePermissions
                  permissions={value.permissions}
                  setValue={setValue}
                />
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={t('entity.admin.action._plural')}
                key="actions"
              >
                <RoleActions form={form} />
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
        </FormWrapper>
        <Space>
          <SaveButton />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </>
  );
};

export default EditRoleForm;
