import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const EditEntityButton = ({ path, entity }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      icon={<EditOutlined />}
      onClick={() => history.push(path)}
    >
      {t('actions.edit')} {entity}
    </Button>
  );
};

export default EditEntityButton;
