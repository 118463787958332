import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Space,
  message,
  Tooltip,
  notification,
} from 'antd';
import {
  UploadOutlined,
  RollbackOutlined,
  CheckOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import moment from 'moment/moment';
import {
  SERVICE_URI,
  BASE_URI,
  updateDocExpDate,
} from '../../../services/orders';
import { downloadFiles } from '../../../services/files';
import { postFiles } from '../../../helpers/api';
import Uploader from './Uploader';
import { ORDER_STATUS } from '../../../pages/orders/constants';
import DatePickerLocale from '../../DatePickerLocale';

const ProductModalDocUpload = ({ props }) => {
  const { t } = useTranslation();
  const [uploadForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    dt,
    orderId,
    disabled,
    name,
    setValue,
    currentDoc,
    status,
    customerType,
    setFormValue,
    getValue,
  } = props;
  const [file, setFile] = useState(null);

  useEffect(() => {
    uploadForm.setFieldsValue({
      uploadFile: {
        expirationDate: currentDoc
          ? currentDoc.expirationDate && moment(currentDoc.expirationDate)
          : null,
      },
    });
  }, [uploadForm, currentDoc]);

  return (
    <Space>
      <Button
        type="primary"
        htmlType="button"
        disabled={disabled}
        onClick={() => setIsModalVisible(true)}
        icon={<UploadOutlined />}
      />

      <Modal
        title={dt.name}
        visible={isModalVisible}
        closable={false}
        footer={
          <Space>
            <Button
              className="ant-btn-success"
              type="primary"
              htmlType="button"
              icon={<CheckOutlined />}
              onClick={() => {
                if (ORDER_STATUS.SELECT_PRODUCTS !== status) {
                  const { expirationDate } =
                    uploadForm.getFieldValue('uploadFile');
                  if (ORDER_STATUS.DOC_VALIDATION === status) {
                    const updateDocumentDto = {
                      fileId: currentDoc.fileId,
                      expirationDate,
                    };
                    updateDocExpDate(updateDocumentDto).then(() => {
                      setValue(name, currentDoc, expirationDate, true);
                      setIsModalVisible(false);
                    });
                  } else {
                    setValue(name, currentDoc, expirationDate, true);
                    setIsModalVisible(false);
                  }
                } else {
                  uploadForm
                    .validateFields()
                    .then(() => {
                      setIsModalVisible(false);
                      const { expirationDate } =
                        uploadForm.getFieldValue('uploadFile');
                      const files = [];
                      files.push(file);
                      postFiles(
                        `${SERVICE_URI}${BASE_URI}/${orderId}/files`,
                        files,
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          setValue(name, res, expirationDate, false);
                          setFormValue('docMap', {
                            ...getValue('docMap'),
                            [res[0].id]: file,
                          });
                        })
                        .then(() => {
                          uploadForm.resetFields();
                        })
                        .catch(() => console.log('error'));
                    })
                    .catch(() =>
                      notification.error({
                        message:
                          'Datele introduse nu corespund criteriilor de validare',
                      }),
                    );
                }
              }}
            >
              {t('actions.accept')}
            </Button>
            <Button
              className="ant-btn-primary"
              type="primary"
              htmlType="button"
              icon={<RollbackOutlined />}
              onClick={() => setIsModalVisible(false)}
            >
              {t('actions.cancel')}
            </Button>
          </Space>
        }
      >
        <Form form={uploadForm} layout="vertical">
          <Form.Item
            name={['uploadFile', 'expirationDate']}
            rules={[
              {
                required: true,
                message: t('errors.selectDate'),
              },
            ]}
          >
            <DatePickerLocale
              format="DD.MM.YYYY HH:mm"
              disabled={customerType}
              showTime
              style={{ width: '100%' }}
              initialValues={moment()}
              disabledDate={(current) => {
                return current < moment();
              }}
            />
          </Form.Item>
          <Row justify="space-around" align="top">
            <Col span={22} push={2}>
              {ORDER_STATUS.SELECT_PRODUCTS === status && (
                <Form.Item
                  name={['uploadFile', 'file']}
                  rules={[
                    {
                      required: true,
                      message: 'Please select a file!',
                    },
                  ]}
                >
                  <Uploader
                    props={{
                      names: ['uploadFile', 'file'],
                      handleFileUploaded: (selectedFile) => {
                        if (selectedFile) {
                          const convertBytesToMB = (bytes) =>
                            bytes / (1024 * 1024);
                          const maxSize = process.env.MAX_FILE_SIZE || 5;
                          if (convertBytesToMB(selectedFile.size) > maxSize) {
                            message.error({
                              content: t('errors.maxFileSizeReached', {
                                fileName: selectedFile.name,
                                maxSize,
                              }),
                              duration: 2.5,
                              className: 'card-message',
                            });
                          } else {
                            setFile(selectedFile);
                          }
                        }
                      },
                    }}
                  />
                </Form.Item>
              )}
            </Col>
            <Col span={2} pull={22} style={{ top: 10 }}>
              {orderId !== null && orderId !== undefined && (
                <Space style={{ paddingLeft: '1rem' }}>
                  <Tooltip title={t('actions.orders.viewDoc')}>
                    <Button
                      shape="circle"
                      type="link"
                      icon={<FileTextOutlined />}
                      style={{ color: '#24ad09' }}
                      onClick={() => downloadFiles(currentDoc.fileId, orderId)}
                    />
                  </Tooltip>
                </Space>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </Space>
  );
};
export default ProductModalDocUpload;
