/* eslint-disable no-unused-vars */
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Row, Space, Tooltip, Upload } from 'antd';
import { UploadOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import FormWrapper from '../FormWrapper';
import PageTitle from '../PageTitle';
import './OrderFileList.scss';
import { FormContext } from '../../hooks/useForm';
import ProductUsersList from './ProductUsersList';
import { csvToJson } from '../../helpers/csv';

const CardsList = () => {
  const form = useContext(FormContext);
  const { t } = useTranslation();

  const { select, value, setValue } = form;
  const { products = [{ productPersons: [] }], disabled } = value;
  if (!products[0]) {
    products.push({ productPersons: [] });
  }
  const { productPersons: productPers } = products[0];

  const getCSVData = useCallback(
    (file) => {
      const { productPersons = [] } = products[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setValue(`products[0].productPersons`, [
          ...productPersons,
          ...csvToJson(e.target.result).map((pp) => ({
            person: {
              ...pp,
              key: Math.random(),
            },
            key: Math.random(),
          })),
        ]);
      };
      reader.readAsText(file);
      return Upload.LIST_IGNORE;
    },
    [products, setValue],
  );

  const addCard = useCallback(() => {
    const { productPersons = [] } = products[0];
    setValue(`products[0].productPersons`, [
      ...productPersons,
      {
        person: {
          key: Math.random(),
        },
        key: Math.random(),
      },
    ]);
  }, [setValue, products]);

  return (
    <>
      <PageTitle title={t('entity.orders.cards._plural')} />
      <>
        <FormWrapper>
          <Col span={1} offset={20}>
            <Tooltip title={t('entity.orders.uploadPersons')}>
              <Upload
                size="small"
                dragger={false}
                beforeUpload={(file) => getCSVData(file)}
                multiple={false}
                accept=".csv"
                disabled={disabled}
              >
                <Button
                  icon={
                    <UploadOutlined
                      style={{ fontSize: '150%', color: '#08c' }}
                    />
                  }
                />
              </Upload>
            </Tooltip>
          </Col>
          <Col span={1}>
            <Tooltip title={t('entity.orders.addPerson')}>
              <Button
                disabled={disabled}
                shape="circle"
                type="link"
                icon={<UsergroupAddOutlined style={{ fontSize: '150%' }} />}
                onClick={() => addCard()}
              />
            </Tooltip>
          </Col>
        </FormWrapper>
        <Row>
          <Col span={24}>
            {productPers && productPers.length > 0 && (
              <ProductUsersList
                index={0}
                disabled={disabled}
                fields={['cardLogicalId']}
              />
            )}
          </Col>
        </Row>
        <Space direction="vertical">
          <Divider dashed />
        </Space>
      </>
    </>
  );
};

export default CardsList;
