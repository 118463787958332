import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import EmvDetailsList from '../../../components/emv-details/EmvDetailsList';

const EmvDetails = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.emvDetails._')} />
      <EmvDetailsList />
    </Default>
  );
};

export default EmvDetails;
