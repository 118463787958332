import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { Button, Dropdown, Menu, notification } from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';

import { getValidTypesByEntityType } from '../../services/stocks/invoices';

import { InvoiceTypes } from './constants';

const AddInvoiceButton = ({ group, isInvoiceAllowed }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [invoiceTypes, setInvoiceTypes] = useState([]);

  useEffect(() => {
    setInvoiceTypes([]);
    getValidTypesByEntityType(group)
      .then((res) => {
        setInvoiceTypes(
          Object.entries(InvoiceTypes)
            .filter(([key]) => res.includes(key))
            .filter(([, value]) => isInvoiceAllowed(value))
            .map(([key]) => key),
        );
      })
      .catch((err) => {
        console.error(err);
        notification.error({ message: err.toString() });
      });
  }, [group, isInvoiceAllowed]);

  const handleMenuClick = useCallback(
    (e) => {
      const { key } = e;
      history.push(`/invoices/${key}/new`);
    },
    [history],
  );

  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuClick}>
        {invoiceTypes.map((invoiceType) => (
          <Menu.Item key={invoiceType}>
            {t(`entity.stocks.invoice.types.${invoiceType}`)}
          </Menu.Item>
        ))}
      </Menu>
    ),
    [handleMenuClick, invoiceTypes, t],
  );

  return (
    <Dropdown overlay={menu} disabled={invoiceTypes.length === 0}>
      <Button type="primary" icon={<PlusOutlined />}>
        {t('actions.add')} {t('entity.stocks.invoice._singular')}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default AddInvoiceButton;
