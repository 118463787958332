import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditRouteGroupForm from '../../../../components/route-groups/EditRouteGroupForm';

import {
  createRouteGroup,
  getRouteGroupById,
  updateRouteGroup,
} from '../../../../services/taxonomies/route-groups';

const initialRouteGroup = { deleted: false, routes: [], routePrices: [] };

const EditRouteGroup = () => {
  const [routeGroup, handleSubmit, handleCancel] = useEditPage({
    initial: initialRouteGroup,
    existent: getRouteGroupById,
    goBackPath: '/admin/taxonomies/route-groups',

    onCreate: createRouteGroup,
    onUpdate: updateRouteGroup,
  });

  return (
    <Default>
      {routeGroup !== undefined && (
        <EditRouteGroupForm
          routeGroup={routeGroup}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditRouteGroup;
