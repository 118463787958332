import React from 'react';

import { getI18n } from 'react-i18next';
import RangePickerEnglish from './RangePickerEnglish';
import RangePickerGerman from './RangePickerGerman';
import RangePickerRomanian from './RangePickerRomanian';

const RangePickerLocale = (props) => {
  const { language } = getI18n();
  switch (language) {
    case 'en':
      return <RangePickerEnglish {...props} />;
    case 'de':
      return <RangePickerGerman {...props} />;
    default:
      return <RangePickerRomanian {...props} />;
  }
};
export default RangePickerLocale;
