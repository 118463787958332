import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Space } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/columns';
import {
  downloadAll,
  getTransactionsFains,
  downloadAllByInspectorId,
} from '../../services/accounts/transactions-fains';
import PageTitle from '../PageTitle';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';

const ACTIONS_DICT = {
  content: [
    { id: 'TO_INSPECTION' },
    { id: 'TO_COMMERCIAL' },
    { id: 'IDENTIFYING_INSPECTOR' },
    { id: 'VERIFYING_CARD' },
    { id: 'ISSUE_PENALTY_DOCUMENT' },
  ],
  loading: false,
  error: false,
};

const TransactionsFines = ({ inspectorId }) => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, sort, criterias } = useDatasource(
    (arg) => {
      const newCriterias = { ...arg.criterias };

      if (inspectorId !== null && inspectorId !== undefined) {
        newCriterias.inspectorId = inspectorId;
      }

      return getTransactionsFains({ ...arg, criterias: newCriterias });
    },
  );

  const downloadFetcher = useCallback(
    (data, spec) =>
      inspectorId === undefined
        ? downloadAll(data, spec)
        : downloadAllByInspectorId(inspectorId, data, spec),
    [inspectorId],
  );

  const columns = useMemo(
    () => [
      Column.text('inspectorId', t('components.inspectors.fines.inspectorId'), {
        filter: true,
      }),
      Column.text(
        'inspectorFullname',
        t('components.inspectors.fines.inspectorFullname'),
        {
          filter: true,
        },
      ),
      Column.dictionary(
        'transactionAction',
        t('components.inspectors.fines.transactionAction'),
        ACTIONS_DICT,
        {
          filter: true,
          dictLabel: (entry) =>
            t(`components.inspectors.fines.actionsDict.${entry.id}`),
        },
      ),
      Column.text('docSeries', t('components.inspectors.fines.docSeries'), {
        filter: true,
      }),
      Column.text('cnp', t('components.inspectors.fines.cnp'), {
        filter: true,
      }),
      Column.text('docNumber', t('components.inspectors.fines.docNumber'), {
        filter: true,
      }),
      Column.text(
        'paymentMethod',
        t('components.inspectors.fines.paymentMethod'),
      ),
      Column.text('cardTypeId', t('components.inspectors.fines.cardTypeId'), {
        filter: true,
      }),
      Column.text(
        'cardPhysicalId',
        t('components.inspectors.fines.cardPhysicalId'),
        {
          filter: true,
        },
      ),
      Column.date(
        'transactionTime',
        t('components.inspectors.fines.transactionTime'),
        { width: 120, filter: true },
      ),

      Column.text('vehicleId', t('components.inspectors.fines.vehicleId'), {
        filter: true,
      }),

      Column.bool('direction', t('components.inspectors.fines.direction'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('components.inspectors.fines.directionsDict.true'),
          t('components.inspectors.fines.directionsDict.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.text('pto', t('components.inspectors.fines.pto'), {
        filter: true,
      }),
      Column.text(
        'validatorPosition',
        t('components.inspectors.fines.validatorPosition'),
        {
          filter: true,
        },
      ),
      Column.text('deviceSN', t('components.inspectors.fines.deviceSN'), {
        filter: true,
      }),
      Column.text('latitude', t('components.inspectors.fines.latitude'), {
        filter: true,
      }),
      Column.text('longitude', t('components.inspectors.fines.longitude'), {
        filter: true,
      }),
    ],
    [t],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadFetcher}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="id"
        columns={columns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
        size="small"
      />
    </>
  );
};

export default TransactionsFines;
