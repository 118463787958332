import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/user-types';

export const getUserTypes = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getUserTypesByIds = (ids) => async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, {
    ...data,
    criterias: { ...data.criterias, id: ids },
  });

export const getUserTypeById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const getUserTypeByCode = async (code) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/code/${code}`);

export const createUserType = async (userType) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, userType);

export const updateUserType = async (userType) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${userType.id}`, userType);

export const deleteUserType = async (userType) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${userType.id}`);
