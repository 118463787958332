import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Column from '../../helpers/columns';
import { FormContext } from '../../hooks/useForm';
import Printing from './Printing';

const PrintList = () => {
  const { t } = useTranslation();
  const form = useContext(FormContext);

  const { value } = form;

  const { products = [] } = value;

  const finalProducts =
    products.length > 1
      ? products.filter((p) => p.productCode !== 'MIFARE_1K')
      : products;

  const mapPersons = useMemo(
    () =>
      finalProducts?.flatMap(
        ({ productPersons }) => productPersons.map(({ person }) => person), // Extract person from each productPerson
      ),
    [finalProducts],
  );

  const columns = useMemo(
    () => [
      {
        title: t('entity.orders.index'),
        width: 50,
        sorter: false,
        render: (_, __, idx) => <div>{idx + 1}</div>,
      },
      ...['fullname', 'email', 'identityCard', 'phone', 'employeeNumber'].map(
        (name) =>
          Column.text(name, t(`entity.admin.user.${name}`), {
            sort: false,
          }),
      ),
      Column.actions(t('entity.orders.printedCard'), ({ printed }) =>
        printed ? (
          <CheckCircleOutlined style={{ fontSize: '32px', color: '#24ad09' }} />
        ) : (
          <CloseCircleOutlined style={{ fontSize: '32px', color: '#ed0e0e' }} />
        ),
      ),
    ],
    [t],
  );

  return (
    <div style={{ padding: 20 }}>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        pagination={false}
        dataSource={mapPersons}
      />
      <Printing />
    </div>
  );
};

export default PrintList;
