import React, { useContext, useMemo } from 'react';

import { Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import Default from '../../../../layouts/Default';
import PageTitle from '../../../../components/PageTitle';
import useDatasource from '../../../../hooks/useDatasource';
import {
  downloadAll,
  findAll,
  remove,
} from '../../../../services/validators/validators';
import Column from '../../../../helpers/columns';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import { VALIDATOR_STATUS } from '../../../../components/validators/EditValidatorForm';

import AuthContext, { hasPermission } from '../../../../components/auth';
import ExportEntitiesButton from '../../../../components/buttons/ExportEntitiesButton';

const ValidatorsList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const { pagination, content, handleChange, reload, sort, criterias } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('serialNr', t('entity.taxonomy.validators.serialNumber'), {
        filter: true,
      }),

      ...(hasPermission(permissions, ['SYS_SUPERUSER', 'SYS_VALIDATOR_EDIT'])
        ? [
            Column.text('tokenId', t('entity.taxonomy.validators.tokenId'), {
              filter: true,
            }),
          ]
        : []),
      Column.text(
        'vehicleNumber',
        t('entity.taxonomy.validators.vehicleNumber'),
        {
          filter: true,
        },
      ),
      Column.text(
        'validatorPosition',
        t('entity.taxonomy.validators.position'),
        {
          filter: true,
        },
      ),
      Column.date(
        'lastConnectionAt',
        t('entity.taxonomy.validators.lastConnectionAt'),
        {
          filter: true,
        },
      ),
      Column.dictionary(
        'status',
        t('entity.taxonomy.validators.status._'),
        { content: VALIDATOR_STATUS, loading: false, error: false },
        {
          width: 150,
          filter: true,
          dictLabel: (entry, row) =>
            t(
              row?.deleted
                ? 'entity.taxonomy.validators.status.DELETED'
                : `entity.taxonomy.validators.status.${entry.id}`,
            ),
        },
      ),
      Column.bool('isOnline', t('entity.taxonomy.validators.online._'), {
        filter: false,
        inverted: false,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.validators.online.online'),
          t('entity.taxonomy.validators.online.offline'),
          t('table.filter.unknown'),
        ],
      }),
      Column.text(
        'softwareVersion',
        t('entity.taxonomy.validators.softwareVersion'),
        {
          filter: true,
        },
      ),
      Column.text('cdVersion', t('entity.taxonomy.validators.cdVersion'), {
        filter: true,
      }),
      Column.text('depot', t('entity.taxonomy.validators.depot'), {
        filter: true,
      }),
      Column.text(
        'whiteListVersion',
        t('entity.taxonomy.validators.whitelistVersion'),
        {
          filter: true,
        },
      ),
      Column.text('lineVersion', t('entity.taxonomy.validators.lineVersion'), {
        filter: true,
      }),
      Column.text('equipmentType', t('entity.taxonomy.validators.type'), {
        filter: true,
      }),

      ...(hasPermission(permissions, ['SYS_SUPERUSER', 'SYS_VALIDATOR_EDIT'])
        ? [
            Column.actions(t('table.actions'), (record) => (
              <>
                <EditItemIcon
                  path={`/admin/taxonomies/validators/${record.id}`}
                />
                <DeleteItemIcon
                  title={t('entity.taxonomy.validators.delete._', record)}
                  message={t(
                    'entity.taxonomy.validators.delete.deleted',
                    record,
                  )}
                  item={record}
                  disabled={record?.deleted}
                  action={remove}
                  reload={reload}
                />
              </>
            )),
          ]
        : []),
    ],
    [permissions, reload, t],
  );
  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.validators._plural')} />
      <Row style={{ paddingBottom: '24px' }} justify="end" gutter={16}>
        <Col>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Col>
        {hasPermission(permissions, [
          'SYS_SUPERUSER',
          'SYS_VALIDATOR_EDIT',
        ]) && (
          <Col style={{ textAlign: 'right' }}>
            <AddEntityButton
              entity={t('entity.taxonomy.validators._singular')}
              path="/admin/taxonomies/validators/new"
            />
          </Col>
        )}
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        scroll={{ x: 700, y: 700 }}
        onChange={handleChange}
      />
    </Default>
  );
};

export default ValidatorsList;
