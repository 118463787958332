import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/validators/parameters';

export const getParameters = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/search`, data);

export const getParameterById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createParameter = async (parameter) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, parameter);

export const updateParameter = async (parameter) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${parameter.id}`, parameter);

export const deleteParameter = async (parameter) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${parameter.id}`);
