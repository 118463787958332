import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import SaleReportTvmGrid from '../../../components/sale-report-tvm/SaleReportTvmGrid';

const SaleReportTvm = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.salesReportTvm._')} />
      <SaleReportTvmGrid />
    </Default>
  );
};

export default SaleReportTvm;
