import {
  search,
  post,
  get,
  put,
  remove as removeMethod,
} from '../../../helpers/api';

export const SERVICE_URI = '/taxonomies';
export const BASE_URI = '/api/v1/transport/depos';

export const findAll = async (data) =>
  search(`${SERVICE_URI}${BASE_URI}/`, data);

export const findOne = async (id) => get(`${SERVICE_URI}${BASE_URI}/${id}`);

export const create = async (entity) =>
  post(`${SERVICE_URI}${BASE_URI}`, entity);

export const update = async (entity) =>
  put(`${SERVICE_URI}${BASE_URI}/${entity.id}`, entity);

export const remove = async (entity) =>
  removeMethod(`${SERVICE_URI}${BASE_URI}/${entity.id}`);
