import { remove, get, downloadFile } from '../helpers/api';

export const SERVICE_URI = '/files';
export const PORTAL_SERVICE_URI = '/portal';
export const BASE_URI = '/api/v1/files';

export const removeFile = async (file) =>
  remove(`${SERVICE_URI}${BASE_URI}/${file.id}`);

export const getFiles = async (id) =>
  get(`${SERVICE_URI}${BASE_URI}/${Array.isArray(id) ? id.join(',') : id}`);

export const downloadFiles = async (id) =>
  downloadFile(
    `${SERVICE_URI}${BASE_URI}/${
      Array.isArray(id) ? id.join(',') : id
    }?download`,
  )
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

export const downloadFilesById = async (id) =>
  downloadFile(
    `${SERVICE_URI}${BASE_URI}/${
      Array.isArray(id) ? id.join(',') : id
    }?download`,
  )
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

export const downloadInvoiceById = async (id, transactionId) =>
  downloadFile(
    // TODO aici trebuie sa gasim o gasim o metoda mai bune
    // si nu prim portal ca sa ajungem in accounts
    `${PORTAL_SERVICE_URI}/api/v1/accounts/invoice/${
      Array.isArray(id) ? id.join(',') : id
    }?transactionId=${transactionId}`,
  )
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
