import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'antd';

import { FormContext } from '../../../../hooks/useForm';

import FormWrapper from '../../../FormWrapper';

const PERIOD_TYPES = ['months', 'weeks', 'days', 'hours'];

const UsagePerPeriod = ({ path }) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);

  const { input, select } = form;

  const periodTypes = useMemo(
    () =>
      PERIOD_TYPES.map((v, index) => ({
        id: index,
        name: t(
          `entity.offers.restrictions.restriction.USAGE_PER_PERIOD.periodType.${v}`,
        ),
      })),
    [t],
  );

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Number
          label={t(
            'entity.offers.restrictions.restriction.USAGE_PER_PERIOD.travelCount',
          )}
          props={{ ...input(`${path}.value1`) }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Select
          label={t(
            'entity.offers.restrictions.restriction.USAGE_PER_PERIOD.periodType._',
          )}
          props={{
            options: periodTypes,
            ...select(`${path}.value2`),
          }}
        />
      </Col>
    </Row>
  );
};

export default UsagePerPeriod;
