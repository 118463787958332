import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';
import { getProducts } from '../../services/offers/products';
import { NominalTypes } from './OffersConstants';

const ProductsGrid = () => {
  const { t } = useTranslation();

  const form = useContext(FormContext);

  const { value, setValue, errorMessages } = form;

  const { products } = value;

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.offers.product.name._')),
      Column.dictionary(
        'nominalType',
        t('entity.offers.product.nominalType._'),
        NominalTypes,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`entity.offers.product.nominalType.${entry.id}`),
        },
      ),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: products.map((val) => val),
      onSelect: (record, selected) =>
        selected
          ? setValue('products', [...products, record.id])
          : setValue(
              'products',
              products.filter((id) => id !== record.id),
            ),
      getCheckboxProps: ({ deleted, id }) => ({
        disabled: deleted && !products.some((prId) => prId === id),
      }),
    }),
    [products, setValue],
  );

  const { pagination, content, handleChange } = useDatasource(getProducts);

  return (
    <>
      <FormWrapper.ErrorMessage message={errorMessages?.products} />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default ProductsGrid;
