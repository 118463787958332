import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';

export function useRouteParams(name = 'id') {
  const { pathname } = useLocation();
  const params = useParams();

  const value = useMemo(() => {
    const param = params[name];
    const ident = Number(param);

    if (!Number.isInteger(ident)) {
      return {
        [name]: null,
        isNew: param === 'new',
        isInvalid: param !== 'new',
      };
    }

    return {
      [name]: ident,
      isNew: pathname.endsWith('/clone'),
      isInvalid: false,
    };
  }, [params, pathname, name]);

  return value;
}
