import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';

import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';

import Column from '../../../../helpers/columns';
import { deleteOffer, getOffers } from '../../../../services/offers/offers';

const OffersList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } =
    useDatasource(getOffers);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.offers.offer.name'), { filter: true }),
      Column.bool('deleted', t('entity.offers.offer.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.offers.offer.deleted.true'),
          t('entity.offers.offer.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),

      Column.actions(t('table.actions'), (product) => (
        <>
          <EditItemIcon path={`/admin/offers/offers/${product.id}`} />
          <DeleteItemIcon
            title={t('entity.offers.offer._delete', product)}
            message={t('entity.offers.offer._deleted', product)}
            item={product}
            action={deleteOffer}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.offers.offer._plural')}>
        <AddEntityButton
          entity={t('entity.offers.offer._singular')}
          path="/admin/offers/offers/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default OffersList;
