import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'antd';

import { FormContext } from '../../../../hooks/useForm';

import FormWrapper from '../../../FormWrapper';

const PassBack = ({ path }) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);

  const { input } = form;

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Number
          label={t('entity.offers.restrictions.restriction.PASS_BACK.duration')}
          props={{ ...input(`${path}.value1`) }}
        />
      </Col>

      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Number
          label={t('entity.offers.restrictions.restriction.PASS_BACK.station')}
          props={{ ...input(`${path}.value2`) }}
        />
      </Col>

      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Number
          label={t('entity.offers.restrictions.restriction.PASS_BACK.line')}
          props={{ ...input(`${path}.value3`) }}
        />
      </Col>
    </Row>
  );
};

export default PassBack;
