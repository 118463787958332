import React, { useMemo, useEffect } from 'react';
import { Space } from 'antd';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';

const EditStationTypeForm = ({ stationType, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const { value, handleSubmit, input, setFormValue } = useForm({
    initialValue: stationType,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  useEffect(() => {
    setFormValue(stationType);
  }, [setFormValue, stationType]);

  const { deleted } = value;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={stationType.name || t('entity.taxonomy.stationType._singular')}
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.stationType.name')}
              props={{ ...input('name'), disabled: deleted }}
            />

            <Space style={{ paddingTop: '1rem' }}>
              {!deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditStationTypeForm;
