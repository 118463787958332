import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { FormContext } from '../../../../hooks/useForm';
import FormWrapper from '../../../FormWrapper';
import { getRouteGroups } from '../../../../services/taxonomies/route-groups';
import useDictionaries from '../../../../hooks/useDictionaries';
import { useResetComponent } from '../../../../context/ResetComponentContext';

const dictionaties = { routeGroups: getRouteGroups };
const ROWS_CURSOR = new Array(4).fill(null).map((_, idx) => idx);
const options = [{ id: 0 }, { id: 1 }];

const UsagePerPeriod = ({ path }) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);
  const [{ routeGroups }] = useDictionaries(dictionaties);
  const { select, value } = form;
  const isPassCategory = value.category === 'PASS';
  const { resetComponent } = useResetComponent();
  const [adjustedOptions, setAdjustedOptions] = useState([]);

  useEffect(() => {
    const newOptions = options.map((option) => ({
      ...option,
      disabled: !isPassCategory && option.id === 1,
    }));
    setAdjustedOptions(newOptions);
  }, [isPassCategory]);

  useEffect(() => {
    if (!isPassCategory) {
      ROWS_CURSOR.forEach((val) => {
        select(`${path}.value${val * 2 + 1}`).onChange(0);
        select(`${path}.value${val * 2 + 2}`).onChange(null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetComponent]);

  return ROWS_CURSOR.map((val) => (
    <Row gutter={16} key={val}>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Select
          label={t(
            'entity.offers.restrictions.restriction.LINE_GROUPS.option._',
          )}
          props={{
            options: adjustedOptions,
            labelProp: (obj) =>
              t(
                `entity.offers.restrictions.restriction.LINE_GROUPS.option.${obj.id}`,
              ),
            ...select(`${path}.value${val * 2 + 1}`),
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Select
          label={t('entity.offers.restrictions.restriction.LINE_GROUPS.value')}
          props={{
            options: routeGroups.content,
            loading: routeGroups.loading,
            ...select(`${path}.value${val * 2 + 2}`),
          }}
        />
      </Col>
    </Row>
  ));
};

export default UsagePerPeriod;
