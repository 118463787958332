import React from 'react';
import { Default } from '../../layouts';

import { useEditPage } from '../../hooks/useEditPage';

import { findOne, create, update } from '../../services/taxonomies/messages';
import EditMessageForm from '../../components/messages/EditMessageForm';

const initialMessage = { active: true };

const EditMessage = () => {
  const [message, handleSubmit, handleCancel] = useEditPage({
    initial: initialMessage,
    existent: findOne,
    goBackPath: '/messages',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {message !== undefined && (
        <EditMessageForm
          message={message}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditMessage;
