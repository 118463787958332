import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import { Default } from '../../layouts';
import PageTitle from '../../components/PageTitle';
import InspectorsList from '../../components/inspectors/InspectorsList';
import InspectorTransactionsList from '../../components/inspectors/InspectorTransactionsList';
import InspectorLastVehiclesList from '../../components/inspectors/InspectorLastVehiclesList';
import TransactionsFines from '../../components/inspectors/TransactionsFines';

const Inspectors = () => {
  const { t } = useTranslation();

  const [isFinesEnabledString] = useState(window._env_.ENABLE_FINES);
  const isFinesEnabled = isFinesEnabledString === 'true';

  return (
    <Default>
      <PageTitle title={t('entity.inspectors._')} />
      <Tabs>
        <Tabs.TabPane tab={t('components.inspectors.list._')} key="list">
          <InspectorsList />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('components.inspectors.transactions._')}
          key="transactions"
        >
          <InspectorTransactionsList />
        </Tabs.TabPane>
        {isFinesEnabled && (
          <Tabs.TabPane tab={t('components.inspectors.fines._')} key="fines">
            <TransactionsFines />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={t('components.inspectors.lastVehicles._')}
          key="lastVehicles"
        >
          <InspectorLastVehiclesList />
        </Tabs.TabPane>
      </Tabs>
    </Default>
  );
};

export default Inspectors;
