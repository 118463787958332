import React, { useMemo, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Tabs, Space } from 'antd';
import useForm, { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import RoutesList from './RouteList';
import RoutePriceTable from '../routes/RoutePricesList';
import RoutesSelection from './RoutesSelection';
import CancelButton from '../buttons/CancelButton';

const EditRouteGroupForm = ({ routeGroup, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: routeGroup,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });
  const { value, handleSubmit, input, setFormValue } = form;

  useEffect(() => {
    setFormValue(routeGroup);
  }, [setFormValue, routeGroup]);

  const { deleted } = value;

  return (
    <>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <PageTitle
            title={routeGroup.name || t('entity.taxonomy.routeGroup._singular')}
          />

          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.Input
                label={t('entity.taxonomy.routeGroup.name')}
                props={{ ...input('name'), disabled: deleted }}
              />

              <Space style={{ paddingTop: '1rem' }}>
                {!deleted && <SaveButton />}
                <CancelButton onCancel={onCancel} />
              </Space>
            </FormWrapper.Single>

            <FormWrapper.Double>
              <Tabs>
                <Tabs.TabPane
                  tab={t('entity.taxonomy.routeGroup.tabs.selectedRoutes')}
                  key="sellers"
                >
                  <RoutesSelection />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={t('entity.taxonomy.routeGroup.tabs.routes')}
                  key="pos"
                >
                  <RoutesList />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={t('entity.taxonomy.route.price')}
                  key="route_price"
                >
                  <RoutePriceTable />
                </Tabs.TabPane>
              </Tabs>
            </FormWrapper.Double>
          </FormWrapper>
        </form>
      </FormContext.Provider>
    </>
  );
};
export default EditRouteGroupForm;
