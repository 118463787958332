import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { Default } from '../../../layouts';

import { useEditPage } from '../../../hooks/useEditPage';

import EditUserForm from '../../../components/users/EditUserForm';

import {
  getUserById,
  createUser,
  updateUser,
  deletePersonalInfo,
} from '../../../services/admin/users';

const initialUser = { hasContract: false, files: [] };

const EditUser = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [user, handleSubmit, handleCancel] = useEditPage({
    initial: initialUser,
    existent: getUserById,
    goBackPath: '/admin/users',
    onCreate: createUser,
    onUpdate: updateUser,
  });
  const handleDeletePersonalData = useCallback(
    (value) => [
      deletePersonalInfo(value).then(() => {
        notification.success({
          message: t('actions.changeSuccessful'),
        });
      }),
      history.push(`/admin/users`),
    ],
    [history, t],
  );

  return (
    <Default>
      {user !== undefined && (
        <EditUserForm
          user={user}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onDeletePersonalData={handleDeletePersonalData}
        />
      )}
    </Default>
  );
};

export default EditUser;
