import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';
import {
  remove,
  findAll,
} from '../../../../services/taxonomies/blacklist-reason';

import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import Column from '../../../../helpers/columns';

const BlacklistReasonsList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } = useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.taxonomy.blacklistReason.id')),
      Column.text('name', t('entity.taxonomy.blacklistReason.name'), {
        filter: true,
      }),
      Column.text(
        'description',
        t('entity.taxonomy.blacklistReason.description'),
        {
          filter: true,
        },
      ),
      Column.bool('deleted', t('entity.taxonomy.blacklistReason.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.blacklistReason.deleted.true'),
          t('entity.taxonomy.blacklistReason.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <>
          <EditItemIcon
            path={`/admin/taxonomies/blacklist-reasons/${record.id}`}
          />
          {!record.isDefault && (
            <DeleteItemIcon
              title={t('entity.taxonomy.blacklistReason.delete._', record)}
              message={t(
                'entity.taxonomy.blacklistReason.delete.deleted',
                record,
              )}
              item={record}
              action={remove}
              reload={reload}
            />
          )}
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.blacklistReason._plural')}>
        <AddEntityButton
          entity={t('entity.taxonomy.blacklistReason._singular')}
          path="/admin/taxonomies/blacklist-reasons/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default BlacklistReasonsList;
