import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import TravelerValidationsList from '../../../components/traveler-validations/TravelerValidationsList';

const TravelerValidations = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.travelerValidations._')} />
      <TravelerValidationsList />
    </Default>
  );
};

export default TravelerValidations;
