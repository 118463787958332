import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const CustomSaveButton = ({ disabled, form, formRef }) => {
  const { t } = useTranslation();
  return (
    <Button
      type="primary"
      icon={<SaveOutlined />}
      disabled={disabled}
      htmlType="submit"
      onClick={() => {
        form.setValue('action', null);
        formRef.current.dispatchEvent(new Event('submit'));
      }}
    >
      {t('actions.save')}
    </Button>
  );
};

export default CustomSaveButton;
