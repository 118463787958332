export const BULK_TYPES = {
  ISSUING_CARDS: 'ISSUING_CARDS',
  RECHARGE_CARDS: 'RECHARGE_CARDS',
  DISABLE_CARDS: 'DISABLE_CARDS',
};

export const UserTypesEnum = {
  ELEV: 'ELEV',
  STUDENT_ORFAN: 'STUDENT_ORFAN',
  STUDENT: 'STUDENT',
  PERSOANE_HANDICAP: 'PERSOANE_HANDICAP',
  PENSIONAR_CONVENTIE: 'PENSIONAR_CONVENTIE',
  PENSIONAR: 'PENSIONAR',
  EROI: 'EROI',
  CALATOR: 'CALATOR',
  DONATORI: 'DONATORI',
  COPIL_PANA_IN_6_ANI: 'COPIL_PANA_IN_6_ANI',
  ANGAJAT: 'ANGAJAT',
  ELEV_ORFAN: 'ELEV_ORFAN',
  ELEV_POSTLICEALA: 'ELEV_POSTLICEALA',
  ELEV_FARA_CI: 'ELEV_FARA_CI',
  ELEV_CI: 'ELEV_CI',
};

export const COMMAND_TYPES = [{ id: 'INTERNAL' }, { id: 'EXTERNAL' }];

export const ORDER_STATUS = {
  SELECT_PRODUCTS: 'SELECT_PRODUCTS',
  DOC_VALIDATION: 'DOC_VALIDATION',
  PAYMENT: 'PAYMENT',
  PAID: 'PAID',
  CUSTOMIZE_CARD: 'CUSTOMIZE_CARD',
  PRINTING: 'PRINTING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  PICKED_UP: 'PICKED_UP',
  OP_VALIDATION: 'OP_VALIDATION',
  PAYMENT_DUE: 'PAYMENT_DUE',
  DELETED: 'DELETED',
};

export const CUSTOMER_TYPE = {
  LEGAL: 'LEGAL',
  INDIVIDUAL: 'INDIVIDUAL',
  PAN: 'PAN',
};

export const OrderTypes = {
  content: [
    { id: 'SELECT_PRODUCTS' },
    { id: 'DOC_VALIDATION' },
    { id: 'PAYMENT' },
    { id: 'CUSTOMIZE_CARD' },
    { id: 'PRINTING' },
    { id: 'CONFIRMED' },
    { id: 'REJECTED' },
    { id: 'DELETED' },
    { id: 'PAID' },
    { id: 'PAYMENT_DUE' },
    { id: 'OP_VALIDATION' },
  ],
  loading: false,
  error: false,
};

export const BADGE_COLORS = {
  SELECT_PRODUCTS: 'pink',
  DOC_VALIDATION: 'red',
  PAYMENT: 'yellow',
  CONFIRMED: 'green',
  CUSTOMIZE_CARD: 'blue',
  PRINTING: 'aqua',
  ACTIVE: 'green',
  DELETED: '#999',
  REJECTED: '#999',
  PICKED_UP: '#f19205',
  PAID: '#be0ed2',
  OP_VALIDATION: '#e70a65',
  PAYMENT_DUE: '#8b4cf8',
};
