import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Space } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/columns';
import { findAll, downloadAll } from '../../services/accounts/emv-details';
import PageTitle from '../PageTitle';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';

const EmvDetailsList = () => {
  const { t } = useTranslation();

  const { loading, pagination, criterias, content, handleChange, sort } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('components.emvDetails.list.columns.id'), {
        filter: true,
      }),
      Column.text(
        'physicalId',
        t('components.emvDetails.list.columns.physicalId'),
        {
          filter: true,
        },
      ),
      Column.text('tid', t('components.emvDetails.list.columns.tid'), {
        filter: true,
      }),
      Column.text('aid', t('components.emvDetails.list.columns.aid'), {
        filter: true,
      }),
      Column.text('mid', t('components.emvDetails.list.columns.mid'), {
        filter: true,
      }),
      Column.text('batch', t('components.emvDetails.list.columns.batch'), {
        filter: true,
      }),
      Column.text(
        'receiptNumber',
        t('components.emvDetails.list.columns.receiptNumber'),
        {
          filter: true,
        },
      ),
      Column.text('rrn', t('components.emvDetails.list.columns.rrn'), {
        filter: true,
      }),
      Column.text(
        'authorizationCode',
        t('components.emvDetails.list.columns.authorizationCode'),
        {
          filter: true,
        },
      ),
      Column.text('panMask', t('components.emvDetails.list.columns.panMask'), {
        filter: true,
      }),
      Column.date(
        'paymentDate',
        t('components.emvDetails.list.columns.paymentDate'),
        {
          filter: true,
        },
      ),
      Column.text('line', t('components.emvDetails.list.columns.line'), {
        filter: true,
      }),
      Column.text('sumPaid', t('components.emvDetails.list.columns.sumPaid'), {
        filter: true,
      }),
      Column.text('vehicle', t('components.emvDetails.list.columns.vehicle'), {
        filter: true,
      }),
    ],
    [t],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="id"
        columns={columns}
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
        size="small"
      />
    </>
  );
};

export default EmvDetailsList;
