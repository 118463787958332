import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Space, Tabs } from 'antd';

import useForm, { FormContext } from '../../../hooks/useForm';

import PageTitle from '../../PageTitle';
import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';
import SectorInspectorsList from './SectorInspectorsList';
import { getRetailers } from '../../../services/taxonomies/retailers';
import useDictionaries from '../../../hooks/useDictionaries';

const dictionaries = {
  retailers: getRetailers,
};

const EditSectorForm = ({ sector, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().trim().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: sector,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const { handleSubmit, value, input, select } = form;

  const [{ retailers }] = useDictionaries(dictionaries);

  return (
    <FormContext.Provider value={form}>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={sector.name || t('entity.taxonomy.sector._singular')}
        />
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.sector.name')}
              props={{
                ...input('name'),
                disabled: value.deleted,
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.sector.description')}
              props={{
                ...input('description'),
                disabled: value.deleted,
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.sector.retailer')}
              props={{
                ...select('retailer'),
                options: retailers.content,
                loading: retailers.loading,
                disabled: value.deleted,
                allowClear: true,
              }}
            />
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={t('entity.taxonomy.sector.tabs.inspectors.tabTitle')}
                key="stock"
              >
                <SectorInspectorsList />
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
        </FormWrapper>
        <Space>
          <SaveButton disabled={value.deleted} />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </FormContext.Provider>
  );
};
export default EditSectorForm;
