import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Radio, Row } from 'antd';
import { FormContext } from '../../hooks/useForm';
import {
  SERVICE_URI as FILE_SERVICE_URI,
  BASE_URI as FILE_BASE_URI,
} from '../../services/files';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll } from '../../services/taxonomies/card-templates';
import { getProducts } from '../../services/offers/products';
import PageTitle from '../PageTitle';

const dictionaries = {
  allCardTemplates: findAll,
  productTypes: getProducts,
};

const CustomizeCard = () => {
  const [{ productTypes, allCardTemplates }] = useDictionaries(dictionaries);
  const [templates, setTemplates] = useState({});
  const { t } = useTranslation();
  const form = useContext(FormContext);

  const { value, input } = form;
  const { products } = value;

  const getProductType = useCallback(
    (productId) => {
      return productTypes?.content?.find((pt) => pt.id === productId);
    },
    [productTypes.content],
  );
  const getAvailableLayouts = useCallback(
    (userTypeId) => {
      return (
        userTypeId
          ? allCardTemplates?.content?.filter((pt) =>
              pt.userTypes.includes(userTypeId),
            )
          : allCardTemplates?.content
      )?.map((ct) => ct.fileId);
    },
    [allCardTemplates?.content],
  );

  useEffect(() => {
    const finalProducts =
      products.length > 1
        ? products.filter((p) => p.productCode !== 'MIFARE_1K')
        : products;
    const data = finalProducts
      .map(({ productId }) => {
        const { userTypeId, name } = getProductType(productId) || {};
        const layouts = getAvailableLayouts(userTypeId);
        return { productId, layouts, name };
      })
      .reduce(
        (acc, { productId, ...props }) => ({ ...acc, [productId]: props }),
        {},
      );
    setTemplates(data);
  }, [getAvailableLayouts, getProductType, products, setTemplates]);

  return (
    <>
      <PageTitle title={t('entity.orders.customizeCardTitle')} />
      {products.map(({ productId }, idx) => (
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}
          style={{ paddingBottom: 40 }}
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
        >
          <Col span={24}>
            <Divider>{templates[productId]?.name}</Divider>
            <Radio.Group {...input(`products[${idx}].templateFileId`)}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                {templates[productId]?.layouts?.map((id) => (
                  <Col key={id} style={{ textAlign: 'center' }}>
                    <Radio value={id}>
                      <img
                        className="img-streched"
                        src={`${window._env_.API_BACKEND_URL}${FILE_SERVICE_URI}${FILE_BASE_URI}/${id}?download`}
                        alt=""
                      />
                    </Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default CustomizeCard;
