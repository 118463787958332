import React from 'react';
import { Col, Input, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const Comment = ({ value }) => {
  const { t } = useTranslation();
  const { comment, author, createdAt } = value;

  const email = author?.email;

  return (
    <div style={{ margin: '2rem' }}>
      <Row>
        <Col span={12}>
          {email || (
            <>
              <Spin />
              <span>{t('entity.tvm.burglary.loadingMessage')}</span>
            </>
          )}
        </Col>
        <Col offset={4}>
          {createdAt ? (
            new Date(createdAt).toLocaleString('ro-RO', {
              timeZone: 'Europe/Bucharest',
            })
          ) : (
            <Spin />
          )}
        </Col>
      </Row>
      <Row>
        <TextArea
          maxLength={9999}
          style={{
            borderRadius: '7px',
            resize: 'none',
            boxShadow: '2px 2px #888888',
            caretColor: 'transparent',
          }}
          autoSize
          value={comment}
          readOnly
          spellCheck="false"
        />
      </Row>
    </div>
  );
};
export default Comment;
