import React from 'react';

import { POSContextWrapper } from './POSContext';

import POSScreenSwitcher from './POSScreenSwitcher';

const POSApplication = () => {
  return (
    <POSContextWrapper>
      <POSScreenSwitcher />
    </POSContextWrapper>
  );
};

export default POSApplication;
