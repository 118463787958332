import React, { useMemo, useEffect } from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';
import { BlockingPlaces, UnblockingPlaces } from './constants';

export const BlockingPlace = {
  content: [
    ...Object.values(BlockingPlaces).map((item) => ({
      id: item,
    })),
  ],
  loading: false,
  error: false,
};

export const UnblockingPlace = {
  content: [
    ...Object.values(UnblockingPlaces).map((item) => ({
      id: item,
    })),
  ],
  loading: false,
  error: false,
};

const EditBlacklistReasonForm = ({ blacklistReasons, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const { value, handleSubmit, input, select, setFormValue } = useForm({
    initialValue: blacklistReasons,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  useEffect(() => {
    setFormValue(blacklistReasons);
  }, [setFormValue, blacklistReasons]);
  const { deleted } = value;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={
            blacklistReasons.name ||
            t('entity.taxonomy.blacklistReason._singular')
          }
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.blacklistReason.name')}
              props={{
                ...input('name'),
                disabled: deleted || value.isDefault,
              }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.blacklistReason.description')}
              props={{
                ...input('description'),
                disabled: deleted || value.isDefault,
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.blacklistReason.blockingPlace._')}
              props={{
                options: BlockingPlace.content,
                loading: BlockingPlace.loading,
                mode: 'multiple',
                allowClear: true,
                ...select('blockingPlaces'),
                disabled: value.deleted,
                labelProp: (val) =>
                  t(`entity.taxonomy.blacklistReason.blockingPlace.${val.id}`),
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.blacklistReason.unblockingPlace')}
              props={{
                options: UnblockingPlace.content,
                loading: UnblockingPlace.loading,
                mode: 'multiple',
                allowClear: true,
                ...select('unblockingPlaces'),
                disabled: value.deleted,
                labelProp: (val) =>
                  t(`entity.taxonomy.blacklistReason.blockingPlace.${val.id}`),
              }}
            />

            <Space style={{ paddingTop: '1rem' }}>
              {!value.isDefault && !deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditBlacklistReasonForm;
