import React from 'react';

import { Default } from '../../../../../layouts';

import { useEditPage } from '../../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../../services/taxonomies/transport/types';
import EditTransportTypeForm from '../../../../../components/taxonomies/transports/EditTransportTypeForm';

const initial = {};

const EditTransportType = () => {
  const [type, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/transport/types',

    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {type !== undefined && (
        <EditTransportTypeForm
          type={type}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditTransportType;
