import { search, get, post, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/offers';
export const AUTH_BASE_URI = '/api/v1/calendars';

export const getCalendars = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const deleteCalendar = async (calendar) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${calendar.id}`);

export const getCalendarById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createCalendar = async (calendar) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, calendar);

export const updateCalendar = async (calendar) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${calendar.id}`, calendar);
