import React, { useContext, useEffect, useMemo } from 'react';
import { Badge, Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useForm, { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import PosRetailersList from './PosRetailersList';
import PosSellersList from './PosSellersList';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';
import { PosTypes } from './constants';
import ShiftsList from './ShiftsList';
import { getRetailUsers } from '../../services/admin/users';
import AuthContext, { hasPermission } from '../auth';

const EditPosForm = ({ pos, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        address: yup.string().required(t('errors.notEmpty')),
        retailer: yup.number().required(t('errors.mustSelected')),
        type: yup.string().required(t('errors.mustSelected')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: pos,
    schema: validationSchema,
    validateOnChange: true,
    onSubmit,
    onCancel,
  });

  const {
    value,
    handleSubmit,
    input,
    setFormValue,
    select,
    setValue,
    errorMessages,
  } = form;

  useEffect(() => {
    setFormValue(pos);
  }, [setFormValue, pos]);

  useEffect(() => {
    getRetailUsers({
      pageSize: 1000,
      criterias: {
        retailerId: value.retailer,
        posId: value.id,
        pos: '',
      },
    }).then((res) =>
      setValue(
        'sellers',
        res?.content
          .filter(({ posId }) => posId === value.id)
          .map(({ id }) => id),
      ),
    );
  }, [value.id, setValue, value.retailer]);

  const { deleted } = value;

  return (
    <>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <PageTitle title={pos.name || t('entity.taxonomy.poses._singular')} />
          <FormWrapper>
            <FormWrapper.Single>
              {value?.id && (
                <FormWrapper.Input
                  label="ID"
                  props={{ ...input('id'), disabled: true }}
                />
              )}
              <FormWrapper.Input
                label={t('entity.taxonomy.poses.name')}
                props={{
                  ...input('name'),
                  disabled:
                    deleted ||
                    !hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'TAXONOMIES_PERMISSION',
                    ]),
                }}
              />

              <FormWrapper.Input
                label={t('entity.taxonomy.poses.address')}
                props={{
                  ...input('address'),
                  disabled:
                    deleted ||
                    !hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'TAXONOMIES_PERMISSION',
                    ]),
                }}
              />

              <FormWrapper.Input
                label={t('entity.taxonomy.poses.workstation')}
                props={{
                  ...input('workstation'),
                  disabled:
                    deleted ||
                    !hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'TAXONOMIES_PERMISSION',
                    ]),
                }}
              />

              <FormWrapper.Select
                label={t('entity.taxonomy.poses.type._')}
                props={{
                  options: PosTypes.content,
                  loading: PosTypes.loading,
                  disabled:
                    deleted ||
                    !hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'TAXONOMIES_PERMISSION',
                    ]),
                  ...select('type'),
                  labelProp: (val) => t(`entity.taxonomy.poses.type.${val.id}`),
                }}
              />

              <Space style={{ paddingTop: '1rem' }}>
                {!deleted &&
                  hasPermission(permissions, [
                    'SYS_SUPERUSER',
                    'TAXONOMIES_PERMISSION',
                  ]) && <SaveButton />}
                <CancelButton onCancel={onCancel} />
              </Space>
            </FormWrapper.Single>

            <FormWrapper.Double>
              <Tabs>
                {pos.id && (
                  <Tabs.TabPane
                    tab={t('pages.admin.taxonomies.poses.tabs.shifts._')}
                    key="shifts"
                  >
                    <ShiftsList posId={value.id} disable={value.deleted} />
                  </Tabs.TabPane>
                )}

                <Tabs.TabPane
                  style={{ position: 'static' }}
                  tab={
                    <span>
                      {t('pages.admin.taxonomies.poses.tabs.sellers')}
                      {errorMessages &&
                        Object.keys(errorMessages).filter(
                          (error) => error !== 'retailer',
                        ).length > 0 && (
                          <Badge
                            count={
                              errorMessages &&
                              Object.keys(errorMessages).filter(
                                (error) => error !== 'retailer',
                              ).length
                            }
                            style={{
                              position: 'absolute',
                              top: -25,
                              right: -20,
                            }}
                          />
                        )}
                    </span>
                  }
                  key="sellers"
                >
                  <PosSellersList />
                </Tabs.TabPane>
                <Tabs.TabPane
                  style={{ position: 'static' }}
                  tab={
                    <span>
                      {t('pages.admin.taxonomies.poses.tabs.retailers')}
                      {errorMessages && errorMessages.retailer && (
                        <Badge
                          count={1}
                          style={{ position: 'absolute', top: -25, right: -20 }}
                        />
                      )}
                    </span>
                  }
                  key="retailers"
                >
                  <PosRetailersList />
                </Tabs.TabPane>
              </Tabs>
            </FormWrapper.Double>
          </FormWrapper>
        </form>
      </FormContext.Provider>
    </>
  );
};
export default EditPosForm;
