import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import useDatasource from '../../hooks/useDatasource';

import Column from '../../helpers/columns';
import { findSystemParametersByCode } from '../../services/taxonomies/system-parameters';
import {
  downloadOnlyValidatorsData,
  getValidatorsData,
} from '../../services/validators/validators-status';
import ExportEntitiesButton from '../../components/buttons/ExportEntitiesButton';

const ValidatorsStatuses = () => {
  const { t } = useTranslation();

  const fetcher = useCallback(
    (data) =>
      getValidatorsData({
        ...data,
        criterias: {
          ...data.criterias,
          equipmentType: 'VALIDATOR',
        },
      }),
    [],
  );

  const downloadFetcher = useCallback(
    (data, spec) => downloadOnlyValidatorsData(data, spec),
    [],
  );

  const getData = useCallback(
    (params) =>
      fetcher(params).then(async (data) => {
        return data;
      }),
    [fetcher],
  );

  const {
    loading,
    pagination,
    content,
    handleChange,
    reload,
    criterias,
    sort,
  } = useDatasource(getData);

  const [refreshTimeSysParams, setRefreshTimeSysParams] = useState(null);

  const refTime = useRef(Date.now());
  const [alerTrigger, setAlerTrigger] = useState(0);

  useEffect(() => {
    findSystemParametersByCode('EVENT_CHECKER').then((res) =>
      setRefreshTimeSysParams(res),
    );
  }, []);

  const refreshRate = useMemo(() => {
    if (refreshTimeSysParams !== null && refreshTimeSysParams !== undefined) {
      const refreshTime = refreshTimeSysParams.value;
      return refreshTime !== null ? refreshTime * 1000 : null;
    }
    return null;
  }, [refreshTimeSysParams]);

  useEffect(() => {
    const intervalId = setInterval(
      () => setAlerTrigger((val) => val + 1),
      refreshRate + 1,
    );
    return () => {
      clearInterval(intervalId);
    };
  }, [setAlerTrigger, refreshRate]);

  useEffect(() => {
    if (content != null && !loading) {
      if (Date.now() - refTime.current > refreshRate) {
        refTime.current = Date.now();
        reload();
      }
    }
  }, [alerTrigger, refreshRate, reload, loading, content]);

  const columns = useMemo(
    () => [
      {
        ...Column.text(
          'serialNr',
          t('pages.validators_dashboard.tabs.columns.serialNr'),
          {
            width: 200,
            filter: true,
            fixed: 'left',
          },
        ),
        className: 'red',
      },
      Column.text(
        'vehicleNumber',
        t('pages.validators_dashboard.tabs.columns.vehicleNumber'),
        {
          width: 200,
          filter: true,
        },
      ),
      Column.text(
        'plateNumber',
        t('pages.validators_dashboard.tabs.columns.plateNumber'),
        {
          width: 200,
          filter: true,
        },
      ),
      Column.text(
        'validatorPosition',
        t('pages.validators_dashboard.tabs.columns.validatorPosition'),
        { width: 200, filter: true },
      ),
      Column.date(
        'lastConnectionAt',
        t('pages.validators_dashboard.tabs.columns.lastConnectionAt'),
        {
          width: 180,
          filter: true,
        },
      ),
      Column.text(
        'status',
        t('pages.validators_dashboard.tabs.columns.status'),
        {
          width: 160,
          filter: true,
        },
      ),
      Column.bool(
        'isOnline',
        t('pages.validators_dashboard.tabs.columns.isOnline'),
        {
          width: 180,
          // filter: true,
        },
      ),
      Column.text(
        'cdVersion',
        t('pages.validators_dashboard.tabs.columns.cdVersion'),
        {
          width: 160,
          filter: true,
        },
      ),
      Column.text(
        'lineVersion',
        t('pages.validators_dashboard.tabs.columns.lineVersion'),
        {
          width: 160,
          filter: true,
        },
      ),
      Column.text(
        'whiteListVersion',
        t('pages.validators_dashboard.tabs.columns.whiteListVersion'),
        {
          width: 160,
          filter: true,
        },
      ),
      Column.text(
        'softwareVersion',
        t('pages.validators_dashboard.tabs.columns.softwareVersion'),
        {
          width: 160,
          filter: true,
        },
      ),
      Column.text('mid', t('pages.validators_dashboard.tabs.columns.mid'), {
        width: 160,
        filter: true,
      }),
      Column.text('tid', t('pages.validators_dashboard.tabs.columns.tid'), {
        width: 160,
        filter: true,
      }),
      Column.bool(
        'hasEmvKeys',
        t('pages.validators_dashboard.tabs.columns.hasEmvKeys'),
        {
          width: 180,
          // filter: true,
        },
      ),
      Column.text(
        'gpsLatitude',
        t('pages.validators_dashboard.tabs.columns.gpsLatitude'),
        {
          width: 160,
          filter: true,
        },
      ),
      Column.text(
        'gpsLongitude',
        t('pages.validators_dashboard.tabs.columns.gpsLongitude'),
        {
          width: 160,
          filter: true,
        },
      ),
    ],
    [t],
  );

  return (
    <>
      <ExportEntitiesButton
        columns={columns}
        data={{
          sort,
          criterias,
        }}
        downloadFiles={downloadFetcher}
      />
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ y: 550 }}
        style={{ marginTop: 10 }}
      />
    </>
  );
};

export default ValidatorsStatuses;
