import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { ORDER_STATUS } from '../../pages/orders/constants';
import { useRouteParams } from '../../hooks/useRouteParams';
import AuthContext, { hasPermission } from '../auth';

const DisablingOrderButtons = ({ form, formRef }) => {
  const { t } = useTranslation();
  const { isNew } = useRouteParams();

  const {
    user: { permissions },
  } = useContext(AuthContext);

  const { status } = form.value;

  const submitWithAction = useCallback(
    (action) => {
      form.setValue('action', action);
      formRef.current.dispatchEvent(new Event('submit'));
    },
    [form, formRef],
  );

  return (
    <Space>
      {status === ORDER_STATUS.SELECT_PRODUCTS &&
        hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_VIEW']) && (
          <Button
            className="ant-btn-success"
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
            onClick={() => submitWithAction(ORDER_STATUS.CONFIRMED)}
          >
            {t('actions.orders.finish')}
          </Button>
        )}

      {![ORDER_STATUS.REJECTED, ORDER_STATUS.CONFIRMED].includes(status) &&
        !isNew && (
          <Button
            type="danger"
            htmlType="submit"
            onClick={() => submitWithAction(ORDER_STATUS.REJECTED)}
            icon={<CloseOutlined />}
          >
            {t('actions.reject')}
          </Button>
        )}
    </Space>
  );
};

export default DisablingOrderButtons;
