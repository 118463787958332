import React from 'react';
import notFoundImage from '../assets/404-not-found.png';
import { Empty } from '../layouts';

const NotFound = () => (
  <Empty>
    <div style={{ textAlign: 'center' }}>
      <img src={notFoundImage} alt="Page not found" />
    </div>
  </Empty>
);

export default NotFound;
