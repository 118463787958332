import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/holidays';

export const getHolidays = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/search`, data);

export const getHolidayById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createHoliday = async (holiday) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, holiday);

export const updateHoliday = async (holiday) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${holiday.id}`, holiday);

export const deleteHoliday = async (holiday) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${holiday.id}`);
