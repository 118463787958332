import React, { useEffect, useMemo } from 'react';

import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import PageTitle from '../../PageTitle';
import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';
import useForm from '../../../hooks/useForm';
import { VALIDATOR_PARAMETER_TYPE, ValidatorParameterTypes } from './constants';

const EditParameterForm = ({ parameter, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        idx: yup.number().required(t('errors.notEmpty')),
        name: yup.string().required(t('errors.notEmpty')),
        type: yup
          .string()
          .oneOf(Object.values(VALIDATOR_PARAMETER_TYPE))
          .required(t('errors.mustSelected')),
        value: yup
          .mixed()
          .required(t('errors.notEmpty'))
          .when('type', {
            is: VALIDATOR_PARAMETER_TYPE.UBYTE,
            then: yup
              .number()
              .required(t('errors.notEmpty'))
              .min(0, t('errors.number.minLength', { min: 0 }))
              .max(255, t('errors.number.maxLength', { max: 255 })),
          })
          .when('type', {
            is: (type) => type === VALIDATOR_PARAMETER_TYPE.UWORD,
            then: yup
              .number()
              .required(t('errors.notEmpty'))
              .min(0, t('errors.number.minLength', { min: 0 }))
              .max(65535, t('errors.number.maxLength', { max: 65535 })),
          })
          .when('type', {
            is: VALIDATOR_PARAMETER_TYPE.UDWORD,
            then: yup
              .number()
              .required(t('errors.notEmpty'))
              .min(0, t('errors.number.minLength', { min: 0 }))
              .max(
                4294967295,
                t('errors.number.maxLength', { max: 4294967295 }),
              ),
          })
          .when('type', {
            is: (type) =>
              [
                VALIDATOR_PARAMETER_TYPE.UBYTE_6,
                VALIDATOR_PARAMETER_TYPE.UBYTE_8,
                VALIDATOR_PARAMETER_TYPE.UBYTE_10,
              ].includes(type),
            then: yup.string().test('length', (value, context) => {
              const { parent } = context;
              const { type } = parent;
              if (type && type.match(/\d+/)) {
                const lengthLimit = parseInt(type.match(/\d+/)[0], 10) - 1;

                if (value && value.length > lengthLimit) {
                  return context.createError({
                    message: t('errors.maxLength', { max: lengthLimit }),
                  });
                }
              }
              return true;
            }),
          }),
      }),
    [t],
  );

  const { value, handleSubmit, input, textarea, custom, select, setFormValue } =
    useForm({
      initialValue: parameter,
      schema: validationSchema,
      onSubmit,
      onCancel,
    });

  useEffect(() => {
    setFormValue(parameter);
  }, [setFormValue, parameter]);

  const { deleted, type } = value;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={
            parameter.name ||
            t('entity.taxonomy.validators.parameters._singular')
          }
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.validators.parameters.idx')}
              props={{ ...input('idx'), disabled: value.id }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.validators.parameters.name')}
              props={{ ...input('name'), disabled: value.id }}
            />

            <FormWrapper.Select
              label={t('entity.taxonomy.validators.parameters.type')}
              props={{
                disabled: value.id,
                options: ValidatorParameterTypes.content,
                loading: ValidatorParameterTypes.loading,
                labelProp: (option) => option.id,
                ...select('type'),
              }}
            />
          </FormWrapper.Single>
          <FormWrapper.Double>
            {![
              VALIDATOR_PARAMETER_TYPE.UBYTE_MASK_SERVICE_STATE,
              VALIDATOR_PARAMETER_TYPE.UBYTE_MASK_DAYS,
            ].includes(type) && (
              <FormWrapper.Input
                label={t('entity.taxonomy.validators.parameters.value')}
                props={{ ...input('value'), disabled: deleted }}
              />
            )}
            {[
              VALIDATOR_PARAMETER_TYPE.UBYTE_MASK_SERVICE_STATE,
              VALIDATOR_PARAMETER_TYPE.UBYTE_MASK_DAYS,
            ].includes(type) && (
              <FormWrapper.BitMaskCheckBoxes
                key="value"
                maskType={{ type }}
                props={{ ...custom('value') }}
              />
            )}

            <FormWrapper.TextArea
              label={t('entity.taxonomy.validators.parameters.description')}
              props={{ ...textarea('description', true) }}
            />
          </FormWrapper.Double>

          <Space style={{ padding: '1.5rem' }}>
            {!deleted && <SaveButton disabled={!value.id} />}
            <CancelButton onCancel={onCancel} />
          </Space>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditParameterForm;
