import { get, post, put, remove, search } from '../../helpers/api';

export const SERVICE_URI = '/taxonomies';
export const BASE_URI = '/api/v1/system-parameters';

export const findSystemParametersByGroupCode = async (groupCode) =>
  get(`${SERVICE_URI}${BASE_URI}/findByGroupCode/${groupCode}`);

export const findSystemParametersByCode = async (groupCode) =>
  get(`${SERVICE_URI}${BASE_URI}/findByCode/${groupCode}`);

export const searchSystemParameters = async (params) => {
  const paramList = params;
  paramList.criterias = { ...paramList.criterias, deleted: false };
  return search(`${SERVICE_URI}${BASE_URI}`, paramList);
};
export const getSystemParameterById = async (id) =>
  get(`${SERVICE_URI}${BASE_URI}/${id}`);

export const createSystemParameter = async (param) =>
  post(`${SERVICE_URI}${BASE_URI}/create`, param);

export const updateSystemParameter = async (param) =>
  put(`${SERVICE_URI}${BASE_URI}/${param.id}/update`, param);

export const softDeleteSystemParameter = async (id) =>
  remove(`${SERVICE_URI}${BASE_URI}/${id}`);
