import React, { useEffect, useMemo, useState } from 'react';

import { Button, message, Tooltip, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { postFiles } from '../helpers/api';
import {
  SERVICE_URI as FILE_SERVICE_URI,
  BASE_URI as FILE_BASE_URI,
} from '../services/files';

import './FileUploader.scss';

const FileUploader = ({
  uploadUrl = `${FILE_SERVICE_URI}${FILE_BASE_URI}`,
  text = '',
  multiple = true,
  dragger = true,
  tooltip,
  accept,
  disabled,
  onUploadSuccess = () => null,
  onUploadFailed = () => null,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState({});

  useEffect(() => {
    const filesForUpload = Object.keys(files)
      .filter((uid) => !files[uid].uploaded)
      .map((uid) => files[uid].file);

    if (filesForUpload.length > 0) {
      setFiles(
        Object.keys(files).reduce(
          (acc, uid) => ({ ...acc, [uid]: { ...files[uid], uploaded: true } }),
          {},
        ),
      );
      postFiles(uploadUrl, filesForUpload)
        .then((res) => res.json())
        .then((res) => {
          onUploadSuccess(res, filesForUpload[0]);
        })
        .catch(onUploadFailed);
    }
  }, [files, onUploadFailed, onUploadSuccess, uploadUrl]);

  const props = useMemo(
    () => ({
      multiple,
      disabled,
      accept,
      showUploadList: false,
      customRequest: () => null,
      beforeUpload: (...[, filesBeforeUpload]) => {
        let isFileSizeOver = false;
        const convertBytesToMB = (bytes) => bytes / (1024 * 1024);
        const maxSize = process.env.MAX_FILE_SIZE || 5;
        filesBeforeUpload.forEach((file) => {
          if (convertBytesToMB(file.size) > maxSize) {
            isFileSizeOver = true;
            message.error({
              content: t('errors.maxFileSizeReached', {
                fileName: file.name,
                maxSize,
              }),
              duration: 2.5,
              className: 'card-message',
            });
          }
        });
        if (isFileSizeOver) {
          return;
        }

        setFiles((existingFiles) => ({
          ...existingFiles,
          ...filesBeforeUpload.reduce(
            (acc, cur) => ({
              ...acc,
              ...(cur.uid && !(cur.uid in existingFiles)
                ? { [cur.uid]: { file: cur, uploaded: false } }
                : null),
            }),
            {},
          ),
        }));
      },
    }),
    [accept, disabled, multiple, t],
  );

  return dragger ? (
    <div style={{ height: '80px' }}>
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">{text}</p>
      </Upload.Dragger>
    </div>
  ) : (
    <Tooltip title={tooltip}>
      <Upload {...props}>
        <Button icon={<UploadOutlined />}>{text}</Button>
      </Upload>
    </Tooltip>
  );
};

export default FileUploader;
