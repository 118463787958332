import React from 'react';
import POSApplication from '../../components/pos2/POSApplication';
import { Clean } from '../../layouts';

import '../../pos.scss';

const POS = () => (
  <Clean>
    <POSApplication />
  </Clean>
);

export default POS;
