import React from 'react';

import { DatePicker } from 'antd';

import 'moment/locale/de';
import locale from 'antd/es/date-picker/locale/de_DE';

const RangePickerGerman = (props) => (
  <DatePicker.RangePicker locale={locale} {...props} />
);
export default RangePickerGerman;
