import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Space } from 'antd';
import useForm from '../../../hooks/useForm';
import useDictionaries from '../../../hooks/useDictionaries';

import { findAll as getVehicleTypes } from '../../../services/taxonomies/transport/types';
import { findAll as getVehicleBrands } from '../../../services/taxonomies/transport/brands';
import { findAll as getVehicleDepos } from '../../../services/taxonomies/transport/depos';
import { findAll as getVehicleCostCenters } from '../../../services/taxonomies/transport/cost-centers';
import { getRoutes } from '../../../services/taxonomies/routes';

import PageTitle from '../../PageTitle';
import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';

const dictionaries = {
  types: getVehicleTypes,
  brands: getVehicleBrands,
  depos: getVehicleDepos,
  costCenters: getVehicleCostCenters,
  routes: getRoutes,
};

const EditTransportUnitForm = ({ transportUnit, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const { handleSubmit, value, input, select, custom, setFormValue } = useForm({
    initialValue: transportUnit,
    onSubmit,
    onCancel,
  });

  useEffect(() => {
    setFormValue(transportUnit);
  }, [setFormValue, transportUnit]);

  const [{ types, brands, depos, costCenters, routes }] =
    useDictionaries(dictionaries);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={
            transportUnit.plate || t('entity.taxonomy.transportUnit._singular')
          }
        />
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Select
              label={t('entity.taxonomy.transportUnit.type')}
              props={{
                ...select('type'),
                loading: types.loading,
                options: types.content,
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.transportUnit.brand')}
              props={{
                ...select('brand'),
                loading: brands.loading,
                options: brands.content,
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.transportUnit.inventoryNumber')}
              props={{ ...input('inventoryNumber') }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.transportUnit.serialNumber')}
              props={{ ...input('serialNumber') }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.transportUnit.plateNumber')}
              props={{ ...input('plateNumber') }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.transportUnit.auxNumber')}
              props={{ ...input('auxNumber') }}
            />
          </FormWrapper.Single>
          <FormWrapper.Single>
            <FormWrapper.Select
              label={t('entity.taxonomy.transportUnit.depo')}
              props={{
                ...select('depo'),
                loading: depos.loading,
                options: depos.content,
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.transportUnit.costCenter')}
              props={{
                ...select('costCenter'),
                loading: costCenters.loading,
                options: costCenters.content,
              }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.transportUnit.warranty')}
              props={{ ...input('warranty') }}
            />

            <FormWrapper.DateTimePicker
              label={t('entity.taxonomy.transportUnit.depoStartDate')}
              props={{ ...custom('depoStartDate') }}
            />

            <FormWrapper.DateTimePicker
              label={t('entity.taxonomy.transportUnit.usageStartDate')}
              props={{ ...custom('usageStartDate') }}
            />

            <FormWrapper.Select
              label={t('entity.taxonomy.transportUnit.routes')}
              props={{
                ...select('route'),
                loading: routes.loading,
                options: routes.content,
              }}
            />
          </FormWrapper.Single>
        </FormWrapper>
        <Space style={{ paddingTop: '1rem' }}>
          <SaveButton disabled={value.deleted} />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </>
  );
};
export default EditTransportUnitForm;
