import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import TravelersComparationList from '../../../components/travelers/TravelersComparationList';

const Travelers = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.travelers.list.title')} />
      <TravelersComparationList />
    </Default>
  );
};

export default Travelers;
