import React from 'react';

import { Default } from '../../../layouts';

import { useEditPage } from '../../../hooks/useEditPage';
import EditRoleForm from '../../../components/roles/EditRoleForm';

import {
  createRole,
  getRoleById,
  updateRole,
} from '../../../services/admin/roles';

const initialRole = {};

const EditRole = () => {
  const [role, handleSubmit, handleCancel] = useEditPage({
    initial: initialRole,
    existent: getRoleById,
    goBackPath: '/admin/roles',
    onCreate: createRole,
    onUpdate: updateRole,
  });

  return (
    <Default>
      {role !== undefined && (
        <EditRoleForm
          role={role}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditRole;
