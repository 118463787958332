import { Table } from 'antd';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';
import { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import { getRetailPoses } from '../../services/taxonomies/poses';

const RetailPosList = () => {
  const form = useContext(FormContext);
  const { t } = useTranslation();

  const { value, setValue, errorMessages } = form;
  const { posId, retailerId } = value;

  const columns = useMemo(
    () => [
      Column.text('name', t('pages.retail.name'), { filter: true }),
      Column.text('address', t('pages.retail.address')),
      Column.text('retailerName', t('pages.retail._retailer'), {
        sort: false,
      }),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys) => setValue('posId', ...selectedRowKeys),
      selectedRowKeys: [posId],
      getCheckboxProps: ({ retailer, deleted }) => ({
        disabled: !retailer || retailer !== retailerId || deleted,
      }),
    }),
    [posId, setValue, retailerId],
  );

  const { pagination, content, handleChange } = useDatasource((args) => {
    return getRetailPoses({
      ...args,
      criterias: {
        ...args.criterias,
        retailerId,
        nullable: true,
      },
    });
  });

  const poses = useMemo(
    () =>
      content.length
        ? [{ id: null, name: 'NONE', retailer: retailerId }, ...content]
        : [],
    [content, retailerId],
  );

  return (
    <>
      <FormWrapper.ErrorMessage message={errorMessages?.retailer} />
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={poses}
        onChange={handleChange}
      />
    </>
  );
};

export default RetailPosList;
