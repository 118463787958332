import {
  search,
  post,
  get,
  put,
  remove,
  download,
  genericCRUDService,
} from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/poses';

export const getPoses = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const downloadPoses = async (data) => {
  const url = `${AUTH_SERVICE_URI}${AUTH_BASE_URI}`;
  const payload = {
    ...data,
    criterias: {
      fields: ['id', 'name', 'address', 'deleted'],
      ...data.criterias,
    },
    sort: data.sort,
  };

  return download(url, payload);
};

export const { downloadAll } = genericCRUDService(
  AUTH_SERVICE_URI,
  `${AUTH_BASE_URI}`,
);

export const downloadRetailPoses = async (data) => {
  const url = `${AUTH_SERVICE_URI}${AUTH_BASE_URI}`;
  const payload = {
    ...data,
    criterias: {
      byrole: '',
      fields: ['id', 'name', 'address', 'deleted'],
      ...data.criterias,
    },
    sort: data.sort,
  };

  return download(url, payload);
};

export const getRetailPoses = async (data) => {
  return search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, {
    ...data,
    criterias: { ...data.criterias, list: '' },
  });
};

export const getPosesByRole = async (data) => {
  return search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, {
    ...data,
    criterias: { ...data.criterias, byrole: '' },
  });
};

export const getPosById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createPos = async (pos) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, pos);

export const updatePos = async (pos) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${pos.id}`, pos);

export const updatePosSeller = async ({ id, posId = null }) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/seller/${id}`, posId);

export const deletePos = async (pos) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${pos.id}`);

export const getPosesByRoleId = async () => {
  return search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, {
    criterias: { byrole: '' },
  });
};
