import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Row, Col, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const AddComment = ({ setValue = () => null }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');

  return (
    <div>
      <Row>
        <Col span={22}>
          <TextArea
            maxLength={9999}
            style={{
              'border-radius': '7px',
              resize: 'none',
              'box-shadow': '2px 2px #888888',
            }}
            autoSize
            spellcheck="false"
            placeholder={t('entity.tvm.burglary.writeComment')}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={!comment || comment.length === 0}
            style={{
              marginBlock: '10px',
              'border-radius': '7px',
            }}
            onClick={() => {
              setValue(comment);
              setComment('');
            }}
          >
            {t('entity.tvm.burglary.add')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default AddComment;
