import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

import { Default } from '../../../../layouts';
import useDatasource from '../../../../hooks/useDatasource';
import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';

import Column from '../../../../helpers/columns';
import {
  deleteCalendar,
  getCalendars,
} from '../../../../services/offers/calendars';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';

const CALENDARS_DICT = {
  content: [{ id: 'SCHOOL_YEAR_DYNAMIC' }, { id: 'ACADEMIC_YEAR_DYNAMIC' }],
  loading: false,
  error: false,
};

const CalendarsList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } = useDatasource((arg) =>
    getCalendars(arg),
  );

  const columns = useMemo(
    () => [
      Column.text(
        'id',
        t('entity.offers.calendars.list.id', { filter: true }),
        { filter: true },
      ),
      Column.text('name', t('entity.offers.calendars.list.name'), {
        filter: true,
      }),
      Column.dictionary(
        'calendarType',
        t('entity.offers.calendars.list.calendarType'),
        CALENDARS_DICT,
        {
          filter: true,
          dictLabel: (entry) =>
            t(`entity.offers.calendars.list.calendarTypes.${entry.id}`),
        },
      ),

      Column.date('startDate', t('entity.offers.calendars.list.startDate'), {
        filter: true,
      }),
      Column.date('endDate', t('entity.offers.calendars.list.endDate'), {
        filter: true,
      }),
      Column.bool('deleted', t('common.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('common.deleted.true'),
          t('common.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (calendar) => (
        <>
          <EditItemIcon path={`/admin/offers/calendars/${calendar.id}`} />
          <DeleteItemIcon
            title={t('entity.offers.schedule._delete', calendar)}
            message={t('entity.offers.schedule._deleted', calendar)}
            item={calendar}
            action={deleteCalendar}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.offers.calendars._')}>
        <AddEntityButton
          entity={t('entity.offers.calendars._singular')}
          path="/admin/offers/calendars/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default CalendarsList;
