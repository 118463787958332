import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Space } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/columns';
import {
  findAll,
  downloadAll,
} from '../../services/accounts/inspector-last-vehicles';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';
import PageTitle from '../PageTitle';

const InspectorLastVehiclesList = () => {
  const { t } = useTranslation();

  const { pagination, criterias, content, handleChange, sort } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text(
        'accountId',
        t('components.inspectors.lastVehicles.accountId'),
        { filter: true },
      ),
      Column.text(
        'transportTitleId',
        t('components.inspectors.lastVehicles.transportTitleId'),
        {
          filter: true,
        },
      ),
      Column.text(
        'vehicleNumber',
        t('components.inspectors.lastVehicles.vehicleNumber'),
        {
          filter: true,
        },
      ),
      Column.text(
        'trailerNumber',
        t('components.inspectors.lastVehicles.trailerNumber'),
        { filter: true },
      ),
      Column.text('routeId', t('components.inspectors.lastVehicles.routeId'), {
        filter: true,
      }),
      Column.bool(
        'direction',
        t('components.inspectors.lastVehicles.direction'),
        {
          filter: true,
          labels: [
            t('table.filter.all'),
            t('components.inspectors.lastVehicles.directionsDict.true'),
            t('components.inspectors.lastVehicles.directionsDict.false'),
            t('table.filter.unknown'),
          ],
        },
      ),
      Column.text('pto', t('components.inspectors.lastVehicles.pto'), {
        filter: true,
      }),
      {
        ...Column.date(
          'routeStartTime',
          t('components.inspectors.lastVehicles.routeStartTime'),
          { filter: true },
        ),
        ellipsis: true,
      },
      Column.text(
        'stationId',
        t('components.inspectors.lastVehicles.stationId'),
        { filter: true },
      ),
      Column.text('zoneId', t('components.inspectors.lastVehicles.zoneId'), {
        filter: true,
      }),
    ],
    [t],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="transportTitleId"
        columns={columns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
        size="small"
      />
    </>
  );
};

export default InspectorLastVehiclesList;
