import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Space } from 'antd';

import PageTitle from '../PageTitle';
import useDatasource from '../../hooks/useDatasource';
import {
  getActiveInspectors,
  downloadActiveInspectors,
} from '../../services/admin/users';
import Column from '../../helpers/columns';
import useDictionaries, { EMPTY_DICTIONARY } from '../../hooks/useDictionaries';
import { findAll } from '../../services/admin/sectors';
import AuthContext, { hasPermission } from '../auth';
import ViewItemIcon from '../buttons/ViewItemIcon';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';
import TransferOperatorCardButton from '../buttons/TransferOperatorCardButton';
import { getOperatorStatuses } from '../../services/accounts/accounts';

const InspectorsList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const { pagination, content, criterias, handleChange, reload, sort } =
    useDatasource(getActiveInspectors);

  const [operatorStatuses, setOperatorStatuses] = useState({});

  useEffect(() => {
    if (content.length > 0) {
      getOperatorStatuses(content.map((row) => row.id)).then(
        setOperatorStatuses,
      );
    }
  }, [content]);

  const dictionaries = useMemo(
    () =>
      hasPermission(permissions, ['SYS_SUPERUSER'])
        ? { sectors: findAll }
        : EMPTY_DICTIONARY,
    [permissions],
  );

  const [{ sectors }] = useDictionaries(dictionaries);

  const columns = useMemo(
    () => [
      Column.text('fullname', t('components.inspectors.list.fullname'), {
        sort: false,
        filter: true,
      }),
      Column.text(
        'identityCard',
        t('components.inspectors.list.identityCard'),
        {
          sort: false,
          filter: true,
        },
      ),
      Column.text(
        'employeeNumber',
        t('components.inspectors.list.employeeNumber'),
        {
          filter: true,
        },
      ),
      ...(hasPermission(permissions, ['SYS_SUPERUSER'])
        ? [
            Column.dictionary(
              'sectorId',
              t('components.inspectors.list.sector'),
              sectors,
              { filter: true },
            ),
          ]
        : []),
      Column.actions(t('table.actions'), (record) => {
        const inList = Object.keys(operatorStatuses).includes(
          record.id.toString(),
        );
        const active = operatorStatuses[record.id] === true;
        const inactive = operatorStatuses[record.id] === false;
        return (
          <>
            <TransferOperatorCardButton
              disabled={!inList || (inList && active)}
              userId={record.id}
              reload={reload}
              activate
            />
            <TransferOperatorCardButton
              disabled={!inList || (inList && inactive)}
              userId={record.id}
              reload={reload}
              activate={false}
            />
            <ViewItemIcon path={`/inspectors/${record.id}`} />
          </>
        );
      }),
    ],
    [operatorStatuses, permissions, reload, sectors, t],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadActiveInspectors}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="id"
        columns={columns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </>
  );
};

export default InspectorsList;
