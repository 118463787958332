import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';
import { getProducts } from '../../services/offers/products';
import { getTVARates } from '../../services/taxonomies/tva-rate';
import useDictionaries from '../../hooks/useDictionaries';
import { NominalTypes } from './OffersConstants';

const dictionaries = {
  tvaRates: getTVARates,
};

export const getProductsWithCurrentPrice = (products) =>
  products.map((row) => ({
    ...row,
    price:
      row.prices
        .map((productPrice) => ({
          ...productPrice,
          dateStart: new Date(productPrice.dateStart),
        }))
        .filter((productPrice) => productPrice.dateStart < new Date())
        .sort((a, b) => b.dateStart - a.dateStart)?.[0]?.price || 0,
  }));

const ProductsGrid = ({
  showDiscountPrice = false,
  limitPasses = false,
  limitSearchToPassAndTicket = false,
}) => {
  const { t } = useTranslation();

  const form = useContext(FormContext);

  const [{ tvaRates }] = useDictionaries(dictionaries);

  const { value, setValue, input, errorMessages } = form;

  const { products } = value;

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.offers.product.name._')),
      Column.text('price', t('entity.offers.product.price')),
      ...(showDiscountPrice
        ? [
            {
              title: t('entity.offers.product.discountPrice'),
              key: 'discountPrice',
              dataIndex: 'discountPrice',
              width: 200,
              render: (_, record) => {
                const { id: productId } = record;
                const index = products.findIndex(
                  (existent) => existent.productId === productId,
                );
                return (
                  <>
                    {index !== -1 && (
                      <FormWrapper.Number
                        props={{
                          ...input(`products[${index}].discountPrice`),
                          min: 0,
                          step: 100,
                          size: 'small',
                        }}
                      />
                    )}
                  </>
                );
              },
            },
          ]
        : []),
      Column.dictionary(
        'vatRateId',
        t('entity.offers.product.vatRate'),
        tvaRates,
        {
          dictLabel: (entry) => `${entry.tvaRate} %`,
        },
      ),
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
        render: (_, record) => {
          const { id: productId, category } = record;
          const index = products.findIndex(
            (existent) => existent.productId === productId,
          );
          return (
            <>
              {index !== -1 && (
                <FormWrapper.Number
                  props={{
                    ...input(`products[${index}].quantity`),
                    min: '1',
                    max: category === 'PASS' && limitPasses ? '1' : undefined,
                    step: '1',
                    size: 'small',
                  }}
                />
              )}
            </>
          );
        },
      },
      Column.text('unit', t('entity.offers.product.unit')),
      Column.dictionary(
        'nominalType',
        t('entity.offers.product.nominalType._'),
        NominalTypes,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`entity.offers.product.nominalType.${entry.id}`),
        },
      ),
    ],
    [t, showDiscountPrice, tvaRates, products, input, limitPasses],
  );

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: products.map((val) => val.productId),
      onSelect: (record, selected) =>
        selected
          ? setValue('products', [
              ...products,
              products.find((existent) => existent.productId === record.id) || {
                productId: record.id,
                quantity: 1,
                discountPrice: record.price,
                price: record.price,
              },
            ])
          : setValue(
              'products',
              products.filter((product) => product.productId !== record.id),
            ),
      getCheckboxProps: ({ deleted, id }) => ({
        disabled: deleted && !products.some((pr) => pr.productId === id),
      }),
    }),
    [products, setValue],
  );

  const fetchProducts = (params) => {
    const processedParams = { ...params };

    if (limitSearchToPassAndTicket) {
      // Ensure criterias object exists
      if (!processedParams.criterias) {
        processedParams.criterias = {};
      }

      // Ensure category array exists within criterias
      if (!Array.isArray(processedParams.criterias.category)) {
        processedParams.criterias.category = [];
      }

      // Append the new categories, avoiding duplicates
      processedParams.criterias.category = [
        ...new Set([
          ...processedParams.criterias.category,
          ...['PASS', 'TICKET'],
        ]),
      ];
    }

    return getProducts(processedParams);
  };
  const { pagination, content, handleChange } = useDatasource((params) =>
    fetchProducts(params),
  );

  const processedContent = useMemo(
    () => getProductsWithCurrentPrice(content),
    [content],
  );

  return (
    <>
      <FormWrapper.ErrorMessage message={errorMessages?.products} />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={processedContent}
        onChange={handleChange}
      />
    </>
  );
};

export default ProductsGrid;
