import React, { useCallback, useContext, useMemo } from 'react';
import { Table, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Column from '../../helpers/columns';
import { FormContext } from '../../hooks/useForm';
import {
  enableCard,
  disableCard,
  getCardsByAccountId,
} from '../../services/accounts/accounts';
import useDatasource from '../../hooks/useDatasource';
import FormWrapper from '../FormWrapper';

import { findAll } from '../../services/taxonomies/blacklist-reason';
import useDictionaries from '../../hooks/useDictionaries';
import {
  BlockingPlaces,
  UnblockingPlaces,
} from '../blacklist-reasons/constants';
import AuthContext, { hasPermission } from '../auth';

const CARD_CATEGORIES = {
  content: [
    { id: 1, name: 'UID' },
    { id: 2, name: 'PAN' },
    { id: 4, name: 'QR' },
  ],
};

const dictionaries = {
  blacklistReasons: findAll,
};

const CardsList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const form = useContext(FormContext);
  const { value } = form;
  const { id } = value;
  const [{ blacklistReasons }] = useDictionaries(dictionaries);
  const { pagination, content, handleChange, reload } = useDatasource((arg) =>
    getCardsByAccountId(id, arg),
  );

  const changeBlacklistReasonIdAndStatus = useCallback(
    (blReasonId, val) => {
      if (blReasonId || blReasonId === 0) {
        disableCard(id, val.id, blReasonId).then(reload);
      } else {
        enableCard(id, val.id).then(reload);
      }
    },
    [id, reload],
  );

  const columns = useMemo(
    () => [
      Column.label('logicIdSerial', t('entity.account.cards.serialNr'), {
        width: 100,
      }),
      Column.label('physicalId', t('entity.account.cards.physicalId'), {
        width: 165,
      }),
      Column.dictionary(
        'cardCategory',
        t('entity.account.cards.cardCategory'),
        CARD_CATEGORIES,
      ),
      Column.bool('deleted', t('entity.account.cards.status._'), {
        width: 100,
        inverted: true,
        sort: false,
        labels: [
          t('table.filter.all'),
          t('entity.account.cards.status.true'),
          t('entity.account.cards.status.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('entity.account.cards.blacklistReason'), (val) => (
        <FormWrapper.FieldWrapper>
          <Select
            value={val.blacklistReason}
            allowClear={(
              blacklistReasons.content.find(
                (item) => item.id === val.blacklistReason,
              )?.unblockingPlaces || []
            ).includes(UnblockingPlaces.BACK_OFFICE)}
            onChange={(blReasonId) =>
              changeBlacklistReasonIdAndStatus(blReasonId, val)
            }
            disabled={
              !hasPermission(permissions, [
                'SYS_SUPERUSER',
                'ADMINISTRATION_ACCOUNTS_PERMISSION',
              ])
            }
          >
            {blacklistReasons.content
              .filter(
                (option) =>
                  option.id === val.blacklistReason ||
                  (option.blockingPlaces &&
                    option.blockingPlaces.includes(BlockingPlaces.BACK_OFFICE)),
              )
              .map((option) => (
                <Select.Option
                  value={option.id}
                  key={option.id}
                  disabled={option.disabled || option.deleted}
                  hidden={
                    !(
                      option.blockingPlaces &&
                      option.blockingPlaces.includes(BlockingPlaces.BACK_OFFICE)
                    )
                  }
                >
                  {option.name}
                </Select.Option>
              ))}
          </Select>
        </FormWrapper.FieldWrapper>
      )),
    ],
    [
      t,
      blacklistReasons.content,
      permissions,
      changeBlacklistReasonIdAndStatus,
    ],
  );

  const contentWithProdType = useMemo(
    () =>
      content.map((title) => ({
        ...title,
        prodTypeName: t(
          `entity.account.transportTitle.product_types.${title.productType}`,
        ),
      })),

    [content, t],
  );

  return (
    <Table
      columns={columns}
      rowKey="id"
      // loading={loading}
      pagination={pagination}
      dataSource={contentWithProdType}
      onChange={handleChange}
      scroll={{ x: 700, y: 700 }}
    />
  );
};
export default CardsList;
