import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/tva-rates';

export const getTVARates = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/search`, data);

export const getTVARateDescription = async (description) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, description);

export const getTVARateById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createTVARate = async (tvaRate) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, tvaRate);

export const updateTVARate = async (tvaRate) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${tvaRate.id}`, tvaRate);

export const deleteTVARate = async (tvaRate) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${tvaRate.id}`);
