import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditUserTypeForm from '../../../../components/user-types/EditUserTypeForm';

import {
  createUserType,
  getUserTypeById,
  updateUserType,
} from '../../../../services/taxonomies/user-types';

const initialUserType = { deleted: false, docTypes: [], showColumns: [] };

const EditUserType = () => {
  const [userType, handleSubmit, handleCancel] = useEditPage({
    initial: initialUserType,
    existent: getUserTypeById,
    goBackPath: '/admin/taxonomies/user-types',

    onCreate: createUserType,
    onUpdate: updateUserType,
  });

  return (
    <Default>
      {userType !== undefined && (
        <EditUserTypeForm
          userType={userType}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditUserType;
