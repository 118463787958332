import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';

import useDatasource from '../../../hooks/useDatasource';
import { getProducts } from '../../../services/offers/products';

const ProductList = ({ value, onChange }) => {
  const { content } = useDatasource(
    (arg) =>
      getProducts({ ...arg, criterias: { ...arg.criterias, deleted: false } }),
    {
      pagination: { pageSize: 10000 },
    },
  );

  const internalValue = useMemo(() => {
    return (value || '')
      .split(',')
      .map((v) => parseInt(v, 10))
      .filter(Number.isInteger);
  }, [value]);

  const handleChange = useCallback(
    (val) => {
      onChange(Array.isArray(val) ? val.join(',') : '');
    },
    [onChange],
  );

  return (
    <Select
      mode="multiple"
      allowClear
      value={internalValue}
      onChange={handleChange}
      showSearch
      optionFilterProp="children"
    >
      {content.map(({ id, name }) => (
        <Select.Option key={id} value={id}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProductList;
