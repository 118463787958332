import {
  get,
  search,
  post,
  remove,
  put,
  genericCRUDService,
} from '../../helpers/api';

export const ACCOUNTS_SERVICE_URI = '/accounts';
export const ACCOUNTS_BASE_URI = '/api/v1/accounts';
export const CARDS_BASE_URI = '/api/v1/cards';
export const TRANSACTIONS_BASE_URI = '/api/v1/transactions';
export const OPERATOR_STATUSES_BASE_URI = '/api/v1/operator-statuses';

export const { downloadAll } = genericCRUDService(
  ACCOUNTS_SERVICE_URI,
  `${ACCOUNTS_BASE_URI}`,
);

export const getAccounts = async (data) =>
  search(`${ACCOUNTS_SERVICE_URI}${ACCOUNTS_BASE_URI}`, data);

export const getTransactions = (data) =>
  search(`${ACCOUNTS_SERVICE_URI}${TRANSACTIONS_BASE_URI}`, data);

export const getAccountById = async (id) =>
  get(`${ACCOUNTS_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}`);

export const getTitlesByParentId = async (id, data) =>
  search(
    `${ACCOUNTS_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}/children/titles/pageable`,
    data,
  );

export const getTitlesById = async (id, data) =>
  search(`${ACCOUNTS_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}/titles`, data);

export const getPeriodForTransaction = async (id, data) =>
  search(`${ACCOUNTS_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}/period`, data);

export const getCardsByAccountId = async (id, data) =>
  search(`${ACCOUNTS_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}/cards`, data);

export const enableCard = (accountId, cardId) =>
  post(
    `${ACCOUNTS_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/cards/${cardId}/enable`,
    cardId,
  );

export const disableCard = (accountId, cardId, blReasonId) =>
  remove(
    `${ACCOUNTS_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/cards/${cardId}/disable/${blReasonId}`,
  );

export const getBlacklist = async (data) =>
  search(`${ACCOUNTS_SERVICE_URI}/api/v1/blacklist`, data);

export const getBlacklistAccountById = async (id) =>
  get(`${ACCOUNTS_SERVICE_URI}/api/v1/blacklist/${id}`);

export const getCardDetails = async (cardPhysicalId) =>
  get(`${ACCOUNTS_SERVICE_URI}${CARDS_BASE_URI}/${cardPhysicalId}`);

export const getOperatorCardDetailsByUserId = async (userId) =>
  get(`${ACCOUNTS_SERVICE_URI}${CARDS_BASE_URI}/user/${userId}/operator`);

export const disableTitle = async (titleId, { dateType, reason }) =>
  put(`${ACCOUNTS_SERVICE_URI}/api/v1/transport-title/${titleId}/disable`, {
    dateType,
    reason,
  });

export const activateOperatorCard = async ({ physicalId, pin, userId }) =>
  put(`${ACCOUNTS_SERVICE_URI}${CARDS_BASE_URI}/operator/activate`, {
    physicalId,
    pin,
    userId,
  });

export const deactivateOperatorCard = async ({ physicalId, pin, userId }) =>
  put(`${ACCOUNTS_SERVICE_URI}${CARDS_BASE_URI}/operator/deactivate`, {
    physicalId,
    pin,
    userId,
  });

export const getOperatorStatuses = async (ids) =>
  get(
    `${ACCOUNTS_SERVICE_URI}${OPERATOR_STATUSES_BASE_URI}?${ids
      .map((id) => `id=${id}`)
      .join('&')}`,
  );

export const getCardsByUserIdentifierAndLogicalId = async ({
  userIdentifier,
  logicIdSerial,
}) =>
  put(`${ACCOUNTS_SERVICE_URI}${CARDS_BASE_URI}/get-cards`, {
    userIdentifier,
    logicIdSerial,
  });

export const updateDeletedStatus = async (accountId) =>
  remove(`${ACCOUNTS_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/deleted`);
