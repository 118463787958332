import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const CopyEntityIcon = ({ path }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Button
      title={t('actions.copy')}
      shape="circle"
      type="link"
      icon={<CopyOutlined />}
      onClick={() => history.push(path)}
    />
  );
};

export default CopyEntityIcon;
