import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Space } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import useDictionaries from '../../hooks/useDictionaries';
import Column from '../../helpers/columns';
import {
  findAll,
  downloadAll,
} from '../../services/validators/traveler-validations';
import { getProducts } from '../../services/offers/products';
import PageTitle from '../PageTitle';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';

const TYPES_DICT = {
  content: [
    { id: 'TRIP' },
    { id: 'PURSE' },
    { id: 'SUBSCRIPTION' },
    { id: 'TRANSFER' },
  ],
  loading: false,
  error: false,
};
const dictionaries = {
  products: getProducts,
};

const TravelerValidationsList = () => {
  const { t } = useTranslation();

  const { pagination, criterias, content, handleChange, sort } =
    useDatasource(findAll);

  const [{ products }] = useDictionaries(dictionaries);

  const columns = useMemo(
    () => [
      Column.text('userId', t('components.travelerValidations.userId'), {
        filter: true,
      }),
      Column.text('appId', t('components.travelerValidations.appId'), {
        filter: true,
      }),
      Column.dictionary(
        'appId',
        t('components.travelerValidations.application'),
        products,
        {
          width: 150,
          filter: true,
        },
      ),
      Column.dictionary(
        'validationType',
        t('components.travelerValidations.validationType'),
        TYPES_DICT,
        {
          filter: true,
          dictLabel: (entry) =>
            t(`components.travelerValidations.typesDict.${entry.id}`),
        },
      ),
      Column.text(
        'passengerCounter',
        t('components.travelerValidations.passengerCounter'),
        {
          filter: true,
        },
      ),
      {
        ...Column.date(
          'validationTime',
          t('components.travelerValidations.validationTime'),
          { filter: true },
        ),
        ellipsis: true,
      },
      Column.text('vehicleId', t('components.travelerValidations.vehicleId'), {
        filter: true,
      }),
      Column.text('routeId', t('components.travelerValidations.routeId'), {
        filter: true,
      }),
      Column.bool('direction', t('components.travelerValidations.direction'), {
        filter: true,
        labels: [
          t('table.filter.all'),
          t('components.travelerValidations.directionsDict.true'),
          t('components.travelerValidations.directionsDict.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.text('pto', t('components.travelerValidations.pto'), {
        filter: true,
      }),
      Column.text(
        'validatorPosition',
        t('components.travelerValidations.validatorPosition'),
        {
          filter: true,
        },
      ),
      Column.text('deviceSn', t('components.travelerValidations.deviceSn'), {
        filter: true,
      }),
      Column.bool(
        'button2status',
        t('components.travelerValidations.button2status'),
        {
          filter: true,
          labels: [
            t('table.filter.all'),
            t('components.travelerValidations.button2statusDict.true'),
            t('components.travelerValidations.button2statusDict.false'),
            t('table.filter.unknown'),
          ],
        },
      ),
      Column.text(
        'periodUseType',
        t('components.travelerValidations.periodUseType'),
        {
          filter: true,
        },
      ),
      Column.text(
        'periodUseLimit',
        t('components.travelerValidations.periodUseLimit'),
        {
          filter: true,
        },
      ),
      Column.text(
        'periodUseCounter',
        t('components.travelerValidations.periodUseCounter'),
        {
          filter: true,
        },
      ),
      Column.text('baseFare', t('components.travelerValidations.baseFare'), {
        filter: true,
      }),
      Column.text(
        'actualFare',
        t('components.travelerValidations.actualFare'),
        {
          filter: true,
        },
      ),
      Column.text('balance', t('components.travelerValidations.balance'), {
        filter: true,
      }),
      Column.text('tsn', t('components.travelerValidations.tsn'), {
        filter: true,
      }),
      Column.text('latitude', t('components.travelerValidations.latitude'), {
        filter: true,
      }),
      Column.text('longitude', t('components.travelerValidations.longitude'), {
        filter: true,
      }),
    ],
    [t, products],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="id"
        columns={columns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
        size="small"
      />
    </>
  );
};

export default TravelerValidationsList;
