import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Modal, notification, Select, Space, Table } from 'antd';
import moment from 'moment';

import FormItem from 'antd/es/form/FormItem';
import { EditOutlined, RollbackOutlined } from '@ant-design/icons';
import useDatasource, { filterDate } from '../../hooks/useDatasource';
import Column from '../../helpers/columns';

import {
  getSaleReport,
  downloadAll,
  changeInstitution,
} from '../../services/pos/pos';
import { getInstitutions } from '../../services/taxonomies/institutions';
import PageTitle from '../PageTitle';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';
import useDictionaries from '../../hooks/useDictionaries';
import ResizableHeaderCell from '../../helpers/ResizableHeaderCell';
import { getSubdivisions } from '../../services/taxonomies/subdivisions';

const dictionaries = {
  institutions: () =>
    getInstitutions({ pageSize: 200, criterias: { deleted: 'false' } }),
};

const ProductCategory = {
  content: [
    { id: 'STOCABIL' },
    { id: 'OPERATOR' },
    { id: 'TICKET' },
    { id: 'PASS' },
    { id: 'PURSE' },
    { id: 'INTEGRATION' },
    { id: 'GROUP' },
    { id: 'OFFER' },
  ],
  loading: false,
  error: false,
};

const Status = {
  content: [
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
    { id: '6' },
    { id: '7' },
  ],
  loading: false,
  error: false,
};

const ActivationType = {
  content: [{ id: '0' }, { id: '1' }, { id: '2' }],
  loading: false,
  error: false,
};

const OrderType = {
  content: [{ id: 'NEW' }, { id: 'EXCHANGE' }, { id: 'EXISTENT' }],
  loading: false,
};
const SaleReportGrid = () => {
  const { t } = useTranslation();

  const [{ institutions }] = useDictionaries(dictionaries);
  const [subdivisions, setSubdivisions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [institutionForm] = Form.useForm();
  const { setFieldsValue } = institutionForm;

  const { pagination, sort, criterias, content, handleChange, reload } =
    useDatasource(getSaleReport);

  useEffect(() => {
    if (content?.[0]) {
      localStorage.setItem('julianStartDate', content[0].julianStartDate);
    }
  }, [content]);

  const getSubdivisionsByInstitutionId = useCallback((institutionId) => {
    if (institutionId) {
      return getSubdivisions({
        pageSize: 1000,
        criterias: { institutionId },
      }).then((res) => {
        return Promise.resolve(res.content);
      });
    }
    return [];
  }, []);

  const columns = useMemo(
    () => [
      Column.text('orderId', t('components.salesReport.orderId'), {
        filter: true,
      }),
      Column.date('updatedAt', t('components.salesReport.updatedAt'), {
        filter: true,
      }),
      Column.text('userFullname', t('components.salesReport.userFullname'), {
        filter: true,
      }),
      Column.text(
        'userIdentityCard',
        t('components.salesReport.userIdentityCard'),
        { filter: true },
      ),
      Column.text('cardLogicalId', t('components.salesReport.cardLogicalId'), {
        filter: true,
      }),
      Column.dictionary(
        'dateStatusA',
        t('components.salesReport.dateStatus.A'),
        ActivationType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.dateStatus.${entry.id}`),
        },
      ),
      {
        title: t('components.salesReport.valStaDateA'),
        key: 'valStartDateA',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valStaDateA') }),
        render: ({ dateStatusA, valStartDateA, julianStartDate }) => {
          if (dateStatusA !== 0) {
            return null;
          }
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + valStartDateA - 1);
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                valStartDateA ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      {
        title: t('components.salesReport.valEndDateA'),
        key: 'valEndDateA',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valEndDateA') }),
        render: ({ dateStatusA, valEndDateA, julianStartDate }) => {
          if (dateStatusA !== 0) {
            return null;
          }
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + valEndDateA - 1);
          // eslint-disable-next-line react/destructuring-assignment
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                valEndDateA ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      Column.dictionary(
        'dateStatusB',
        t('components.salesReport.dateStatus.B'),
        ActivationType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.dateStatus.${entry.id}`),
        },
      ),
      {
        title: t('components.salesReport.valStaDateB'),
        key: 'valStartDateB',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valStaDateB') }),
        render: ({ dateStatusB, valStartDateB, julianStartDate }) => {
          if (dateStatusB !== 0) {
            return null;
          }
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + valStartDateB - 1);
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                valStartDateB ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      {
        title: t('components.salesReport.valEndDateB'),
        key: 'valEndDateB',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valEndDateB') }),
        render: ({ dateStatusB, valEndDateB, julianStartDate }) => {
          if (dateStatusB !== 0) {
            return null;
          }
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + valEndDateB - 1);
          // eslint-disable-next-line react/destructuring-assignment
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                valEndDateB ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      Column.text('userEmail', t('components.salesReport.userEmail'), {
        filter: true,
      }),
      Column.text('posId', t('components.salesReport.posId'), { filter: true }),
      Column.date('startedAt', t('components.salesReport.startedAt'), {
        filter: true,
      }),
      Column.text(
        'origProductName',
        t('components.salesReport.origProductName'),
        { filter: true },
      ),
      Column.text('qty', t('components.salesReport.qty'), { filter: true }),
      {
        title: t('components.salesReport.price'),
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (price) => <>{(price * 0.01).toFixed(2)}</>,
        sorter: true,
      },
      Column.text('origProductId', t('components.salesReport.origProductId'), {
        filter: true,
      }),
      Column.dictionary(
        'origProductCategory',
        t('components.salesReport.category._'),
        ProductCategory,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.category.${entry.id}`),
        },
      ),
      Column.dictionary(
        'orderType',
        t('components.salesReport.orderType._'),
        OrderType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.orderType.${entry.id}`),
        },
      ),
      Column.dictionary(
        'status',
        t('components.salesReport.status.title'),
        Status,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) => t(`components.salesReport.status.${entry.id}`),
        },
      ),
      Column.dictionary(
        'institution',
        t('entity.admin.user.institutionId'),
        institutions,
        {
          width: 150,
          filter: true,
          dictKey: 'name',
        },
      ),
      Column.text('subdivision', t('entity.admin.user.subdivisionId'), {
        width: 150,
        filter: true,
      }),
      Column.actions(t('table.actions'), (record) => (
        <Button
          shape="circle"
          type="link"
          icon={<EditOutlined />}
          onClick={() => {
            setFieldsValue({ orderId: record?.orderId });
            setIsModalVisible(true);
          }}
        />
      )),
    ],
    [t, institutions, setFieldsValue],
  );
  const [newColumns, setNewColumns] = useState(() => columns);

  const handleChangeHandler = useCallback(
    (first, filters, ...args) => {
      const julianDate = new Date(
        new Date(localStorage.getItem('julianStartDate')).toLocaleDateString(
          'en-US',
          {
            timeZone: 'Europe/Bucharest',
          },
        ),
      );

      const newValues = [
        'valStartDateA',
        'valEndDateA',
        'valStartDateB',
        'valEndDateB',
      ]
        .filter((field) => filters[field])
        .reduce((obj, field) => {
          return {
            ...obj,
            [`dateStatus${field.slice(-1)}`]: [0],
            [field]: [
              {
                from: Math.max(
                  -32768,
                  Math.ceil(
                    (new Date(filters[field][0]?.from) -
                      julianDate -
                      (new Date(filters[field][0]?.from).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
                to: Math.min(
                  32767,
                  Math.ceil(
                    (new Date(filters[field][0]?.to) -
                      julianDate -
                      (new Date(filters[field][0]?.to).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
              },
            ],
          };
        }, {});
      return handleChange(first, { ...filters, ...newValues }, ...args);
    },
    [handleChange],
  );

  const handleResize =
    (index) =>
    (e, { size }) => {
      setNewColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  useEffect(() => {
    setNewColumns(
      columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(index),
        }),
      })),
    );
  }, [columns]);

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="productId"
        columns={newColumns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChangeHandler}
        scroll={{ x: 700, y: 700 }}
        size="small"
        components={{
          header: {
            cell: ResizableHeaderCell,
          },
        }}
      />
      <Modal
        title="Schimba institutia"
        visible={isModalVisible}
        closable={false}
        footer={
          <Space>
            <Button
              className="ant-btn-warning"
              type="primary"
              htmlType="submit"
              icon={<EditOutlined />}
              onClick={() => {
                institutionForm.validateFields().then(() => {
                  changeInstitution(
                    institutionForm.getFieldValue('orderId'),
                    institutionForm.getFieldValue('institutionId'),
                    institutionForm.getFieldValue('subInstitutionId'),
                  )
                    .then(() => {
                      reload();
                      notification.success({
                        message: t('Datele au fost modificate!'),
                      });
                    })
                    .catch((err) => console.log(err));
                  institutionForm.resetFields();
                  setIsModalVisible(false);
                });
              }}
            >
              Schimba
            </Button>
            <Button
              className="ant-btn-primary"
              type="primary"
              htmlType="button"
              icon={<RollbackOutlined />}
              onClick={() => {
                setIsModalVisible(false);
                institutionForm.resetFields();
              }}
            >
              {t('actions.cancel')}
            </Button>
          </Space>
        }
      >
        <Form form={institutionForm}>
          <FormItem
            label="Institutia"
            name="institution"
            rules={[
              {
                required: true,
                message: t('errors.notEmpty'),
              },
            ]}
          >
            <Select
              onChange={(e) => {
                setFieldsValue({ institutionId: e });
                institutionForm.resetFields(['subInstitutionId']);
                getSubdivisionsByInstitutionId(e).then((data) =>
                  setSubdivisions(data),
                );
              }}
            >
              {institutions.content.map((option) => (
                <Select.Option value={option.id} key={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            label="SubInstitutie"
            name="subInstitutionId"
            rules={[
              {
                required: true,
                message: t('errors.notEmpty'),
              },
            ]}
          >
            <Select
              onChange={(subInst) => {
                setFieldsValue({ subInstitutionId: subInst });
              }}
            >
              {subdivisions?.map((option) => (
                <Select.Option value={option.id} key={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default SaleReportGrid;
