/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useCallback, useState, useRef } from 'react';

import { Modal } from 'antd';
import { CameraOutlined } from '@ant-design/icons';

import Webcam from 'react-webcam';

import { uploadImage } from '../../services/pos/pos';

const videoConstraints = {
  width: 640,
  height: 640,
};

const WebCamImage = ({ value, onChange, layout }) => {
  const [showModal, setShowModal] = useState(false);
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    uploadImage(imageSrc).then((res) => {
      onChange(res);
      setShowModal(false);
    });
  }, [onChange]);

  return (
    <>
      <div
        className={`webcam-image webcam-image__${layout}`}
        onClick={() => setShowModal(true)}
      >
        {value || <CameraOutlined />}
      </div>
      <Modal
        title="Basic Modal"
        visible={showModal}
        width={688}
        onOk={capture}
        onCancel={() => setShowModal(false)}
      >
        <Webcam
          audio={false}
          height={640}
          width={640}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
      </Modal>
    </>
  );
};

export default WebCamImage;
