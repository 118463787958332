import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'antd';

import { FormContext } from '../../../../hooks/useForm';
import useDictionaries from '../../../../hooks/useDictionaries';

import { getSchedules } from '../../../../services/offers/schedules';

import FormWrapper from '../../../FormWrapper';

const dictionaries = {
  schedules: getSchedules,
};

const Schedule = ({ path }) => {
  const { t } = useTranslation();

  const form = useContext(FormContext);

  const [{ schedules }] = useDictionaries(dictionaries);

  const { select } = form;

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Select
          label={t(
            'entity.offers.restrictions.restriction.USAGE_PER_PERIOD.periodType._',
          )}
          props={{
            options: schedules.content,
            loading: schedules.loading,
            ...select(`${path}.value2`),
          }}
        />
      </Col>
    </Row>
  );
};

export default Schedule;
