import { search, get, post, put, remove, download } from '../../helpers/api';

export const USERS_SERVICE_URI = '/users';
export const USERS_BASE_URI = '/api/v1/users';
export const RETAILERS_BASE_URI = '/api/v1/retailers';
export const VIEW_RETAILERS_BASE_URI = '/api/v1/view-retailers';
export const INSPECTORS_BASE_URI = '/api/v1/inspectors';
export const VIES_BASE_URI = '/api/v1/vies';

export const getUsers = async (data) =>
  search(`${USERS_SERVICE_URI}${USERS_BASE_URI}`, data);

export const getRetailUsers = async (data) =>
  search(`${USERS_SERVICE_URI}${RETAILERS_BASE_URI}`, data);

export const getViewRetailUsers = async (data) =>
  search(`${USERS_SERVICE_URI}${VIEW_RETAILERS_BASE_URI}`, data);

export const downLoadRetailUsers = async (data) =>
  download(`${USERS_SERVICE_URI}${RETAILERS_BASE_URI}`, data);

export const getCurrentRetailer = async () =>
  get(`${USERS_SERVICE_URI}${RETAILERS_BASE_URI}/current`);

export const getRetailUserById = async (id) =>
  get(`${USERS_SERVICE_URI}${RETAILERS_BASE_URI}/${id}`);

export const getUserFilesById = async (id) =>
  get(`${USERS_SERVICE_URI}${USERS_BASE_URI}/${id}/files`);

export const getUserById = async (id) =>
  get(`${USERS_SERVICE_URI}${USERS_BASE_URI}/${id}`);

export const verifyUserContract = async (id) =>
  get(`${USERS_SERVICE_URI}${USERS_BASE_URI}/${id}/contract`);

export const createUser = async (user) =>
  post(`${USERS_SERVICE_URI}${USERS_BASE_URI}`, user);

export const updateUser = async (user) =>
  put(`${USERS_SERVICE_URI}${USERS_BASE_URI}/${user.id}`, user);

export const deleteUser = async (user) =>
  remove(`${USERS_SERVICE_URI}${USERS_BASE_URI}/${user.id}`);

export const getActiveInspectors = async (data) =>
  search(`${USERS_SERVICE_URI}${INSPECTORS_BASE_URI}`, {
    ...data,
    criterias: { ...data.criterias, status: ['ACTIVE'] },
  });

export const downloadActiveInspectors = async (spec) =>
  download(`${USERS_SERVICE_URI}${INSPECTORS_BASE_URI}`, {
    criterias: { ...spec.criterias, status: ['ACTIVE'] },
    ...spec,
  });

export const getInspectorById = async (id) =>
  get(`${USERS_SERVICE_URI}${INSPECTORS_BASE_URI}/${id}`);

export const deletePersonalInfo = async (id) =>
  remove(`${USERS_SERVICE_URI}${USERS_BASE_URI}/${id}/personal-data`);

export const forgot = ({ email }) =>
  post(`${USERS_SERVICE_URI}${USERS_BASE_URI}/reset`, { email });

export const reset = (token, payload) =>
  post(`${USERS_SERVICE_URI}${USERS_BASE_URI}/reset/${token}`, payload);

export const getUsersWithExistingCards = (emailList) =>
  post(`${USERS_SERVICE_URI}${USERS_BASE_URI}/check-user-has-account`, {
    emailList,
  });

export const validateCui = async (country, vatNUmber) =>
  get(
    `${USERS_SERVICE_URI}${VIES_BASE_URI}?country=${country}&vatNumber=${vatNUmber}`,
  );
