import { search, get, post, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/offers';
export const AUTH_BASE_URI = '/api/v1/groups';

export const getProductGroups = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getProductGroupById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createProductGroup = async (productGroup) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, productGroup);

export const updateProductGroup = async (productGroup) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${productGroup.id}`, productGroup);

export const deleteProductGroup = async (productGroup) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${productGroup.id}`);
