import { genericCRUDService } from '../../helpers/api';

export const SERVICE_URI = '/validators';
export const BASE_URI = '/api/v1/travelers-comparation';

export const { findAll } = genericCRUDService(SERVICE_URI, BASE_URI);

export const { downloadAll } = genericCRUDService(
  SERVICE_URI,
  `${BASE_URI}/report`,
);
