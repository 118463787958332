import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Switch, Table, Tabs } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CancelButton from '../buttons/CancelButton';
import FormWrapper from '../FormWrapper';
import PageTitle from '../PageTitle';

const CountryForm = ({ country, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(country);
  }, [form, country]);

  return (
    <>
      <PageTitle
        title={country.name || t('entity.taxonomy.country._singular')}
      />
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <FormWrapper>
          <FormWrapper.Single>
            <Form.Item
              name="name"
              label="Nume"
              rules={[
                {
                  required: true,
                  message: t('errors.notEmpty'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="code"
              label="Cod"
              normalize={(val) => val.toUpperCase()}
              rules={[
                {
                  required: true,
                  message: t('errors.notEmpty'),
                },
                {
                  len: 2,
                  message: t('errors.invalidStringLength', { length: 2 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={t('entity.taxonomy.districts._plural')}
                key="districts"
              >
                <Form.List name="districts">
                  {(distr, { add }) => {
                    return (
                      <Table
                        dataSource={distr}
                        pagination={false}
                        footer={() => {
                          return (
                            <Form.Item>
                              <Button onClick={() => add({})}>
                                <PlusOutlined />{' '}
                                {t('entity.taxonomy.districts._add')}
                              </Button>
                            </Form.Item>
                          );
                        }}
                      >
                        <Column
                          title={t('entity.taxonomy.districts.name')}
                          render={(_, __, index) => {
                            return (
                              <Form.Item
                                name={[index, 'name']}
                                shouldUpdate={(prevValues, currentValues) =>
                                  prevValues.name !== currentValues.name
                                }
                              >
                                <Input
                                  placeholder="name"
                                  style={{ width: '100%', marginRight: 8 }}
                                />
                              </Form.Item>
                            );
                          }}
                        />
                        <Column
                          title={t('entity.taxonomy.districts.deleted._')}
                          render={(_, __, index) => {
                            return (
                              <Form.Item
                                name={[index, 'deleted']}
                                valuePropName="checked"
                              >
                                <Switch />
                              </Form.Item>
                            );
                          }}
                        />
                      </Table>
                    );
                  }}
                </Form.List>
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                {t('actions.save')}
              </Button>

              <CancelButton onCancel={onCancel} />
            </Space>
          </Form.Item>
        </FormWrapper>
      </Form>
    </>
  );
};
export default CountryForm;
