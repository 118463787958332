import { genericCRUDService, post, put, search } from '../helpers/api';

export const SERVICE_URI = '/stocks';
export const BASE_URI = '/api/v1/orders';
export const SIMPLE_BASE_URI = '/api/v1';

export const BASE_V2_URI = '/api/v2/orders';

export const { findAll } = genericCRUDService(SERVICE_URI, BASE_V2_URI);

export const { findOne, create, remove } = genericCRUDService(
  SERVICE_URI,
  BASE_URI,
);
export const { downloadAll } = genericCRUDService(
  SERVICE_URI,
  `${SIMPLE_BASE_URI}/sales/report`,
);
export const getSaleReport = async (data) =>
  search(`${SERVICE_URI}${SIMPLE_BASE_URI}/sales/report`, data);

export const update = (entity) =>
  put(
    `${SERVICE_URI}${BASE_URI}/${entity.id}${
      entity.action ? `/${entity.action}` : ''
    }`,
    entity,
  );

export const assignPersonWithCard = (id, orderId, entity) =>
  put(
    `${SERVICE_URI}${BASE_URI}/${orderId}/products/person/${id}/assignCard`,
    entity,
  );

export const updateDocExpDate = (updateDocumentDto) =>
  post(`${SERVICE_URI}${BASE_URI}/update/files`, updateDocumentDto);
