import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/doc-types';

export const getDocTypes = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getPosDocTypes = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, {
    ...data,
    criterias: { ...data.criterias, pos: '' },
  });

export const getDocTypeById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createDocType = async (docType) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, docType);

export const updateDocType = async (docType) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${docType.id}`, docType);

export const deleteDocType = async (docType) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${docType.id}`);
