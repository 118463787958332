import React, { useCallback, useEffect, useState } from 'react';

import { Button } from 'antd';
import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  CreditCardOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import useCard from '../../hooks/useCard';
import { getCardDetails } from '../../services/accounts/accounts';

const ScanCardDetailsButton = ({ setCardDetails }) => {
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(false);

  const [icon, setIcon] = useState(<CreditCardOutlined />);

  const onCardFound = useCallback(
    (cardPhysicalId) => {
      setDisabled(false);
      getCardDetails(cardPhysicalId)
        .then((cardInfo) => {
          setCardDetails(cardInfo);
          setIcon(<CheckSquareOutlined />);
        })
        .catch(() => setIcon(<CloseSquareOutlined />));
    },
    [setCardDetails],
  );

  const onCardMissing = useCallback(() => {
    setDisabled(false);
    setIcon(<CloseSquareOutlined />);
  }, []);

  const [, loading, fetchCard] = useCard({
    onCardFound,
    onCardMissing,
  });

  useEffect(() => {
    if (loading) {
      setDisabled(true);
      setIcon(<LoadingOutlined />);
    }
  }, [loading]);

  const onClick = useCallback(() => {
    setCardDetails(null);
    fetchCard();
  }, [fetchCard, setCardDetails]);

  return (
    <Button
      type="primary"
      block
      htmlType="button"
      icon={icon}
      onClick={onClick}
      disabled={disabled}
    >
      {t('actions.scanCardDetails')}
    </Button>
  );
};

export default ScanCardDetailsButton;
