import React, { useContext, useCallback } from 'react';

import POSContext from './POSContext';

import Startup from './screens/Startup';
import Initial from './screens/Initial';
import AccountDetails from './screens/account-details/AccountDetails';
import SelectProducts from './screens/SelectProducts';

import SelectCard from './screens/SelectCard';
import CustomizeCard from './screens/CustomizeCard';
import Complete from './screens/Complete';
import PrintCard from './screens/PrintCard';

const POSScreenSwitcher = () => {
  const { shift, order } = useContext(POSContext);

  const renderScreen = useCallback(() => {
    if (shift) {
      const orderStatus = order ? order.status : undefined;

      switch (orderStatus) {
        case 'SELECT_PRODUCTS': {
          const { account } = order;
          if (account.userId) {
            return <AccountDetails />;
          }
          return <SelectProducts />;
        }

        case 'SELECT_CARD':
          return <SelectCard />;

        case 'CUSTOMIZE_CARD':
          return <CustomizeCard />;

        case 'COMPLETE':
          return <Complete />;

        case 'PRINTING':
          return <PrintCard />;

        default:
          return <Initial />;
      }
    } else {
      return <Startup />;
    }
  }, [shift, order]);

  return <>{renderScreen()}</>;
};

export default POSScreenSwitcher;
