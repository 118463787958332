import { genericCRUDService, put } from '../../helpers/api';

export const SERVICE_URI = '/taxonomies';
export const BASE_URI = '/api/v1/messages';

export const { findAll, findOne, create, remove } = genericCRUDService(
  SERVICE_URI,
  BASE_URI,
);

export const update = (entity) =>
  put(
    `${SERVICE_URI}${BASE_URI}/${entity.id}${
      entity.action ? `/${entity.action}` : ''
    }`,
    entity,
  );

export const inactivateMessage = async (id) =>
  put(`${SERVICE_URI}${BASE_URI}/${id}/inactivate`);
