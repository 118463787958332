import React from 'react';

import './AppLoader.scss';

const AppLoader = () => (
  <div className="apploader">
    <i className="apploader__layer" />
    <i className="apploader__layer" />
    <i className="apploader__layer" />
  </div>
);

export default AppLoader;
