import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import PageTitle from '../../../../components/PageTitle';
import FormWrapper from '../../../../components/FormWrapper';
import CancelButton from '../../../../components/buttons/CancelButton';

const SystemParameterForm = ({ param, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(param);
  }, [form, param]);

  return (
    <>
      <PageTitle
        title={param.name || t('entity.taxonomy.system-parameters._singular')}
      />
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <FormWrapper>
          <FormWrapper.Single>
            <Form.Item
              name="name"
              label={t('entity.taxonomy.system-parameters.name')}
              rules={[
                {
                  required: true,
                  message: t('errors.notEmpty'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="code"
              label={t('entity.taxonomy.system-parameters.code')}
              normalize={(val) => val.toUpperCase()}
              rules={[
                {
                  required: true,
                  message: t('errors.notEmpty'),
                },
                {
                  max: 30,
                  message: t('errors.maxLength', { max: 30 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </FormWrapper.Single>
          <FormWrapper.Single>
            <Form.Item
              name="value"
              label={t('entity.taxonomy.system-parameters.value')}
              rules={[
                {
                  required: true,
                  message: t('errors.notEmpty'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="groupCode"
              label={t('entity.taxonomy.system-parameters.groupCode')}
              normalize={(val) => val.toUpperCase()}
              rules={[
                {
                  required: true,
                  message: t('errors.notEmpty'),
                },
                {
                  max: 30,
                  message: t('errors.maxLength', { max: 30 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Form.Item
              name="description"
              label={t('entity.taxonomy.system-parameters.description')}
              rules={[
                {
                  required: true,
                  message: t('errors.notEmpty'),
                },
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 3 }}
                maxLength={200}
                showCount
              />
            </Form.Item>
          </FormWrapper.Double>
        </FormWrapper>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              {t('actions.save')}
            </Button>

            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default SystemParameterForm;
