import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditRetailerForm from '../../../../components/retailers/EditRetailerForm';

import {
  createRetailer,
  getRetailerById,
  updateRetailer,
} from '../../../../services/taxonomies/retailers';

const initialRetailer = {
  deleted: false,
  reserves: [],
  posReserves: [],
  posLimits: [],
};

const EditRetailer = () => {
  const [retailer, handleSubmit, handleCancel] = useEditPage({
    initial: initialRetailer,
    existent: getRetailerById,
    goBackPath: '/admin/taxonomies/retailers',
    onCreate: createRetailer,
    onUpdate: updateRetailer,
  });

  return (
    <Default>
      {retailer !== undefined && (
        <EditRetailerForm
          retailer={retailer}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditRetailer;
