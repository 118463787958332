import { download, search } from '../../helpers/api';

export const SERVICE_URI = '/telelink';
export const BASE_URI = '/v2/buy';

export const getAll = async (data) =>
  search(`${SERVICE_URI}${BASE_URI}/all`, data);

export const downloadAll = async (data) =>
  download(`${SERVICE_URI}${BASE_URI}`, data);
