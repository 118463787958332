import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import SaleReportGrid from '../../../components/sale-report/SaleReportGrid';

const SaleReport = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.salesReport._')} />
      <SaleReportGrid />
    </Default>
  );
};

export default SaleReport;
