import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import SaleReportTvmExternGrid from '../../../components/sale-report-tvm-extern/SaleReportTvmExternGrid';

const SaleReportTvmExtern = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.salesReportTvmExtern._')} />
      <SaleReportTvmExternGrid />
    </Default>
  );
};

export default SaleReportTvmExtern;
