import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';

import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';

import Column from '../../../../helpers/columns';

import {
  deleteProductGroup,
  getProductGroups,
} from '../../../../services/offers/product-groups';
import { NominalTypes } from '../../../../components/offers/OffersConstants';

const ProductGroupsList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } =
    useDatasource(getProductGroups);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.offers.productGroup.name._'), {
        filter: true,
      }),
      Column.text('price', t('entity.offers.productGroup.price')),
      Column.bool('deleted', t('entity.offers.productGroup.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.offers.productGroup.deleted.true'),
          t('entity.offers.productGroup.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.dictionary(
        'nominalType',
        t('entity.offers.productGroup.nominalType._'),
        NominalTypes,
        {
          width: 150,
          dictLabel: (entry) =>
            t(`entity.offers.productGroup.nominalType.${entry.id}`),
        },
      ),

      Column.actions(t('table.actions'), (product) => (
        <>
          <EditItemIcon path={`/admin/offers/groups/${product.id}`} />
          <DeleteItemIcon
            title={t('entity.offers.productGroup._delete', product)}
            message={t('entity.offers.productGroup._deleted', product)}
            item={product}
            action={deleteProductGroup}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.offers.productGroup._plural')}>
        <AddEntityButton
          entity={t('entity.offers.productGroup._singular')}
          path="/admin/offers/groups/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default ProductGroupsList;
