import React from 'react';
import EditRetailForm from '../../components/retail/EditRetailForm';
import { useEditPage } from '../../hooks/useEditPage';
import { Default } from '../../layouts';
import { getRetailUserById } from '../../services/admin/users';
import { updatePosSeller } from '../../services/taxonomies/poses';

const initialRetail = {};

const EditRetail = () => {
  const [retail, handleSubmit, handleCancel] = useEditPage({
    initial: initialRetail,
    existent: getRetailUserById,
    goBackPath: '/retail',
    onUpdate: updatePosSeller,
  });

  return (
    <Default>
      {retail !== undefined && (
        <>
          <EditRetailForm
            retail={retail}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </>
      )}
    </Default>
  );
};

export default EditRetail;
