import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import arrayMove from 'array-move';
import { FormContext } from '../../hooks/useForm';
import Column from '../../helpers/columns';
import { getStations } from '../../services/taxonomies/stations';
import useDictionaries from '../../hooks/useDictionaries';
import DragSortingTable from '../DragSortingTable';

const dictionaries = {
  stations: getStations,
};

const StationsSelection = () => {
  const form = useContext(FormContext);
  const { t } = useTranslation();

  const { value, setValue } = form;

  const [{ stations }] = useDictionaries(dictionaries);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.station.name'), {
        filter: false,
        sort: false,
      }),
      Column.bool('deleted', t('entity.taxonomy.station.deleted._'), {
        width: 150,
        filter: false,
        sort: false,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.station.deleted.true'),
          t('entity.taxonomy.station.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const content = useMemo(
    () =>
      value.stations.map((stationId, index) => ({
        index,
        ...stations.content.find((station) => station.id === stationId),
      })),
    [stations.content, value.stations],
  );

  const moveRow = useCallback(
    (oldIndex, newIndex) =>
      setValue('stations', arrayMove(value.stations, oldIndex, newIndex)),
    [setValue, value.stations],
  );

  return (
    <DragSortingTable
      rowKey="index"
      moveRow={moveRow}
      columns={columns}
      dataSource={content}
      // loading={stations.loading}
    />
  );
};

export default StationsSelection;
