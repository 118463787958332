import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Space, Tabs } from 'antd';

import PageTitle from '../PageTitle';
import FormWrapper from '../FormWrapper';
import CancelButton from '../buttons/CancelButton';
import InspectorTransactionsList from './InspectorTransactionsList';
import TransactionsFines from './TransactionsFines';

const InspectorForm = ({ inspector, onCancel }) => {
  const { t } = useTranslation();

  const [isFinesEnabledString] = useState(window._env_.ENABLE_FINES);
  const isFinesEnabled = isFinesEnabledString === 'true';

  return (
    <>
      <PageTitle title={`${inspector.fullname || ''}`} />
      <form>
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('components.inspectors.form.fullname')}
              props={{
                value: inspector.fullname || '',
                disabled: true,
              }}
            />
          </FormWrapper.Single>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('components.inspectors.form.identityCard')}
              props={{
                value: inspector.identityCard || '',
                disabled: true,
              }}
            />
          </FormWrapper.Single>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('components.inspectors.form.employeeNumber')}
              props={{
                value: inspector.employeeNumber || '',
                disabled: true,
              }}
            />
          </FormWrapper.Single>
        </FormWrapper>
      </form>

      <Tabs>
        <Tabs.TabPane
          tab={t('components.inspectors.transactions._')}
          key="transactions"
        >
          <InspectorTransactionsList inspectorId={inspector.employeeNumber} />
        </Tabs.TabPane>
        {isFinesEnabled && (
          <Tabs.TabPane tab={t('components.inspectors.fines._')} key="fines">
            <TransactionsFines inspectorId={inspector.employeeNumber} />
          </Tabs.TabPane>
        )}
      </Tabs>

      <Space>
        <CancelButton onCancel={onCancel} />
      </Space>
    </>
  );
};

export default InspectorForm;
