import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, notification, Table, Tooltip } from 'antd';
import { RedoOutlined, StopOutlined } from '@ant-design/icons';
import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/columns';

import PageTitle from '../PageTitle';
import ResizableHeaderCell from '../../helpers/ResizableHeaderCell';
import {
  cancelInvoiceGeneration,
  getTransactions,
  retryInvoiceGeneration,
} from '../../services/erp/erp-inoices';

const EvenTypes = {
  content: [{ id: 'ADD_NEW' }, { id: 'RECHARGE' }],
  loading: false,
  error: false,
};

const Sources = {
  content: [
    { id: 'MOBILE' },
    { id: 'PORTAL' },
    { id: 'TVM' },
    { id: 'TVM_EXTERN' },
    { id: 'TVM_EXTERN2' },
    { id: 'TVM_EXTERN3' },
    { id: 'TVM_EXTERN4' },
    { id: 'TVM_EXTERN5' },
    { id: 'TVM_EXTERN6' },
    { id: 'TVM_EXTERN7' },
    { id: 'TVM_EXTERN8' },
    { id: 'TVM_EXTERN9' },
  ],
  loading: false,
  error: false,
};

const RetryInvoiceGenerationGrid = () => {
  const { t } = useTranslation();

  const [expandedRowKeys, setExpandedRowKeys] = useState([]); // Track expanded row keys

  // Handle row expansion, allowing only one row to expand at a time
  const handleExpand = (expanded, record) => {
    console.log(record);
    setExpandedRowKeys(expanded ? [record.id] : []); // Set the row key if expanding, otherwise empty the array
  };

  const { pagination, content, handleChange, reload } = useDatasource((arg) =>
    getTransactions({
      ...arg,
      criterias: {
        ...arg.criterias,
        invoiceStatus: 'FAILED',
      },
    }),
  );

  const expandedRowRender = (record) => {
    const productColumns = [
      {
        title: t('pages.reports.retryInvoiceGeneration.transactions.productId'),
        dataIndex: 'productId',
        key: 'productId',
      },
      {
        title: t(
          'pages.reports.retryInvoiceGeneration.transactions.productName',
        ),
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: t('pages.reports.retryInvoiceGeneration.transactions.qty'),
        dataIndex: 'productQty',
        key: 'productQty',
      },
      {
        title: t('pages.reports.retryInvoiceGeneration.transactions.price'),
        dataIndex: 'productPrice',
        key: 'productPrice',
      },
      {
        title: t('pages.reports.retryInvoiceGeneration.transactions.total'),
        key: 'totalPrice',
        render: (_, product) => product.productPrice * product.productQty,
      },
    ];

    return (
      <Table
        columns={productColumns}
        dataSource={record.products}
        pagination={false}
        rowKey="id" // Ensure a unique row key for the nested table
      />
    );
  };

  const handleRetryGeneration = useCallback(
    (record) => {
      retryInvoiceGeneration(record.id)
        .then((data) => {
          console.log('data', data);
          reload();
        })
        .catch((msg) => {
          console.error(msg);
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};

          notification.error({
            message: messageCode ? t(messageCode) : t('actions.deleteFailed'),
          });
        });
    },
    [t, reload],
  );

  const handleCancelGeneration = useCallback(
    (record) => {
      cancelInvoiceGeneration(record.id)
        .then((data) => {
          console.log('data', data);
          reload();
        })
        .catch((msg) => {
          console.error(msg);
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};

          notification.error({
            message: messageCode ? t(messageCode) : t('actions.deleteFailed'),
          });
        });
    },
    [t, reload],
  );

  useEffect(() => {
    if (content?.[0]) {
      localStorage.setItem('julianStartDate', content[0].julianStartDate);
    }
  }, [content]);

  const columns = useMemo(
    () => [
      Column.text(
        'fullname',
        t('pages.reports.retryInvoiceGeneration.transactions.userName'),
        {
          filter: false,
          sort: false,
        },
      ),
      Column.text(
        'identityCode',
        t('pages.reports.retryInvoiceGeneration.transactions.userIdentifier'),
        {
          filter: false,
          sort: false,
        },
      ),
      Column.date('generationDate', t('entity.orders.createdAt'), {
        width: 250,
      }),
      Column.dictionary(
        'eventType',
        t('pages.reports.retryInvoiceGeneration.transactions.eventType._'),
        EvenTypes,
        {
          filter: false,
          width: 150,
          dictLabel: (entry) => {
            return t(
              `pages.reports.retryInvoiceGeneration.transactions.eventType.${entry.id}`,
            );
          },
        },
      ),
      Column.dictionary(
        'saleChanel',
        t('pages.reports.retryInvoiceGeneration.transactions.source._'),
        Sources,
        {
          filter: false,
          width: 150,
          dictLabel: (entry) => {
            return t(
              `pages.reports.retryInvoiceGeneration.transactions.source.${entry.id}`,
            );
          },
        },
      ),
      Column.text(
        'orderId',
        t('pages.reports.retryInvoiceGeneration.transactions.orderId'),
        {
          filter: false,
          sort: false,
          width: 120,
        },
      ),
      Column.actions(t('table.actions'), (record) => (
        <>
          <Tooltip title={t('actions.regenerateInvoice')}>
            <Button
              shape="circle"
              type="link"
              icon={<RedoOutlined />}
              onClick={() => handleRetryGeneration(record)}
            />
          </Tooltip>
          <Tooltip title={t('actions.cancelInvoiceGenerator')}>
            <Button
              shape="circle"
              type="link"
              icon={<StopOutlined />}
              onClick={() => handleCancelGeneration(record)}
            />
          </Tooltip>
        </>
      )),
    ],
    [t, handleRetryGeneration, handleCancelGeneration],
  );
  const [newColumns, setNewColumns] = useState(() => columns);

  const handleChangeHandler = useCallback(
    (first, filters, ...args) => {
      const julianDate = new Date(
        new Date(localStorage.getItem('julianStartDate')).toLocaleDateString(
          'en-US',
          {
            timeZone: 'Europe/Bucharest',
          },
        ),
      );

      const newValues = [
        'valStartDateA',
        'valEndDateA',
        'valStartDateB',
        'valEndDateB',
      ]
        .filter((field) => filters[field])
        .reduce((obj, field) => {
          return {
            ...obj,
            [`dateStatus${field.slice(-1)}`]: [0],
            [field]: [
              {
                from: Math.max(
                  -32768,
                  Math.ceil(
                    (new Date(filters[field][0]?.from) -
                      julianDate -
                      (new Date(filters[field][0]?.from).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
                to: Math.min(
                  32767,
                  Math.ceil(
                    (new Date(filters[field][0]?.to) -
                      julianDate -
                      (new Date(filters[field][0]?.to).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
              },
            ],
          };
        }, {});
      return handleChange(first, { ...filters, ...newValues }, ...args);
    },
    [handleChange],
  );

  const handleResize =
    (index) =>
    (e, { size }) => {
      setNewColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  useEffect(() => {
    setNewColumns(
      columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(index),
        }),
      })),
    );
  }, [columns]);

  return (
    <>
      <PageTitle />
      <Table
        rowKey="id"
        columns={newColumns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        expandable={{
          expandedRowRender, // Nested product table
          expandedRowKeys, // Control expanded rows
          onExpand: handleExpand, // Handle row expansion
        }}
        onChange={handleChangeHandler}
        scroll={{ x: 700, y: 700 }}
        size="small"
        components={{
          header: {
            cell: ResizableHeaderCell,
          },
        }}
      />
    </>
  );
};

export default RetryInvoiceGenerationGrid;
