import { genericCRUDService, search } from '../helpers/api';

export const SERVICE_URI = '/stocks';
export const BASE_URI = '/api/v1/orders';

export const BASE_V2_URI = '/api/v2/orders';

export const { downloadAll } = genericCRUDService(
  SERVICE_URI,
  `${BASE_V2_URI}`,
);

export const findAll = async (data) =>
  search(`${SERVICE_URI}${BASE_V2_URI}`, data);

export const { findOne, create, remove } = genericCRUDService(
  SERVICE_URI,
  BASE_URI,
);
