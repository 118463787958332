import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { Col, Row, Space, Tabs } from 'antd';
import useForm, { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import CancelButton from '../buttons/CancelButton';
import { getAccounts } from '../../services/accounts/accounts';
import {
  BULK_TYPES,
  COMMAND_TYPES,
  ORDER_STATUS,
} from '../../pages/orders/constants';
import OrderFileList from './OrderFileList';
import Products from './Products';
import CustomizeCard from './CustomizeCard';
import PrintList from './PrintList';
import AuthContext, { hasPermission } from '../auth';
import CardsList from './CardsList';
import { useRouteParams } from '../../hooks/useRouteParams';
import IssuingOrderButtons from './IssuingOrderButtons';
import DisablingOrderButtons from './DisablingOrderButtons';
import RechargingOrderButtons from './RechargingOrderButtons';
import CustomSaveButton from '../buttons/CustomSaveButton';
import PosSelector from './PosSelector';
import { verifyUserContract } from '../../services/admin/users';
import TotalPrice from './TotalPrice';

const getLegalAccounts = (data) =>
  getAccounts({
    ...data,
    pageSize: 1000,
    criterias: { ...data.criterias, customerType: 'LEGAL', userName: '' },
  });
const EditOrderForm = ({ order, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const { isNew } = useRouteParams();
  const {
    user: { permissions },
  } = useContext(AuthContext);
  const formRef = useRef(0);
  const dynamicPriceString = window._env_.DYNAMIC_PRICE;
  const dynamicPrice = dynamicPriceString === 'true';

  const schema = useMemo(
    () =>
      yup.object().shape({
        bulkType: yup.string().required(t('errors.mustSelected')),
        commandType: yup.string().required(t('errors.mustSelected')),
        products: yup.array().of(
          yup.object().shape({
            productId: yup
              .number()
              .nullable()
              .when('$bulkType', {
                is: BULK_TYPES.ISSUING_CARDS,
                then: yup.number().required(t('errors.notEmpty')),
              }),
            roleId: yup
              .number()
              .nullable()
              .when(['$bulkType', '$commandType'], {
                is: (bulkType, commandType) =>
                  bulkType === BULK_TYPES.ISSUING_CARDS &&
                  commandType === 'INTERNAL',
                then: yup.number().required(t('errors.notEmpty')),
              }),
            count: yup.number().nullable(),
            productPersons: yup.array().of(
              yup.object().shape({
                person: yup.object().shape({
                  fullname: yup
                    .string()
                    .nullable()
                    .when('$bulkType', {
                      is: BULK_TYPES.ISSUING_CARDS,
                      then: yup.string().required(t('errors.notEmpty')),
                    }),
                  email: yup
                    .string()
                    .email(t('errors.incorrectField'))
                    .nullable(),
                  identityCard: yup
                    .string()
                    .nullable()
                    .when('$bulkType', {
                      is: BULK_TYPES.ISSUING_CARDS,
                      then: yup.string().required(t('errors.notEmpty')),
                    }),
                  cardLogicalId: yup
                    .number()
                    .typeError(t('table.validation.cardLogicalIdError'))
                    .nullable()
                    .when('$bulkType', {
                      is: BULK_TYPES.DISABLE_CARDS,
                      then: yup
                        .number()
                        .required(t('errors.notEmpty'))
                        .typeError(t('table.validation.cardLogicalIdError')),
                    }),
                  employeeNumber: yup
                    .string()
                    .nullable()
                    .matches(/^(\d+)?$/, t('errors.incorrectField')),
                  phone: yup
                    .string()
                    .nullable()
                    .matches(/^(\+?\d+)?$/, t('errors.incorrectField')),
                  documents: yup.array().of(
                    yup.object().shape({
                      documentTypeId: yup
                        .number()
                        .required(t('errors.mustSelected')),
                      fileId: yup.number().required(t('errors.mustSelected')),
                    }),
                  ),
                }),
              }),
            ),
          }),
        ),
      }),
    [t],
  );

  const form = useForm({
    initialValue: order,
    schema,
    onSubmit,
    onCancel,
  });

  const { handleSubmit, setFormValue, custom, select, value, setValue } = form;
  const { status, disabled, bulkType, posId, customerType, userId } = value;

  const [hasContract, setHasContract] = useState(null);

  const isPortalLegalOrder = useMemo(() => {
    return customerType !== null && customerType === 'LEGAL';
  }, [customerType]);

  useEffect(() => {
    // userId is actually the account id
    if (
      isPortalLegalOrder !== null &&
      userId !== null &&
      userId !== undefined &&
      ORDER_STATUS.PAYMENT === status
    ) {
      verifyUserContract(userId).then(
        (res) => {
          setHasContract(res);
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }, [isPortalLegalOrder, userId, status]);

  useEffect(() => {
    setFormValue(order);
  }, [setFormValue, order]);

  const handleChangeBulkType = useCallback(
    (currentBulkType) => {
      setValue('bulkType', currentBulkType);
      setValue('products[0].productPersons', []);
    },
    [setValue],
  );

  return (
    <FormContext.Provider value={form}>
      <form
        onSubmit={handleSubmit}
        ref={(r) => {
          formRef.current = r;
        }}
      >
        {![ORDER_STATUS.CUSTOMIZE_CARD, ORDER_STATUS.PRINTING].includes(
          status,
        ) && (
          <>
            <FormWrapper>
              <FormWrapper.Single>
                <FormWrapper.Search
                  label={t('entity.orders.user')}
                  props={{
                    ...custom('userId'),
                    disabled,
                    fetcher: getLegalAccounts,
                    searchField: 'userName',
                    field: 'id',
                    labelProp: (obj) => `${obj.id} - ${obj.userName}`,
                  }}
                />
                <FormWrapper.Select
                  label={t('entity.orders.bulkTypes')}
                  props={{
                    disabled: !isNew,
                    options: Object.keys(BULK_TYPES).map((o) => ({ id: o })),
                    allowClear: false,
                    labelProp: (obj) => t(`entity.orders.bulkType.${obj.id}`),
                    ...select('bulkType'),
                    onChange: handleChangeBulkType,
                  }}
                />
                <FormWrapper.Select
                  label={t('entity.orders.command')}
                  props={{
                    disabled,
                    options: COMMAND_TYPES,
                    allowClear: false,
                    labelProp: (obj) =>
                      t(`entity.orders.commandType.${obj.id}`),
                    ...select('commandType'),
                  }}
                />
                <FormWrapper.Input
                  label={t('entity.orders.status.title')}
                  props={{
                    disabled: true,
                    value: t(`entity.orders.status.${status}`),
                  }}
                />
                {BULK_TYPES.ISSUING_CARDS === bulkType && (
                  <PosSelector
                    props={{
                      t,
                      setValue,
                      disabled: true,
                      defaultValue: posId,
                    }}
                  />
                )}
              </FormWrapper.Single>

              <FormWrapper.Double>
                <Tabs>
                  <Tabs.TabPane
                    tab={t('entity.admin.user.tabs.files._')}
                    key="files"
                  >
                    <OrderFileList
                      props={{ ...custom('attachments'), select, custom }}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </FormWrapper.Double>
            </FormWrapper>

            {[BULK_TYPES.ISSUING_CARDS, BULK_TYPES.RECHARGE_CARDS].includes(
              bulkType,
            ) && (
              <Row>
                <Col span={24}>
                  <Products status={status} permisions={permissions} />
                </Col>
              </Row>
            )}
            {bulkType === BULK_TYPES.DISABLE_CARDS && (
              <Row>
                <Col span={24}>
                  <CardsList />
                </Col>
              </Row>
            )}
          </>
        )}

        {/* {[ORDER_STATUS.PAYMENT, ORDER_STATUS.PAID].includes(status) && (
          <TotalPrice />
        )} */}
        {[
          ORDER_STATUS.PAYMENT,
          ORDER_STATUS.DOC_VALIDATION,
          ORDER_STATUS.PAID,
          ORDER_STATUS.OP_VALIDATION,
        ].includes(status) &&
          dynamicPrice && <TotalPrice />}
        {status === ORDER_STATUS.CUSTOMIZE_CARD && <CustomizeCard />}
        {status === ORDER_STATUS.PRINTING && <PrintList />}
        <Space>
          <CancelButton onCancel={onCancel} />
          {![
            ORDER_STATUS.DOC_VALIDATION,
            ORDER_STATUS.REJECTED,
            ORDER_STATUS.CONFIRMED,
            ORDER_STATUS.DELETED,
            ORDER_STATUS.PICKED_UP,
          ].includes(status) &&
            hasPermission(permissions, [
              'SYS_SUPERUSER',
              'ORDERS_VIEW',
              'ORDERS_REVIEW',
              'ORDERS_CREATE',
              'ORDERS_PRINT',
            ]) &&
            (status !== ORDER_STATUS.SELECT_PRODUCTS || !customerType) && (
              <CustomSaveButton form={form} formRef={formRef} />
            )}
          {status !== ORDER_STATUS.DELETED &&
            bulkType === BULK_TYPES.ISSUING_CARDS && (
              <IssuingOrderButtons
                form={form}
                formRef={formRef}
                hasContract={hasContract}
              />
            )}
          {status !== ORDER_STATUS.DELETED &&
            bulkType === BULK_TYPES.RECHARGE_CARDS && (
              <RechargingOrderButtons
                form={form}
                formRef={formRef}
                hasContract={hasContract}
              />
            )}
          {status !== ORDER_STATUS.DELETED &&
            bulkType === BULK_TYPES.DISABLE_CARDS && (
              <DisablingOrderButtons form={form} formRef={formRef} />
            )}
        </Space>
      </form>
    </FormContext.Provider>
  );
};

export default EditOrderForm;
