import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';
import {
  deleteStation,
  getStations,
} from '../../../../services/taxonomies/stations';

import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import Column from '../../../../helpers/columns';

const StationsList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } =
    useDatasource(getStations);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.station.name'), {
        filter: true,
      }),
      Column.text('latitude', t('entity.taxonomy.station.latitude'), {
        filter: true,
      }),
      Column.text('longitude', t('entity.taxonomy.station.longitude'), {
        filter: true,
      }),
      Column.bool('deleted', t('entity.taxonomy.station.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.station.deleted.true'),
          t('entity.taxonomy.station.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <>
          <EditItemIcon path={`/admin/taxonomies/stations/${record.id}`} />
          <DeleteItemIcon
            title={t('entity.taxonomy.station._delete', record)}
            message={t('entity.taxonomy.station._deleted', record)}
            item={record}
            action={deleteStation}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.station._plural')}>
        <AddEntityButton
          entity={t('entity.taxonomy.station._singular')}
          path="/admin/taxonomies/stations/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="name"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default StationsList;
