import React from 'react';

import { Default } from '../../layouts';

import { useEditPage } from '../../hooks/useEditPage';

import InspectorForm from '../../components/inspectors/InspectorForm';
import { getInspectorById } from '../../services/admin/users';

const initial = {};

const Inspector = () => {
  const [inspector, , handleCancel] = useEditPage({
    initial,
    existent: getInspectorById,
    goBackPath: '/inspectors',
  });

  return (
    <Default>
      {inspector && (
        <InspectorForm inspector={inspector} onCancel={handleCancel} />
      )}
    </Default>
  );
};

export default Inspector;
