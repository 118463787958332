import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import POSContext from '../POSContext';
import POSButton from '../POSButton';
import {
  cancelOrders,
  checkout,
  getOpenOrders,
  printReceipt,
  validateCui,
} from '../../../services/pos/pos';
import { getCountries } from '../../../services/taxonomies/countires';
import useDictionaries from '../../../hooks/useDictionaries';

const dictionaries = {
  countries: () =>
    getCountries({ pageSize: 200, criterias: { deleted: 'false' } }),
};

const Complete = () => {
  const { Text } = Typography;
  const { shift, setOrder } = useContext(POSContext);
  const { t } = useTranslation();
  const [openOrders, setOpenOrders] = useState([]);
  const [stateCode, setStateCode] = useState('RO');
  const [cuiCode, setCuiCode] = useState(null);
  const [vatEntity, setVatEntity] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [{ countries }] = useDictionaries(dictionaries);

  const countryCodes = useMemo(
    () => ['', ...countries?.content?.map((val) => val.code)] || [],
    [countries],
  );

  const totalPrice = useMemo(() => {
    return openOrders
      .map((ord) =>
        ord.products
          .map((prod) => prod.price * prod.qty * 0.01)
          .reduce((acc, val) => acc + val, 0.0),
      )
      .reduce((acc, val) => acc + val, 0.0);
  }, [openOrders]);

  const columns = useMemo(
    () => [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: t('entity.account.transportTitle.startValidPeriod'),
        key: 'payload',
        render: (element) => {
          // eslint-disable-next-line no-nested-ternary
          return element.category === 'GROUP' || element.category === 'OFFER'
            ? ''
            : // eslint-disable-next-line no-nested-ternary
            element.payload[0].validityStartType === 'FUTURE'
            ? moment(element.payload[0].startDate).format('YYYY-MM-DD HH:mm')
            : element.payload[0].validityStartType === 'SALE'
            ? moment(new Date()).format('YYYY-MM-DD HH:mm')
            : t('entity.account.transportTitle.activeAtValidation');
        },
        width: 200,
      },
      {
        title: 'Pret',
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (_, row) =>
          row.category === 'PURSE'
            ? (row.qty * 0.01).toFixed(2)
            : (row.price * 0.01).toFixed(2),
      },
      {
        title: 'Cantitate',
        width: 150,
        dataIndex: 'qty',
        key: 'count',
        render: (_, row) => (row.category === 'PURSE' ? '-' : row.qty),
      },
      {
        title: 'Perioada de valabilitate',
        width: 300,
        dataIndex: 'validPeriod',
        key: 'validPeriod',
      },
      {
        title: 'Pret total',
        width: 150,
        key: 'totalPrice',
        render: (_, row) => <>{(row.qty * row.price * 0.01).toFixed(2)}</>,
      },
    ],
    [t],
  );

  const checkCuiCode = useCallback((stCode, vatCode) => {
    if (vatCode) {
      return validateCui(stCode, vatCode).then((resp) => {
        setVatEntity(resp);
        Promise.resolve();
      });
    }
    return Promise.resolve();
  }, []);

  const displayError = useCallback((msg) => {
    message.error({
      content: msg || 'Eroare la plata',
      key: 'pos',
      duration: 3,
      className: 'card-message',
    });
  }, []);

  const handleCheckout = useCallback(
    (payMeth) => {
      const cui = cuiCode ? `${stateCode}${cuiCode}` : null;
      setDisabled(true);
      checkCuiCode(stateCode, cuiCode)
        .then(() => printReceipt(payMeth, shift.posId, openOrders, cui))
        .then((resp) =>
          checkout(shift, {
            ...resp,
            paymentMethod: payMeth,
            cuiCode: cui,
          }),
        )
        .then((resp) => {
          setOrder(resp.find((o) => o.status === 'PRINTING') || resp[0]);
          setDisabled(false);
          message.success({
            content: 'Produsul a fost achitat cu success',
            key: 'pos',
            duration: 3,
            className: 'card-message',
          });
        })
        .catch((err) => {
          setDisabled(false);
          setVatEntity(null);
          console.error(err);
          if (err.inner) {
            displayError(err.inner?._);
          } else {
            err.json(err).then((res) => displayError(t(res?.message)));
          }
        });
    },
    [
      t,
      shift,
      setOrder,
      openOrders,
      cuiCode,
      stateCode,
      checkCuiCode,
      displayError,
    ],
  );

  const handleCancelOrders = useCallback(() => {
    cancelOrders(shift)
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.error(err);
        displayError('Eroare la anulare comanda');
      });
  }, [shift, setOrder, displayError]);

  const getVatData = useCallback(() => {
    setDisabled(true);
    checkCuiCode(stateCode, cuiCode)
      .then(() => {
        setDisabled(false);
      })
      .catch((err) => {
        setVatEntity(null);
        setDisabled(false);
        if (err.inner) {
          displayError(err.inner?._);
        } else {
          err.json(err).then((res) => displayError(res?.message));
        }
      });
  }, [checkCuiCode, cuiCode, stateCode, displayError]);

  useEffect(() => {
    getOpenOrders(shift)
      .then((res) => setOpenOrders(res))
      .catch((err) => {
        console.error(err);
        setOpenOrders([]);
      });
  }, [shift]);

  return (
    <div className="pos">
      <div className="pos__wrapper">
        <div>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}
            style={{ height: '100%' }}
          >
            <Col span={20}>
              <Descriptions bordered>
                <Descriptions.Item label="Pret total">
                  <Typography.Title
                    style={{ textAlign: 'right', padding: 0, margin: 0 }}
                  >
                    {totalPrice.toFixed(2)}
                  </Typography.Title>
                </Descriptions.Item>
              </Descriptions>

              {openOrders.map((ord, idx) => (
                <div key={ord.id}>
                  <Divider>Comanda {idx + 1}</Divider>
                  <Table
                    dataSource={ord.products}
                    rowKey="id"
                    columns={columns}
                    pagination={false}
                  />
                </div>
              ))}
            </Col>
            <Col span={4}>
              <POSButton
                title="Plata cash"
                color={disabled ? 'grey' : 'blue'}
                onClick={() => handleCheckout('CASH')}
                style={{ marginBottom: '1rem' }}
                disabled={disabled}
              />
              <POSButton
                title="Plata cu card"
                color={disabled ? 'grey' : 'green'}
                onClick={() => handleCheckout('CARD')}
                disabled={disabled}
              />
              <div style={{ padding: 15, paddingTop: 15 }} />
              <Input.Group compact size="small">
                <Select
                  defaultValue="RO"
                  disabled={!cuiCode}
                  onChange={(val) => setStateCode(val)}
                >
                  {countryCodes.map((val) => (
                    <Select.Option key={val} value={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
                <Input
                  placeholder="CUI Cod"
                  onChange={({ type, target: { value } }) => {
                    if (type === 'click') {
                      setVatEntity(null);
                    }
                    setCuiCode(value);
                  }}
                  allowClear
                  style={{
                    width: '70%',
                    height: '50%',
                  }}
                  height="50%"
                />
              </Input.Group>

              <div style={{ padding: 5, paddingTop: 5 }} />
              <Button disabled={!cuiCode} type="primary" onClick={getVatData}>
                Verifica CUI
              </Button>
              <div style={{ padding: 5, paddingTop: 5 }} />

              {vatEntity ? (
                <Space direction="vertical">
                  <Text type={vatEntity.isValid ? 'success' : 'danger'}>
                    {vatEntity.companyName}
                  </Text>
                  <Text type="success">{vatEntity.companyAddress}</Text>
                  <Divider style={{ margin: '1rem 0' }} />
                </Space>
              ) : (
                <Divider style={{ margin: '2rem 0' }} />
              )}

              <POSButton
                title={t('entity.pos.actions.addCard')}
                color="yellow"
                onClick={() => setOrder(null)}
              />
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
            <Col span={4} style={{ margin: '1rem 0' }}>
              <POSButton
                title="Anulare"
                color="red"
                size="small"
                onClick={handleCancelOrders}
                disabled={disabled}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Complete;
