import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const SaveButton = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      htmlType="submit"
      icon={<SaveOutlined />}
      disabled={disabled}
    >
      {t('actions.save')}
    </Button>
  );
};

export default SaveButton;
