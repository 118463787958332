import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Typography, Spin, Space } from 'antd';

import useDatasource from '../../hooks/useDatasource';

import {
  findAllByEntityType,
  downloadAllByEntityType,
} from '../../services/stocks/invoices';

import EditItemIcon from '../buttons/EditItemIcon';
import PageTitle from '../PageTitle';
import { getRetailers } from '../../services/taxonomies/retailers';
import { getPosesByRole } from '../../services/taxonomies/poses';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';
// import { getCurrentRetailer } from '../../services/admin/users';
import {
  InvoiceTypes,
  CurrentRetail,
  Retail,
  POS,
  Warehouse,
  Enrollment,
} from './constants';
import Column from '../../helpers/columns';
import useDictionaries, { EMPTY_DICTIONARY } from '../../hooks/useDictionaries';
import AuthContext, { hasPermission } from '../auth';

const STATUSES = ['CREATED', 'PENDING', 'CONFLICT', 'CONFIRMED', 'REJECTED'];

const dictionaryByEntityType = (entityType) => {
  switch (entityType) {
    // case 'current':
    //   return () =>
    //     getCurrentRetailer().then((content) => ({
    //       content: [{ id: content.retailerId, name: content.retailerName }],
    //     }));
    case 'retail':
      return getRetailers;
    case 'pos':
      return getPosesByRole;
    default:
      return EMPTY_DICTIONARY;
  }
};

const InvoicesList = ({ entityType }) => {
  const { t } = useTranslation();
  const statuses = useMemo(
    () => ({
      content: STATUSES.map((item) => ({
        id: item,
        name: t(`entity.stocks.invoice.status.${item}`),
      })),
    }),
    [t],
  );
  const invoiceTypes = useMemo(
    () => ({
      content: Object.keys(InvoiceTypes || {})?.map((val) => ({
        id: val,
        name: t(`entity.stocks.invoice.types.${val}`),
      })),
    }),
    [t],
  );
  const {
    user: { permissions },
  } = useContext(AuthContext);
  const isSuper = hasPermission(permissions, ['SYS_SUPERUSER']);
  const isHeadRetailer = hasPermission(permissions, ['HEAD_RETAILER']);
  const isWarehouse = hasPermission(permissions, ['WAREHOUSE_PERMISSION']);
  const dictionaries = useMemo(
    () => ({
      retailer:
        // eslint-disable-next-line no-nested-ternary
        isWarehouse || isSuper || isHeadRetailer
          ? dictionaryByEntityType('retail')
          : EMPTY_DICTIONARY,
      poses:
        isSuper || isHeadRetailer
          ? dictionaryByEntityType('pos')
          : EMPTY_DICTIONARY,
    }),
    [isSuper, isWarehouse, isHeadRetailer],
  );
  const [{ retailer, poses }] = useDictionaries(dictionaries);
  const sourceColumn = useCallback(
    (key, title, direction) => ({
      title,
      key,
      width: 130,
      dataIndex: key.split('.'),
      render: (value, row) => {
        if (retailer.loading || poses.loading) {
          return <Spin size="small" />;
        }
        const type =
          InvoiceTypes[row.invoiceType][direction] === CurrentRetail
            ? Retail
            : InvoiceTypes[row.invoiceType][direction];
        switch (type) {
          case Enrollment:
            return <Typography.Text>{t('pages.enrollment._')}</Typography.Text>;
          case Warehouse:
            return <Typography.Text>{t('pages.warehouse._')}</Typography.Text>;
          case POS:
            return (
              <Typography.Text>
                {poses.content.find((item) => item.id === value)?.name}
              </Typography.Text>
            );
          case Retail:
            return (
              <Typography.Text>
                {retailer.content.find((item) => item.id === value)?.name}
              </Typography.Text>
            );
          default:
            return <Typography.Text />;
        }
      },
    }),
    [retailer, poses, t],
  );
  const { pagination, criterias, content, handleChange, sort } = useDatasource(
    findAllByEntityType(entityType),
  );
  const columns = useMemo(
    () => [
      Column.dictionary(
        'invoiceType',
        t('entity.stocks.invoice.invoiceType'),
        invoiceTypes,
        { width: 150, filter: true },
      ),
      Column.text('invoiceNumber', t('entity.stocks.invoice.invoiceNumber')),
      Column.date('invoiceDate', t('entity.stocks.invoice.invoiceDate')),

      sourceColumn('originId', t('entity.stocks.invoice.origin'), 'origin'),
      sourceColumn(
        'destinationId',
        t('entity.stocks.invoice.destination'),
        'destination',
      ),
      Column.dictionary(
        'status',
        t('entity.stocks.invoice.status._'),
        statuses,
        { width: 150, filter: true },
      ),

      Column.text('records', t('entity.stocks.invoice.records')),
      Column.text('totalQuantity', t('entity.stocks.invoice.items')),
      Column.text(
        'totalWithoutTva',
        t('entity.stocks.invoice.totalWithoutTva'),
      ),
      Column.text('totalWithTva', t('entity.stocks.invoice.totalWithTva')),

      Column.text('createdBy', t('entity.stocks.invoice.createdBy')),
      Column.date('createdAt', t('entity.stocks.invoice.createdAt')),
      Column.text('updatedBy', t('entity.stocks.invoice.updatedBy')),
      Column.date('updatedAt', t('entity.stocks.invoice.updatedAt')),
      Column.bool('deleted', t('entity.taxonomy.transportUnit.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.transportUnit.deleted.true'),
          t('entity.taxonomy.transportUnit.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <>
          <EditItemIcon path={`/invoices/${record.invoiceType}/${record.id}`} />
        </>
      )),
    ],
    [t, sourceColumn, statuses, invoiceTypes],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAllByEntityType(entityType)}
          />
        </Space>
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        scroll={{ x: 700, y: 700 }}
        onChange={handleChange}
      />
    </>
  );
};

export default InvoicesList;
