import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import SaleReportOrdersGrid from '../../../components/sale-report-orders/SaleReportOrdersGrid';

const SaleReportOrders = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.salesReportOrders._')} />
      <SaleReportOrdersGrid />
    </Default>
  );
};

export default SaleReportOrders;
