import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditArticleTypeForm from '../../../../components/article-types/EditArticleTypeForm';

import {
  findOne,
  create,
  update,
} from '../../../../services/stocks/article-types';

const initialArticleType = {
  deleted: false,
  enrollable: false,
  external: false,
};

const EditArticleType = () => {
  const [articleType, handleSubmit, handleCancel] = useEditPage({
    initial: initialArticleType,
    existent: findOne,
    goBackPath: '/admin/taxonomies/article-types',

    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {articleType !== undefined && (
        <EditArticleTypeForm
          articleType={articleType}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditArticleType;
