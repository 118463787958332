import React from 'react';
import { useTranslation } from 'react-i18next';

import { Default } from '../../../layouts';
import PageTitle from '../../../components/PageTitle';
import EnrolledProductsList from '../../../components/enrolled-products/EnrolledProductsList';

const EnrolledProducts = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.reports.enrolledProducts._')} />
      <EnrolledProductsList />
    </Default>
  );
};

export default EnrolledProducts;
