import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import {
  createOfferType,
  getOfferTypeById,
  updateOfferType,
} from '../../../../services/offers/offerTypes';
import EditOfferTypeForm from '../../../../components/offers/offer-types/EditOfferTypeForm';

const initialOfferType = { products: [], productGroups: [], offers: [] };

const EditOfferType = () => {
  const [offerType, handleSubmit, handleCancel] = useEditPage({
    initial: initialOfferType,
    existent: getOfferTypeById,
    goBackPath: '/admin/offers/offer-types',

    onCreate: createOfferType,
    onUpdate: updateOfferType,
  });

  return (
    <Default>
      {offerType !== undefined && (
        <EditOfferTypeForm
          offerType={offerType}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditOfferType;
