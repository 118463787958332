import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'antd';

import { FormContext } from '../../../../hooks/useForm';

import FormWrapper from '../../../FormWrapper';

const DateValidation = ({ path }) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);

  const { select } = form;

  const options = useMemo(
    () =>
      [false, true].map((v, index) => ({
        id: index,
        name: t(
          `entity.offers.restrictions.restriction.DATE_VALIDATION.options.${v}`,
        ),
      })),
    [t],
  );

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Select
          label={t(
            'entity.offers.restrictions.restriction.DATE_VALIDATION.validityDate',
          )}
          props={{
            options,
            ...select(`${path}.value1`),
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Select
          label={t(
            'entity.offers.restrictions.restriction.DATE_VALIDATION.globalExpireDate',
          )}
          props={{
            options,
            ...select(`${path}.value2`),
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <FormWrapper.Select
          label={t(
            'entity.offers.restrictions.restriction.DATE_VALIDATION.validityEndTime',
          )}
          props={{
            options,
            ...select(`${path}.value3`),
          }}
        />
      </Col>
    </Row>
  );
};

export default DateValidation;
