import React, { useMemo } from 'react';
import { Table, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';
import {
  getRetailUsers,
  downLoadRetailUsers,
} from '../../services/admin/users';
import EditItemIcon from '../buttons/EditItemIcon';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';
import PageTitle from '../PageTitle';

const RetailList = () => {
  const [t] = useTranslation();

  const { pagination, criterias, content, handleChange, sort } = useDatasource(
    (args) => {
      return getRetailUsers({
        ...args,
        criterias: { ...args.criterias },
      });
    },
  );

  const columns = useMemo(
    () => [
      Column.text('fullname', t('entity.admin.user.fullname'), {
        sort: false,
        filter: true,
      }),
      Column.text('identityCard', t('entity.admin.user.identityCard'), {
        sort: false,
        filter: true,
      }),
      Column.text('retailerName', t('entity.taxonomy.retailer._singular')),
      Column.text('posName', t('entity.taxonomy.poses._singular')),
      Column.actions(
        t('table.actions'),
        ({ id, retailerId }) =>
          retailerId && (
            <>
              <EditItemIcon path={`/retail/${id}`} />
            </>
          ),
      ),
    ],
    [t],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downLoadRetailUsers}
          />
        </Space>
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </>
  );
};
export default RetailList;
