import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Default } from '../../layouts';

import ViewAccountForm from '../../components/accounts/ViewAccountForm';
import { useEditPage } from '../../hooks/useEditPage';

import { getAccountById } from '../../services/accounts/accounts';

const ViewAccount = () => {
  const history = useHistory();

  const [account] = useEditPage({
    existent: getAccountById,
  });

  const handleCancel = useCallback(
    () => history.push('/admin/accounts'),
    [history],
  );

  return (
    <Default>
      {account !== undefined && (
        <ViewAccountForm account={account} onCancel={handleCancel} />
      )}
    </Default>
  );
};

export default ViewAccount;
