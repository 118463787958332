import React from 'react';

import { Default } from '../../../../../layouts';

import { useEditPage } from '../../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../../services/taxonomies/transport/cost-centers';

import EditTransportCostCenterForm from '../../../../../components/taxonomies/transports/EditTransportCostCenterForm';

const initial = {};

const EditTransportType = () => {
  const [costCenter, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/transport/cost-centers',

    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {costCenter !== undefined && (
        <EditTransportCostCenterForm
          costCenter={costCenter}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditTransportType;
