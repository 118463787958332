import React, { useMemo, useEffect } from 'react';
import * as yup from 'yup';

import { Table, Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import useDatasource from '../../hooks/useDatasource';
import { getDocTypes } from '../../services/taxonomies/doc-types';
import Column from '../../helpers/columns';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';
import { getInstitutions } from '../../services/taxonomies/institutions';
import { ShowColumns } from './ShowColumns';

const selectionHandler = (value, key, setValue) => ({
  selectedRowKeys: value || [],
  onSelect: (record, selected) =>
    selected
      ? setValue(key, [...(value || []), record.id])
      : setValue(
          key,
          value.filter((id) => id !== record.id),
        ),
  getCheckboxProps: (record) => ({
    disabled:
      record.deleted &&
      (value?.length > 0 ? value?.indexOf(record.id) === -1 : true),
  }),
});

const EditUserTypeForm = ({ userType, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        code: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const { value, handleSubmit, input, setValue, setFormValue, checkbox } =
    useForm({
      initialValue: userType,
      schema: validationSchema,
      onSubmit,
      onCancel,
    });

  const showColumns = useMemo(
    () =>
      Object.values(ShowColumns).map((it) => ({
        id: it,
        name: t(`entity.taxonomy.userType.showColumns.${it}`),
      })),
    [t],
  );

  useEffect(() => {
    setFormValue(userType);
  }, [setFormValue, userType]);

  const { deleted } = value;

  const docTypesColumns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.docType.name'), {
        width: 300,
      }),
      Column.text('description', t('entity.taxonomy.docType.description')),
    ],
    [t],
  );

  const showColumnColumns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.userType.showColumns.name'), {
        width: 300,
        sort: false,
      }),
    ],
    [t],
  );

  const institutionsColumns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.docType.name'), {
        width: 300,
      }),
    ],
    [t],
  );

  const {
    // loading: docTypespLoading,
    pagination: docTypespPagination,
    content: docTypes,
    handleChange: docTypesHandleChange,
  } = useDatasource(getDocTypes);

  const {
    // loading: institutionsLoading,
    pagination: institutionsPagination,
    content: institutions,
    handleChange: institutionsHandleChange,
  } = useDatasource(getInstitutions);

  const docTypesSelection = useMemo(
    () => selectionHandler(value.docTypes, 'docTypes', setValue),
    [value.docTypes, setValue],
  );

  const showColumnsSelection = useMemo(
    () => selectionHandler(value.showColumns, 'showColumns', setValue),
    [value.showColumns, setValue],
  );

  const institutionsSelection = useMemo(
    () => selectionHandler(value.institutions, 'institutions', setValue),
    [value.institutions, setValue],
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={userType.name || t('entity.taxonomy.userType._singular')}
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.userType.name')}
              props={{ ...input('name'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.userType.description')}
              props={{ ...input('description'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.admin.role.code')}
              props={{ ...input('code') }}
            />

            <FormWrapper.Switch
              label={t('entity.taxonomy.userType.hasDiscountOrFree')}
              props={{ ...checkbox('hasDiscountOrFree') }}
            />

            <Space style={{ paddingTop: '1rem' }}>
              {!deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={t('entity.taxonomy.docType._plural')}
                key="docTypes"
              >
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    ...docTypesSelection,
                  }}
                  columns={docTypesColumns}
                  rowKey="id"
                  // loading={docTypespLoading}
                  pagination={docTypespPagination}
                  dataSource={docTypes}
                  onChange={docTypesHandleChange}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('entity.taxonomy.userType.showColumns._')}
                key="showColumns"
              >
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    ...showColumnsSelection,
                  }}
                  columns={showColumnColumns}
                  rowKey="id"
                  dataSource={showColumns}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('entity.taxonomy.institutions._plural')}
                key="subdivisions"
              >
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    ...institutionsSelection,
                  }}
                  columns={institutionsColumns}
                  rowKey="id"
                  // loading={institutionsLoading}
                  pagination={institutionsPagination}
                  dataSource={institutions}
                  onChange={institutionsHandleChange}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('entity.taxonomy.userType.details')}
                key="details"
              >
                <FormWrapper.TextArea
                  label={t('entity.taxonomy.transportCostCenter.description')}
                  props={{
                    ...input('details'),
                    disabled: value.deleted,
                    rows: 6,
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditUserTypeForm;
