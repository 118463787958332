import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Space } from 'antd';
import useForm, { FormContext } from '../../../hooks/useForm';
import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';
import { Calendars } from '../OffersConstants';

const EditCalendarForm = ({ calendar, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        calendarType: yup.string().required(t('errors.mustSelected')),
        startDate: yup.date().required(t('errors.notEmpty')),
        endDate: yup
          .date()
          .min(yup.ref('startDate'), t('errors.endDateMustBeAfterStartDate'))
          .required(t('errors.notEmpty')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: calendar,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const { handleSubmit, value, input, custom, select, setFormValue } = form;

  useEffect(() => {
    setFormValue(calendar);
  }, [setFormValue, calendar]);

  const disabledStartDate = (current) => {
    if (!value.endDate) {
      return false;
    }
    return current && current > value.endDate;
  };

  const disabledEndDate = (current) => {
    if (!value.startDate) {
      return false;
    }
    return current && current < value.startDate;
  };

  const { deleted } = value;

  return (
    <>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.Input
                label={t('entity.offers.calendars.list.name')}
                props={{ ...input('name'), disabled: deleted }}
              />
              <FormWrapper.Select
                label={t('entity.offers.product.category._')}
                props={{
                  options: Calendars.content,
                  loading: Calendars.loading,
                  ...select('calendarType'),
                  disabled: value.deleted,
                  labelProp: (val) =>
                    t(`entity.offers.calendars.list.calendarTypes.${val.id}`),
                }}
              />
              <FormWrapper.DateTimePicker
                key="startDate"
                label={t(`entity.offers.calendars.list.startDate`)}
                props={{
                  ...custom('startDate'),
                  disabled: deleted,
                  hideTime: true,
                  disabledDate: disabledStartDate,
                }}
              />

              <FormWrapper.DateTimePicker
                key="endDate"
                label={t(`entity.offers.calendars.list.endDate`)}
                props={{
                  ...custom('endDate'),
                  disabled: deleted,
                  hideTime: true,
                  disabledDate: disabledEndDate,
                }}
              />
            </FormWrapper.Single>
          </FormWrapper>

          <Space>
            {!deleted && <SaveButton />}
            <CancelButton onCancel={onCancel} />
          </Space>
        </form>
      </FormContext.Provider>
    </>
  );
};

export default EditCalendarForm;
