import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { Table } from 'antd';
import useDatasource from '../../../../hooks/useDatasource';
import {
  softDeleteSystemParameter,
  searchSystemParameters,
} from '../../../../services/taxonomies/system-parameters';
import Column from '../../../../helpers/columns';
import { Default } from '../../../../layouts';
import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';

const SystemParametersList = () => {
  const { t } = useTranslation();
  const { content, pagination, handleChange, reload } = useDatasource(
    searchSystemParameters,
  );

  const columns = useMemo(
    () => [
      Column.text('code', t('entity.taxonomy.system-parameters.code'), {
        filter: true,
      }),
      Column.text('name', t('entity.taxonomy.system-parameters.name'), {
        filter: true,
      }),
      Column.text('value', t('entity.taxonomy.system-parameters.value'), {
        filter: true,
      }),
      Column.text(
        'groupCode',
        t('entity.taxonomy.system-parameters.groupCode'),
        {
          filter: true,
        },
      ),
      Column.text(
        'description',
        t('entity.taxonomy.system-parameters.description'),
        {
          filter: true,
        },
      ),
      Column.actions(t('table.actions'), (param) => (
        <>
          <EditItemIcon
            path={`/admin/taxonomies/system-parameters/${param.id}`}
          />
          <DeleteItemIcon
            title={t('entity.taxonomy.system-parameters._delete', param)}
            message={t('entity.taxonomy.system-parameters._deleted', param)}
            item={param.id}
            action={softDeleteSystemParameter}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );
  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.system-parameters._plural')} />
      <AddEntityButton
        entity={t('entity.taxonomy.system-parameters._singular')}
        path="/admin/taxonomies/system-parameters/new"
      />
      <Table
        columns={columns}
        rowKey="id"
        dataSource={content}
        pagination={pagination}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default SystemParametersList;
