import { Popover, Spin } from 'antd';
import React from 'react';

const RawStatusPopover = ({ tvmId, data, children, device }) => (
  <Popover
    trigger="click"
    content={
      data.tvmId === tvmId ? (
        <textarea
          cols="100"
          rows="20"
          readOnly
          style={{ border: 'none', outline: 'none' }}
          value={JSON.stringify(data[device], undefined, 4)}
        />
      ) : (
        <Spin tip="Loading" />
      )
    }
  >
    {children}
  </Popover>
);

export default RawStatusPopover;
