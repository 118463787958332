import { genericCRUDService, search } from '../../helpers/api';

export const SERVICE_URI = '/portal';
export const BASE_URI = '/api/v1';
export const { findAll, findOne } = genericCRUDService(
  '/portal',
  '/api/v1/status',
);
export const { downloadAll } = genericCRUDService(
  SERVICE_URI,
  `${BASE_URI}/sales/report`,
);
export const getSaleReport = async (data) =>
  search(`${SERVICE_URI}${BASE_URI}/sales/report`, data);
