import React from 'react';
import { Input } from 'antd';

const PhoneInput = ({ value, onChange }) => {
  const handleOnChange = ({ target }) => {
    const { value: newValue } = target;
    const reg = /^\+?\d{0,16}$/;
    if (reg.test(newValue) || newValue === '') {
      onChange(newValue);
    }
  };
  return <Input value={value} onChange={handleOnChange} />;
};
export default PhoneInput;
