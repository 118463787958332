import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Button, notification, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';
import EditValidatorForm from '../../../../components/validators/EditValidatorForm';
import { useEditPage } from '../../../../hooks/useEditPage';
import { Default } from '../../../../layouts';
import {
  create,
  findOne,
  update,
} from '../../../../services/validators/validators';
import {
  activate,
  activateDevice,
  findOne as getTVMStatus,
  inactivate,
  inactivateDevice,
  ping,
} from '../../../../services/tvm/tvm';
import TVMDetails from './TVMDetails';
import PageTitle from '../../../../components/PageTitle';
import AlertsDashboard from '../../../tvm/alerts/AlertsDashboard';
import TVMSalesDashboard from '../../../tvm/TVMSalesDashboard';
import AuthContext, { hasPermission } from '../../../../components/auth';
import TVMLogFilesDashboard from '../../../tvm/TVMLogFilesDashboard';
import RemoteDevice from './remoteDevice';

const initial = {};

const REMOTE_COMMAND_REFETCH_DELAY = 4000;
const EditValidator = () => {
  const {
    user: { permissions },
  } = useContext(AuthContext);

  const [enableRemoteActions] = useState(window._env_.ENABLE_REMOTE_ACTIONS);
  const isRemoteActionsEnabled = enableRemoteActions === 'true';

  const [validator, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: hasPermission(permissions, ['TVM_DASHBOARD_PERMISSION'])
      ? '/tvm'
      : '/admin/taxonomies/validators',
    onCreate: create,
    onUpdate: update,
  });
  const { t } = useTranslation();
  const [tvmDetails, setTvmDetails] = useState(null);

  useEffect(() => {
    if (validator && validator.id && validator.equipmentType === 'TVM') {
      getTVMStatus(validator.id).then((res) => setTvmDetails(res));
    } else {
      setTvmDetails(null);
    }
  }, [validator]);

  const handlePing = useCallback(
    (tvmId) => {
      const startTime = performance.now(); // Start time
      ping(tvmId)
        .then((response) => {
          const endTime = performance.now(); // End time
          const timeTaken = endTime - startTime;
          if (!response.ok) {
            response.json().then((reason) => {
              switch (reason.message) {
                case 'errors.tvmIpNull':
                  notification.error({
                    message: t('entity.tvm.actions.errorIP'),
                  });
                  break;
                case 'errors.tvmNotFound':
                  notification.error({
                    message: t('entity.tvm.actions.errorID'),
                  });
                  break;
                default:
                  notification.error({
                    message: JSON.stringify(reason),
                  });
              }
            });
          }
          if (response.status === 200) {
            notification.success({
              message: t('entity.tvm.actions.ping.success', { timeTaken }),
            });
          }
        })
        .catch(() => {
          notification.error({
            message: t('entity.tvm.actions.error'),
          });
        });
    },
    [t],
  );

  const handleActivate = useCallback(
    (tvmId) => {
      activate(tvmId)
        .then((response) => {
          if (!response.ok) {
            response.json().then((reason) => {
              switch (reason.message) {
                case 'errors.tvmIpNull':
                  notification.error({
                    message: t('entity.tvm.actions.errorIP'),
                  });
                  break;
                case 'errors.tvmNotFound':
                  notification.error({
                    message: t('entity.tvm.actions.errorID'),
                  });
                  break;
                default:
                  notification.error({
                    message: JSON.stringify(reason),
                  });
              }
            });
          }
          if (response.status === 200) {
            setTimeout(() => {
              notification.success({
                message: t('entity.tvm.actions.activate.success'),
              });
              getTVMStatus(validator.id).then((res) => setTvmDetails(res));
            }, 1000);
          }
        })
        .catch(() => {
          notification.error({
            message: t('entity.tvm.actions.error'),
          });
        });
    },
    [t, validator?.id],
  );

  const handleInactivate = useCallback(
    (tvmId) => {
      inactivate(tvmId)
        .then((response) => {
          if (!response.ok) {
            response.json().then((reason) => {
              switch (reason.message) {
                case 'errors.tvmIpNull':
                  notification.error({
                    message: t('entity.tvm.actions.errorIP'),
                  });
                  break;
                case 'errors.tvmNotFound':
                  notification.error({
                    message: t('entity.tvm.actions.errorID'),
                  });
                  break;
                default:
                  notification.error({
                    message: JSON.stringify(reason),
                  });
              }
            });
          }
          if (response.status === 200) {
            setTimeout(() => {
              notification.success({
                message: t('entity.tvm.actions.inactivate.success'),
              });
              getTVMStatus(validator.id).then((res) => setTvmDetails(res));
            }, 1000);
          }
        })
        .catch(() => {
          notification.error({
            message: t('entity.tvm.actions.error'),
          });
        });
    },
    [t, validator?.id],
  );

  const handleActivateDevice = useCallback(
    (tvmId, device) => {
      activateDevice(tvmId, device)
        .then((response) => {
          if (!response.ok) {
            response.json().then((reason) => {
              switch (reason.message) {
                case 'errors.tvmIpNull':
                  notification.error({
                    message: t('entity.tvm.actions.errorIP'),
                  });
                  break;
                case 'errors.tvmNotFound':
                  notification.error({
                    message: t('entity.tvm.actions.errorID'),
                  });
                  break;
                default:
                  notification.error({
                    message: JSON.stringify(reason),
                  });
              }
            });
          }
          if (response.status === 200) {
            setTimeout(() => {
              notification.success({
                message: t('entity.tvm.actions.activate.success'),
              });
              getTVMStatus(validator.id).then((res) => setTvmDetails(res));
            }, REMOTE_COMMAND_REFETCH_DELAY);
          }
        })
        .catch(() => {
          notification.error({
            message: t('entity.tvm.actions.error'),
          });
        });
    },
    [t, validator?.id],
  );

  const handleInactivateDevice = useCallback(
    (tvmId, device) => {
      inactivateDevice(tvmId, device)
        .then((response) => {
          if (!response.ok) {
            response.json().then((reason) => {
              switch (reason.message) {
                case 'errors.tvmIpNull':
                  notification.error({
                    message: t('entity.tvm.actions.errorIP'),
                  });
                  break;
                case 'errors.tvmNotFound':
                  notification.error({
                    message: t('entity.tvm.actions.errorID'),
                  });
                  break;
                default:
                  notification.error({
                    message: JSON.stringify(reason),
                  });
              }
            });
          }
          if (response.status === 200) {
            setTimeout(() => {
              notification.success({
                message: t('entity.tvm.actions.inactivate.success'),
              });
              getTVMStatus(validator.id).then((res) => setTvmDetails(res));
            }, REMOTE_COMMAND_REFETCH_DELAY);
          }
        })
        .catch(() => {
          notification.error({
            message: t('entity.tvm.actions.error'),
          });
        });
    },
    [t, validator?.id],
  );

  return (
    <Default>
      {validator !== undefined && (
        <>
          <PageTitle title={validator.name} />
          <Tabs>
            {validator?.equipmentType === 'TVM' &&
              hasPermission(permissions, ['SYS_SUPERUSER']) && (
                <>
                  <Tabs.TabPane tab={t('entity.tvm.sales')} key="tvmRaports">
                    <TVMSalesDashboard
                      id={validator.id}
                      createdAt={validator.createdAt}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={t('entity.tvm.logFilesList')}
                    key="tvmLogList"
                  >
                    <TVMLogFilesDashboard id={validator.id} />
                  </Tabs.TabPane>
                  {isRemoteActionsEnabled && (
                    <Tabs.TabPane
                      tab={t('entity.tvm.actions._')}
                      key="tvmActions"
                    >
                      {tvmDetails?.remoteStatus === 'REMOTE_INACTIVATE' && (
                        <div style={{ padding: '2px' }}>
                          <h2>{t('entity.tvm.actions.remoteInactive')}</h2>
                        </div>
                      )}
                      <div style={{ padding: '2px' }}>
                        <Button
                          type="primary"
                          icon={<ReloadOutlined />}
                          onClick={() => handlePing(validator.id)}
                          size="midle"
                        >
                          {t('entity.tvm.actions.ping._')}
                        </Button>
                      </div>
                      {tvmDetails?.remoteStatus === 'REMOTE_INACTIVATE' && (
                        <div style={{ padding: '2px' }}>
                          <Button
                            type="primary"
                            onClick={() => handleActivate(validator.id)}
                            size="midle"
                          >
                            {t('entity.tvm.actions.activate._')}
                          </Button>
                        </div>
                      )}
                      {!tvmDetails?.remoteStatus && (
                        <div style={{ padding: '2px' }}>
                          <Button
                            type="primary"
                            onClick={() => handleInactivate(validator.id)}
                            size="midle"
                          >
                            {t('entity.tvm.actions.inactivate._')}
                          </Button>
                        </div>
                      )}
                      <RemoteDevice
                        t={t}
                        tvmDetails={tvmDetails}
                        validator={validator}
                        handleInactivateDevice={handleInactivateDevice}
                        handleActivateDevice={handleActivateDevice}
                      />
                    </Tabs.TabPane>
                  )}
                </>
              )}
            <Tabs.TabPane tab="Echipament" key="equipment">
              <EditValidatorForm
                validator={validator}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                permisions={permissions}
              />
            </Tabs.TabPane>
            {tvmDetails && (
              <Tabs.TabPane tab="Detalii" key="tvmdetails">
                <TVMDetails details={tvmDetails} />
              </Tabs.TabPane>
            )}
            {validator?.equipmentType === 'TVM' && (
              <Tabs.TabPane tab={t('entity.tvm.alerts.title')} key="tvmAlerts">
                <AlertsDashboard id={validator.id} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </>
      )}
    </Default>
  );
};

export default EditValidator;
