import React, { useEffect } from 'react';
import { Select } from 'antd';
import FormWrapper from '../FormWrapper';
import useDatasource from '../../hooks/useDatasource';
import { getInstitutions } from '../../services/taxonomies/institutions';

const SearchInstitutions = ({ label, props }) => {
  const {
    invalid: errorMessage,
    getValue,
    name,
    disabled,
    field,
    searchField,
    setValue,
    labelProp = 'name',
    userTypes,
    userTypeId,
    resetSubdivisions,
  } = props;
  const ids = userTypes.content.find(
    (ut) => userTypeId === ut.id,
  )?.institutions;
  const { content, handleChange, reload } = useDatasource((data) =>
    (ids || []).length > 0
      ? getInstitutions({
          ...data,
          pageSize: 1000,
          criterias: {
            ...data.criterias,
            ids,
          },
        })
      : Promise.resolve({
          content: [],
          loading: false,
          last: true,
          pageNumber: 1,
          pageSize: 10,
          totalElements: 0,
        }),
  );
  useEffect(() => reload(), [userTypeId, reload]);
  return (
    <FormWrapper.FieldWrapper label={label} errorMessage={errorMessage}>
      <Select
        allowClear
        showSearch
        // loading={loading}
        disabled={disabled}
        value={getValue(name)}
        style={{ width: '100%' }}
        onChange={(val) => {
          resetSubdivisions();
          setValue(name, val);
        }}
        onSearch={(filter) =>
          handleChange(null, { [searchField]: [filter] }, {})
        }
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }
      >
        {content.map((el) => (
          <Select.Option value={el.id} key={el[field]}>
            {typeof labelProp === 'string' ? el[labelProp] : labelProp(el)}
          </Select.Option>
        ))}
      </Select>
    </FormWrapper.FieldWrapper>
  );
};
export default SearchInstitutions;
