import React from 'react';

import { Default } from '../../../../../layouts';

import { useEditPage } from '../../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../../services/taxonomies/transport/brands';

import EditTransportBrandForm from '../../../../../components/taxonomies/transports/EditTransportBrandForm';

const initial = {};

const EditTransportBrand = () => {
  const [brand, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/transport/brands',

    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {brand !== undefined && (
        <EditTransportBrandForm
          brand={brand}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditTransportBrand;
