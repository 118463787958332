import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';

import {
  Table,
  Tooltip,
  Button,
  notification,
  Modal,
  Row,
  Col,
  Popconfirm,
} from 'antd';

import useDatasource from '../../hooks/useDatasource';

import Column from '../../helpers/columns';
import {
  getShiftsByPosId,
  getShiftSummaryById,
  closeShiftById,
} from '../../services/pos/pos';

const ShiftsList = ({ posId, disabled }) => {
  const [t] = useTranslation();
  const [summaryCard, setSummaryCard] = useState(null);
  const [currentShift, setCurrentShift] = useState(null);
  const [summaryCash, setSummaryCash] = useState(null);
  const { pagination, content, handleChange, reload } = useDatasource(
    getShiftsByPosId(posId, ['startedAt', 'desc']),
  );
  const handleCloseShift = useCallback(
    (id) => {
      closeShiftById(id)
        .then(() => {
          notification.success({
            message: t('pages.admin.taxonomies.poses.tabs.shifts._deleted'),
          });
          reload();
          setCurrentShift({
            id,
            deleted: true,
          });
        })
        .catch((msg) => {
          console.error(msg);
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};

          notification.error({
            message: messageCode
              ? t(messageCode)
              : t('pages.admin.taxonomies.poses.tabs.shifts.deleteFailed'),
          });
          reload();
        });
    },
    [reload, t, setCurrentShift],
  );
  const handleSummary = useCallback(
    (record) => () => {
      getShiftSummaryById(record.id)
        .then((res) => {
          setCurrentShift(record);
          setSummaryCard([
            ...res.summaryCard,
            {
              productName: 'TOTAL Schimb',
              totalPrice: res.summaryCard.reduce(
                (acc, row) => acc + row.qty * row.price,
                0,
              ),
            },
          ]);
          setSummaryCash([
            ...res.summaryCash,
            {
              productName: 'TOTAL Schimb',
              totalPrice: res.summaryCash.reduce(
                (acc, row) => acc + row.qty * row.price,
                0,
              ),
            },
          ]);
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: err.message,
          });
        });
    },
    [],
  );
  const sumaryColumns = useMemo(
    () => [
      {
        title: 'Cod Articol',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Categorie',
        dataIndex: 'productCategory',
        key: 'productCategory',
      },
      {
        title: 'Denumire produs',
        dataIndex: 'productName',
        key: 'productCategory',
      },
      {
        title: 'Pret',
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (_, row) =>
          // eslint-disable-next-line no-nested-ternary
          row.price
            ? row.productCategory === 'PURSE'
              ? (row.qty * 0.01).toFixed(2)
              : (row.price * 0.01).toFixed(2)
            : '',
      },
      {
        title: 'Cantitate',
        width: 150,
        dataIndex: 'qty',
        key: 'qty',
        render: (_, row) => (row.category === 'PURSE' ? '-' : row.qty),
      },
      {
        title: 'Pret total',
        width: 150,
        key: 'totalPrice',
        render: (_, row) => (
          <>
            {((row.totalPrice || row.qty * row.price || 0) * 0.01).toFixed(2)}
          </>
        ),
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      Column.date(
        'startedAt',
        t('pages.admin.taxonomies.poses.tabs.shifts.startedAt'),
        {
          filter: true,
          width: 110,
        },
      ),
      Column.date(
        'endedAt',
        t('pages.admin.taxonomies.poses.tabs.shifts.endedAt'),
        {
          filter: true,
          width: 110,
        },
      ),
      Column.text(
        'userName',
        t('pages.admin.taxonomies.poses.tabs.shifts.user.name'),
        {
          filter: true,
          width: 50,
          sort: false,
        },
      ),
      Column.text(
        'userIdentityCard',
        t('pages.admin.taxonomies.poses.tabs.shifts.user.identityCard'),
        {
          filter: true,
          width: 50,
          sort: false,
        },
      ),
      Column.text(
        'userEmail',
        t('pages.admin.taxonomies.poses.tabs.shifts.user.email'),
        {
          filter: true,
          width: 50,
        },
      ),
      Column.actions(t('table.actions'), (record) => (
        <span
          style={{
            textAlign: 'right',
          }}
        >
          <Tooltip title={t('actions.view')}>
            <Button
              shape="circle"
              type="link"
              icon={<EyeOutlined />}
              disabled={disabled}
              onClick={handleSummary(record)}
              size="midle"
            />
          </Tooltip>
        </span>
      )),
    ],
    [t, disabled, handleSummary],
  );

  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
      <Modal
        title="Raport inchidere schimb"
        visible={summaryCard != null && summaryCash != null}
        closable={false}
        width="1000px"
        footer={[
          <Popconfirm
            key="delete"
            placement="topLeft"
            title={t('pages.admin.taxonomies.poses.tabs.shifts._delete')}
            disabled={currentShift?.deleted || currentShift?.endedAt}
            onConfirm={() => handleCloseShift(currentShift.id)}
            okText={t('pages.admin.taxonomies.poses.tabs.shifts.yes')}
            cancelText={t('pages.admin.taxonomies.poses.tabs.shifts.no')}
          >
            <Button
              type="danger"
              disabled={currentShift?.deleted || currentShift?.endedAt}
            >
              {t('pages.admin.taxonomies.poses.tabs.shifts.closeShift')}
            </Button>
          </Popconfirm>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setSummaryCard(null);
              setSummaryCash(null);
            }}
          >
            {t('actions.cancel')}
          </Button>,
        ]}
      >
        <Row>
          <Col>Plata cu card</Col>
        </Row>
        <Table
          dataSource={summaryCard}
          columns={sumaryColumns}
          pagination={false}
        />
        <Row>
          <Col>Plata cash</Col>
        </Row>
        <Table
          dataSource={summaryCash}
          columns={sumaryColumns}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default ShiftsList;
