import { search, get } from '../../helpers/api';

export const ERP_INVOICES_SERVICE_URI = '/erp-integration';
export const ERP_INVOICES_BASE_URI = '/api/v1/invoice-transactions';

export const getTransactions = (data) =>
  search(`${ERP_INVOICES_SERVICE_URI}${ERP_INVOICES_BASE_URI}`, data);

export const retryInvoiceGeneration = async (id) =>
  get(
    `${ERP_INVOICES_SERVICE_URI}${ERP_INVOICES_BASE_URI}/retryGenerateInvoice/${id}`,
  );

export const cancelInvoiceGeneration = async (id) =>
  get(
    `${ERP_INVOICES_SERVICE_URI}${ERP_INVOICES_BASE_URI}/${id}/status/CANCELED`,
  );
