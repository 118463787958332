import React, { useMemo, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Space } from 'antd';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';

const EditTVARatesForm = ({ tvaRate, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        tvaRate: yup.string().required(t('errors.notEmpty')),
        dateStart: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const { value, handleSubmit, input, custom, setFormValue } = useForm({
    initialValue: tvaRate,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  useEffect(() => {
    setFormValue(tvaRate);
  }, [setFormValue, tvaRate]);

  const { deleted } = value;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle title={t('entity.taxonomy.tvaRate._singular')} />
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Number
              label={t('entity.taxonomy.tvaRate._singular')}
              props={{
                ...input('tvaRate'),
                min: '0',
                max: '100',
                step: '0.01',
                formatter: (tva) => `${tva}%`,
                disabled: deleted,
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.tvaRate.description')}
              props={{ ...input('description'), disabled: deleted }}
            />
            <FormWrapper.RangePicker
              label={`${t('entity.taxonomy.tvaRate.dateStart')} / ${t(
                'entity.taxonomy.tvaRate.dateEnd',
              )}`}
              props={{
                start: custom('dateStart'),
                end: custom('dateEnd'),
                allowEmpty: [false, true],
                disabled: deleted,
              }}
            />
            <Space style={{ paddingTop: '1rem' }}>
              {!deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditTVARatesForm;
