import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Space } from 'antd';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';
import Comments from './comments/Comments';

export const BurglaryStatuses = [
  { id: 'NEW' },
  { id: 'IN_PROGRESS' },
  { id: 'CLOSED' },
];

const EditBurglaryForm = ({ users, burglary, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const { value, handleSubmit, input, setValue, setFormValue, custom, select } =
    useForm({
      initialValue: burglary,
      onSubmit,
      onCancel,
    });

  useEffect(() => {
    setFormValue(burglary);
  }, [setFormValue, burglary]);

  const { comments } = value;

  const readOnly = useMemo(() => burglary.status === 'CLOSED', [burglary]);

  return (
    <form onSubmit={handleSubmit}>
      <FormWrapper>
        <FormWrapper.Single>
          <PageTitle
            title={burglary.name || t('entity.tvm.burglary._singular')}
          />
          {['id', 'tvmId', 'tvmName'].map((key) => (
            <FormWrapper.Input
              key={key}
              label={t(`entity.tvm.burglary.${key}`)}
              props={{ ...input(key), disabled: true }}
            />
          ))}

          <FormWrapper.DateTimePicker
            key="createdAt"
            label={t(`entity.tvm.burglary.createdAt`)}
            props={{ ...custom('createdAt'), disabled: true }}
          />

          <FormWrapper.DateTimePicker
            key="updatedAt"
            label={t(`entity.tvm.burglary.updatedAt`)}
            props={{ ...custom('updatedAt'), disabled: true }}
          />

          <FormWrapper.Select
            label={t('entity.tvm.burglary.personId')}
            key="personId"
            props={{
              options: users,
              ...select('personId'),
              labelProp: ({ email }) => email || 'No email',
              keyProp: 'id',
              disabled: readOnly,
            }}
          />

          <FormWrapper.Select
            label={t('entity.tvm.burglary.status')}
            key="status"
            props={{
              options: BurglaryStatuses,
              ...select('status'),
              labelProp: ({ id }) => id,
              keyProp: 'id',
              disabled: readOnly,
            }}
          />

          <Space style={{ paddingTop: '2rem' }}>
            {!readOnly && <SaveButton />}
            <CancelButton onCancel={onCancel} />
          </Space>
        </FormWrapper.Single>
        <Col span={12}>
          <Comments
            users={users}
            setValue={(newVal) => setValue('comments', newVal)}
            value={comments}
            enableComment={!readOnly}
          />
        </Col>
      </FormWrapper>
    </form>
  );
};
export default EditBurglaryForm;
