import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';
import { Default } from '../../layouts';

import PageTitle from '../../components/PageTitle';
import ValidatorsStatuses from './ValidatorsStatuses';

const ValidatorsDashboard = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.validators_dashboard._')} />
      <Tabs>
        <Tabs.TabPane
          tab={t('pages.validators_dashboard.tabs.validators')}
          key="validators"
          active
        >
          <ValidatorsStatuses />
        </Tabs.TabPane>
      </Tabs>
    </Default>
  );
};

export default ValidatorsDashboard;
