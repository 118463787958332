import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';
import {
  createOffer,
  getOfferById,
  updateOffer,
} from '../../../../services/offers/offers';
import EditOfferForm from '../../../../components/offers/offers/EditOfferForm';

const initialOffer = {
  dateStart: new Date(),
  dateEnd: new Date(),
  products: [],
  productGroups: [],
};

const EditOffer = () => {
  const [offer, handleSubmit, handleCancel] = useEditPage({
    initial: initialOffer,
    existent: getOfferById,
    goBackPath: '/admin/offers/offers',

    onCreate: createOffer,
    onUpdate: updateOffer,
  });

  return (
    <Default>
      {offer !== undefined && (
        <EditOfferForm
          offer={offer}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditOffer;
