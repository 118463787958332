import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditHolidayForm from '../../../../components/holidays/EditHolidayForm';

import {
  createHoliday,
  getHolidayById,
  updateHoliday,
} from '../../../../services/taxonomies/holidays';

const initialHoliday = { deleted: false, recurring: true };

const EditHoliday = () => {
  const [holiday, handleSubmit, handleCancel] = useEditPage({
    initial: initialHoliday,
    existent: getHolidayById,
    goBackPath: '/admin/taxonomies/holidays',

    onCreate: createHoliday,
    onUpdate: updateHoliday,
  });

  return (
    <Default>
      {holiday !== undefined && (
        <EditHolidayForm
          holiday={holiday}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditHoliday;
