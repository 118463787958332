import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditCompanyForm from '../../../../components/companies/EditCompanyForm';

import {
  createCompany,
  getCompanyById,
  updateCompany,
} from '../../../../services/taxonomies/companies';

const initialCompany = {
  deleted: false,
  cardIssuer: false,
  transportCompany: false,
  vatTaxPayer: false,
  seller: false,
};

const EditCompany = () => {
  const [company, handleSubmit, handleCancel] = useEditPage({
    initial: initialCompany,
    existent: getCompanyById,
    goBackPath: '/admin/taxonomies/companies',
    onCreate: createCompany,
    onUpdate: updateCompany,
  });

  return (
    <Default>
      {company !== undefined && (
        <EditCompanyForm
          company={company}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditCompany;
