import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Space, Table, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import useDatasource from '../../../../hooks/useDatasource';

import { Default } from '../../../../layouts';
import {
  deletePos,
  getPoses,
  downloadPoses,
} from '../../../../services/taxonomies/poses';
import Column from '../../../../helpers/columns';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import PageTitle from '../../../../components/PageTitle';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import ExportEntitiesButton from '../../../../components/buttons/ExportEntitiesButton';

const PosesList = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const { pagination, content, handleChange, sort, criterias, reload } =
    useDatasource(getPoses);

  const columns = useMemo(
    () => [
      Column.text('id', 'ID', { filter: true, width: 50 }),
      Column.text('name', t('entity.taxonomy.poses.name'), { filter: true }),
      Column.text('address', t('entity.taxonomy.poses.address')),
      Column.bool('deleted', t('entity.taxonomy.poses.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.poses.deleted.false'),
          t('entity.taxonomy.poses.deleted.true'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title={t('actions.edit')}>
            <Button
              disabled={record.deleted}
              shape="circle"
              type="link"
              icon={<EditOutlined />}
              onClick={() =>
                history.push(`/admin/taxonomies/poses/${record.id}`)
              }
            />{' '}
          </Tooltip>
          <DeleteItemIcon
            title={t('entity.taxonomy.company._delete', record)}
            item={record}
            action={deletePos}
            disabled={record.deleted}
            reload={() => reload()}
          />
        </span>
      )),
    ],
    [t, history, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.poses._plural')}>
        <AddEntityButton
          entity={t('entity.taxonomy.poses._singular')}
          path="/admin/taxonomies/poses/new"
        />
      </PageTitle>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadPoses}
          />
        </Space>
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};
export default PosesList;
