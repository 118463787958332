import { get, post, put, remove, search } from '../../helpers/api';

export const OFFERS_SERVICE_URI = '/offers';
export const AUTH_BASE_URI = '/api/v1/products';

export const getProducts = async (data) =>
  search(`${OFFERS_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getOrderedProducts = async (data) =>
  search(`${OFFERS_SERVICE_URI}${AUTH_BASE_URI}`, {
    ...data,
    criterias: { ...data.criterias, sort: [['position'], 'desc'] },
  });

export const getProductById = async (id) =>
  get(`${OFFERS_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createProduct = async (product) =>
  post(`${OFFERS_SERVICE_URI}${AUTH_BASE_URI}`, product);

export const updateProduct = async (product) =>
  put(`${OFFERS_SERVICE_URI}${AUTH_BASE_URI}/${product.id}`, product);

export const deleteProduct = async (product) =>
  remove(`${OFFERS_SERVICE_URI}${AUTH_BASE_URI}/${product.id}`);

export const reorderProducts = async (data) =>
  post(`${OFFERS_SERVICE_URI}${AUTH_BASE_URI}/reorder`, data);

export const isProductNameInUse = async (name, id) => {
  const endpoint = `${OFFERS_SERVICE_URI}${AUTH_BASE_URI}/check-name`;
  let url = `${endpoint}?name=${encodeURIComponent(name)}`;
  if (id) {
    url += `&id=${id}`;
  }
  return get(url);
};
