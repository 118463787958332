import React, { useEffect, useState } from 'react';

import { Default } from '../../layouts';

import { useEditPage } from '../../hooks/useEditPage';

import { getBurglaryById, updateBurglary } from '../../services/tvm/tvm';
import EditBurglaryForm from '../../components/burglaries/EditBurglaryForm';
import { getUsers } from '../../services/admin/users';

const EditBurglary = () => {
  const [burglary, handleSubmit, handleCancel] = useEditPage({
    existent: getBurglaryById,
    goBackPath: '/tvm',
    onUpdate: updateBurglary,
  });

  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers({
      criterias: {
        permission: ['TVM_DASHBOARD_PERMISSION', 'SYS_SUPERUSER'],
        tvm: '',
      },
      pageSize: 1000,
    }).then((u) => setUsers(u.content));
  }, []);

  return (
    <Default>
      {burglary !== undefined && (
        <EditBurglaryForm
          burglary={burglary}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          users={users}
        />
      )}
    </Default>
  );
};

export default EditBurglary;
