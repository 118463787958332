import {
  genericCRUDService,
  put,
  get,
  search,
  post,
  download,
} from '../../helpers/api';

export const SERVICE_URI = '/tvm';
export const BASE_URI = '/api/v1';
export const {
  findAll,
  findOne,
  downloadAll: downloadAllStatuses,
} = genericCRUDService('/tvm', '/api/v1/status');
export const { downloadAll } = genericCRUDService(
  SERVICE_URI,
  `${BASE_URI}/sales/report`,
);
export const getSaleReport = async (data) =>
  search(`${SERVICE_URI}${BASE_URI}/sales/report`, data);

export const setTVMLoggingStatus = async (tvmId, enable) =>
  put(`${SERVICE_URI}${BASE_URI}/status/${tvmId}/enable-logs-saving/${enable}`);

export const getLastCashForAllTVMs = () =>
  get(`${SERVICE_URI}${BASE_URI}/status/cash-for-all`);

export const getSummary = (id, date) =>
  get(`${SERVICE_URI}${BASE_URI}/orders/tvm/${id}/summary/${date}`);

export const downloadSummary = (id, date, fields) =>
  download(
    `${SERVICE_URI}${BASE_URI}/orders/tvm/${id}/summary/${date}/downloadSummary`,
    { criterias: { field: fields } },
  );

export const getLogFilesList = (tvmId) =>
  get(`${SERVICE_URI}${BASE_URI}/status/log-list/${tvmId}`);

export const updateLogsFilesList = (list, tvmId) =>
  post(`${SERVICE_URI}${BASE_URI}/status/log-list/${tvmId}`, list);

export const getJSONStatus = (tvmId) =>
  get(`${SERVICE_URI}${BASE_URI}/status/${tvmId}/raw`);

export const getBurglaries = (params) =>
  search(`${SERVICE_URI}${BASE_URI}/alerts/burglaries`, params);

export const getBurglaryById = (id) =>
  get(`${SERVICE_URI}${BASE_URI}/alerts/burglary/${id}`);

export const updateBurglary = (entity) =>
  put(`${SERVICE_URI}${BASE_URI}/alerts/burglary/${entity.id}`, entity);

// endpoint returns only Http OK, custom get will throw error
export const ping = (tvmId) =>
  fetch(
    `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/remote/ping/${tvmId}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then((response) => {
    return response;
  });

export const activate = (tvmId) =>
  fetch(
    `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/remote/activate/${tvmId}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then((response) => {
    return response;
  });

export const inactivate = (tvmId) =>
  fetch(
    `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/remote/inactivate/${tvmId}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then((response) => {
    return response;
  });

export const activateDevice = (tvmId, device) =>
  fetch(
    `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/remote/activate/${tvmId}/${device}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then((response) => {
    return response;
  });

export const inactivateDevice = (tvmId, device) =>
  fetch(
    `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/remote/inactivate/${tvmId}/${device}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then((response) => {
    return response;
  });
