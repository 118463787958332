import { search, remove, get, post, put } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/countries';

export const getCountries = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getCountryById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createCountry = async (country) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, country);

export const updateCountry = async (country) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${country.id}`, country);

export const deleteCountry = async (country) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${country.id}`);
