import { search, get, post, put, remove } from '../../helpers/api';

export const OFFERS_SERVICE_URI = '/offers';
export const OFFERS_BASE_URI = '/api/v1/offer-types';

export const getOfferTypes = async (data) =>
  search(`${OFFERS_SERVICE_URI}${OFFERS_BASE_URI}`, data);

export const getOfferTypeById = async (id) =>
  get(`${OFFERS_SERVICE_URI}${OFFERS_BASE_URI}/${id}`);

export const createOfferType = async (offerType) =>
  post(`${OFFERS_SERVICE_URI}${OFFERS_BASE_URI}`, offerType);

export const updateOfferType = async (offerType) =>
  put(`${OFFERS_SERVICE_URI}${OFFERS_BASE_URI}/${offerType.id}`, offerType);

export const deleteOfferType = async (offerType) =>
  remove(`${OFFERS_SERVICE_URI}${OFFERS_BASE_URI}/${offerType.id}`);

export const updateOfferTypes = async (offerTypes) =>
  put(`${OFFERS_SERVICE_URI}${OFFERS_BASE_URI}`, offerTypes);
