import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import { Default } from '../../layouts';

import PageTitle from '../../components/PageTitle';
import InvoicesList from '../../components/stocks/InvoicesList';

import {
  Enrollment as EnrollmentSymbol,
  symbolToString,
} from '../../components/stocks/constants';
import AddInvoiceButton from '../../components/stocks/AddInvoiceButton';

const entityType = symbolToString(EnrollmentSymbol);

const isInvoiceAllowed = ({ origin }) => origin === EnrollmentSymbol;

const Enrollment = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('pages.enrollment._')}>
        <AddInvoiceButton
          isInvoiceAllowed={isInvoiceAllowed}
          group="enrollment"
        />
      </PageTitle>
      <Tabs>
        <Tabs.TabPane tab={t('entity.stocks.invoice._plural')} key="invoice">
          <InvoicesList entityType={entityType} />
        </Tabs.TabPane>
      </Tabs>
    </Default>
  );
};

export default Enrollment;
