import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Space } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/columns';
import {
  findAll,
  findAllByInspectorId,
  downloadAll,
  downloadAllByInspectorId,
} from '../../services/validators/inspector-transactions';
import PageTitle from '../PageTitle';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';

const ACTIONS_DICT = {
  content: [
    { id: 'TO_INSPECTION' },
    { id: 'TO_COMMERCIAL' },
    { id: 'IDENTIFYING_INSPECTOR' },
    { id: 'VERIFYING_CARD' },
  ],
  loading: false,
  error: false,
};

const InspectorTransactionsList = ({ inspectorId }) => {
  const { t } = useTranslation();

  const fetcher = useCallback(
    (data) =>
      inspectorId === undefined
        ? findAll(data)
        : findAllByInspectorId(data, inspectorId),
    [inspectorId],
  );
  const downloadFetcher = useCallback(
    (data, spec) =>
      inspectorId === undefined
        ? downloadAll(data, spec)
        : downloadAllByInspectorId(inspectorId, data, spec),
    [inspectorId],
  );

  const { pagination, content, criterias, handleChange, sort } =
    useDatasource(fetcher);

  const columns = useMemo(
    () => [
      ...(inspectorId === undefined
        ? [
            Column.text(
              'inspectorId',
              t('components.inspectors.transactions.inspectorId'),
              {
                filter: true,
              },
            ),
            Column.text(
              'fullname',
              t('components.inspectors.transactions.fullname'),
              {
                filter: true,
              },
            ),
          ]
        : []),
      Column.text('userId', t('components.inspectors.transactions.userId'), {
        filter: true,
      }),
      Column.dictionary(
        'action',
        t('components.inspectors.transactions.action'),
        ACTIONS_DICT,
        {
          filter: true,
          dictLabel: (entry) =>
            t(`components.inspectors.transactions.actionsDict.${entry.id}`),
        },
      ),
      Column.text(
        'passengerCounter',
        t('components.inspectors.transactions.passengerCounter'),
        {
          filter: true,
        },
      ),
      {
        ...Column.date(
          'transactionTime',
          t('components.inspectors.transactions.transactionTime'),
          { filter: true },
        ),
        ellipsis: true,
      },
      Column.text(
        'vehicleId',
        t('components.inspectors.transactions.vehicleId'),
        {
          filter: true,
        },
      ),
      Column.text('routeId', t('components.inspectors.transactions.routeId'), {
        filter: true,
      }),
      Column.bool(
        'direction',
        t('components.inspectors.transactions.direction'),
        {
          filter: true,
          labels: [
            t('table.filter.all'),
            t('components.inspectors.transactions.directionsDict.true'),
            t('components.inspectors.transactions.directionsDict.false'),
            t('table.filter.unknown'),
          ],
        },
      ),
      Column.text('pto', t('components.inspectors.transactions.pto'), {
        filter: true,
      }),
      Column.text(
        'validatorPosition',
        t('components.inspectors.transactions.validatorPosition'),
        {
          filter: true,
        },
      ),
      Column.text(
        'deviceSn',
        t('components.inspectors.transactions.deviceSn'),
        {
          filter: true,
        },
      ),
      Column.text(
        'latitude',
        t('components.inspectors.transactions.latitude'),
        {
          filter: true,
        },
      ),
      Column.text(
        'longitude',
        t('components.inspectors.transactions.longitude'),
        {
          filter: true,
        },
      ),
    ],
    [inspectorId, t],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadFetcher}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="id"
        columns={columns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
        size="small"
      />
    </>
  );
};

export default InspectorTransactionsList;
