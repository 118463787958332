import React, { useCallback, useEffect, useMemo } from 'react';

import { notification, Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useForm, { FormContext } from '../../../../../hooks/useForm';
import FormWrapper from '../../../../../components/FormWrapper';
import SaveButton from '../../../../../components/buttons/SaveButton';
import CancelButton from '../../../../../components/buttons/CancelButton';
import PageTitle from '../../../../../components/PageTitle';
import { findAll as findAllTokenSubtype } from '../../../../../services/taxonomies/token-subtypes';
import { getUserTypes } from '../../../../../services/taxonomies/user-types';
import TemplateUploader from '../../../../../components/TemplateUploader';
import TaxonomyGrid from './TaxonomyGrid';

const EditCardTemplateForm = ({ template, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        fileId: yup
          .number()
          .typeError(t('errors.imgRequired'))
          .required(t('errors.imgRequired')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: template,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const { handleSubmit, value, input, setFormValue } = form;

  useEffect(() => {
    setFormValue(template);
  }, [setFormValue, template]);

  const onUploadSuccess = useCallback(
    (image) => {
      notification.success({
        message: t('entity.taxonomy.transportUnit.import.success', {
          quantity: image.length,
        }),
      });
    },
    [t],
  );

  return (
    <>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <PageTitle
            title={template.name || t('entity.taxonomy.cardTemplate._singular')}
          />
          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.Input
                label={t('entity.taxonomy.cardTemplate.name')}
                props={{
                  ...input('name'),
                  disabled: value.deleted,
                }}
              />
              <TemplateUploader
                text={t('entity.taxonomy.cardTemplate.upload')}
                field="fileId"
                form={form}
                onUploadSuccess={onUploadSuccess}
              />
            </FormWrapper.Single>
            <FormWrapper.Double>
              <Tabs>
                <Tabs.TabPane
                  tab={t('entity.taxonomy.userType._plural')}
                  key="userTypes"
                >
                  <TaxonomyGrid
                    fieldName="userTypes"
                    getTaxonomyData={getUserTypes}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t('entity.taxonomy.tokenSubtype._plural')}
                  key="tokenSubtypes"
                >
                  <TaxonomyGrid
                    fieldName="tokenSubtypes"
                    getTaxonomyData={findAllTokenSubtype}
                  />
                </Tabs.TabPane>
              </Tabs>
            </FormWrapper.Double>
          </FormWrapper>
          <Space style={{ paddingTop: '1rem' }}>
            <SaveButton disabled={value.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </form>
      </FormContext.Provider>
    </>
  );
};
export default EditCardTemplateForm;
