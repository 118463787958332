import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { Button, Modal, notification, Space, Table, Tooltip } from 'antd';
import PageTitle from '../../../components/PageTitle';
import Column from '../../../helpers/columns';
import {
  getSaleReport,
  getSummary,
  getSummaryExcel,
} from '../../../services/mobile/mobile';
import { Default } from '../../../layouts';
import FilterDateWrapper from '../../../components/FilterDateWrapper';

const MobileSummary = () => {
  const { t } = useTranslation();
  const [dateStart, setDateStart] = useState(null);

  useEffect(() => {
    getSaleReport({ sort: [['updatedAt'], 'asc'] }).then((res) => {
      setDateStart(res.content[0].updatedAt);
    });
  }, []);

  const [summary, setSummary] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [filteredContent, setFilteredContent] = useState(null);
  const content = useMemo(() => {
    const date = moment(dateStart).toDate();
    const arr = [];
    const numberOfdays = (new Date() - date) / (3600 * 24 * 1000);
    for (let i = 0; i <= numberOfdays; i += 1) {
      arr.unshift({
        id: i + 1,
        date: `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, 0)}-${date.getDate().toString().padStart(2, 0)}`,
      });
      date.setDate(date.getDate() + 1);
    }
    setFilteredContent(arr);
    return arr;
  }, [dateStart]);
  const filterSearchContent = useCallback(
    (filter) => {
      const normalizedFilter = Object.entries(filter)
        .filter((entry) => entry[0] && entry[1] && Array.isArray(entry[1]))
        .map((el) => ({
          name: el[0],
          value: el[1][0],
        }));

      if (normalizedFilter) {
        setFilteredContent(
          content.filter((item) => {
            const period = normalizedFilter.find(
              ({ name }) => name === 'date',
            )?.value;
            return (
              !period ||
              (moment(item.date.concat('Z'), "YYYY-MM-DD'Z'").toDate() >=
                +moment(period.from)
                  .set({ hour: 0, second: 0, minute: 0, milisecond: 0 })
                  .toDate() &&
                moment(item.date.concat('Z'), "YYYY-MM-DD'Z'").toDate() <=
                  moment(period.to).toDate())
            );
          }),
        );
      }
    },
    [content],
  );

  const handleChange = (pagination, filters) => {
    filterSearchContent(filters);
  };

  const handleSummary = useCallback(
    (date) => () => {
      getSummary(date)
        .then((res) => {
          setCurrentRecord(date);
          setSummary([
            ...res,
            {
              productName: 'TOTAL',
              totalPrice: res.reduce(
                (acc, row) => acc + row.qty * row.price,
                0,
              ),
            },
          ]);
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: err.message,
          });
        });
    },
    [],
  );

  const sumaryColumns = useMemo(
    () => [
      {
        title: 'Id produsului',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Categorie',
        dataIndex: 'productCategory',
        key: 'productCategory',
      },
      {
        title: 'Denumire produs',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: 'Pret',
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (price) => <>{price ? (price * 0.01).toFixed(2) : ''}</>,
      },
      {
        title: 'Cantitate',
        width: 150,
        dataIndex: 'qty',
        key: 'qty',
      },
      {
        title: 'Pret total',
        width: 150,
        key: 'totalPrice',
        render: (_, row) => (
          <>
            {((row.totalPrice || row.qty * row.price || 0) * 0.01).toFixed(2)}
          </>
        ),
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      Column.actions(t('table.actions'), (record) => (
        <span
          style={{
            textAlign: 'right',
          }}
        >
          <Tooltip title={t('table.actions')}>
            <Button
              shape="circle"
              type="link"
              icon={<EyeOutlined />}
              onClick={handleSummary(record.date)}
              size="midle"
            />
          </Tooltip>
        </span>
      )),
      {
        title: t('pages.reports.mobileSummary.date'),
        key: 'date',
        width: 100,
        dataIndex: 'date',
        sorter: false,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
          <FilterDateWrapper
            title={t('pages.reports.mobileSummary.date')}
            searchTitle="Search"
            resetTitle="Reset"
            setSelectedKeys={setSelectedKeys}
            clearFilters={clearFilters}
            confirm={confirm}
          />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
      },
    ],
    [t, handleSummary],
  );

  // const filteredColumnsForCSV = useMemo(
  //   () => sumaryColumns.filter((column) => column.key !== 'productCategory'),
  //   [sumaryColumns],
  // );

  return (
    <Default>
      <PageTitle title={t('pages.reports.mobileSummary._')} />
      <Table
        columns={columns}
        rowKey="id"
        dataSource={filteredContent}
        onChange={handleChange}
        size="small"
        style={{ width: 400 }}
      />
      <Modal
        title="Raport"
        visible={summary != null}
        closable={false}
        width="1000px"
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setSummary(null);
            }}
          >
            {t('actions.cancel')}
          </Button>,
        ]}
      >
        <Space>
          <Button
            icon={<DownloadOutlined />}
            onClick={() =>
              getSummaryExcel(
                currentRecord,
                'field=id;id produs&field=productCategory;Categorie&field=productName;Denumire produs&field=qty;Cantitate&field=price;Pret&',
              )
            }
          >
            {t('actions.download')}
          </Button>
        </Space>
        <Table
          dataSource={summary}
          columns={sumaryColumns}
          pagination={false}
        />
      </Modal>
    </Default>
  );
};

export default MobileSummary;
