import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import FormWrapper from '../FormWrapper';
import Column from '../../helpers/columns';
import DeleteItemIcon from '../buttons/DeleteItemIcon';
import { getDocTypes } from '../../services/taxonomies/doc-types';
import useDictionaries from '../../hooks/useDictionaries';
import { downloadFiles } from '../../services/files';
import { getUserTypes } from '../../services/taxonomies/user-types';
import './UserFileList.scss';

const dictionaries = {
  docTypes: getDocTypes,
  userTypes: getUserTypes,
};

const defaultFileProps = {
  userFileDeleted: false,
};

const UserFileList = ({ props }) => {
  const { t } = useTranslation();

  const { name, setValue, getValue, select, custom, disabled } = props;

  const [{ docTypes, userTypes }] = useDictionaries(dictionaries);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.admin.user.tabs.files.id'), {
        sort: false,
        width: 75,
      }),
      {
        title: t('entity.admin.user.tabs.files.type'),
        dataIndex: 'docTypeId',
        key: 'docTypeId',
        width: 200,
        render: (_, record, index) => (
          <FormWrapper.Select
            props={{
              options: docTypes.content.map((docType) => ({
                ...docType,
                disabled:
                  getValue('userTypeId') &&
                  !userTypes.loading &&
                  !userTypes.content
                    .find((userType) => userType.id === getValue('userTypeId'))
                    .docTypes.includes(docType.id),
              })),
              loading: docTypes.loading,
              ...select(`${name}[${index}].docTypeId`),
              disabled: record.userFileDeleted || disabled,
            }}
          />
        ),
      },

      Column.date('uploadDate', t('entity.admin.user.tabs.files.uploadDate'), {
        sort: false,
      }),
      Column.text('uploadBy', t('entity.admin.user.tabs.files.uploadBy'), {
        sort: false,
      }),
      {
        title: t('entity.admin.user.tabs.files.expirationDate'),
        dataIndex: 'expirationDate',
        key: 'expirationDate',
        width: 200,
        render: (_, record, index) => (
          <FormWrapper.DateTimePicker
            props={{
              ...custom(`${name}[${index}].expirationDate`),
              disabled: record.userFileDeleted || disabled,
            }}
          />
        ),
      },
      Column.text('name', t('entity.admin.user.tabs.files.name'), {
        sort: false,
      }),
      Column.bool(
        'userFileDeleted',
        t('entity.admin.user.tabs.files.deleted._'),
        {
          width: 150,
          sort: false,
          inverted: true,
          labels: [
            t('table.filter.all'),
            t('entity.admin.user.tabs.files.deleted.true'),
            t('entity.admin.user.tabs.files.deleted.false'),
            t('table.filter.unknown'),
          ],
        },
        disabled,
      ),
      Column.actions(t('table.actions'), (file, index) => (
        <>
          <Button
            shape="circle"
            type="link"
            icon={<DownloadOutlined />}
            disabled={file.userFileDeleted || file.size === 0}
            onClick={() => downloadFiles(file.id)}
          />
          <DeleteItemIcon
            title={t('entity.admin.user.tabs.files._delete', file)}
            item={file}
            action={async () =>
              setValue(`${name}[${index}]`, {
                ...getValue(name)[index],
                userFileDeleted: true,
                expirationDate: Date.now(),
              })
            }
            disabled={file.userFileDeleted || disabled}
            reload={() => null}
          />
        </>
      )),
    ],
    [
      custom,
      docTypes.content,
      docTypes.loading,
      getValue,
      name,
      select,
      setValue,
      t,
      userTypes.content,
      userTypes.loading,
      disabled,
    ],
  );

  const tableProps = useMemo(
    () => ({
      rowKey: 'id',
      scroll: { x: 1000 },
      rowClassName: () => 'user-file-list__table-row',
      columns,
    }),
    [columns],
  );

  return (
    <FormWrapper.FileList
      props={{
        name,
        setValue,
        getValue,
        defaultFileProps,
        tableProps,
        // if it's only disabled it wouldn't work
        allowUpload: disabled ? false : undefined,
      }}
    />
  );
};

export default UserFileList;
