import {
  search,
  get,
  genericCRUDService,
  handleHttpErrors,
} from '../../helpers/api';

export const SERVICE_URI = '/mobile';
export const BASE_URI = '/api/v1';
export const { downloadAll } = genericCRUDService(
  SERVICE_URI,
  `${BASE_URI}/sales/report`,
);
export const getSaleReport = async (data) =>
  search(`${SERVICE_URI}${BASE_URI}/sales/report`, data);

export const getSummary = (date) =>
  get(`${SERVICE_URI}${BASE_URI}/summary/${date}`);

export const getSummaryExcel = async (date, parameters) => {
  return fetch(
    `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/summary/download/${date}?${parameters}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'text/csv',
      },
    },
  )
    .then(handleHttpErrors)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = 'document.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};
