import React, { useEffect, useMemo } from 'react';

import * as yup from 'yup';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import useForm from '../../../hooks/useForm';

import PageTitle from '../../PageTitle';
import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';

const CARD_CATEGORIES = [{ id: 'UID' }, { id: 'PAN' }, { id: 'QR' }];

const EditTokenSubtypeForm = ({ type, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        code: yup.number().required(t('errors.notEmpty')),
        name: yup.string().required(t('errors.notEmpty')),
        tokenType: yup.string().required(t('errors.notEmpty')),
        reloadable: yup.bool().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const { handleSubmit, value, input, setFormValue, select, checkbox } =
    useForm({
      schema: validationSchema,
      initialValue: type,
      onSubmit,
      onCancel,
    });

  useEffect(() => {
    setFormValue(type);
  }, [setFormValue, type]);

  const { deleted } = value;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={type.name || t('entity.taxonomy.tokenSubtype._singular')}
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Number
              label={t('entity.taxonomy.tokenSubtype.code')}
              props={{ ...input('code'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.tokenSubtype.name')}
              props={{ ...input('name'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.tokenSubtype.description')}
              props={{ ...input('description'), disabled: deleted }}
            />

            <FormWrapper.Select
              label={t('entity.taxonomy.tokenSubtype.tokenType')}
              props={{
                options: CARD_CATEGORIES,
                labelProp: (obj) => obj.id,
                ...select('tokenType'),
              }}
            />

            <FormWrapper.Switch
              label={t('entity.taxonomy.tokenSubtype.reloadable')}
              props={{ ...checkbox('reloadable') }}
            />

            <Space style={{ paddingTop: '1rem' }}>
              {!deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditTokenSubtypeForm;
