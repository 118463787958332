import React, { useCallback, useEffect, useState } from 'react';

import { notification, Upload } from 'antd';

import { useTranslation } from 'react-i18next';
import { downloadFile, postFiles } from '../helpers/api';
import {
  SERVICE_URI as FILE_SERVICE_URI,
  BASE_URI as FILE_BASE_URI,
} from '../services/files';
import FormWrapper from './FormWrapper';

const acceptedTypes = ['image/gif', 'image/jpeg', 'image/bmp', 'image/png'];

const TemplateUploader = ({
  form,
  field,
  text = '+ Upload',
  onUploadSuccess = () => null,
}) => {
  const { t } = useTranslation();

  const { value, setValue, errorMessages } = form;
  const fileId = value[field];

  const [images, setImages] = useState(null);

  useEffect(() => {
    if (fileId && !images) {
      downloadFile(`${FILE_SERVICE_URI}${FILE_BASE_URI}/${fileId}?download`)
        .then((res) => res.blob())
        .then((blob) =>
          setImages([
            {
              status: 'done',
              url: URL.createObjectURL(blob),
            },
          ]),
        );
    }
  }, [fileId, images]);

  const beforeUpload = useCallback(
    (file) => {
      const isImage = acceptedTypes.includes(file.type);
      if (!isImage) {
        notification.error({
          message: t('entity.taxonomy.cardTemplate.import.incorectType'),
        });
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    [t],
  );

  const onChange = useCallback(
    ({ fileList }) => setImages(fileList.slice(-1)),
    [setImages],
  );

  const uploadFile = useCallback(
    ({ onSuccess, onError }) => {
      postFiles(
        `${FILE_SERVICE_URI}${FILE_BASE_URI}`,
        images.map((img) => img.originFileObj),
      )
        .then((res) => res.json())
        .then((res) => {
          onSuccess(res);
          setValue(field, res && res[0] && res[0].id);
          onUploadSuccess(res);
        })
        .catch(onError);
    },
    [images, onUploadSuccess, setValue, field],
  );

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onImageRemove = useCallback(() => {
    setValue(field, null);
  }, [field, setValue]);

  return (
    <>
      <Upload
        customRequest={uploadFile}
        listType="picture-card"
        multiple={false}
        accept="image/*"
        fileList={images}
        onChange={onChange}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        onRemove={onImageRemove}
      >
        <span>{text}</span>
      </Upload>

      <FormWrapper.ErrorMessage
        message={errorMessages && errorMessages[field]}
      />
    </>
  );
};

export default TemplateUploader;
