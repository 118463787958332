import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../services/taxonomies/token-subtypes';
import EditTokenSubtypeForm from '../../../../components/taxonomies/token-subtypes/EditTokenSubtypeForm';

const initial = { reloadable: false };

const EditTokenSubtype = () => {
  const [type, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/token/subtypes',

    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {type !== undefined && (
        <EditTokenSubtypeForm
          type={type}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditTokenSubtype;
