import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditDocTypeForm from '../../../../components/doc-types/EditDocTypeForm';

import {
  createDocType,
  getDocTypeById,
  updateDocType,
} from '../../../../services/taxonomies/doc-types';

const initialDocType = {
  deleted: false,
  hasExpirationDate: false,
  dateChangeable: false,
  docRequired: false,
};

const EditDocType = () => {
  const [docType, handleSubmit, handleCancel] = useEditPage({
    initial: initialDocType,
    existent: getDocTypeById,
    goBackPath: '/admin/taxonomies/doc-types',

    onCreate: createDocType,
    onUpdate: updateDocType,
  });

  return (
    <Default>
      {docType !== undefined && (
        <EditDocTypeForm
          docType={docType}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditDocType;
