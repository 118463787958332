import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';
import {
  getCompanies,
  deleteCompany,
} from '../../../../services/taxonomies/companies';

import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import Column from '../../../../helpers/columns';

const CompaniesList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } =
    useDatasource(getCompanies);

  const columns = useMemo(
    () => [
      Column.text('code', t('entity.taxonomy.company.code'), { filter: true }),
      Column.text('name', t('entity.taxonomy.company.name'), { filter: true }),
      Column.text('fiscalCode', t('entity.taxonomy.company.fiscalCode')),
      Column.bool('vatTaxPayer', t('entity.taxonomy.company.vatTaxPayer._'), {
        filter: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.company.vatTaxPayer.true'),
          t('entity.taxonomy.company.vatTaxPayer.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.text('address', t('entity.taxonomy.company.address')),
      Column.text(
        'commercialCode',
        t('entity.taxonomy.company.commercialCode'),
      ),
      Column.bool('deleted', t('entity.taxonomy.company.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.company.deleted.true'),
          t('entity.taxonomy.company.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.bool(
        'transportCompany',
        t('entity.taxonomy.company.transportCompany._'),
        {
          filter: true,
          inverted: false,
          labels: [
            t('table.filter.all'),
            t('entity.taxonomy.company.transportCompany.true'),
            t('entity.taxonomy.company.transportCompany.false'),
            t('table.filter.unknown'),
          ],
        },
      ),
      Column.bool('cardIssuer', t('entity.taxonomy.company.cardIssuer._'), {
        filter: true,
        inverted: false,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.company.cardIssuer.true'),
          t('entity.taxonomy.company.cardIssuer.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.bool('seller', t('entity.taxonomy.company.seller._'), {
        filter: true,
        inverted: false,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.company.seller.true'),
          t('entity.taxonomy.company.seller.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (company) => (
        <>
          <EditItemIcon path={`/admin/taxonomies/companies/${company.id}`} />
          <DeleteItemIcon
            title={t('entity.taxonomy.company._delete', company)}
            message={t('entity.taxonomy.company._deleted', company)}
            item={company}
            action={deleteCompany}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.company._plural')}>
        <AddEntityButton
          entity={t('entity.taxonomy.company._singular')}
          path="/admin/taxonomies/companies/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        scroll={{ x: 700, y: 700 }}
        onChange={handleChange}
      />
    </Default>
  );
};

export default CompaniesList;
