import React, { useCallback, useMemo, useState } from 'react';

import { Button, Col, Input, message, Row, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import FormWrapper from '../FormWrapper';
import { validateCui } from '../../services/admin/users';

const UserIdentifier = ({ form, countries, disabled = false }) => {
  const { t } = useTranslation();
  const [vatEntity, setVatEntity] = useState(null);
  const [canValidate, setCanValidate] = useState(false);

  const { value, input, select, setValue } = form;
  const { customerType, identityCard, countryCode } = value;

  const inputIdentityCard = input('identityCard');
  const { invalid: invalidIdentityCard } = inputIdentityCard;

  const countryOptions = useMemo(
    () =>
      (countries?.content || []).map((ety) => ({
        ...ety,
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries],
  );

  const displayError = useCallback((msg) => {
    message.error({
      content: msg || 'Eroare la validare',
      key: 'pos',
      duration: 3,
      className: 'card-message',
    });
  }, []);

  const validate = useCallback(() => {
    if (identityCard) {
      setCanValidate(true);
      validateCui(countryCode, identityCard)
        .then((resp) => {
          const { isValid, companyName } = resp;
          setVatEntity(resp);
          if (isValid) {
            setValue('fullname', companyName);
          }
          setCanValidate(false);
        })
        .catch((err) => {
          setVatEntity(null);
          setCanValidate(false);
          if (err.inner) {
            displayError(err.inner?._);
          } else {
            err.json(err).then((res) => displayError(res?.message));
          }
        });
    }
  }, [identityCard, countryCode, setValue, displayError]);

  return (
    <>
      {customerType === 'LEGAL' ? (
        <>
          <FormWrapper.FieldWrapper
            label={t('entity.admin.user._identityCard')}
            errorMessage={invalidIdentityCard}
          >
            <Row>
              <Col span={4}>
                <Select
                  {...select('countryCode')}
                  onChange={(val) => {
                    setVatEntity(null);
                    setValue('countryCode', val);
                  }}
                >
                  {[{ code: '' }, ...countryOptions].map(({ code }) => (
                    <Select.Option key={code} value={code}>
                      {code}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={14}>
                <Input {...inputIdentityCard} disabled={disabled} />
              </Col>
              <Col span={6}>
                <Button
                  style={{
                    width: '100%',
                  }}
                  type="primary"
                  onClick={validate}
                  disabled={canValidate}
                >
                  Valideaza
                </Button>
              </Col>
            </Row>
          </FormWrapper.FieldWrapper>

          {vatEntity && (
            <FormWrapper.FieldWrapper>
              <Row>
                <Typography.Text
                  type={vatEntity.isValid ? 'success' : 'danger'}
                >
                  {vatEntity.companyName}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text
                  type={vatEntity.isValid ? 'success' : 'danger'}
                >
                  {vatEntity.companyAddress}
                </Typography.Text>
              </Row>
            </FormWrapper.FieldWrapper>
          )}
        </>
      ) : (
        <FormWrapper.Input
          label={t('entity.admin.user._identityCard')}
          props={{
            ...inputIdentityCard,
            // ESLint: Expected property shorthand.(object-shorthand) doesn't work
            disabled: disabled ? true : undefined,
          }}
        />
      )}
    </>
  );
};
export default UserIdentifier;
