import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { FormContext } from '../../../hooks/useForm';
import useDatasource from '../../../hooks/useDatasource';

import FormWrapper from '../../FormWrapper';

import Column from '../../../helpers/columns';

import { getRoles } from '../../../services/admin/roles';

const RolesGrid = () => {
  const { t } = useTranslation();

  const form = useContext(FormContext);

  const { value, setValue, errorMessages } = form;

  const { pagination, content, handleChange } = useDatasource(getRoles);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.role.name'), {
        filter: true,
        width: 300,
        fixed: 'left',
      }),
      Column.text('description', t('entity.admin.role.description')),
      Column.bool('deleted', t('entity.admin.role.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.admin.role.deleted.true'),
          t('entity.admin.role.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: value.roles,
      onSelect: ({ id }) => {
        setValue('roles', [id]);
      },
      getCheckboxProps: ({ deleted, id }) => ({
        disabled:
          (deleted &&
            (value.roles?.length > 0
              ? value.roles?.indexOf(id) === -1
              : true)) ||
          value.deleted,
      }),
    }),
    [setValue, value],
  );

  return (
    <>
      <FormWrapper.ErrorMessage message={errorMessages?.roles} />
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default RolesGrid;
