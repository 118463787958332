import React, { useMemo, useEffect } from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';
import { findAll } from '../../services/taxonomies/token-subtypes';
import useDictionaries from '../../hooks/useDictionaries';

const dictionaries = {
  tokenSubtypes: findAll,
};

const EditArticleTypeForm = ({ articleType, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const enrollExternalCard = useMemo(
    () => window._env_.ENROLL_EXTERNAL_CARD,
    [],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        code: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const { value, handleSubmit, input, checkbox, select, setFormValue } =
    useForm({
      initialValue: articleType,
      schema: validationSchema,
      onSubmit,
      onCancel,
    });

  useEffect(() => {
    setFormValue(articleType);
  }, [setFormValue, articleType]);
  const { deleted } = value;

  const [{ tokenSubtypes }] = useDictionaries(dictionaries);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={articleType.name || t('entity.taxonomy.articleType._singular')}
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.articleType.code')}
              props={{ ...input('code'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.articleType.name')}
              props={{ ...input('name'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.articleType.description')}
              props={{ ...input('description'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.articleType.measurementUnit')}
              props={{ ...input('measurementUnit'), disabled: deleted }}
            />

            <FormWrapper.Select
              label={t('entity.taxonomy.tokenSubtype._plural')}
              props={{
                options: tokenSubtypes.content,
                loading: tokenSubtypes.loading,
                allowClear: true,
                labelProp: (obj) => obj.name,
                ...select('tokenSubtypeId'),
              }}
            />

            <FormWrapper.Switch
              label={t('entity.taxonomy.articleType.enrollable._')}
              props={{ ...checkbox('enrollable') }}
            />

            {enrollExternalCard === 'true' && (
              <FormWrapper.Switch
                label={t('entity.taxonomy.articleType.external._')}
                props={{ ...checkbox('external') }}
              />
            )}

            <Space style={{ paddingTop: '1rem' }}>
              {!deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditArticleTypeForm;
