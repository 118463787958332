import React from 'react';

import {
  createInstitution,
  getInstitutionById,
  updateInstitution,
} from '../../../../services/taxonomies/institutions';
import { useEditPage } from '../../../../hooks/useEditPage';
import { Default } from '../../../../layouts';
import InstitutionForm from '../../../../components/institutions/InstitutionForm';

const initial = { subdivisions: [] };

const EditInstitution = () => {
  const [institution, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: getInstitutionById,
    goBackPath: '/admin/taxonomies/institutions',
    onCreate: createInstitution,
    onUpdate: updateInstitution,
  });

  return (
    <Default>
      {institution !== undefined && (
        <InstitutionForm
          institution={institution}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditInstitution;
