import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Popconfirm,
  Row,
  Table,
} from 'antd';

import { useTranslation } from 'react-i18next';
import useCard from '../../../hooks/useCard';

import POSButton from '../POSButton';
import POSContext from '../POSContext';

import {
  closeShift,
  enableOrderCard,
  getShiftSummary,
  openOrder,
  printZ,
  updateScrapNumber,
} from '../../../services/pos/pos';
import { getCardsByUserIdentifierAndLogicalId } from '../../../services/accounts/accounts';
import FoundCardsList from '../FoundCardsList';
import { ArticleTypes } from '../../orders/Constants';
import { enrollProductExternal } from '../../../services/stocks/cards';
import { showMessage } from '../../../helpers/printing';

const Initial = () => {
  const { t } = useTranslation();
  const { shift, setShift, order, setOrder, cardBlocked, setCardBlocked } =
    useContext(POSContext);
  const [searchCard, setSearchCard] = useState(false);
  const [modalDisabled, setModalDisabled] = useState(true);
  const [internalCard, setInternalCard] = useState(true);
  const [cards, setCards] = useState([]);
  const [form] = Form.useForm();
  const enrollExternalCard = useMemo(
    () => window._env_.ENROLL_EXTERNAL_CARD,
    [],
  );

  const enableCard = useCallback(
    () =>
      enableOrderCard(shift, order)
        .then(() => {
          setCardBlocked(false);
        })
        .catch((err) => {
          setCardBlocked(false);
          console.error(err);
          notification.error({
            message: err.message,
          });
        }),
    [setCardBlocked, shift, order],
  );

  const [isStocksEnabledString] = useState(window._env_.STOCKS_ENABLE);
  const isStocksEnabled = isStocksEnabledString === 'true';
  const [scrapCardModalVisible, setScrapCardModalVisible] = useState(false);
  const [noScrap, setNoScrap] = useState(0);

  const sendScrapRequest = useCallback(
    (body) => {
      // there is no order id, so it will be default -1
      updateScrapNumber(-1, body)
        .then(() => {
          notification.success({
            message: t('actions.scrapSuccess'),
          });
        })
        .catch((msg) => {
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};

          notification.error({
            message: messageCode
              ? t(messageCode, inner)
              : t('actions.saveFailed'),
          });
        })
        .finally(() => {
          setNoScrap(0);
          setScrapCardModalVisible(false);
        });
    },
    [t],
  );

  const sendScrap = () => {
    const body = {
      articleType: ArticleTypes.Mifare1K,
      articleNumbers: noScrap,
      entityRefId: shift.posId,
    };

    sendScrapRequest(body);
  };

  const [summaryCard, setSummaryCard] = useState(null);
  const [summaryCash, setSummaryCash] = useState(null);

  const handleOk = useCallback(() => {
    const identifier = form.getFieldValue('userIdentifier');
    const id = form.getFieldValue('logicIdSerial');

    getCardsByUserIdentifierAndLogicalId({
      userIdentifier: identifier,
      logicIdSerial: id,
    })
      .then((res) => setCards(res))
      .catch((err) => {
        setCards([]);
        message.error({
          content: err.inner._ ? t(err.inner._) : 'N-a fost găsit nici un card',
          key: 'pos',
          duration: 5,
          className: 'card-message',
        });
      });
  }, [form, t]);

  const handleCloseShift = useCallback(() => {
    printZ(shift)
      .then((res) => closeShift(res.body ? res.body : res))
      .then((res) => {
        setSummaryCard([
          ...res.summaryCard,
          {
            close: true,
            productName: 'TOTAL Schimb',
            totalPrice: res.summaryCard.reduce(
              (acc, row) => acc + row.qty * row.price,
              0,
            ),
          },
        ]);
        setSummaryCash([
          ...res.summaryCash,
          {
            close: true,
            productName: 'TOTAL Schimb',
            totalPrice: res.summaryCash.reduce(
              (acc, row) => acc + row.qty * row.price,
              0,
            ),
          },
        ]);
      })
      .catch(async (streamError) => {
        const err = await streamError.json();

        notification.error({
          message:
            t(err.message) ||
            'S-a produs o eroare . Adresați-va va rog la administorul sistemului',
        });
      });
  }, [t, shift]);

  const handleOpenOrder = useCallback(
    (card) => {
      openOrder(shift, card)
        .then((res) => {
          if (res.card.deleted) {
            setCardBlocked(card);
          }
          setOrder(res);
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: err?.inner?._ ? t(err.inner._) : err.message,
          });
        });
    },
    [shift, setOrder, setCardBlocked, t],
  );

  const handleEnrollExternalCard = useCallback(
    async (physicalId) => {
      if (physicalId && physicalId !== 0) {
        const articleType = ArticleTypes.Mifare1KExtern;
        try {
          const product = await enrollProductExternal({
            physicalId,
            articleType,
          });
          if (product.status === 'ENROLLED') {
            showMessage('success', t('errors.cardEnrolled'));
          } else if (product.status === 'BLOCKED') {
            showMessage('error', t('errors.blockedCardEnrolled'));
          } else if (product.status === 'SOLD') {
            showMessage('error', t('errors.soldCardEnroll'));
          } else {
            showMessage('error', t('errors.errorCardEnroll'));
          }
        } catch (err) {
          const { inner } = err || {};
          const { _: messageCode } = inner || {};
          showMessage(
            'error',
            messageCode ? t(messageCode) : t('errors.errCardEnroll'),
          );
        }
      }
    },
    [t],
  );

  const onCardScan = useCallback(() => {
    message.loading({
      content: 'Identificare card',
      key: 'pos',
      duration: 0,
      className: 'card-message',
    });
  }, []);

  const onCardFound = useCallback(
    (card) => {
      message.success({
        content: t('errors.identifiedCard', { card }),
        key: 'pos',
        duration: 1,
        className: 'card-message',
      });
      if (internalCard === true) {
        handleOpenOrder(card);
      } else {
        handleEnrollExternalCard(card);
      }
    },
    [internalCard, handleOpenOrder, handleEnrollExternalCard, t],
  );

  const onCardMissing = useCallback(() => {
    message.error({
      content: t('errors.cardNotFound'),
      key: 'pos',
      duration: 5,
      className: 'card-message',
    });
  }, [t]);

  const [, , fetchCard, cancelFetchCard] = useCard({
    onCardScan,
    onCardFound,
    onCardMissing,
  });

  const columns = useMemo(
    () => [
      {
        title: 'Categorie',
        dataIndex: 'productCategory',
        key: 'productCategory',
      },
      {
        title: 'Denumire produs',
        dataIndex: 'productName',
        key: 'productCategory',
      },
      {
        title: 'Pret',
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (_, row) =>
          // eslint-disable-next-line no-nested-ternary
          row.price
            ? row.productCategory === 'PURSE'
              ? (row.qty * 0.01).toFixed(2)
              : (row.price * 0.01).toFixed(2)
            : '',
      },
      {
        title: 'Cantitate',
        width: 150,
        dataIndex: 'qty',
        key: 'qty',
        render: (_, row) => (row.productCategory === 'PURSE' ? '-' : row.qty),
      },
      {
        title: 'Pret total',
        width: 150,
        key: 'totalPrice',
        render: (_, row) => (
          <>
            {((row.totalPrice || row.qty * row.price || 0) * 0.01).toFixed(2)}
          </>
        ),
      },
    ],
    [],
  );

  const handleSummary = useCallback(() => {
    cancelFetchCard();
    getShiftSummary()
      .then((res) => {
        setSummaryCash([
          ...res.summaryCash,
          {
            productName: 'TOTAL Schimb',
            totalPrice: res.summaryCash.reduce(
              (acc, row) => acc + row.qty * row.price,
              0,
            ),
          },
        ]);
        setSummaryCard([
          ...res.summaryCard,
          {
            productName: 'TOTAL Schimb',
            totalPrice: res.summaryCard.reduce(
              (acc, row) => acc + row.qty * row.price,
              0,
            ),
          },
        ]);
      })
      .catch((err) => {
        console.error(err);
        notification.error({
          message: err.message,
        });
      });
  }, [cancelFetchCard]);

  return (
    <>
      <div className="pos">
        <div className="pos__wrapper">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 4, offset: enrollExternalCard === 'true' ? 4 : 6 }}
            >
              <POSButton
                title="Card existent"
                onClick={() => {
                  setInternalCard(true);
                  fetchCard();
                }}
                color="blue"
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              <POSButton
                title="Card nou"
                onClick={() => {
                  cancelFetchCard();
                  handleOpenOrder();
                }}
                color="orange"
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              <POSButton
                title="Caută card"
                onClick={() => {
                  cancelFetchCard();
                  setSearchCard(true);
                }}
                color="green"
              />
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              {enrollExternalCard === 'true' && (
                <POSButton
                  title="Înrolare card extern"
                  onClick={() => {
                    setInternalCard(false);
                    fetchCard();
                  }}
                  color="green"
                />
              )}
            </Col>
          </Row>

          <Divider style={{ margin: '6rem 0' }} />

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
            {isStocksEnabled ? (
              <Col xs={{ span: 24 }} lg={{ span: 4, offset: 6 }}>
                <div>
                  <POSButton
                    title="Carduri rebut"
                    onClick={() => setScrapCardModalVisible(true)}
                    color="green"
                  />
                </div>
              </Col>
            ) : null}

            <Col
              xs={{ span: 24 }}
              lg={{ span: 4, offset: !isStocksEnabled ? 8 : 0 }}
            >
              <Popconfirm
                title="Sigur doriti sa inchideti schimbul?"
                onConfirm={handleCloseShift}
                okText="Da"
                cancelText="No"
              >
                <div>
                  <POSButton
                    title="Inchidere schimb"
                    onClick={() => cancelFetchCard()}
                    color="red"
                  />
                </div>
              </Popconfirm>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              <POSButton
                title="Raport schimb"
                onClick={handleSummary}
                color="magenta"
              />
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        title="Caută card"
        visible={searchCard}
        onOk={() => {
          form
            .validateFields()
            .then(handleOk)
            .catch((info) => {
              console.error('Validate Failed:', info);
            });
        }}
        onCancel={() => {
          form.setFieldsValue({
            userIdentifier: null,
            logicIdSerial: null,
          });
          setModalDisabled(true);
          setCards('');
          setSearchCard(false);
        }}
        okButtonProps={{
          disabled: modalDisabled,
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="CNP" name="userIdentifier">
            <Input
              onChange={() =>
                setModalDisabled(
                  !form.getFieldValue('userIdentifier') &&
                    !form.getFieldValue('logicIdSerial'),
                )
              }
            />
          </Form.Item>
          <Form.Item label="Număr card" name="logicIdSerial">
            <Input
              onChange={() =>
                setModalDisabled(
                  !form.getFieldValue('userIdentifier') &&
                    !form.getFieldValue('logicIdSerial'),
                )
              }
            />
          </Form.Item>
          <FoundCardsList cards={cards} />
        </Form>
      </Modal>
      <Modal
        title="Raport inchidere schimb"
        visible={summaryCard != null && summaryCash != null}
        closable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              if (
                summaryCard[summaryCard.length - 1].close &&
                summaryCash[summaryCash.length - 1].close
              ) {
                setShift(null);
              }
              setSummaryCard(null);
              setSummaryCash(null);
            }}
          >
            Inchide
          </Button>,
        ]}
      >
        <Row>
          <Col>Plata cu card</Col>
        </Row>
        <Table dataSource={summaryCard} columns={columns} pagination={false} />
        <Row>
          <Col>Plata cash</Col>
        </Row>
        <Table dataSource={summaryCash} columns={columns} pagination={false} />
      </Modal>
      <Modal
        title="Deblocarea cardului"
        visible={cardBlocked === true}
        closable={false}
        footer={[
          <Button key="submit" type="primary" onClick={enableCard}>
            Deblochează cardul
          </Button>,
          <Button
            key="close"
            type="primary"
            onClick={() => {
              setCardBlocked(false);
            }}
          >
            Inchide
          </Button>,
        ]}
      >
        Doriți să deblocați cardul
      </Modal>

      <Modal
        title="Carduri rebut"
        open={scrapCardModalVisible === true}
        closable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={sendScrap}
            disabled={noScrap < 1}
          >
            Confirmă
          </Button>,
          <Button
            key="close"
            type="secondary"
            onClick={() => setScrapCardModalVisible(false)}
          >
            Renunță
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={8}>
            <POSButton
              title="Scade"
              onClick={() => setNoScrap(noScrap > 0 ? noScrap - 1 : 0)}
              color="red"
              size="xsmall"
            />
          </Col>
          <Col span={8}>
            <InputNumber
              style={{
                height: '100%',
                width: '100%',
                fontSize: '2rem',
              }}
              value={noScrap}
              onChange={(value) => setNoScrap(Math.max(0, value))}
            />
          </Col>
          <Col span={8}>
            <POSButton
              title="Adaugă"
              onClick={() => setNoScrap(noScrap + 1)}
              color="green"
              size="xsmall"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Initial;
