import React, { useContext, useMemo, useState } from 'react';
import {
  Button,
  Form,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';
import FormItem from 'antd/es/form/FormItem';
import Column from '../../helpers/columns';
import { FormContext } from '../../hooks/useForm';
import {
  disableTitle,
  getPeriodForTransaction,
  getTitlesById,
} from '../../services/accounts/accounts';
import useDatasource from '../../hooks/useDatasource';
import AuthContext, { hasPermission } from '../auth';
import { ProductCategories } from './TitlesDescandantsList';

export const CustomerTypes = {
  content: [
    { id: 'CERERE', name: 'Cerere' },
    { id: 'CORECTIE', name: 'Corectie' },
  ],
};

const TransportTitles = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const form = useContext(FormContext);
  const { value } = form;
  const [disableTitleForm] = Form.useForm();
  const { setFieldsValue, resetFields } = disableTitleForm;
  const { id } = value;
  const [showModal, setShowModal] = useState(false);
  const [period, setPeriod] = useState([]);
  const [title, setTitle] = useState(null);
  const [selectedDate, setDate] = useState(1);

  const { pagination, content, handleChange, reload } = useDatasource((arg) =>
    getTitlesById(id, arg).then((response) => {
      response?.content?.forEach((rTitle) => {
        if (rTitle.productType === 'PURSE') {
          // eslint-disable-next-line no-param-reassign
          rTitle.quantity = `${parseFloat(rTitle.purseValue / 100).toFixed(
            2,
          )} RON`;
        }
      });
      return response;
    }),
  );

  const actionsColumn = Column.actions(
    t('table.actions'),
    (val) => (
      <span style={{ textAlign: 'right' }}>
        <Tooltip title="Inactivare">
          <Button
            className="ant-btn-success"
            type="primary"
            icon={<SaveOutlined />}
            disabled={val.deleted}
            onClick={() => {
              getPeriodForTransaction(val.id).then((res) => {
                setPeriod(res);
                if (res.canEditB) {
                  setDate(2);
                  setFieldsValue({ dateType: 2 });
                }
                if (res.canEditA) {
                  setFieldsValue({ dateType: 1 });
                  setDate(1);
                }
                setTitle(val);
                setShowModal(true);
              });
            }}
            size="medium"
          >
            Inactivare
          </Button>
        </Tooltip>
      </span>
    ),
    {
      sort: false,
      width: 120,
    },
  );

  const columns = useMemo(
    () => [
      Column.text('productId', t('entity.account.transportTitle.product_id'), {
        filter: false,
        width: 50,
      }),
      Column.text(
        'productName',
        t('entity.account.transportTitle.product_name'),
        {
          filter: true,
        },
      ),
      Column.dictionary(
        'productType',
        t('entity.account.transportTitle.product_types._'),
        ProductCategories,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`entity.account.transportTitle.product_types.${entry.id}`),
        },
      ),
      Column.text(
        'validPeriod',
        t('entity.account.transportTitle.validPeriod'),
        {
          sort: false,
          width: 600,
        },
      ),
      Column.text('quantity', t('entity.account.transportTitle.quantity'), {
        sort: false,
      }),
      Column.bool('deleted', t('entity.account.transportTitle.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.account.transportTitle.deleted.false'),
          t('entity.account.transportTitle.deleted.true'),
          t('table.filter.unknown'),
        ],
      }),
      Column.bool('active', t('entity.account.transportTitle.active._'), {
        filter: true,
        inverted: false,
        labels: [
          t('table.filter.all'),
          t('entity.account.transportTitle.active.true'),
          t('entity.account.transportTitle.active.false'),
          t('table.filter.unknown'),
        ],
      }),
      hasPermission(permissions, [
        'SYS_SUPERUSER',
        'ADMINISTRATION_ACCOUNTS_PERMISSION',
      ])
        ? actionsColumn
        : {},
    ],
    [t, permissions, actionsColumn],
  );

  const contentWithProdType = useMemo(
    () =>
      content.map((ttl) => ({
        ...ttl,
        prodTypeName: t(
          `entity.account.transportTitle.product_types.${ttl.productType}`,
        ),
      })),

    [content, t],
  );

  return (
    <>
      {showModal && (
        <Modal
          title="Inactivare titlu"
          width={350}
          visible
          closable={false}
          footer={[
            <Popconfirm
              key="enableTitle"
              placement="topLeft"
              title={`Sunteți sigur că doriți să anulați perioada perioadă pentru ${title.productName}?`}
              okText="Da"
              cancelText="Nu"
              okButtonProps={{ htmlType: 'submit', type: 'primary' }}
              onConfirm={() => {
                disableTitleForm.validateFields().then((r) => {
                  disableTitle(title.id, r).then(() => {
                    setShowModal(false);
                    resetFields();
                    reload();
                  });
                });
              }}
            >
              {(period.canEditA || period.canEditB) && (
                <Tooltip title="Anulare perioada selectata">
                  <Button
                    className="ant-btn-success"
                    type="primary"
                    icon={<SaveOutlined />}
                  >
                    Anuleaza perioada selectata
                  </Button>
                </Tooltip>
              )}
            </Popconfirm>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setShowModal(false);
                resetFields();
              }}
            >
              Renunta
            </Button>,
          ]}
        >
          {' '}
          {period.canEditA || period.canEditB ? (
            <>
              <Form form={disableTitleForm}>
                <Radio.Group
                  onChange={(e) => {
                    setDate(e.target.value);
                    setFieldsValue({ dateType: e.target.value });
                  }}
                  defaultValue={selectedDate}
                >
                  <FormItem name="dateType">
                    <Space direction="vertical">
                      {period.canEditA && (
                        <Radio value={1}>
                          {period.startDateA} - {period.endDateA}
                        </Radio>
                      )}
                      {period.canEditB && (
                        <Radio value={2}>
                          {' '}
                          {period.startDateB} - {period.endDateB}
                        </Radio>
                      )}
                    </Space>
                  </FormItem>
                </Radio.Group>

                <FormItem
                  label="Motivul inactivarii"
                  name="reason"
                  rules={[
                    {
                      required: true,
                      message: t('errors.requiredField'),
                    },
                  ]}
                >
                  <Select
                    // loading={CustomerTypes.loading}
                    onChange={(e) => {
                      setFieldsValue({ reason: e });
                    }}
                  >
                    {CustomerTypes.content.map((option) => (
                      <Select.Option value={option.id} key={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Form>
            </>
          ) : (
            'Acest titlu nu se poate inactiva'
          )}
        </Modal>
      )}
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={contentWithProdType}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </>
  );
};
export default TransportTitles;
