import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/offers';
export const AUTH_BASE_URI = '/api/v1/schedules';

export const getSchedules = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getScheduleById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createSchedule = async (schedule) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, schedule);

export const updateSchedule = async (schedule) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${schedule.id}`, schedule);

export const deleteSchedule = async (schedule) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${schedule.id}`);
