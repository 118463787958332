import React from 'react';

import { Default } from '../../../../../layouts';

import { useEditPage } from '../../../../../hooks/useEditPage';
import {
  getParameterById,
  updateParameter,
} from '../../../../../services/validators/parameters';
import EditParameterForm from '../../../../../components/validators/parameters/EditParameterForm';

const initialParameter = { deleted: false };

const EditParameter = () => {
  const [parameter, handleSubmit, handleCancel] = useEditPage({
    initial: initialParameter,
    existent: getParameterById,
    goBackPath: '/admin/taxonomies/validators/parameters',
    // onCreate: createParameter,
    onUpdate: updateParameter,
  });

  return (
    <Default>
      {parameter !== undefined && (
        <EditParameterForm
          parameter={parameter}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditParameter;
