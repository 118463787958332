import React from 'react';

import { Default } from '../../../../layouts';

import { useEditPage } from '../../../../hooks/useEditPage';

import EditStationTypeForm from '../../../../components/station-types/EditStationTypeForm';

import {
  createStationType,
  getStationTypeById,
  updateStationType,
} from '../../../../services/taxonomies/station-types';

const initialStationType = { deleted: false };

const EditStationType = () => {
  const [stationType, handleSubmit, handleCancel] = useEditPage({
    initial: initialStationType,
    existent: getStationTypeById,
    goBackPath: '/admin/taxonomies/station-types',

    onCreate: createStationType,
    onUpdate: updateStationType,
  });

  return (
    <Default>
      {stationType !== undefined && (
        <EditStationTypeForm
          stationType={stationType}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditStationType;
