import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { notification } from 'antd';
import EditPosForm from '../../../../components/poses/EditPosForm';
import {
  createPos,
  getPosById,
  updatePos,
} from '../../../../services/taxonomies/poses';
import { useEditPage } from '../../../../hooks/useEditPage';
import { Default } from '../../../../layouts';

const initialPos = {
  deleted: false,
  useRetailerReserves: true,
  reserves: [],
  useRetailerLimits: true,
  limits: [],
};

const EditPos = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleFail = useCallback(() => {
    notification.error({
      message: t('Eroare la incarcarea datelor'),
    });
  }, [t]);

  const [pos, handleSubmit] = useEditPage({
    initial: initialPos,
    existent: getPosById,
    goBackPath: '/admin/taxonomies/poses',
    onInvalid: handleFail,
    onFailed: handleFail,
    onCreate: createPos,
    onUpdate: updatePos,
  });

  const handleCancel = () => history.goBack();

  return (
    <Default>
      {pos !== undefined && (
        <EditPosForm
          pos={pos}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};
export default EditPos;
