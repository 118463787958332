import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../hooks/useForm';
import FormWrapper from '../../FormWrapper';

const VALIDATOR_STATES = [
  { id: 'CLOSED' },
  { id: 'GARAGE' },
  { id: 'ACCESS' },
  { id: 'COMMERCIAL' },
  { id: 'STATISTICS' },
  { id: 'CONTROL' },
];

const AdditionalInfo = ({ isPassTitle }) => {
  const { t } = useTranslation();

  const form = useContext(FormContext);

  const { value, input, select, checkbox } = form;

  return (
    <>
      <FormWrapper>
        <FormWrapper.Single>
          <FormWrapper.Select
            label={t('entity.offers.product.validStates._')}
            props={{
              options: VALIDATOR_STATES,
              mode: 'multiple',
              allowClear: true,
              labelProp: ({ id }) =>
                t(`entity.offers.product.validStates.${id}`),
              ...select('validStates'),
              disabled: value.deleted,
            }}
          />
          <FormWrapper.Number
            label={t('entity.offers.product.maxValue')}
            props={{ ...input('maxValue'), disabled: value.deleted }}
          />
          <FormWrapper.Number
            label={t('entity.offers.product.dispDivFct')}
            props={{
              ...input('dispDivFct'),
              min: 0,
              max: 100,
              disabled: value.deleted,
            }}
          />

          <FormWrapper.Number
            label={t('entity.offers.product.dispId')}
            props={{ ...input('dispId'), disabled: value.deleted }}
          />

          <FormWrapper.Number
            label={t('entity.offers.product.dispId2')}
            props={{ ...input('dispId2'), disabled: value.deleted }}
          />

          <FormWrapper.Number
            label={t('entity.offers.product.dispId2valStart')}
            props={{ ...input('dispId2valStart'), disabled: value.deleted }}
          />
          {isPassTitle && (
            <FormWrapper.Switch
              label={t('entity.offers.product.extensionPeriodRecharge')}
              props={{ ...checkbox('extensionPeriodRecharge') }}
            />
          )}
        </FormWrapper.Single>
      </FormWrapper>
    </>
  );
};

export default AdditionalInfo;
