import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Row, Col } from 'antd';
import Default from '../../../../layouts/Default';
import PageTitle from '../../../../components/PageTitle';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import {
  getInstitutions,
  deleteInstitution,
  downloadInstitutions,
} from '../../../../services/taxonomies/institutions';
import useDatasource from '../../../../hooks/useDatasource';
import Column from '../../../../helpers/columns';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import ExportEntitiesButton from '../../../../components/buttons/ExportEntitiesButton';

const InstitutionsList = () => {
  const { t } = useTranslation();

  const { content, reload, pagination, handleChange, criterias, sort } =
    useDatasource(getInstitutions);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.institutions.name'), {
        filter: true,
      }),
      Column.text('createdBy', t('entity.taxonomy.institutions.createdBy'), {
        sort: false,
      }),
      Column.date('createdAt', t('entity.taxonomy.institutions.createdAt'), {
        sort: false,
      }),
      Column.bool('deleted', t('entity.taxonomy.institutions.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.institutions.deleted.true'),
          t('entity.taxonomy.institutions.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (institution) => (
        <>
          <EditItemIcon
            path={`/admin/taxonomies/institutions/${institution.id}`}
          />
          <DeleteItemIcon
            title={t('entity.taxonomy.institutions._delete', institution)}
            message={t('entity.taxonomy.institutions._deleted', institution)}
            item={institution}
            action={deleteInstitution}
            reload={reload}
          />
        </>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.institutions._plural')} />
      <Row style={{ paddingBottom: '24px' }} justify="end" gutter={16}>
        <Col>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadInstitutions}
          />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <AddEntityButton
            entity={t('entity.taxonomy.institutions._singular')}
            path="/admin/taxonomies/institutions/new"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default InstitutionsList;
