import React, { useMemo } from 'react';

import { Card, Col, Descriptions, Row, Space, Table, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import moment from 'moment';

import Column from '../../../../helpers/columns';
import DatePickerLocale from '../../../../components/DatePickerLocale';

const TVMDetails = ({ details }) => {
  const cashColumns = useMemo(
    () => [
      Column.text('label', 'Denominator'),
      Column.text('recycler', 'Recycler'),
      Column.text('box', 'Box'),
    ],
    [],
  );
  const cashDetails = useMemo(
    () =>
      new Array(10)
        .fill(null)
        .map((v, idx) => ({
          id: idx,
          label: details[`cashDenomination${idx}`],
          recycler: details[`cashRecycler${idx}`],
          box: details[`cashBox${idx}`],
        }))
        .filter((row) => !!row.label),
    [details],
  );

  return (
    <>
      <Space style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <DatePickerLocale
          disabled
          showTime
          value={moment(details.updatedAt)}
          style={{ width: '200px' }}
        />
      </Space>
      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col span={12}>
          <Card
            title="POS"
            extra={
              details.posEnabled ? (
                <Tag
                  icon={<CheckCircleOutlined />}
                  color="success"
                  style={{ marginRight: 0 }}
                >
                  {details.posStatus || '-'}
                </Tag>
              ) : (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="error"
                  style={{ marginRight: 0 }}
                >
                  {details.posStatus || '-'}
                </Tag>
              )
            }
          >
            &nbsp;
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title="Cash"
            extra={
              details.cashEnabled ? (
                <Tag
                  icon={<CheckCircleOutlined />}
                  color="success"
                  style={{ marginRight: 0 }}
                >
                  {details.cashStatus || '-'}
                </Tag>
              ) : (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="error"
                  style={{ marginRight: 0 }}
                >
                  {details.cashStatus || '-'}
                </Tag>
              )
            }
          >
            <Table
              columns={cashColumns}
              rowKey="id"
              dataSource={cashDetails}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col span={8}>
          <Card
            title="Alarm"
            extra={
              details.alarmEnabled ? (
                <Tag
                  icon={<CheckCircleOutlined />}
                  color="success"
                  style={{ marginRight: 0 }}
                >
                  {details.alarmStatus || '-'}
                </Tag>
              ) : (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="error"
                  style={{ marginRight: 0 }}
                >
                  {details.alarmStatus || '-'}
                </Tag>
              )
            }
          >
            &nbsp;
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Dispenser"
            extra={
              details.dispenserEnabled ? (
                <Tag
                  icon={<CheckCircleOutlined />}
                  color="success"
                  style={{ marginRight: 0 }}
                >
                  {details.dispenserStatus || '-'}
                </Tag>
              ) : (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="error"
                  style={{ marginRight: 0 }}
                >
                  {details.dispenserStatus || '-'}
                </Tag>
              )
            }
          >
            <Descriptions>
              <Descriptions.Item label="Carduri">
                {details.dispenserCards0}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Printer"
            extra={
              details.printerEnabled ? (
                <Tag
                  icon={<CheckCircleOutlined />}
                  color="success"
                  style={{ marginRight: 0 }}
                >
                  {details.printerStatus || '-'}
                </Tag>
              ) : (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="error"
                  style={{ marginRight: 0 }}
                >
                  {details.printerStatus || '-'}
                </Tag>
              )
            }
          >
            &nbsp;
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TVMDetails;
