import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { notification, Table } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import { FormContext } from '../../hooks/useForm';
import { getViewRetailUsers } from '../../services/admin/users';
import Column from '../../helpers/columns';
import FormWrapper from '../FormWrapper';

const SellersList = ({ collection }) => {
  const form = useContext(FormContext);
  const { t } = useTranslation();

  const { value, setValue, errorMessages, isSubmitting } = form;

  const { id, deleted } = form.value;
  let collectionData = null;

  switch (collection) {
    case 'sellers':
      collectionData = form.value.sellers;
      break;
    case 'cashiers':
      collectionData = form.value.cashiers;
      break;
    case 'headCashiers':
      collectionData = form.value.headCashiers;
      break;
    default:
      notification.error({
        message: t('actions.loadFailed'),
      });
  }

  const [unselected, setUnselected] = useState([]);

  const columns = useMemo(
    () => [
      Column.text('fullname', t('entity.admin.user.fullname'), {
        filter: true,
      }),
      Column.text('identityCard', t('entity.admin.user.identityCard'), {
        filter: true,
      }),
      Column.text('retailerName', t('entity.taxonomy.retailer._singular')),
    ],
    [t],
  );

  const { pagination, content, handleChange, reload } = useDatasource(
    deleted
      ? () => Promise.resolve({ content: [] })
      : (args) => {
          return getViewRetailUsers({
            ...args,
            criterias: {
              ...args.criterias,
              retailerId: value.id ? value.id : -1,
              type: collection,
            },
          });
        },
  );
  const handleSelect = useCallback(
    (record, selected) => {
      if (selected) {
        setValue(collection, [...(collectionData || []), record.id]);
        setUnselected(unselected?.filter((seller) => seller !== record.id));
      } else {
        setValue(
          collection,
          collectionData.filter((seller) => seller !== record.id),
        );
        setUnselected([...(unselected || []), record.id]);
      }
    },
    [collection, collectionData, unselected, setValue],
  );
  useEffect(() => {
    content
      .filter(
        (usr) =>
          usr.retailerId === id &&
          !collectionData.includes(usr.id) &&
          !unselected.includes(usr.id),
      )
      .forEach((usr) => {
        setValue(collection, [...collectionData, usr.id]);
      });
    return () => null;
  }, [collection, content, id, setValue, collectionData, unselected]);

  useEffect(() => {
    if (isSubmitting === false) {
      reload();
    }
    return () => null;
  }, [reload, isSubmitting]);

  const rowSelection = useMemo(
    () => ({
      onSelect: handleSelect,
      selectedRowKeys: collectionData || [],
    }),
    [collectionData, handleSelect],
  );

  return (
    <>
      <FormWrapper.ErrorMessage message={errorMessages?.sellers} />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default SellersList;
