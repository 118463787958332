import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/routes';

export const getRoutes = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/search`, data);

export const getRouteById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createRoute = async (route) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, route);

export const updateRoute = async (route) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${route.id}`, route);

export const deleteRoute = async (route) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${route.id}`);
