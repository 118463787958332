import React from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Default } from '../../layouts';

import { useEditPage } from '../../hooks/useEditPage';

import { findOne, create, update } from '../../services/orders';
import EditOrderForm from '../../components/orders/EditOrderForm';
import { ORDER_STATUS } from './constants';

const initialOrderData = {
  attachments: [],
  products: [],
  status: 'SELECT_PRODUCTS',
  deleted: false,
};

const EditOrder = () => {
  const { t } = useTranslation();

  const notify = (value) => {
    if (
      value?.hasAlreadyStockableWarning &&
      [ORDER_STATUS.SELECT_PRODUCTS, ORDER_STATUS.DOC_VALIDATION].includes(
        value?.status,
      )
    ) {
      notification.warn({
        message: t('actions.hasAlreadyStockableWarning'),
      });
    }
  };

  const [order, handleSubmit, handleCancel] = useEditPage({
    initial: initialOrderData,
    existent: findOne,
    goBackPath: '/orders',
    onCreate: create,
    onUpdate: update,
    customNotification: notify,
  });

  return (
    <Default>
      {order !== undefined && (
        <EditOrderForm
          order={{
            ...order,
            disabled:
              order.status !== ORDER_STATUS.SELECT_PRODUCTS ||
              order.customerType,
          }}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditOrder;
