export const PosTypes = {
  content: [
    { id: 'POS' },
    { id: 'TERMINAL' },
    { id: 'OPERATOR' },
    { id: 'PORTAL' },
    { id: 'PORTAL_PJ' },
    { id: 'MOBILE' },
    { id: 'ENROLLMENT' },
    { id: 'TVM_EXTERN' },
    { id: 'TVM_EXTERN2' },
    { id: 'TVM_EXTERN3' },
    { id: 'TVM_EXTERN4' },
    { id: 'TVM_EXTERN5' },
    { id: 'TVM_EXTERN6' },
    { id: 'TVM_EXTERN7' },
    { id: 'TVM_EXTERN8' },
    { id: 'TVM_EXTERN9' },
  ],
  loading: false,
  error: false,
};

export const SalesChannels = {
  content: [
    { id: 'BACKOFFICE' },
    { id: 'PORTAL' },
    { id: 'MOBILE' },
    { id: 'TVM' },
    { id: 'POS' },
  ],
  loading: false,
  error: false,
};
