import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import useDatasource from '../../hooks/useDatasource';
import { FormContext } from '../../hooks/useForm';
import Column from '../../helpers/columns';
import { getRoutes } from '../../services/taxonomies/routes';

const RoutesList = () => {
  const form = useContext(FormContext);
  const { t } = useTranslation();

  const { value, setValue } = form;

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.route.name'), {
        filter: true,
      }),
      Column.bool('deleted', t('entity.taxonomy.route.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.route.deleted.true'),
          t('entity.taxonomy.route.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      onSelect: (record, selected) =>
        selected
          ? setValue('routes', [...value.routes, record.id])
          : setValue(
              'routes',
              value.routes.filter((id) => id !== record.id),
            ),
      selectedRowKeys: value.routes || [],
      getCheckboxProps: ({ routeGroup, deleted, id }) => ({
        disabled:
          (routeGroup && value.id !== routeGroup) ||
          (deleted &&
            (value.routes?.length > 0
              ? value.routes?.filter((ar) => ar === id).length === 0
              : true)),
      }),
    }),
    [setValue, value.id, value.routes],
  );

  const { pagination, content, handleChange } = useDatasource(getRoutes);

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns}
      rowKey="id"
      // loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
    />
  );
};

export default RoutesList;
