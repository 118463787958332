import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Space } from 'antd';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';

const EditCompanyForm = ({ company, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        code: yup.string().required(t('errors.notEmpty')),
        name: yup.string().required(t('errors.notEmpty')),
        address: yup.string().required(t('errors.notEmpty')),
        commercialCode: yup.string().required(t('errors.notEmpty')),
        fiscalCode: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const { value, handleSubmit, input, checkbox, setFormValue } = useForm({
    initialValue: company,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  useEffect(() => {
    setFormValue(company);
  }, [setFormValue, company]);

  const { deleted } = value;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={company.name || t('entity.taxonomy.company._singular')}
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.company.code')}
              props={{ ...input('code'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.company.name')}
              props={{ ...input('name'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.company.address')}
              props={{ ...input('address'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.company.commercialCode')}
              props={{ ...input('commercialCode'), disabled: deleted }}
            />

            <FormWrapper.Input
              label={t('entity.taxonomy.company.fiscalCode')}
              props={{ ...input('fiscalCode'), disabled: deleted }}
            />
            <Space style={{ paddingTop: '1rem' }}>
              {!deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
          <FormWrapper.Single>
            <FormWrapper.Switch
              label={t('entity.taxonomy.company.cardIssuer._')}
              props={{ ...checkbox('cardIssuer'), disabled: deleted }}
            />

            <FormWrapper.Switch
              label={t('entity.taxonomy.company.transportCompany._')}
              props={{ ...checkbox('transportCompany'), disabled: deleted }}
            />

            <FormWrapper.Switch
              label={t('entity.taxonomy.company.vatTaxPayer._')}
              props={{ ...checkbox('vatTaxPayer'), disabled: deleted }}
            />

            <FormWrapper.Switch
              label={t('entity.taxonomy.company.seller._')}
              props={{ ...checkbox('seller'), disabled: deleted }}
            />
          </FormWrapper.Single>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditCompanyForm;
