import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

import { Default } from '../../../../../layouts';
import useDatasource from '../../../../../hooks/useDatasource';
import PageTitle from '../../../../../components/PageTitle';
import EditItemIcon from '../../../../../components/buttons/EditItemIcon';
import Column from '../../../../../helpers/columns';
import { getParameters } from '../../../../../services/validators/parameters';

const ParametersList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange } = useDatasource(getParameters);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.taxonomy.validators.parameters.name'), {
        filter: true,
      }),
      Column.bool(
        'deleted',
        t('entity.taxonomy.validators.parameters.deleted._'),
        {
          width: 150,
          filter: true,
          inverted: true,
          labels: [
            t('table.filter.all'),
            t('entity.taxonomy.validators.parameters.deleted.true'),
            t('entity.taxonomy.validators.parameters.deleted.false'),
            t('table.filter.unknown'),
          ],
        },
      ),
      Column.actions(t('table.actions'), (record) => (
        <>
          <EditItemIcon
            path={`/admin/taxonomies/validators/parameters/${record.id}`}
          />
          {/* <DeleteItemIcon */}
          {/*  title={t('entity.taxonomy.validators.parameters._delete', record)} */}
          {/*  message={t( */}
          {/*    'entity.taxonomy.validators.parameters._deleted', */}
          {/*    record, */}
          {/*  )} */}
          {/*  item={record} */}
          {/*  action={deleteParameter} */}
          {/*  reload={reload} */}
          {/* /> */}
        </>
      )),
    ],
    [t],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.validators.parameters._plural')}>
        {/* <AddEntityButton */}
        {/*  entity={t('entity.taxonomy.validators.parameters._singular')} */}
        {/*  path="/admin/taxonomies/validators/parameters/new" */}
        {/* /> */}
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default ParametersList;
