import React, { useMemo, useContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Select, Button, notification, Card } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { FormContext } from '../../../hooks/useForm';

import FormWrapper from '../../FormWrapper';
import {
  UsagePerPeriod,
  Schedule,
  PassBack,
  FreeTransfer,
  LineGroups,
} from './restrictions';
import DateValidation from './restrictions/DateValidation';

const RESTRICTIONS = {
  USAGE_PER_PERIOD: {
    component: UsagePerPeriod,
    defaultValue: { value1: 62, value2: 0 },
  },
  SCHEDULE: {
    component: Schedule,
    defaultValue: {},
  },
  PASS_BACK: {
    component: PassBack,
    defaultValue: { value1: 0, value2: 0, value3: 0 },
  },
  FREE_TRANSFER: {
    component: FreeTransfer,
    defaultValue: { value1: 15, value2: 3 },
  },
  LINE_GROUPS: {
    component: LineGroups,
    defaultValue: {
      value1: 0,
      value2: undefined,
      value3: 0,
      value4: undefined,
      value5: 0,
      value6: undefined,
      value7: 0,
      value8: undefined,
    },
  },
  DATE_VALIDATION: {
    component: DateValidation,
    defaultValue: { value1: 0, value2: 0, value3: 0 },
  },
};

const RestrictionsGrid = () => {
  const { t } = useTranslation();
  const form = useContext(FormContext);

  const { value, setValue, errorMessages } = form;

  const [selectedRestriction, setSelectedRestriction] = useState(null);

  const restrictions = useMemo(() => {
    return Array.isArray(value.restrictions) ? value.restrictions : [];
  }, [value]);

  const handleAddRestriction = useCallback(() => {
    if (!selectedRestriction) {
      return;
    }
    const index = restrictions.findIndex(
      (v) => v.restriction === selectedRestriction,
    );

    if (index === -1) {
      const initialValue = Object.entries(RESTRICTIONS).find(
        (v) => v[0] === selectedRestriction,
      )[1].defaultValue;

      setValue(
        'restrictions',
        restrictions.concat({
          restriction: selectedRestriction,
          ...initialValue,
        }),
      );
    } else {
      notification.error({
        message: t('actions.loadFailed'),
      });
    }
  }, [restrictions, selectedRestriction, setValue, t]);

  const handleDeleteRestriction = useCallback(
    (index) => {
      const newRestrictions = value.restrictions;
      newRestrictions.splice(index, 1);
      setValue('restrictions', newRestrictions);
    },
    [setValue, value.restrictions],
  );

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={12} lg={16} xl={16}>
          <Select
            value={selectedRestriction}
            onChange={setSelectedRestriction}
            disabled={value.deleted}
          >
            {Object.keys(RESTRICTIONS).map((r) => (
              <Select.Option value={r} key={r}>
                {t(`entity.offers.restrictions.restriction.${r}._`)}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Button
            disabled={!selectedRestriction || value.deleted}
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: '100%' }}
            onClick={handleAddRestriction}
          >
            {t('actions.add')} {t(`entity.offers.restrictions._singular`)}
          </Button>
        </Col>
      </Row>

      <FormWrapper.ErrorMessage message={errorMessages?.restrictions} />

      {restrictions.map((rest, index) => {
        const Component = RESTRICTIONS[rest.restriction].component;
        return (
          <Card
            key={rest.restriction}
            size="small"
            style={{ marginTop: '1rem' }}
            title={t(
              `entity.offers.restrictions.restriction.${rest.restriction}._`,
            )}
            extra={
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteRestriction(index)}
                disabled={value.deleted}
              />
            }
          >
            <Component
              path={`restrictions[${index}]`}
              disabled={value.deleted}
            />
          </Card>
        );
      })}
    </>
  );
};

export default RestrictionsGrid;
