import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import { Default } from '../../layouts';

import PageTitle from '../../components/PageTitle';
import {
  CurrentRetail as CurrentRetailSymbol,
  symbolToString,
} from '../../components/stocks/constants';
import AddInvoiceButton from '../../components/stocks/AddInvoiceButton';
import RetailList from '../../components/retail/RetailList';
import PosesList from '../../components/retail/PosesList';

const entityType = symbolToString(CurrentRetailSymbol);

const isInvoiceAllowed = ({ origin, destination }) =>
  origin === CurrentRetailSymbol || destination === CurrentRetailSymbol;

const Retail = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageTitle title={t('entity.retail._')}>
        <AddInvoiceButton isInvoiceAllowed={isInvoiceAllowed} group="retail" />
      </PageTitle>
      <Tabs>
        <Tabs.TabPane tab={t('entity.retail._')} key="retail">
          <RetailList />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('entity.taxonomy.poses._plural')} key="poses">
          <PosesList entityType={entityType} />
        </Tabs.TabPane>
      </Tabs>
    </Default>
  );
};

export default Retail;
