import React from 'react';
import { Typography } from 'antd';

const TitleLogo = () => {
  return (
    <div style={{ textAlign: 'center', padding: '2rem 0' }}>
      <Typography.Title level={2}>Urban GO!</Typography.Title>
    </div>
  );
};

export default TitleLogo;
