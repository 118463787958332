export const VALIDATOR_PARAMETER_TYPE = {
  UBYTE: 'UBYTE',
  UBYTE_6: 'UBYTE_6',
  UBYTE_8: 'UBYTE_8',
  UBYTE_10: 'UBYTE_10',
  UBYTE_MASK_SERVICE_STATE: 'UBYTE_MASK_SERVICE_STATE',
  UBYTE_MASK_DAYS: 'UBYTE_MASK_DAYS',
  UWORD: 'UWORD',
  UDWORD: 'UDWORD',
};

export const ValidatorParameterTypes = {
  content: Object.values(VALIDATOR_PARAMETER_TYPE).map((value) => ({
    id: value,
  })),
  loading: false,
  error: false,
};
