import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Space } from 'antd';
import useForm, { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';

const EditCurrencyForm = ({ currency, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        exchangeRate: yup.number().required(t('errors.notEmpty')),
        currencyDefault: yup.string().required(t('errors.mustSelected')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: currency,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const { value, handleSubmit, input, checkbox, setFormValue, setValue } = form;
  const { currencyDefault } = value;

  useEffect(() => {
    setFormValue(currency);
  }, [setFormValue, currency]);

  useEffect(() => {
    if (currencyDefault) {
      setValue('exchangeRate', 1);
    }
  }, [currencyDefault, setValue]);

  const { deleted } = value;

  return (
    <>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <PageTitle
            title={currency.name || t('entity.taxonomy.currency._singular')}
          />
          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.Input
                label={t('entity.taxonomy.currency.name')}
                props={{ ...input('name'), disabled: deleted }}
              />
              <FormWrapper.Switch
                label={t('entity.taxonomy.currency.currencyDefault._')}
                props={{ ...checkbox('currencyDefault'), disabled: deleted }}
              />
              <FormWrapper.Number
                label={t('entity.taxonomy.currency.exchangeRate')}
                props={{
                  ...input('exchangeRate'),
                  min: 0,
                  disabled: deleted || value.currencyDefault,
                }}
              />
              <Space>
                <SaveButton />
                <CancelButton onCancel={onCancel} />
              </Space>
            </FormWrapper.Single>
          </FormWrapper>
        </form>
      </FormContext.Provider>
    </>
  );
};
export default EditCurrencyForm;
