import { search, post, get, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/taxonomies';
export const AUTH_BASE_URI = '/api/v1/stations';

export const getStations = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/search`, data);

export const getStationById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createStation = async (station) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, station);

export const updateStation = async (station) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${station.id}`, station);

export const deleteStation = async (station) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${station.id}`);
