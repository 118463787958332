import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';

import useDatasource from '../../../hooks/useDatasource';
import { getInstitutions } from '../../../services/taxonomies/institutions';

const InstitutionsList = ({ form }) => {
  const { content } = useDatasource(getInstitutions, {
    pagination: { pageSize: 10000 },
  });

  const internalValue = useMemo(() => {
    return form.getFieldValue('Oraganizatie');
  }, [form]);

  const handleChange = useCallback(
    (val) => {
      form.setFieldsValue({ Oraganizatie: val });
    },
    [form],
  );

  return (
    <Select
      allowClear
      value={internalValue}
      onChange={handleChange}
      showSearch
      optionFilterProp="children"
    >
      {content.map(({ id, name }) => (
        <Select.Option key={id} value={name}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default InstitutionsList;
