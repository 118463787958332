import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { Default } from '../../../../layouts';

import useDatasource from '../../../../hooks/useDatasource';
import {
  getRetailers,
  deleteRetailer,
} from '../../../../services/taxonomies/retailers';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import PageTitle from '../../../../components/PageTitle';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import Column from '../../../../helpers/columns';

const RetailersList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } =
    useDatasource(getRetailers);

  const columns = useMemo(
    () => [
      Column.text('code', t('entity.taxonomy.retailer.code'), { filter: true }),
      Column.text('name', t('entity.taxonomy.retailer.name'), { filter: true }),
      Column.bool('deleted', t('entity.taxonomy.retailer.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.retailer.deleted.true'),
          t('entity.taxonomy.retailer.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <EditItemIcon
            path={`/admin/taxonomies/retailers/${record.id}`}
            disabled={record.deleted}
          />
          <DeleteItemIcon
            title={t('entity.taxonomy.retailer._delete', record)}
            message={t('entity.taxonomy.retailer._deleted', record)}
            item={record}
            action={deleteRetailer}
            reload={reload}
          />
        </span>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.retailer._plural')}>
        <AddEntityButton
          entity={t('entity.taxonomy.retailer._singular')}
          path="/admin/taxonomies/retailers/new"
        />
      </PageTitle>

      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default RetailersList;
