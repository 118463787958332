import React, { useEffect, useState } from 'react';
import { getPosesByRoleId } from '../../services/taxonomies/poses';
import FormWrapper from '../FormWrapper';

function PosSelector({ props }) {
  const { t, setValue, disabled, defaultValue } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getPosesByRoleId().then((data) => setOptions(data.content));
  }, []);

  return (
    <FormWrapper.Select
      label={t('entity.orders.posId')}
      props={{
        options: options.map((o) => ({
          id: o.id,
          name: o.name,
        })),
        onSelect: (value) => {
          setValue('posId', value);
        },
        allowClear: false,
        labelProp: (obj) => obj.name,
        disabled,
        value: defaultValue,
      }}
    />
  );
}

export default PosSelector;
