import React, { useMemo, useState, useCallback } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';

import { Table, Tooltip, Modal, Row, Col, Button, notification } from 'antd';
import PageTitle from '../../components/PageTitle';
import Column from '../../helpers/columns';
import { downloadSummary, getSummary } from '../../services/tvm/tvm';
import FilterDateWrapper from '../../components/FilterDateWrapper';

const TVMSalesDashboard = ({ id, createdAt }) => {
  const { t } = useTranslation();

  const [summaryCard, setSummaryCard] = useState(null);
  const [summaryCash, setSummaryCash] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);

  const content = useMemo(() => {
    const date = moment(createdAt).toDate();
    const arr = [];
    const numberOfdays = (new Date() - date) / (3600 * 24 * 1000);
    for (let i = 0; i <= numberOfdays; i += 1) {
      arr.unshift({
        id: i + 1,
        date: `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, 0)}-${date.getDate().toString().padStart(2, 0)}`,
      });
      date.setDate(date.getDate() + 1);
    }
    return arr;
  }, [createdAt]);
  const [filteredContent, setFilteredContent] = useState(content);
  const filterSearchContent = useCallback(
    (filter) => {
      const normalizedFilter = Object.entries(filter)
        .filter((entry) => entry[0] && entry[1] && Array.isArray(entry[1]))
        .map((el) => ({
          name: el[0],
          value: el[1][0],
        }));

      if (normalizedFilter) {
        setFilteredContent(
          content.filter((item) => {
            const period = normalizedFilter.find(
              ({ name }) => name === 'date',
            )?.value;
            return (
              !period ||
              (moment(item.date.concat('Z'), "YYYY-MM-DD'Z'").toDate() >=
                +moment(period.from)
                  .set({ hour: 0, second: 0, minute: 0, milisecond: 0 })
                  .toDate() &&
                moment(item.date.concat('Z'), "YYYY-MM-DD'Z'").toDate() <=
                  moment(period.to).toDate())
            );
          }),
        );
      }
    },
    [content],
  );

  const handleChange = useCallback(
    (_pagination, filter) => {
      filterSearchContent(filter);
    },
    [filterSearchContent],
  );

  const handleSummary = useCallback(
    (date) => () => {
      setCurrentDate(date);
      getSummary(id, date)
        .then((res) => {
          setSummaryCard([
            ...res.summaryCard,
            {
              productName: 'TOTAL',
              totalPrice: res.summaryCard.reduce(
                (acc, row) => acc + row.qty * row.price,
                0,
              ),
            },
          ]);
          setSummaryCash([
            ...res.summaryCash,
            {
              productName: 'TOTAL',
              totalPrice: res.summaryCash.reduce(
                (acc, row) => acc + row.qty * row.price,
                0,
              ),
            },
          ]);
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: err.message,
          });
        });
    },
    [id],
  );

  const sumaryColumns = useMemo(
    () => [
      {
        title: 'Id produsului',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Categorie',
        dataIndex: 'productCategory',
        key: 'productCategory',
      },
      {
        title: 'Denumire produs',
        dataIndex: 'productName',
        key: 'productCategory',
      },
      {
        title: 'Pret',
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (price) => <>{price ? (price * 0.01).toFixed(2) : ''}</>,
      },
      {
        title: 'Cantitate',
        width: 150,
        dataIndex: 'qty',
        key: 'qty',
      },
      {
        title: 'Pret total',
        width: 150,
        key: 'totalPrice',
        render: (_, row) => (
          <>
            {((row.totalPrice || row.qty * row.price || 0) * 0.01).toFixed(2)}
          </>
        ),
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      Column.actions(t('table.actions'), (record) => (
        <span
          style={{
            textAlign: 'right',
          }}
        >
          <Tooltip title={t('table.actions')}>
            <Button
              shape="circle"
              type="link"
              icon={<EyeOutlined />}
              onClick={handleSummary(record.date)}
              size="midle"
            />
          </Tooltip>
        </span>
      )),
      {
        title: t('entity.tvm.date'),
        key: 'date',
        width: 100,
        dataIndex: 'date',
        sorter: false,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
          <FilterDateWrapper
            title={t('entity.tvm.date')}
            searchTitle="Search"
            resetTitle="Reset"
            setSelectedKeys={setSelectedKeys}
            clearFilters={clearFilters}
            confirm={confirm}
          />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
      },
    ],
    [t, handleSummary],
  );

  const fields = useMemo(
    () =>
      sumaryColumns
        .filter(({ key }) => key !== '__actions')
        .map(({ key, title }) => `${key};${title}`),
    [sumaryColumns],
  );

  return (
    <>
      <PageTitle title={t('entity.tvm.sales')} />
      <Table
        columns={columns}
        rowKey="id"
        dataSource={filteredContent}
        onChange={handleChange}
        size="small"
        style={{ width: 400 }}
      />
      <Modal
        title="Raport vânzări TVM"
        visible={summaryCard != null && summaryCash != null}
        closable={false}
        width="1000px"
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => downloadSummary(id, currentDate, fields)}
          >
            {t('actions.download')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setSummaryCard(null);
              setSummaryCash(null);
            }}
          >
            {t('actions.cancel')}
          </Button>,
        ]}
      >
        <Row>
          <Col>Plata cu card</Col>
        </Row>
        <Table
          dataSource={summaryCard}
          columns={sumaryColumns}
          pagination={false}
        />
        <Row>
          <Col>Plata cash</Col>
        </Row>
        <Table
          dataSource={summaryCash}
          columns={sumaryColumns}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default TVMSalesDashboard;
