import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Tooltip,
  Upload,
} from 'antd';

import {
  SettingOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import FormWrapper from '../FormWrapper';
import DeleteItemIcon from '../buttons/DeleteItemIcon';
import useDictionaries from '../../hooks/useDictionaries';
import './OrderFileList.scss';
import { getProducts } from '../../services/offers/products';
import { FormContext } from '../../hooks/useForm';
import ProductUsersList from './ProductUsersList';
import { csvToJson } from '../../helpers/csv';
import { getUsersWithExistingCards } from '../../services/admin/users';
import { getRolesWithDetails } from '../../services/admin/roles';
import { getUserTypes } from '../../services/taxonomies/user-types';
import POSButton from '../pos2/POSButton';
import Div from '../pos2/Div';
import Restriction from './Restriction';
import { BULK_TYPES } from '../../pages/orders/constants';
import { getSubdivisions } from '../../services/taxonomies/subdivisions';
import SearchInstitutions from './SearchInstituions';
import DatePickerLocale from '../DatePickerLocale';

const dictionaries = {
  productTypes: getProducts,
  roles: getRolesWithDetails,
  userTypes: getUserTypes,
};

const Products = ({ status, permisions }) => {
  const form = useContext(FormContext);
  const [configForm] = Form.useForm();
  const { t } = useTranslation();
  const [cardProduct, setCardProduct] = useState(null);
  const [categoryProduct, setCategoryProduct] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { select, custom, value, setValue } = form;
  const { products = [], disabled, bulkType } = value;
  const notStorableProducts =
    products && products.length > 1
      ? products.filter((product) => product.productCode !== 'MIFARE_1K')
      : products;
  const showCardProduct = !(
    products.length === 1 && products.find((p) => p.productId === 114)
  );
  let requiredFields;
  let omittedFields;

  switch (bulkType) {
    case BULK_TYPES.ISSUING_CARDS:
      requiredFields = ['fullname', 'identityCard'];
      omittedFields = [
        'email',
        'fullname',
        'identityCard',
        'employeeNumber',
        'metaData1',
        'metaData2',
        'phone',
        'subdivisionId',
      ];
      break;
    case BULK_TYPES.RECHARGE_CARDS:
      requiredFields = ['cardLogicalId'];
      omittedFields = [
        'email',
        'cardLogicalId',
        'fullname',
        'metaData1',
        'metaData2',
        'subdivisionId',
      ];
      break;
    default:
      requiredFields = null;
  }

  if (!products[0]) {
    products.push({ productPersons: [] });
  }

  const [{ productTypes, roles, userTypes }] = useDictionaries(dictionaries);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const sortedRoles = roles?.content
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  dayjs.extend(customParseFormat);

  useEffect(() => {
    if (!cardProduct) {
      getProducts({
        criterias: {
          code: 'MIFARE_1K',
          dateEnd: moment().toISOString(),
        },
      }).then((data) => {
        setCardProduct(data.content[0]);
      });
    }
  }, [cardProduct, products, setCardProduct]);

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current < moment() ||
      Date.parse(current) > Date.parse(moment().add(90, 'days'))
    );
  };

  const configureProduct = useCallback(
    (product, idx) => {
      const originalProduct = productTypes?.content.find(
        ({ id }) => id === product?.productId,
      );
      setCategoryProduct(originalProduct.category);
      setSelectedProduct(originalProduct);

      configForm.setFieldsValue({
        product: {
          ...product,
          count:
            (originalProduct.category === 'PURSE'
              ? product.count / 100
              : product.count) || 1,
          startDate: product.startDate && moment(product.startDate),
        },
        idx,
        originalProduct,
      });
      setIsModalVisible(true);
    },
    [configForm, productTypes],
  );

  const onRemove = useCallback(
    async (record) =>
      setValue(
        'products',
        products.filter((product) =>
          record.key ? product.key !== record.key : product.id !== record.id,
        ),
      ),
    [products, setValue],
  );

  const getSubdivisionsByInstitutionId = useCallback((institutionId) => {
    if (institutionId) {
      return getSubdivisions({
        pageSize: 1000,
        criterias: { institutionId },
      }).then((res) => {
        return Promise.resolve(res.content);
      });
    }
    return [];
  }, []);

  const getUserTypeId = useCallback(
    (productId) => {
      return productTypes?.content?.find((pt) => pt.id === productId)
        ?.userTypeId;
    },
    [productTypes.content],
  );

  const getProductCode = useCallback(
    (productId) => {
      return productTypes?.content?.find((pt) => pt.id === productId)?.code;
    },
    [productTypes.content],
  );

  const getProductCategory = useCallback(
    (productId) => {
      return productTypes?.content?.find((pt) => pt.id === productId)?.category;
    },
    [productTypes.content],
  );

  const getCSVData = useCallback(
    async (idx, file, expectedFields, excludedFields) => {
      const createErrorMessage = (validationResults) => {
        const { missingFields, extraFields } = validationResults;
        const missingFieldsMsg =
          missingFields.length > 0 && missingFields.every(Boolean)
            ? `${t('errors.missingFields')}: ${missingFields.join(', ')}`
            : '';
        const extraFieldsMsg =
          extraFields.length > 0 && extraFields.every(Boolean)
            ? `${missingFieldsMsg ? `${t('conjunctions.and')} ` : ''}${
                missingFieldsMsg
                  ? t('errors.extraFields').toLowerCase()
                  : t('errors.extraFields')
              }: ${extraFields.join(', ')}`
            : '';

        return `${missingFieldsMsg} ${extraFieldsMsg}`;
      };

      const validateCSVFields = (csvData) => {
        const actualFields = csvData.length > 0 ? Object.keys(csvData[0]) : [];
        const missingFields = expectedFields.filter(
          (field) => !actualFields.includes(field),
        );

        const extraFields = actualFields.filter(
          (field) => !excludedFields.includes(field),
        );

        return { missingFields, extraFields };
      };

      // Simple verification that the file is a CSV
      if (file.type !== 'text/csv') {
        notification.error({
          message: t('errors.invalidFileType'),
        });
        return Promise.resolve(null);
      }

      const { productPersons = [], institutionId } = products[idx];
      const userTypeId = getUserTypeId(products[idx].productId);
      const subdivisions = await getSubdivisionsByInstitutionId(institutionId);

      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = csvToJson(e.target.result);

        csvData.forEach((pers, index, array) => {
          const matchingSubdivision = subdivisions.find(
            (s) => s.name === pers.subdivisionId,
          );
          if (matchingSubdivision) {
            // eslint-disable-next-line no-param-reassign
            array[index] = { ...pers, subdivisionId: matchingSubdivision.id };
          }
        });

        const validationResults = validateCSVFields(csvData);

        if (!validationResults) {
          notification.error({
            message: t('errors.emptyFile'),
          });
          return;
        }

        const { missingFields, extraFields } = validationResults;
        if (missingFields.length > 0 || extraFields.length > 0) {
          notification.error({
            message: t('errors.csv'),
            description: createErrorMessage(validationResults),
          });
          return;
        }

        const mailList = csvData.map((pers) => pers.email);
        getUsersWithExistingCards(mailList).then((emails) => {
          if (emails.some((mail) => mail !== null && mail !== '')) {
            notification.warning({
              message: `Exista deja carduri pentru urmatoarele adrese mail : ${emails.join(
                '',
              )}`,
            });
          }
        });

        const validSubinstNames = (subdivisions || []).map((sdv) => sdv.name);
        const usersSubinsts =
          csvData.map((pers) => pers.subinstitution).filter(Boolean) || [];
        const invalidSubinst =
          usersSubinsts.filter(
            (siName) => !validSubinstNames.includes(siName),
          ) || [];

        if (invalidSubinst.length > 0) {
          notification.warning({
            message: `Subinstitutii invalide : ${invalidSubinst.join(',')}`,
          });
          return;
        }

        setValue(`products[${idx}].productPersons`, [
          ...productPersons,
          ...csvData.map((p) => ({
            person: {
              ...p,
              email: p.email === '' ? null : p.email,
              foreignCitizen: p.foreignCitizen === 'TRUE',
              userTypeId,
              key: Math.random(),
            },
            key: Math.random(),
          })),
        ]);
      };

      reader.readAsText(file);
      return Upload.LIST_IGNORE;
    },
    [t, products, setValue, getSubdivisionsByInstitutionId, getUserTypeId],
  );

  const addUser = useCallback(
    (index) => {
      const { productPersons = [] } = products[index];
      const userTypeId = getUserTypeId(products[index].productId);
      setValue(`products[${index}].productPersons`, [
        ...productPersons,
        {
          person: {
            key: Math.random(),
            userTypeId,
          },
          key: Math.random(),
        },
      ]);
    },
    [setValue, products, getUserTypeId],
  );

  return (
    <>
      {/* <PageTitle title={t('entity.orders.productList._plural')}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          disabled={disabled}
          onClick={() => {
            setValue('products', [...products, { key: Math.random() }]);
          }}
        >
          {t('entity.orders.add')}
        </Button>
        </PageTitle> */}
      {notStorableProducts.map((product, idx) => (
        <>
          <FormWrapper key={product.key || product.id}>
            <FormWrapper.Single>
              <FormWrapper.StaticSearch
                label={t('entity.orders.product')}
                props={{
                  disabled,
                  options: productTypes.content.map((type) => ({
                    ...type,
                    disabled: products.find((p) => p.productId === type.id),
                  })),
                  ...select(`products[${idx}].productId`),
                  onChange: (productId) => {
                    const userTypeId = getUserTypeId(productId);
                    const productCode = getProductCode(productId);
                    const productCategory = getProductCategory(productId);
                    const productPersons = value?.products[
                      idx
                    ]?.productPersons?.map((pp) => ({
                      ...pp,
                      person: {
                        ...pp.person,
                        subdivisionId: null,
                        userTypeId,
                      },
                    }));
                    setValue(`products[${idx}]`, {
                      ...products[idx],
                      productId,
                      productPersons,
                      productCode,
                      productCategory,
                      count: null,
                      institutionId: null,
                      startDate: null,
                      lines: null,
                      lineGroups: null,
                    });
                  },
                }}
              />
            </FormWrapper.Single>
            {[BULK_TYPES.ISSUING_CARDS, BULK_TYPES.RECHARGE_CARDS].includes(
              bulkType,
            ) && (
              <Col span={12}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                  {bulkType === BULK_TYPES.ISSUING_CARDS ? (
                    <FormWrapper.Single span={8}>
                      {showCardProduct && cardProduct ? (
                        <FormWrapper.Input
                          label={t('entity.orders.card')}
                          props={{
                            disabled: true,
                            value: cardProduct.name,
                          }}
                        />
                      ) : null}
                    </FormWrapper.Single>
                  ) : null}
                  <Col span={bulkType === BULK_TYPES.ISSUING_CARDS ? 8 : 12}>
                    <FormWrapper.Select
                      label={t('entity.orders.role')}
                      props={{
                        disabled,
                        options: sortedRoles,
                        ...select(`products[${idx}].roleId`),
                      }}
                    />
                  </Col>
                  <Col span={bulkType === BULK_TYPES.ISSUING_CARDS ? 8 : 12}>
                    <SearchInstitutions
                      label={t('entity.admin.user.institutionId')}
                      props={{
                        disabled,
                        searchField: 'name',
                        field: 'id',
                        resetSubdivisions: () =>
                          setValue(`products[${idx}]`, {
                            ...products[idx],
                            productPersons: products[idx].productPersons?.map(
                              (pers) => ({
                                ...pers,
                                subdivisionId: null,
                              }),
                            ),
                          }),
                        userTypes,
                        userTypeId: getUserTypeId(products[idx].productId),
                        ...custom(`products[${idx}].institutionId`),
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={1}>
              <div className="ant-typography" />
              <Tooltip title={t('entity.orders.configureProduct')}>
                <Button
                  disabled={disabled}
                  shape="circle"
                  type="link"
                  icon={<SettingOutlined style={{ fontSize: '150%' }} />}
                  onClick={() => configureProduct(product, idx)}
                />
              </Tooltip>
            </Col>
            <Col span={1}>
              <div className="ant-typography" />
              <Tooltip title={t('entity.orders.uploadPersons')}>
                <Upload
                  size="small"
                  dragger={false}
                  beforeUpload={(file) =>
                    getCSVData(idx, file, requiredFields, omittedFields)
                  }
                  multiple={false}
                  accept=".csv"
                  disabled={disabled || !products[idx].productId}
                >
                  <Button
                    icon={
                      <UploadOutlined
                        style={{ fontSize: '150%', color: '#08c' }}
                      />
                    }
                  />
                </Upload>
              </Tooltip>
            </Col>
            <Col span={1}>
              <div className="ant-typography" />
              <Tooltip title={t('entity.orders.addPerson')}>
                <Button
                  disabled={disabled}
                  shape="circle"
                  type="link"
                  icon={<UsergroupAddOutlined style={{ fontSize: '150%' }} />}
                  onClick={() => addUser(idx)}
                />
              </Tooltip>
            </Col>
            <Col span={1}>
              <div className="ant-typography" />
              <DeleteItemIcon
                title={t('entity.orders.productList._delete', product.id)}
                message={t('entity.orders.productList._deleted', product.id)}
                item={product}
                disabled={disabled}
                action={onRemove}
                reload={() => null}
                size="large"
              />
            </Col>
          </FormWrapper>
          <Row>
            <Col span={24}>
              {product.productPersons && product.productPersons.length > 0 && (
                <ProductUsersList
                  index={idx}
                  disabled={disabled}
                  institutionId={products[idx].institutionId}
                  status={status}
                  bulkType={bulkType}
                  permisions={permisions}
                  fields={
                    bulkType === BULK_TYPES.ISSUING_CARDS
                      ? [
                          'email',
                          'fullname',
                          'foreignCitizen',
                          'identityCard',
                          'phone',
                          'employeeNumber',
                          'metaData1',
                          'subdivisionId',
                        ]
                      : [
                          'cardLogicalId',
                          'email',
                          'fullname',
                          'metaData1',
                          'subdivisionId',
                        ]
                  }
                />
              )}
            </Col>
          </Row>
          <Space direction="vertical">
            <Divider dashed />
          </Space>
        </>
      ))}

      <Modal
        title="Configurare"
        visible={isModalVisible}
        onOk={() => {
          configForm
            .validateFields()
            .then((val) => {
              const idx = configForm.getFieldValue('idx');
              const { product } = val;

              if (categoryProduct === 'PURSE') {
                if (
                  selectedProduct?.maxValue &&
                  product.count * 100 > selectedProduct.maxValue
                ) {
                  return;
                }
                product.count *= 100;
              }

              setValue(`products[${idx}]`, {
                ...products[idx],
                ...product,
              });
              setIsModalVisible(false);
            })
            .catch((info) => {
              console.error('Validate Failed:', info);
            });
        }}
        onCancel={() => {
          configForm.resetFields();
          setIsModalVisible(false);
        }}
      >
        <Form form={configForm} layout="vertical">
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.originalProduct?.category !== next.originalProduct?.category
            }
          >
            {({ getFieldValue }) => {
              const categ = getFieldValue(['originalProduct', 'category']);
              return (
                <>
                  <Form.Item name={['originalProduct', 'name']} noStyle>
                    <Div />
                  </Form.Item>
                  {categ && (categ === 'TICKET' || categ === 'PURSE') && (
                    <Row gutter={16}>
                      <Col span={8}>
                        <POSButton
                          title="Scade"
                          onClick={() =>
                            configForm.setFieldsValue({
                              product: {
                                ...configForm.getFieldValue('product'),
                                count: Math.max(
                                  1,
                                  configForm.getFieldValue([
                                    'product',
                                    'count',
                                  ]) - 1,
                                ),
                              },
                            })
                          }
                          color="red"
                          size="xsmall"
                        />
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          noStyle
                          name={['product', 'count']}
                          rules={[
                            {
                              type: 'number',
                              message: t('errors.maxValueExceeded'),
                            },
                          ]}
                        >
                          {categ === 'PURSE' ? (
                            <InputNumber
                              className="account-custom-input-number"
                              min={1}
                              max={
                                getFieldValue(['originalProduct', 'maxValue'])
                                  ? getFieldValue([
                                      'originalProduct',
                                      'maxValue',
                                    ]) / 100
                                  : undefined
                              } // Conditionally add the upper bound
                              defaultValue={1}
                              step={0.01}
                              addonAfter="RON"
                              parser={(purseVal) => purseVal.replace(',', '.')}
                            />
                          ) : (
                            <Input
                              style={{ height: '100%', fontSize: '2rem' }}
                              disabled
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <POSButton
                          title="Adauga"
                          onClick={() =>
                            configForm.setFieldsValue({
                              product: {
                                ...configForm.getFieldValue('product'),
                                count:
                                  configForm.getFieldValue([
                                    'product',
                                    'count',
                                  ]) + 1,
                              },
                            })
                          }
                          color="green"
                          size="xsmall"
                        />
                      </Col>
                    </Row>
                  )}
                </>
              );
            }}
          </Form.Item>

          <Divider dashed />

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => {
              return prev?.product?.productId !== next?.product?.productId;
            }}
          >
            {({ getFieldValue }) => {
              return (
                getFieldValue(['originalProduct', 'validityStartType']) ===
                  'FUTURE' && (
                  <Form.Item
                    name={['product', 'startDate']}
                    label={t('entity.orders.startDateSubscription')}
                    rules={[
                      {
                        required: true,
                        message: t('errors.selectDate'),
                      },
                    ]}
                  >
                    <DatePickerLocale
                      format="DD.MM.YYYY HH:mm"
                      showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
                      style={{ width: '100%' }}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                )
              );
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => {
              return prev?.originalProduct?.id !== next?.originalProduct?.id;
            }}
          >
            {({ getFieldValue }) => {
              const restrictions = getFieldValue([
                'originalProduct',
                'productRestrictions',
              ])?.find(({ restriction }) => restriction === 'LINE_GROUPS');
              return (
                restrictions && (
                  <Restriction form={configForm} restrictions={restrictions} />
                )
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Products;
