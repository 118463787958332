import React from 'react';
import { Button, Card, Col, Row, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const RemoteDevices = ({
  t,
  tvmDetails,
  validator,
  handleInactivateDevice,
  handleActivateDevice,
}) => {
  // Array of devices to render
  const devices = ['POS', 'CASH', 'DISPENSER', 'PRINTER', 'READER'];
  // Array of Environment Variables to render
  const envVars = ['LOG_ERRORS'];
  return (
    <div>
      <Row style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Card title="Dispozitive" extra="">
            {/* Render a row for each device */}
            {devices.map((device, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index} style={{ padding: '2px' }}>
                <Col span={16}>
                  <Tag
                    icon={
                      tvmDetails[`${device.toLowerCase()}Enabled`] ? (
                        <CheckCircleOutlined />
                      ) : (
                        <CloseCircleOutlined />
                      )
                    }
                    color={
                      tvmDetails[`${device.toLowerCase()}Enabled`]
                        ? 'success'
                        : 'error'
                    }
                    style={{ marginRight: 0 }}
                  />{' '}
                  {device}{' '}
                </Col>
                <Col span={4}>
                  {tvmDetails[`${device.toLowerCase()}Reason`] && (
                    <span>{t('entity.tvm.deviceRemoteInactive')}</span>
                  )}
                  {!tvmDetails[`${device.toLowerCase()}Enabled`] &&
                    !tvmDetails[`${device.toLowerCase()}Reason`] && (
                      <span>{t('entity.tvm.deviceInactive')}</span>
                    )}
                </Col>
                {tvmDetails[`${device.toLowerCase()}Enabled`] && (
                  <Col span={4}>
                    <Button
                      type="primary"
                      onClick={() =>
                        handleInactivateDevice(validator.id, device)
                      }
                      size="midle"
                    >
                      {t('entity.tvm.actions.inactivate._')}
                    </Button>
                  </Col>
                )}
                {tvmDetails[`${device.toLowerCase()}Reason`] && (
                  <Col span={4}>
                    <Button
                      type="primary"
                      onClick={() => handleActivateDevice(validator.id, device)}
                      size="midle"
                    >
                      {t('entity.tvm.actions.activate._')}
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Card>
            {/* Render a row for each envVar */}
            {envVars.map((envVar, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index} style={{ padding: '2px' }}>
                <Col span={16}>
                  <Tag
                    icon={
                      tvmDetails[
                        `${envVar
                          .toLowerCase()
                          .replace(/_([a-z])/g, (_, group1) =>
                            group1.toUpperCase(),
                          )}Enabled`
                      ] ? (
                        <CheckCircleOutlined />
                      ) : (
                        <CloseCircleOutlined />
                      )
                    }
                    color={
                      tvmDetails[
                        `${envVar
                          .toLowerCase()
                          .replace(/_([a-z])/g, (_, group1) =>
                            group1.toUpperCase(),
                          )}Enabled`
                      ]
                        ? 'success'
                        : 'error'
                    }
                    style={{ marginRight: 0 }}
                  />{' '}
                  {envVar}{' '}
                </Col>
                <Col span={4}>
                  {tvmDetails[
                    `${envVar
                      .toLowerCase()
                      .replace(/_([a-z])/g, (_, group1) =>
                        group1.toUpperCase(),
                      )}Reason`
                  ] && <span>{t('entity.tvm.deviceRemoteInactive')}</span>}
                  {!tvmDetails[
                    `${envVar
                      .toLowerCase()
                      .replace(/_([a-z])/g, (_, group1) =>
                        group1.toUpperCase(),
                      )}Enabled`
                  ] === true && <span>{t('entity.tvm.deviceInactive')}</span>}
                </Col>
                {tvmDetails[
                  `${envVar
                    .toLowerCase()
                    .replace(/_([a-z])/g, (_, group1) =>
                      group1.toUpperCase(),
                    )}Enabled`
                ] === true && (
                  <Col span={4}>
                    <Button
                      type="primary"
                      onClick={() =>
                        handleInactivateDevice(validator.id, envVar)
                      }
                      size="midle"
                    >
                      {t('entity.tvm.actions.inactivate._')}
                    </Button>
                  </Col>
                )}
                {tvmDetails[
                  `${envVar
                    .toLowerCase()
                    .replace(/_([a-z])/g, (_, group1) =>
                      group1.toUpperCase(),
                    )}Enabled`
                ] === false && (
                  <Col span={4}>
                    <Button
                      type="primary"
                      onClick={() => handleActivateDevice(validator.id, envVar)}
                      size="midle"
                    >
                      {t('entity.tvm.actions.activate._')}
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RemoteDevices;
