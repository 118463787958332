import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import useDatasource from '../../hooks/useDatasource';
import { FormContext } from '../../hooks/useForm';
import Column from '../../helpers/columns';
import { getValidatorsRetailerData } from '../../services/validators/validators-status';

const RetailerTvmList = () => {
  const form = useContext(FormContext);
  const [t] = useTranslation();

  const { value, setValue } = form;

  const { id } = value;

  const { pagination, content, handleChange } = useDatasource((args) => {
    return getValidatorsRetailerData({
      ...args,
      criterias: {
        ...args.criterias,
        retailerId: id,
        nullable: true,
        equipmentType: 'TVM',
      },
    });
  });

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.taxonomy.tvm.id'), { filter: true }),
      Column.text('serialNr', t('entity.taxonomy.tvm.serialNr')),
      Column.text('name', t('entity.taxonomy.tvm.name')),
      Column.text('status', t('entity.taxonomy.tvm.status')),
      Column.bool('deleted', t('entity.taxonomy.tvm.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.tvm.deleted.false'),
          t('entity.taxonomy.tvm.deleted.true'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      onSelect: (record, selected) =>
        selected
          ? setValue('tvms', [...(value.tvms || []), record.id])
          : setValue(
              'tvms',
              value.tvms.filter((tvm) => tvm !== record.id),
            ),
      selectedRowKeys: value.tvms || [],
      getCheckboxProps: (record) => ({
        disabled: record.retailerId !== null && record.retailerId !== id,
      }),
    }),
    [value, setValue, id],
  );

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns}
      rowKey="id"
      // loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
    />
  );
};

export default RetailerTvmList;
