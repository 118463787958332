import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';

import arrayMove from 'array-move';
import PageTitle from '../../PageTitle';

import Column from '../../../helpers/columns';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';

import useDictionaries from '../../../hooks/useDictionaries';
import { getOrderedProducts } from '../../../services/offers/products';
import DragSortingTable from '../../DragSortingTable';
import { ProductCategories } from '../OffersConstants';

const dictionaries = {
  products: getOrderedProducts,
};
const EditProductPrioritiesForm = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const [{ products }] = useDictionaries(dictionaries);

  const [content, setContent] = useState([]);

  useEffect(() => {
    setContent(
      products.content.map((product, index) => ({ index, ...product })),
    );
  }, [products]);

  const columns = useMemo(
    () => [
      Column.label('id', t('entity.offers.product.id'), { width: 100 }),
      Column.text('name', t('entity.offers.product.name._'), { filter: true }),
      Column.text('price', t('entity.offers.product.price')),
      Column.dictionary(
        'category',
        t('entity.offers.product.category._'),
        ProductCategories,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) => t(`entity.offers.product.category.${entry.id}`),
        },
      ),
      Column.text('position', t('entity.offers.productPriority.singular')),
    ],
    [t],
  );

  const moveRow = useCallback(
    (oldIndex, newIndex) =>
      setContent(
        arrayMove(content, oldIndex, newIndex).map((row, idx) => ({
          ...row,
          position: idx + 2,
        })),
      ),
    [content],
  );

  return (
    <>
      <PageTitle title={t('entity.offers.priorities.description')} />
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          return onSubmit(
            content.reduce(
              (acc, cur, idx) => ({ ...acc, [cur.id]: idx + 2 }),
              {},
            ),
          );
        }}
      >
        <DragSortingTable
          rowKey="position"
          moveRow={moveRow}
          columns={columns}
          dataSource={content}
          // loading={products.loading}
          pagination={false}
        />
        <Space>
          <SaveButton />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </>
  );
};

export default EditProductPrioritiesForm;
