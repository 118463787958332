import { Table } from 'antd';
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';

import { Default } from '../../layouts';
import { getBlacklist } from '../../services/accounts/accounts';
import { findAll } from '../../services/taxonomies/blacklist-reason';
import useDictionaries from '../../hooks/useDictionaries';
import { CustomerTypes } from '../../components/users/constants';

const CARD_CATEGORIES = {
  content: [
    { id: 1, name: 'UID' },
    { id: 2, name: 'PAN' },
    { id: 4, name: 'QR' },
  ],
};

const dictionaries = {
  blacklistReasons: findAll,
};

const AccountsList = () => {
  const [t] = useTranslation();
  const [{ blacklistReasons }] = useDictionaries(dictionaries);

  const { pagination, content, handleChange } = useDatasource(getBlacklist);

  const columns = useMemo(
    () => [
      Column.text('userName', t('entity.account.blacklist.fullname'), {
        sort: false,
        width: 50,
        filter: true,
      }),
      Column.label('userIdentifier', t('entity.account.blacklist.identifier'), {
        sort: false,
        width: 25,
        filter: true,
      }),
      Column.text(
        'customerType',
        t('entity.account.blacklist.accountType'),
        CustomerTypes,
        {
          filter: true,
          width: 75,
          dictLabel: ({ id }) => t(`entity.account.type.${id}`),
        },
      ),
      Column.bool('deleted', t('entity.account.blacklist.status'), {
        width: 75,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('common.deleted.true'),
          t('common.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.text(
        'logicIdSerial',
        t('entity.account.blacklist.logicIdSerial'),
        {
          width: 50,
          filter: true,
        },
      ),
      Column.dictionary(
        'cardTypeId',
        t('entity.account.blacklist.cardCategory'),
        CARD_CATEGORIES,
        { filter: true },
      ),
      Column.dictionary(
        'blacklistReasonId',
        t('entity.account.blacklist.blacklistReason'),
        blacklistReasons,
        { filter: true },
      ),
    ],
    [blacklistReasons, t],
  );

  return (
    <Default>
      <PageTitle title={t('entity.account.blacklist._')} />
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </Default>
  );
};

export default AccountsList;
